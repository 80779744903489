import React from 'react'
import * as translator from "../../utils/translator";
import "./BonusBanner.css"


const BonusBanner = () => {
    let language = 'de'
    const LANGS = ['de', 'fr', 'en']
    LANGS.forEach((element:string) => {
        let url = window.location.href;
        let LANG = new RegExp(`/${element}/|=(${element})`);
        if (LANG.test(url)){language = element; return}
    });

    const urlParams = new URLSearchParams(window.location.search);
    let deeplinkCoupon = urlParams.get('cpn');
    if (deeplinkCoupon) {
        sessionStorage.setItem('cpn', deeplinkCoupon);
    }else{
        deeplinkCoupon = sessionStorage.getItem('cpn');
    }

    if (window.location.href.includes("shipping_new")) {
        return <></>
    }


    return (
        <>
            {deeplinkCoupon &&
                <div className='bonus-banner'>{translator.translate(language,"default","BonusBanner/text")}</div>
            }
        </>
    )
}

export default BonusBanner