import React from 'react';
import HeaderController from '../components/Headers/HeaderController';
import ServerError from '../components/ServerError/ServerError';
import LoadingModal from '../components/Modals/LoadingModal/LoadingModal';
import StickyNotification from '../components/StickyNotification/StickyNotification';
import Footer from '../components/Footer/Footer';
import { useAppContext } from '../providers/AppProvider';

type Props = {
    children: React.ReactNode;
    showHeaderSteps?: boolean;
    showFooter?: boolean;
};

const FunnelLayout: React.FC<Props> = ({ children, showHeaderSteps = true, showFooter = true }) => {
    // Get the state and functions from AppContext
    const {
        isLoading,
        errorStatus,
        stickyNotification,
        setStickyNotification
    } = useAppContext();


    // Error 
    if (errorStatus) {
        return (
            <>
                <HeaderController showSteps={false} stage={"Questionnaire"} />
                <ServerError status={errorStatus} />
            </>
        );
    }

    // Children 
    return (
        <>
            <StickyNotification
                showNotification={stickyNotification.show}
                title={stickyNotification.title}
                content={stickyNotification.content}
                level={stickyNotification.level}
                onClick={() => setStickyNotification({ ...stickyNotification, show: false })}
                showInfoIcon={stickyNotification.showInfoIcon}
                refresh={stickyNotification.refresh}
            />

            {isLoading && <LoadingModal />}
            
            <main id="page-container" className="relative flex flex-col min-h-screen">
                <HeaderController showSteps={showHeaderSteps} stage={"Payment"} />
                <section id="page-content-container" className={`${errorStatus ? 'bg-white' : 'bg-[#F7F7F7]'} flex-1 flex justify-center`}>
                    <div id="page-content" className="flex flex-col items-center w-full pt-[30px] md:pt-[60px] pb-[90px] px-[15px]">
                        {children}
                        
                        {/* Footer  */}
                        <div id='page-footer' className='mt-8 mb-8 md:mb-0 w-full'>
                            {!isLoading && showFooter ? <Footer page="Shipping" language={"de"} /> : null}
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default FunnelLayout;
