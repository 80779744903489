import { useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as http from "../../utils/http";
import { MainTracker } from '../../PixelTrackers/MainTracker';
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";

const FLOWZZ_PRESCRIPTION_GENERATOR_PAGE = "https://flowzz.com/rezept-konfigurator";

const CanceledFlowzzOrderPage = () => {
    const { action_token, customer_choice } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        http.postToServer(`canceled-flowzz-order/${action_token}`)
            .then(() => {
                MainTracker.superProperties({
                    "Customer's response after flowzz order rejection": customer_choice,
                });
                MainTracker.track("custom", "Canceled_Flowzz_Order_customer_made_alternative _choice", { customer_choice });
                window.eventsTracking.track({e: `Flowzz_Order_made_alternative _choice_${customer_choice}_success`});

            })
            .catch((error) => {
                const errorMessage = error.response.data?.alert?.message;
                MainTracker.track("custom", "Canceled_Flowzz_Order_customer_made_alternative _choice", { customer_choice, server_error: errorMessage });
                window.eventsTracking.track({e: `Flowzz_Order_made_alternative _choice_${customer_choice}_failure`});
            })
            .finally(() => {
                switch (customer_choice) {
                    // redirect to flowzz     
                    case "continue_on_flowzz":
                        window.location.href = FLOWZZ_PRESCRIPTION_GENERATOR_PAGE;
                        break;
                    // choose treatment for cannabis     
                    case "start_new_premium_order":
                        navigate("/select_treatment/de/de/medical_cannabis");
                        break;
                    // 404
                    default:
                        navigate("/");
                        break;
                }
            });
    }, []);

    return (
        <LoadingModal/>
    )
}

export default CanceledFlowzzOrderPage;