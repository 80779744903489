import "./LoginModal.css";
import { useState } from "react";
import SubmitButton from "../../SubmitButton/SubmitButton";
import InputField from "../../CredentialsInput/InputField";
import Logo from "../../../images/logo-symbol.svg";
import xIcon from "../../../../src/icons/X.svg";
import { useCustomerContext } from "../../../reducers/CustomerReducer";
import * as translator from "../../../utils/translator";
import * as http from "../../../utils/http";
import useForm from "../../../customHooks/useForm";

export default function LoginModal({
  closeModal,
  setStickyNotificationDetails,
  navigationCallback,
  showForgotPasswordOption = true,
  changeShowForgotPassword,
  closeable = true,
  language,
  message=""
}) {
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const { customerDispatch } = useCustomerContext();

  const loginFormAttributes = useForm({ email: "", loginPassword: "" }, language);

  const onSubmit = async (e) => {

    e.preventDefault();

    setSubmitButtonLoading(true);

    if (loginFormAttributes.validateForm()) {
      await http
        .postToServer(`login`, loginFormAttributes.form,[http.MIDDLEWARES.CART_ORDER])
        .then((response) => {
          if (response.data.status == "ok") {
            customerDispatch({
              type: "logged_in",
              customerID: response.data.customerID,
              uuid: response.data.uuid,
              rememberToken: response.data.rememberToken,
              email: loginFormAttributes.form["email"],
              optForSendables: response.data.optForSendables,
              customerFullName: response.data.customerFullName
            });
            const exceededCategoryLimit = response.data.exceededCategoryLimit
            const cameFromMention = response.data.cameFromMention;
            closeModal();
            navigationCallback(exceededCategoryLimit, cameFromMention);
          } else {
            setStickyNotificationDetails((currentDetails) => ({
              show: true,
              title: translator.translate(
                language,
                "default",
                "StickyNotification/login_failed"
              ),
              content:translator.translate(
                language,
                "default",
                "StickyNotification/login_failed"
              ),
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }));
            closeModal();
          }
        })
        .catch((error) => {

          let stickyContent = translator.translate(
            language,
            "default",
            "StickyNotification/danger_title"
          )

          if (error.response.data.status === 'user_blocked') {
            stickyContent = translator.translate(language,"default",`StickyNotification/user_blocked`)
          }

          setStickyNotificationDetails((currentDetails) => ({
            show: true,
            title: stickyContent,
            content: "Server Failed",
            level : "error",
            showInfoIcon:true,
            refresh: !currentDetails.refresh
          }));

          window.clientLogger.error("Loggin failed", error);
        });

    }

    setSubmitButtonLoading(false);
  };

  return (
    <>
      <div
        className="loginModal-background"
        onClick={(event) => {
          closeModal();
        }}
      >
        {/*event.stopPropagation => so it won't close the modal when clicking on form fields */}
        <form
          className="loginModal"
          onClick={(event) => event.stopPropagation()}
        >
          {closeable && (
            <img
              onClick={() => closeModal()}
              className="loginModal-x"
              src={xIcon}
              alt="x"
            />
          )}
          <img className="loginModal-logo" src={Logo} alt="Logo" />
          <p className="loginModal-title">
            {translator.translate(
              language,
              "default",
              "LoginQuestion/login_title"
            )}
          </p>
          {message.length > 0 && <div className="loginModal-message">
            <p>
              {message}
            </p>
          </div>}
          <InputField
            id="email"
            value={loginFormAttributes.form["email"]}
            errorMsg={loginFormAttributes.errorMessagePerField["email"]}
            onChange={(value) => {
              loginFormAttributes.updateForm("email", value);
            }}
            type="email"
            placeholder={"E-Mail"}
            language={language}
            mandatory
            onBlur={() => loginFormAttributes.onFieldBlur("email")}
            touched={loginFormAttributes.touched.email}
          />
          <InputField
            id="loginPassword"
            value={loginFormAttributes.form["loginPassword"]}
            errorMsg={loginFormAttributes.errorMessagePerField["loginPassword"]}
            onChange={(value) => {
              loginFormAttributes.updateForm("loginPassword", value);
            }}
            type="password"
            placeholder={translator.translate(
              language,
              "default",
              "LoginQuestion/password"
            )}
            language={language}
            mandatory
            onBlur={() => loginFormAttributes.onFieldBlur("loginPassword")}
            touched={loginFormAttributes.touched.loginPassword}
          />

          <SubmitButton
            id={"submit-button"}

            loading={submitButtonLoading}
            onSubmit={onSubmit}
            width={"100%"}
            language={language}
            placeholder={translator.translate(
              language,
              "default",
              "LoginQuestion/login_and_continue"
            )}
          />

          {showForgotPasswordOption && (
            <p
              onClick={() => {
                closeModal();
                changeShowForgotPassword && changeShowForgotPassword(true);
              }}
              className="loginquestion-forgot-password"
            >
              {translator.translate(
                language,
                "default",
                "LoginQuestion/forgot_password"
              )}
            </p>
          )}
          <input type="submit" hidden />
        </form>
      </div>
    </>
  );
}
