import { useReducer, createContext, useState, useContext } from "react";
import PagesRouter from "./router/PagesRouter"
import { CustomerContext, CustomerReducer, loadCustomerFromSession } from './reducers/CustomerReducer'
import Cookiebot from "./components/Cookiebot/Cookiebot";
import UnderMaintenance from "./pages/UnderMaintenance/UnderMaintenance";
import { clientConfig } from "./config";
import BonusBanner from "./components/BonusBanner/BonusBanner";
import { CartProvider } from "./customHooks/useCart";
import "./App.css"
import { AppProvider } from "./providers/AppProvider";

export const LocationContext = createContext();

export function useLocationContext() {
  return useContext(LocationContext);
}

function App() {

  const [customerState, customerDispatch] = useReducer(CustomerReducer, loadCustomerFromSession());
  const [location, setLocation] = useState(null)


  const locationProviderState = {
    location,
    setLocation
  }

  const customerProviderState = {
    customerState,
    customerDispatch
  }



  if (clientConfig.IS_UNDER_MAINTENANCE === "true") {
    return <UnderMaintenance />

  } else {
    return (
        <AppProvider>
          <CartProvider>
            <CustomerContext.Provider value={customerProviderState}>
              <LocationContext.Provider value={locationProviderState}>

                {/* Modal Root */}
                <div id="modal-root" />
                <BonusBanner />
                <PagesRouter>
                </PagesRouter>
                <Cookiebot />
              </LocationContext.Provider>
            </CustomerContext.Provider>
          </CartProvider>
        </AppProvider>
    );
  }


}

export default App;
