import { useContext } from 'react';
import ProductAvailabilityTag from './ProductAvailabilityTag'
import { ChooseTreatmentContext } from '../FreeDosageLayoutV2';
import { RouterCartContext } from '../../ChooseTreatmentRouter';

const Legend = () => {
  const chooseTreatmentContext = useContext(ChooseTreatmentContext);
  const routerCartContext:any = useContext(RouterCartContext)
  const cart = routerCartContext.cartData
  const products = chooseTreatmentContext?.products;

  return (
    <div id='legend' className='flex flex-col md:flex-row gap-2 md:gap-10 justify-center text-[#0D2C54] font-medium'>
      {
        products && products.filter((product) => product.in_stock).length > 0 &&
        <div className='flex items-center'>
          <ProductAvailabilityTag inStock={true} />
          <p className='ml-1 text-xs'>Medikament + Rezept + {cart?.self_pickup_pharmacy_id ? "Selbstabholung" : "Lieferung" }</p>
        </div>
      }

      {
        products && products.filter((product) => !product.in_stock).length > 0 &&
        <div className='flex items-center'>
          <ProductAvailabilityTag inStock={false} />
          <p className='ml-1 text-xs'>Nur Rezept</p>
        </div>
      }
    </div>
  )
}

export default Legend
