import CMSModal from './CMSModal';
import Sprite from "../../images/StartConsultation/sprite-main.png";


type Pharmacy =  {
    name: string
    address: string
}

export type PharmaciesModalInfo = {
	title: string;
	triggerText: string;
	pharmacies: Pharmacy[]
}
type Props = {
	pharmaciesModalInfo: PharmaciesModalInfo
};

const MailOrderPharmaciesModalTrigger: React.FC<Props> = ({pharmaciesModalInfo}) => {

	let content = (  
		<div className="max-h[244px]">
		{pharmaciesModalInfo?.pharmacies?.map((pharmacy,index) => (
			<div key={index} className="popup-info">
				<strong className="name">{pharmacy.name}</strong>
				<p className="address">{pharmacy.address}</p>
			</div>
		))}
	
	</div>
	);
	return (
		<CMSModal
			title={pharmaciesModalInfo.title}
			className="max-w-[530px] max-h-[550px]"
			centerTitle={true}
			content={content}
			trigger={
				<a id="open-modal-doctors" href="#" className="relative text-[#656d79] no-underline group">
					{pharmaciesModalInfo.triggerText}
					<span className="absolute left-0 bottom-0 h-[1px] w-0 bg-[#656d79] transition-all duration-500 ease-in-out group-hover:w-full" />
				</a>
			}
			image={<img
				src={Sprite}
				className="mb-4 w-[63px] h-[65px] object-none object-[-815px_-137px]"
				alt="Sprite"
			/>}
		/>
	);
};

export default MailOrderPharmaciesModalTrigger;
