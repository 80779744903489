import React from 'react';
import "./OrderSummary.css";
import { translate } from '../../utils/translator';
import { useParams } from 'react-router-dom';

type OrderSummaryType = {
    rxProductFinalPrice: number; //Rx = prescription medication drug
    otcAddonsTotalPrice:number
    currencySign: string //countryCurrencySign ($)
    otcDiscount?:number
}


const OrderSummary:React.FC<OrderSummaryType> = ({
  rxProductFinalPrice,
  otcAddonsTotalPrice,
  currencySign,
  otcDiscount = 0
}) => {
  const { language } = useParams();

  return (
    <>
    <div className='order-summary-container'>
      <p className="order-summary-title">Ihre Bestellung</p>
      <div className='order-summary-container-row'>
       {/* //TODO:translations for FR*/}
        <span>{translate(language,"default","OrderSummary/prescription_product_price")}</span> 
        {/* received multiplied by currency rate already */}
        <span>{`${(rxProductFinalPrice).toFixed(2)} ${currencySign}`}</span> 
      </div>

      <div className='order-summary-container-row'>
        <span>{translate(language,"default","OrderSummary/otc_addons_price")}</span>
        <span>{`${(otcAddonsTotalPrice).toFixed(2)} ${currencySign}`}</span>
      </div>
      {otcDiscount > 0 &&
        <div className="orderWindow-price-row">
          <p>Coupon</p>
          <p>{`-${(otcDiscount).toFixed(2)} ${currencySign}`}</p>
        </div>
      }

      <div className='order-summary-container-total-row'>
        <span> {translate(language,"default","OrderSummary/total_price")}</span>
        <span id='total-price'>{`${(rxProductFinalPrice + otcAddonsTotalPrice - otcDiscount).toFixed(2)} ${currencySign}`}</span>
      </div>

    </div>

      {
        otcAddonsTotalPrice > 0 ?
        <div className='order-summary-otc-disclaimers-container'>

        <p className='order-summary-otc-disclaimer'>
          {translate(language,"default","OrderSummary/otc_disclaimer1")}
        </p>

        <p className='order-summary-otc-disclaimer'>
          {translate(language,"default","OrderSummary/otc_disclaimer2")}
        </p>
        </div>
        :
        null
      }
    </>
  )
}

export default OrderSummary