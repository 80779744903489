import { useMemo } from "react";
import vCheck from "../../../../icons/vCheck.svg";
import * as translator from "../../../../utils/translator";
import { useCartContext } from "../../../../customHooks/useCart";
import { useShippingPageContext } from "../providers/ShippingPageProvider";


type Props = {
   
}

const CouponWidgetV2: React.FC<Props> = () => {
    const { cartCurrency, getCart } = useCartContext();
    const { language, couponWidgetState, setCouponWidgetState, handleApplyCoupon } = useShippingPageContext();
    const cart = getCart();
    const isCouponConfirmed = useMemo(() => {
        return cart.bonus_value && (cart.bonus_value > 0) && !cart.auto_bonus
    }, [cart.bonus_value])


    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponWidgetState((current) => ({
            ...current,
            inputCode: e.target.value
        }));
    }

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleApplyCoupon(couponWidgetState.inputCode, isCouponConfirmed ? false : true); //If coupon is already confirmed, we dont want to override it
    };

    const handleChangeCouponClick = () => {
        //Reset the state
        setCouponWidgetState((current) => ({
            ...current,
            exposeExtraEnterContainer: !current.exposeExtraEnterContainer,
            inputCode: "",
            errorMsg: "",
        }));
    };


    const enterCouponView = useMemo(() => {
        let errorMessage = couponWidgetState.errorMsg;
        if (couponWidgetState.additionalCouponData?.minimum_price_required > 0) {
            errorMessage = errorMessage?.replace(":order_total_coupon", couponWidgetState.additionalCouponData.minimum_price_required.toString());
        } else if (couponWidgetState.additionalCouponData?.treatment_fee_fixed > 0) {
            errorMessage = errorMessage?.replace(":treatment_fee", couponWidgetState.additionalCouponData.treatment_fee_fixed.toString());
        }
        return (
            <>

                {/* Enter Coupon Container  */}
                <form
                    id="enter-coupon-widget-container"
                    onSubmit={handleOnSubmit}
                    style={couponWidgetState.errorMsg ? { border: "1px solid #ef4747" } : {}}
                    className="relative flex items-center justify-between bg-white h-14 w-full rounded-md px-[20px] pr-[95px] transition-all duration-200"
                >
                    {/* Coupon input field  */}
                    <input
                        id="coupon-widget-input"
                        value={couponWidgetState.inputCode}
                        className="text-[13px] h-full w-full border-none outline-none overflow-hidden pr-6"
                        onChange={handleOnChange}
                        maxLength={25}
                        type={"text"}
                        placeholder={translator.translate(language, "shipping", "CouponWidget/coupon_code_placeholder")}
                    />

                    {/* Submit Button  */}
                    <button
                        id="coupon-widget-submit-button"
                        style={{ border: "2px solid #11DDAC" }}
                        className="absolute flex items-center justify-center w-fit min-w-[67px] text-[12px] font-bold text-[#11DDAC] bg-transparent h-9 rounded-[3px] px-[10px] py-[10px] right-[10px] top-[10px] cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                        disabled={couponWidgetState.inputCode === ""}
                        type="submit"
                    >
                        <p>{translator.translate(language, "shipping", "CouponWidget/coupon_activate")}</p>
                    </button>
                </form>

                {/* Error Message  */}
                {errorMessage ? <span id="error-message" className="text-[12px] w-fit my-1 font-medium text-[#ef4747]"> {errorMessage} </span> : null}
            </>
        )

    }, [couponWidgetState.inputCode , couponWidgetState.errorMsg])


    const couponConfirmedView = useMemo(() => {
        return (
            <>
                {/* Coupon Confirmed Container  */}
                <div
                    id="confirmed-coupon-widget-container"
                    style={{ border: "1px solid var(--primary-lightgreen)" }}
                    className="relative flex items-center justify-between bg-transparent h-14 w-full rounded-md px-5 transition-all duration-200 text-[13px] font-medium text-[var(--primary-lightgreen)] cursor-default"
                >
                    <p>- {translator.getPrice(cart.bonus_value, cartCurrency.locale, cartCurrency.rate)}</p>
                    <img className="coupon-confirmed-vCheck" src={vCheck} alt="check" />
                </div>


                {/* Change Coupon button  */}
                <p
                    onClick={handleChangeCouponClick}
                    className="coupon-confirmed-change-coupon"
                >
                    {translator.translate(language, "shipping", "CouponWidget/change_coupon")}
                </p>
            </>
        )

    }, [cartCurrency.locale, cartCurrency.rate, cart.bonus_value])


    // Hide if:
    if (cart.auto_bonus || cart.applied_subscription_discount) {
        return <></>
    }


    return (
        <>  
            {/* Title  */}
            <span id='coupon-widget-title' className='font-semibold font-roboto text-[#98a0af] text-[13px] my-[15px] block'>
                {translator.translate(language, "shipping", "CouponWidget/title")}
            </span>

            {/* Containers */}
            {isCouponConfirmed ? couponConfirmedView : enterCouponView}
            {couponWidgetState.exposeExtraEnterContainer && isCouponConfirmed ? enterCouponView : null}
        </>
    );
};

export default CouponWidgetV2;