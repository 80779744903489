import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as translator from "../../../utils/translator";
import * as http from "../../../utils/http";
import * as SocialMediaTracker from "../../../PixelTrackers/SocialMediaTracker";
import DeliveryOptionPanel from './components/DeliveryOptionPanel';
import DeliveryAddressForm from './forms/DeliveryAddressForm';
import StickySubmit from '../../../components/StickySubmit';
import { useShippingPageContext } from './providers/ShippingPageProvider';
import { DHL_PACKSTATION, PERSONAL_ADDRESS } from './constants';
import DHLPackStationForm from './forms/DHLPackStationForm';
import { useAppContext } from '../../../providers/AppProvider';
import { loadGoogleApi } from '../../Shipping/helpers';
import { useCartContext } from '../../../customHooks/useCart';
import CartView from '../../../components/CartView';
import DigitalCannabisIDWidget from '../../../components/DigitalCannabisIDWidget/DigitalCannabisIDWidget';
import PortaledModal from '../../../components/Modals/PortaledModal';
import CouponWidgetV2 from './components/CouponWidgetV2';
import OTCAddonsModalV2 from '../../../components/Modals/OTCAddonsModal/OTCAddonsModalV2';
import TogglePrecaution from '../../Shipping/Components/TogglePrecaution';
import { SHIPPING_PAGE_VIEW_EVENT } from '../../../utils/consts';


const ShippingV2 = () => {

  //App context
  const {
    setIsLoading,
    setErrorStatus,
  } = useAppContext();

  // Cart context
  const { saveCart, getCart } = useCartContext();
  const cart = getCart();

  //Shipping Context
  const {
    language,
    country,
    finishedFetching,
    setFinishedFetching,
    selectedOptionPanelCode,
    setSelectedOptionPanelCode,
    isGoogleAPILoaded,
    setIsGoogleAPILoaded,
    selectedFormID,
    setCountryCurrenciesByID,
    customerAddress,
    setCustomerAddress,
    preSelectedExternalPharmacy,
    setPreSelectedExternalPharmacy,
    hidePackstation,
    setHidePackstation,
    selfPickupPharmacySettings,
    setSelfPickupPharmacySettings,
    setContentType,
    categoryCatalogMention,
    setCategoryCatalogMention,
    setAllowPrescriptionRedemption,
    isSubscriptionAlreadyPicked,
    setIsSubscriptionAlreadyPicked,
    OTCAddonsProducts,
    setOTCAddonsProducts,
    showOTCAddonsModal,
    setShowOTCAddonsModal,
    isDeepLinkCouponApplied,
    setIsDeepLinkCouponApplied,
    digitalCannabisIDState,
    setDigitalCannabisIDState,
    handleApplyCoupon,
    handleDigitalCannabisToggle,
    handleDefaultCartCurrency,
    identityCheckState,
    setIdentityCheckState,
    isSubmitButtonDisabled,
    forceFreeTreatmentFeeCoupon,
  } = useShippingPageContext();


  const [searchParams] = useSearchParams();

  // Fetching (handleGet)
  useEffect(() => {
    const fetchData = async () => {
      await loadGoogleApi(() => {
        setIsGoogleAPILoaded(true);
      });

      // 1. Fetch Data (And init the states in the provider)
      http.getFromServer(`v2/shipping/${language}/${country}`, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID])
        .then((response) => {

          // Page View event
          SocialMediaTracker.track("pv", SHIPPING_PAGE_VIEW_EVENT, {searchParamas: Object.fromEntries(searchParams.entries())});

          // 1. Set the provider's states
          const responseData = response.data;
          const cartView = responseData.cartView;
          setCountryCurrenciesByID(responseData.countryCurrenciesByID);
          handleDefaultCartCurrency(responseData.countryCurrenciesByID);
          saveCart(cartView);
          setCustomerAddress(responseData.customerAddresses);
          setPreSelectedExternalPharmacy(responseData.preSelectedExternalPharmacy);
          setSelfPickupPharmacySettings(responseData.selfPickupPharmacySettings);
          setHidePackstation(responseData.hidePackstation);
          setContentType(responseData.contentType);
          setCategoryCatalogMention(responseData.categoryCatalogMention);
          setAllowPrescriptionRedemption(responseData.allowPrescriptionRedemption);
          setIsSubscriptionAlreadyPicked(responseData.isSubscriptionAlreadyPicked);
          setOTCAddonsProducts(responseData.OTCAddonsProducts);
          setShowOTCAddonsModal(responseData.OTCAddonsProducts.length > 0);
          setDigitalCannabisIDState(current => ({
            ...current,
            showWidget: responseData.contentType === "cannabis",
            isToggleEnabled: cartView.products.some((product: CartProductView) => (product.title.trim() === responseData.cannabisIDProductItem.title?.trim())),
            productItem: responseData.cannabisIDProductItem
          }));

          setIdentityCheckState(current => ({
            ...current,
            showWidget: responseData.contentType === "cannabis",
          }));
          
          // 2. Apply "Deep link coupon" if there is one: 
          const deeplinkCoupon = searchParams.get('cpn') || cartView.bonus_coupon_code || sessionStorage.getItem('cpn');
          if (!forceFreeTreatmentFeeCoupon) {
            if (deeplinkCoupon && !cartView.auto_bonus && !cartView.applied_subscription_discount && !cart.auto_bonus) {
              handleApplyCoupon(deeplinkCoupon);
              setIsDeepLinkCouponApplied(true);
            }
          }
        })
        .catch((error) => {
          //TODO: handle errors
          setErrorStatus(500);
        })
        .finally(() => {
          setIsLoading(false);
          setFinishedFetching(true);
        })
    };

    fetchData();

    // 3. scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);



  if (!finishedFetching || !cart.id) {
    return <></>
  }


  return (
    <>

      {isDeepLinkCouponApplied && cart.bonus_value && !cart.applied_subscription_discount ? <div id='bonus-banner' className='bg-[#11DDAC]/90 text-white text-[14px] py-2 px-1 text-center font-roboto top-0 fixed w-full z-10'>{translator.translate(language, "default", "BonusBanner/text")}</div> : null}

      {/* OTCs Modal (incase there are OTC suggestions ) */}
      <PortaledModal
        shouldBackgroundClickHideModal={true}
        shouldShowModal={showOTCAddonsModal}
        hideModal={() => setShowOTCAddonsModal(false)}
        showModal={() => setShowOTCAddonsModal(true)}
      >
        <OTCAddonsModalV2
          productItems={OTCAddonsProducts}
          title={translator.translate(language, "shipping", "OTCAddonsModal/title")}
          language={language!}
          hideModal={() => setShowOTCAddonsModal(false)}
        />
      </PortaledModal>


      {/* Page Title  */}
      <h1 id='shipping-page-title' className='text-center w-fit justify-self-center md:text-[32px] mb-[60px] text-[#0d2c54]'>
        {translator.translate(language, "shipping", "ShippingPage/main_title")}
      </h1>

      {/* Panels Container  */}
      <div id='shipping-page-panels-container' className='relative flex flex-col lg:flex-row h-full md:justify-center gap-[50px]'>

        {/* Left Panel  */}
        <div id='shipping-page-left-panel-container' className='w-full lg:w-[540px]'>
          <span id='left-panel-title' className='font-semibold text-[#98a0af] font-roboto text-[13px] mb-[15px] block'>{translator.translate(language, "shipping", "layout/left_panel_title")}</span>
          <div id='shipping-page-left-panel' className='flex flex-col h-fit w-full rounded-[6px] gap-y-4' >

            {/* Personal Address Shipping Panel  */}
            <DeliveryOptionPanel
              code={PERSONAL_ADDRESS}
              title='An persönliche Adresse liefern'
              isSelected={selectedOptionPanelCode === PERSONAL_ADDRESS}
              hideOptionCards={categoryCatalogMention === "online-vd"}
              onSelect={() => setSelectedOptionPanelCode(PERSONAL_ADDRESS)}
              hideHeader={hidePackstation || Boolean(preSelectedExternalPharmacy) || Boolean(selfPickupPharmacySettings)} //Only in this flow UI changes for some reason... 
            >

              {/* Delivery Address Form  */}
              <DeliveryAddressForm
                showToggle={!cart.self_pickup_pharmacy_id && !cart.external_pharmacy_id}
                defaultValues={{
                  first_name: customerAddress["regular"]?.first_name as string,
                  last_name: customerAddress["regular"]?.last_name as string,
                  zip: customerAddress["regular"]?.zip as string,
                  city: customerAddress["regular"]?.city as string,
                  address: customerAddress["regular"]?.address as string,
                  company: customerAddress["regular"]?.company as string,
                  phone: customerAddress["regular"]?.phone as string,
                }}
                isGoogleAPILoaded={isGoogleAPILoaded}                
              />
            </DeliveryOptionPanel>


            {/* DHL Packstation  */}
            {!hidePackstation &&
              <DeliveryOptionPanel
                code={DHL_PACKSTATION}
                title='An DHL Packstation liefern'
                isSelected={selectedOptionPanelCode === DHL_PACKSTATION}
                onSelect={() => setSelectedOptionPanelCode(DHL_PACKSTATION)}
                isDisabled={cart.only_prescription}
              >

                <DHLPackStationForm
                  defaultValues={{
                    first_name: customerAddress["packstation"]?.first_name as string,
                    last_name: customerAddress["packstation"]?.last_name as string,
                    address: customerAddress["packstation"]?.address as string,
                    company: customerAddress["packstation"]?.company as string,
                    phone: customerAddress["packstation"]?.phone as string,
                    packstation_number: customerAddress["packstation"]?.packstation_number as string,
                    packstation_address: customerAddress["packstation"]?.packstation_address as string,
                    packstation_zip: customerAddress["packstation"]?.zip as string,
                    packstation_city: customerAddress["packstation"]?.city as string
                  }}
                  isGoogleAPILoaded={isGoogleAPILoaded}                
                />
              </DeliveryOptionPanel>
            }

          </div>
        </div>


        {/* Right Panel  */}
        <div id='shipping-page-right-panel' className='flex flex-col w-full lg:w-[375px] rounded-[6px] sticky top-[20px] h-fit'>
          <span id='right-panel-title' className='font-semibold font-roboto text-[#98a0af] text-[13px] mb-[15px] block'>{translator.translate(language, "shipping", "layout/right_panel_title")}</span>

          {/* CartView */}
          <CartView hideSubscriptionPlans={isSubscriptionAlreadyPicked} >

            {/* OTC Addons Trigger  */}
            {
              OTCAddonsProducts.length > 0 &&
              <button
                id='otc-addons-modal-trigger'
                type='button'
                className="open-otc-addons-modal"
                onClick={(e) => { e.preventDefault(); setShowOTCAddonsModal(true) }}
              >
                {translator.translate(language, "default", "OrderWindow/otc_modal_trigger")}
              </button>
            }
          </CartView>

          {/* Digital Cannabis ID Card widget  */}
          {
            digitalCannabisIDState.showWidget ?
              <DigitalCannabisIDWidget
                onTextChange={(text) => setDigitalCannabisIDState(current => ({ ...current, personalIDNumber: text, errorMsg: "" }))}
                onToggle={handleDigitalCannabisToggle}
                isToggleEnabled={digitalCannabisIDState.isToggleEnabled}
                isToggleLoading={digitalCannabisIDState.isToggleLoading}
                inputValue={digitalCannabisIDState.personalIDNumber}
                inputErrorMsg={digitalCannabisIDState.errorMsg}
                setRef={(ref) => setDigitalCannabisIDState((current) => ({ ...current, widgetRef: ref }))}
              />
              :
              null
          }

          {/* CouponWidget */}
          {cart.auto_bonus ? null : <CouponWidgetV2 />}

        </div>
      </div>

      {/* Sticky Submit button */}
      <StickySubmit
        submitButtonLoading={isSubmitButtonDisabled}
        formID={selectedFormID}
        onSubmit={() => {}} //Submit goes from the formID
      >
        {
          identityCheckState?.showWidget ?
          <TogglePrecaution
            isToggleEnabled={identityCheckState?.isPrecautionToggleEnabled}
            precautionLevel={identityCheckState?.precautionLevel}
            updateToggle={() =>
              setIdentityCheckState(current => ({
                ...current,
                precautionLevel: "info",
                isPrecautionToggleEnabled: !current.isPrecautionToggleEnabled
              }))
            }
            isOnlyPrescription={cart.only_prescription}
          />
            :
            <></>
        }
      </StickySubmit>
    </>

  )
}

export default ShippingV2