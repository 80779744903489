import React, { useContext, useMemo } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import RadioSelect from '../../../../components/RadioSelect/RadioSelect';
import { AiOutlineCheck } from 'react-icons/ai';
import { MainTracker } from '../../../../PixelTrackers/MainTracker';

const formatDescription = (unformattedDescription: string, name: string, units: string) => {
    let copy = unformattedDescription.replace(":product_name", name);
    copy = copy.replace(":product_units", units);

    return copy;
};
interface SubscriptionOptionProps {
    planID: number,
    oneTime: boolean,
    maxDiscount?: number,
    isResponsiveLayout?: boolean
}

const SubscriptionOption: React.FC<SubscriptionOptionProps> = ({ planID, maxDiscount, isResponsiveLayout, oneTime = false }) => {

    const context = useContext(ReturingCustomersLayoutContext);
    const isSelected = context?.selectedSubscriptionPlanID === planID;
    const selectedProduct = context?.selectedProduct;
    const selectedProductItemID = context?.selectedProductItemID;
    const selectedItem = selectedProduct?.items.find((productItem) => productItem.product_item_id === selectedProductItemID);

    const planData = context?.subscriptionPlansData[planID];

    const onSelect = () => {
        context?.setSelectedSubscriptionPlanID(planID);

        if (oneTime) {
            window.eventsTracking.track({ e: "Choose One Time Purchase On Choose Treatment Page" });
        } else {
            MainTracker.track(
                "custom",
                "Choose Subscription On Choose Treatment Page"
            );
        }
    }

    const style = useMemo(() => {
        let css = 'text-md font-bold';
        if (isSelected) {
            css += ' text-[#11ddac]';
        } else {
            css += ' text-[#0d2c54]';
        }
        return css
    }, [isSelected])

    return (
        <div style={
            { border: isSelected ? "1px solid #11ddac" : "1px solid #e0e0e0" }
        } id={`plan_${planID  > 0 ?  planID : 'one_time'}${isResponsiveLayout ? "_responsive" : ""}`} onClick={onSelect} className={`cursor-pointer relative rounded-md p-4 ${isSelected ? "bg-[#e7fcf7]" : "bg-white"} `}>
            <div className='flex flex-col gap-2'>

                {planData?.discount === maxDiscount ? <p className="best-price">BESTER PREIS</p> : null}
                <div className='flex items-center'>
                    <RadioSelect selected={isSelected} disabled={false} size={18} />
                    <p className={style}>{planData?.choose_treatments_title || "Einmalkauf"}</p>
                </div>

                {oneTime ? <p className='plan-description'>{formatDescription(
                    "Erhalten Sie einmalig :product_name :product_units zum vollen Preis.",
                    selectedProduct!.title,
                    selectedItem!.custom_quantity_title
                )}</p> : null}

                {planData && <div>
                    <p className="plan-description ">
                        {formatDescription(
                            planData?.frequency_description,
                            selectedProduct!.title,
                            selectedItem!.custom_quantity_title
                        )}
                    </p>

                    <div className="saved-cost-description-container">
                        <AiOutlineCheck className="check-cost" />
                        <p className="saved-cost-description">
                            Sparen Sie <span className={"saved"}>{planData?.discount}€</span> bei
                            jeder Bestellung*
                        </p>
                    </div>

                    <div className="saved-cost-description-container">
                        <AiOutlineCheck className="check-cost" />
                        <p className="saved-cost-description">Jederzeit kündbar</p>
                    </div>
                </div>
                }

                {oneTime && selectedProduct?.subscription_ftd ? (
                    <div className="saved-cost-description-container">
                        <AiOutlineCheck className="check-cost" />
                        <p className="saved-cost-description">
                            Sparen Sie{" "}
                            <span className="saved">
                                {selectedProduct?.subscription_ftd}€
                            </span>{" "}
                            bei dieser Bestellung
                        </p>
                    </div>
                ) : null}

            </div>
        </div>
    )
}

export default SubscriptionOption
