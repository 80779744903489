import React, { useCallback, useContext, useMemo } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import activeLogo from "../../../../icons/Active.svg";
import { MainTracker } from '../../../../PixelTrackers/MainTracker';

interface DosageOptionProps {
    dosageStr: string,
    productID: number,
    isColLayout?: boolean,
    isResponsiveLayout?: boolean
}
const DosageOption: React.FC<DosageOptionProps> = ({ dosageStr, productID, isColLayout, isResponsiveLayout }) => {

    const context = useContext(ReturingCustomersLayoutContext);
    const groupedProducts = context?.groupedProducts;
    const selectedCatalogID = context?.selectedCatalogID;
    const selectedProduct = context?.selectedProduct;
    const isFrOrAllowPrescription = context?.isFR || context?.allowPrescriptionOnlyFlow;
    
    
    const isSelected = useMemo(() => {
        return selectedProduct?.product_id === productID;
    }, [selectedProduct, productID]);
        
        const product = useMemo(() => {
            return groupedProducts && groupedProducts[selectedCatalogID!].find((product) => product.product_id === productID)!;
    }, [selectedCatalogID, productID, groupedProducts]);
    
    const isOutOfStock = product && !product.in_stock;
    const isDisabled = isOutOfStock && (!isFrOrAllowPrescription);
    const productItemToPlan = context?.productItemToPlan;


    const onSelect = useCallback(() => {
        if (groupedProducts && context?.selectedProductID !== productID) {
            const product = groupedProducts[selectedCatalogID!].find((product) => product.product_id === productID)!;
            MainTracker.superProperties({
                "Product Dosage": product.dosage_str || product.product_title,
            });
            window.eventsTracking.track({ e: "Click change Product Dosage" });
            const firstInStockItem = context?.findQuantityBasedOnRule(product) || product.items.find((item) => item.in_stock || isFrOrAllowPrescription);

            context?.setSelectedProductID(productID);
            context?.setSelectedProductItemID(firstInStockItem?.product_item_id || null);
            if (productItemToPlan) {
                const plans = productItemToPlan[firstInStockItem!.product_item_id]
                if (plans) {
                    context?.setSelectedSubscriptionPlanID(plans[0]);
                } else {
                    context?.setSelectedSubscriptionPlanID(null);
                }
            }
        }
    }, [groupedProducts, context, productID, selectedCatalogID, productItemToPlan]);

    return (
        <div id={`product_${productID.toString()}${isResponsiveLayout ? "_responsive" : ""}`} style={{
            border: (isSelected && !isDisabled) ? "1px solid #11ddac" : "1px solid #e0e0e0",
        }} onClick={isDisabled ? () => { } : onSelect} className={`${(isSelected && !isDisabled) ? "bg-[#e7fcf7] text-[#0d2c54]" : "text-[#808080]"} relative rounded-md font-medium ${isDisabled ? "bg-[#f0eded] cursor-not-allowed" : "cursor-pointer"} flex items-center ${isColLayout ? "w-full" : "max-w-[120px]"} justify-center py-5 px-3`}>
            {(isSelected && !isDisabled) && <img className='check-mark' src={activeLogo} alt="" />}
            <p>{dosageStr}</p>
        </div>
    )
}

export default DosageOption
