import "./Footer2.css"
import LegitScript from "../../icons/LegitScript.svg";
import DMCA from "../../icons/DMCA.svg";
import MadeInGermany from "../../icons/MadeInGermany.svg";
import PCi from "../../icons/PCi-logo.svg";
import TrustedShops from "../../icons/TrustedShops.svg";
import LegalHandles from "../../icons/LegalHandles.svg";
import Klarna from "../../icons/Klarna.svg";
import Visa from "../../icons/Visa.svg";
import Mastercard from "../../icons/Mastercard.svg";
import GPay from "../../icons/GPay.svg";
import ApplePay from "../../icons/ApplePay.svg";
import Wire from "../../icons/Wire.svg";
import PayPal from "../../icons/PayPal.svg";
import DHL from "../../icons/DHL.svg";

interface FooterProps {
    page: string;
    language?: string;
    showDiscountDisclaimer?: boolean
}

export const FooterV2: React.FC<FooterProps> = ({language, page, showDiscountDisclaimer=false}) => {
    const nowYear = new Date().getFullYear();

    return (
        <footer className="footer-container">
            <div className="footer flex-col">
                {/* Trust badges/Certification logos */}
                <div className="footer-logos flex-row">
                    <a href="https://www.legitscript.com/websites/?checker_keywords=doktorabc.com" title="Verify LegitScript Approval for doktorabc.com" target="_blank" rel="noreferrer">
                        <img src={LegitScript} alt="LegitScript" className="footer-logo"/>
                    </a>

                    <a href="https://www.dmca.com/Protection/Status.aspx?ID=00ac2ea2-b835-4e18-af6f-3fb65f3e0497&refurl=https://www.doktorabc.com/de" title="DMCA.com Protection Status">
                        <img src={DMCA} alt="DMCA" className="footer-logo"/>
                    </a>

                    <img src={MadeInGermany} alt="Made In Germany" className="footer-logo"/>

                    <a href="https://www.pcisecuritystandards.org" target="_blank" rel="noreferrer">
                        <img src={PCi} alt="PCI" className="footer-logo"/>
                    </a>

                    <a href="https://www.trustedshops.de/shop/certificate.php?shop_id=X418BD71DBE30751ABC36B56ADA98CBA1" target="_blank" rel="noreferrer">
                        <img src={TrustedShops} alt="Trusted Shops" className="footer-logo"/>
                    </a>

                    <a href="https://www.aanbiedersmedicijnen.nl/aanbieders/aanbiederslijst/super-apotheke-b.v.-1/index" target="_blank" rel="noreferrer">
                        <img src={LegalHandles} alt="Legal Handles" className="footer-logo"/>
                    </a>
                </div>

                {/* Payment methods */}
                <div className="footer-payment-methods">
                    <img src={Klarna} alt="Klarna" className="payment-logo"/>
                    <img src={Visa} alt="Visa" className="payment-logo"/>
                    <img src={Mastercard} alt="Mastercard" className="payment-logo"/>
                    <img src={GPay} alt="Google Pay" className="payment-logo"/>
                    <img src={ApplePay} alt="Apple Pay" className="payment-logo"/>
                    <img src={Wire} alt="Wire" className="payment-logo"/>
                    <img src={PayPal} alt="PayPal" className="payment-logo"/>
                    <img src={DHL} alt="DHL" className="payment-logo"/>
                </div>

                {/* Copyright */}
                <div className="footer-text-container">
                    <span className="footer-copyright mt-6 font-bold">
                        © {nowYear} DoktorABC.com 
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default FooterV2;