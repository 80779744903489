import React, {useCallback, useEffect, useState } from 'react'
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import InputField from '../../../components/CredentialsInput/InputField';
import GeneralQuestionsForm from './GeneralQuestionsForm';
import * as http from "../../../utils/http";
import { useCustomerContext } from '../../../reducers/CustomerReducer';
import { ContactPageModalData } from './ContactPageModal';
import LoadingModal from '../../../components/Modals/LoadingModal/LoadingModal';
import * as translator from "../../../utils/translator";
import { useParams, useSearchParams } from 'react-router-dom';
import Popover from '../../../components/Popover/Popover';
import InfoIconGray from '../../../icons/info-icon-gray.svg';



interface props {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLogInModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleStickyNotification: (args: any) => void;
  setModalData: React.Dispatch<React.SetStateAction<ContactPageModalData>>;
}

const ContactOrderIdForm = ({setOpenModal, setModalData, setShowLogInModal, toggleStickyNotification} : props) => {
  const  [searchParams, ]  = useSearchParams();
  const orderHashID = searchParams.get('order');
  const [loading, setLoading] = useState<boolean>(false);
  const [orderID, setOrderID] = useState<string>(orderHashID ? orderHashID : "");
  const [customerHasOrderID, setCustomerHasOrderID] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showPopover, setShowPopover] = useState(false);
  const [isOrderIDValid, setIsOrderIDValid] = useState<boolean>(false);
  const { customerState } = useCustomerContext();
  const isUserLogged = customerState?.customerID ? true : false;
  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);


  const CustomButton = useCallback(({ showOrderIDInput, text }: { showOrderIDInput: boolean; text: string }) => {
    return (
      <button 
      style={{border:"1px solid #F8F8F8 ", borderBottom: customerHasOrderID === showOrderIDInput ? 'none': '1px solid #F8F8F8', borderCollapse: "collapse"}}
      className={`px-4 py-2 w-36 h-14 cursor-pointer !rounded-none ${customerHasOrderID === showOrderIDInput ? "bg-white text-[#11DDAC] border-collapse" : "bg-gray-50 text-gray-600"}`}
      onClick={() => setCustomerHasOrderID(showOrderIDInput)}>
      {showOrderIDInput ? translator.translate(
            language,
            "contact",
            text
          ): translator.translate(
            language,
            "contact",
            text
          )}
    </button>
      )
  },[customerHasOrderID]);

  const getOrderData = async () => {
    await http.getFromServer(`contact/order/${orderID}/${customerState?.customerID}`,[
      http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID])
        .then((response) => {
          setLoading(false);
          setModalData(response.data);
          setOpenModal(true);
        }).catch((error) => {
          setLoading(false);
          setErrorMsg(translator.translate(
            language,
            "contact",
            "invalidOrderID"
          ));
          window.clientLogger.warn("error loading order data",error);
        })
  }

  const validateOrderID = (hashID: string) => {
    if (!hashID || hashID.length !== 7) {
      return false;

      } else {
        return true;
      }
  }

  const onSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    const isValid = validateOrderID(orderID)
    if (!isValid){
      setErrorMsg(translator.translate(
        language,
        "contact",
        "ContactPageForm/order_number_error"
      ))
      setIsOrderIDValid(false);
      return
    }
    setErrorMsg("");
    setIsOrderIDValid(true);
    if (!isUserLogged){
      setShowLogInModal(true);
      return;
    }
    setLoading(true);
    await getOrderData()
  }

  useEffect(() => {
    if (customerState?.customerID && isOrderIDValid) {
      getOrderData();
    }
  }, [customerState?.customerID, isOrderIDValid]);

  return (
    <>
      {loading && <LoadingModal />}
      <div id="contact-order-id-container" className='py-3 w-full'>
          <h1 id='contact-order-id-title' className='text-2xl text-[#0D2C54] font-[700] text-center mb-10 mt-14'>
            {translator.translate(language, "contact", "orderIDFormTitle")}
          </h1>
        <div id='contact-order-id-form-container' className='bg-white p-3 md:p-6 rounded-xl'>

            <div id='contact-order-id-options' className='flex flex-row' style={{borderCollapse: "collapse"}}>

                {<CustomButton showOrderIDInput={true} text="yes"/>}
                {<CustomButton showOrderIDInput={false} text="no"/>}
              
            </div>

          <div id='contact-order-id-form' className='flex flex-col md:!flex-row gap-3' style={{border: "1px solid #F8F8F8"}}>
            {customerHasOrderID ? (
              <>
                <div id='order-id-form' className='flex flex-col w-full p-[30px] gap-[10px]'>
                  <p className=' text-[#98A0AF]'>{translator.translate(language, "contact", "insertOrderID")}</p>
                  <form id='order-id-input-container' className='flex md:flex-row items-start gap-2 flex-col'>
                      <InputField id='order-id-input'
                        touched={true}
                        placeholder={translator.translate(language, "contact", "ContactPageForm/order_number")}
                        mandatory={true}
                        errorMsg={errorMsg}
                        value={orderID}
                        language={language}
                        onChange={(inputValue: string) =>{
                          setOrderID(inputValue);
                        }
                        }
                        onBlur={() => {
                          if (orderID){
                            validateOrderID(orderID);
                          } else {
                            setErrorMsg("")
                          }
                        }}

                        childrenIcon={

                          <div id='order-number-popover' className='absolute top-0 right-[6%] p-2'>
                            <div id='popover-container' className='relative flex mt-[1px] ml-1 desktop:ml-0 desktop:mr-1 p-2 '>
                              <img
                                id='info-icon'
                                className=' select-none cursor-pointer w-[20px] h-[20px] absolute left-3'
                                src={InfoIconGray}
                                alt="i"
                                onMouseLeave={() => setShowPopover(false)}
                                onMouseEnter={() => setShowPopover(true)}
                                onClick={() => setShowPopover(true)}
                              />
    
                              {/* Popover */}
                              {showPopover && (
                                <Popover
                                  content={translator.translate(language, "contact", "ContactPageForm/popover_content")}
                                  onClose={() => setShowPopover(false)}
                                  containerClassName='bottom-5'
                                  traingleClassName='bottom-4'
                                />
                              )}
                            </div>
                          </div>
                        }

                        />
                      <SubmitButton 
                      id="submit-order-id" 
                      placeholder={translator.translate(language, "contact", "ContactPageForm/submit")}
                      onSubmit={onSubmit} 
                      loading={loading}/>
                  </form>
                </div>
              </>)
              :(
                <div className='w-full'>
                  <GeneralQuestionsForm toggleStickyNotification={toggleStickyNotification}/>
                </div>
              )
            }
          </div>
            
        </div>
      </div>
    </>
  )
}

export default ContactOrderIdForm