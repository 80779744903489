import { useContext, useEffect, useState } from 'react';
import { ChooseTreatmentContext, Product, ProductItem, toLocalePrice } from '../FreeDosageLayoutV2';
import ProductAvailabilityTag from './ProductAvailabilityTag';
import BinIcon from '../../../../icons/Bin.svg';
import PlusIcon from '../../../../icons/Plus.svg';
import MinusIcon from '../../../../icons/Minus.svg';

type Props = {
  viewOrder?:boolean
}

const ViewOrder:React.FC<Props> = ({ viewOrder }) => {

  const chooseTreatmentContext = useContext(ChooseTreatmentContext)
  const setViewOrder = chooseTreatmentContext?.setViewOrder
  const productItems = chooseTreatmentContext?.selectedProductItems
  const selectedProducts = chooseTreatmentContext?.desiredProducts
  const setSelectedProductItems = chooseTreatmentContext?.setSelectedProductItems
  const handleProductToggle = chooseTreatmentContext?.handleProductToggle
  const onSubmit = chooseTreatmentContext?.onSubmit
  const calculateTotalPrice = chooseTreatmentContext?.calculateTotalPrice
  
  const [animationState, setAnimationState] = useState<string>('');
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  // Handle animation state based on viewOrder prop
  useEffect(() => {
    if (!animationState){
      setAnimationState("hidden");
    }
    else if (viewOrder) {
      setAnimationState("animate-fade-in-up");
    } else {
      setAnimationState("animate-fade-out-down");
      
      // Reset to hidden after animation completes
      const timer = setTimeout(() => {
        if (!viewOrder) setAnimationState("hidden");
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [viewOrder]);

  // Don't render at all if it's hidden
  if (animationState === "hidden" && !viewOrder) return null;


  const nextProductItem = (index: number, product: Product) => {
      if (index < product.items.length - 1) {
          // find the correct product item and increase the dosage
          setSelectedProductItems!(currentProductItems => {
              return currentProductItems.map((productItem, i) => {
                  if (selectedProducts![i] === product) {
                      return product.items[index + 1];
                  }
                  return productItem;
              })
          })
      }
  }
  
  const previousProductItem = (index: number, product: Product) => {
      if (index > 0) {
          // find the correct product item and decrease the dosage
          setSelectedProductItems!(currentProductItems => {
            return currentProductItems.map((productItem, i) => {
                if (selectedProducts![i] === product) {
                    return product.items[index - 1];
                }
                return productItem;
            })
          })
      }
  }

  const getMaxPricePerQuantity = (product: Product) => {
          if (!product) return 0;
  
          if (product.items?.length > 0) {
              const firstItem = product.items[0];
              return firstItem?.price / firstItem?.quantity;
          };
  
          return 0;
  }

  const calculateSave = (productItem: ProductItem, product: Product) => {
      if (!productItem) {
          return 0;
      }

      const maxPerGramCost = getMaxPricePerQuantity(product);

      const savePrice = productItem.quantity * maxPerGramCost - productItem.price;
      if (savePrice > 0) {
          return toLocalePrice(savePrice);
      }
      return 0;
  }

  const handleRemoveProductItem = (product: Product, productItem: ProductItem) => {
    handleProductToggle!(product, productItem, "REMOVE")
    if (selectedProducts!.length === 1) {
      setViewOrder!(false);
    }
  }

  const handleSubmit = () => {
    setIsSubmiting(true);
    try {
      onSubmit!();
    }
    catch (error) {
      window.alert("An error occurred while submitting your order. Please try again later.");
    }
    finally {
      setIsSubmiting(false);
    }
  }

  // Calculate Cart Costs
  const treatmentFee = Math.max(...selectedProducts!.map(product => product.treatment_fee), 0);
  const medicationTotal = selectedProducts!.length === 0 || !selectedProducts![0].in_stock ? 0 : calculateTotalPrice!();
  const deliveryFee = selectedProducts!.length === 0 || !selectedProducts![0].in_stock ? 0 : 0.00; // TODO: Implement delivery fee calculation
  const totalAmount = treatmentFee + medicationTotal + deliveryFee;


  return (
    <div onClick={() => setViewOrder!(false)} className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-500 z-40 flex items-center justify-center" >

      <div id="view-order-container" className={`fixed p-4 mb-20 mr-2 ml-2 md:p-6 max-h-[700px] bg-white rounded-lg shadow-sm mx-auto ${animationState ? animationState : "hidden" } z-50`} onClick={(e) => e.stopPropagation()} >

        {/* Header */}
        <div id="view-order-title-container" className="flex flex-col md:flex-row justify-between mb-6">
          <h2 id="view-order-title" className="text-2xl font-semibold text-[#0D2C54]">Artikel bestellen</h2>
          <div className="text-[#0A9281] justify-center mt-2 md:mt-1 text-sm font-roboto font-medium cursor-pointer" onClick={() => setViewOrder!(false)}>+ Mehr hinzufügen</div>
        </div>

          <div id="product-items-container" className='max-h-[250px] md:max-h-[350px] p-2 pt-2 overflow-y-auto'>
            {/* Product Items */}
            {productItems!.map((productItem, index) => {

              const product = selectedProducts![index];
              const productItemIndex = product.items.findIndex((item) => item.product_item_id === productItem?.product_item_id) || 0;
              const savedCost = calculateSave(productItem, product);

              const additionalDataObj = product.additional_data ? JSON.parse(product.additional_data) : {};
              let customDosageForCannabis = ''
              let strain = ''
              let shouldUseCannabisDosage = false;


              if (additionalDataObj) {
                  if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
                      shouldUseCannabisDosage = true;
                      customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
                      strain = `Sorte: ${additionalDataObj['strain']}`
                  }
              }

              return (
              <div id={`product-card-container`} key={productItem.product_item_id}
                className="mb-4 w-full md:w-[460px] relative border-[0.5px] border-gray-300 border-solid rounded-lg p-4 md:p-6 gap-2">

                  <ProductAvailabilityTag inStock={productItem.in_stock} className='absolute -top-2 right-6' />
                  
                  <div id='proudct-card-header' className='flex items-start justify-between gap-1'>

                    <h2 className="text-base font-bold text-[#0D2C54] max-w-60">{product.title}</h2>

                    {product.in_stock &&
                    <div className="flex flex-col-reverse md:flex-row items-center min-w-fit md:gap-2">
                      {savedCost && productItem?.in_stock ? <p className='text-[11px] font-medium text-[#0A9281] font-poppins'>Spare €{savedCost}</p> : null}
                      <p className="text-lg font-medium font-roboto">€{productItem.price.toFixed(2)}</p>
                    </div>
                    }

                  </div>

                  {/* Custom dosage text  */}

                  <div id='product-card-body' className='flex gap-1'>
                    {/* Custom dosage text  */}
                    {
                        shouldUseCannabisDosage ?
                            <div id='product-card-custom-dosage' className='flex flex-col w-full mt-2'>
                                <p id='product-card-custom-dosage-text' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{customDosageForCannabis}</p>
                                <p id='product-card-custom-dosage-strain' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{strain}</p>
                            </div>
                            :
                            null
                    }

                    {/* Selected Quantity Control */}
                      <div className="flex flex-col justify-center">
                        {
                            <div className="flex items-center mb-2 gap-1 md:gap-3">
                              <div id='product-card-quantity-control' style={{ border: "1px solid #E5E5E5"}} className='flex justify-between items-center rounded-md w-[140px] md:w-44 px-1.5 md:px-2'>
                                  <div onClick={() => previousProductItem(productItemIndex, product)} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#E7FCF7] text-center flex items-center justify-center'>
                                      <img src={MinusIcon} id='sub-quantity' className='rounded-md flex justify-center p-1 text-white items-center' />
                                  </div>

                                  <div className='flex flex-col justify-center items-center'>
                                      <p className='text-[#0D2C54] font-normal'> {productItem?.quantity} </p>                                        
                                      <p className='text-[#98A0AF] text-sm'>{product.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                  </div>

                                  <div onClick={() => nextProductItem(productItemIndex, product)} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#E7FCF7] text-center flex items-center justify-center'>
                                      <img src={PlusIcon} id='add-quantity' className='rounded-md flex justify-center p-1 text-white items-center' />
                                  </div>               
                              </div>

                                
                                {/* Remove Button */}
                                <button 
                                    id={`product-card-remove-button-${product.product_id}`}
                                    onClick={() => {
                                        handleRemoveProductItem(product, productItem)
                                    }}
                                    className='flex items-center justify-center w-[46px] h-[46px] rounded-md cursor-pointer bg-white'
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FAFAFB'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                    style={{ border: "1px solid #E5E5E5" }} 
                                >
                                    <img src={BinIcon} id='bin-icon' className='rounded-md flex justify-center p-1 text-white items-center w-6 h-6' />
                                </button>
                            </div>
                        }

                        { product.in_stock ?
                            <p className='flex justify-center h-4 font-medium text-xs text-[#0D2C54] whitespace-nowrap'>
                              {toLocalePrice(productItem!.price! / productItem!.quantity!)}€ pro Gramm
                            </p>
                            : null
                        }
                      </div>
                  </div>
              </div>
            )})}
          </div>


        {/* Shipping Cost Details */}
        <div id="costs-container" className="mt-6 text-base text-[#656D79] font-light min-w-[300px] md:min-w-[400px]">

            <div id="treatment-fee" className="flex justify-between">
              <span className='font-roboto'>Behandlungsgebühr:</span>
              <span className='font-roboto'>{treatmentFee.toFixed(2)} €</span>
            </div>

            <div id="medication-total-cost" className="flex justify-between">
              <span className='font-roboto'>Medikament:</span>
              <span className='font-roboto'>{medicationTotal.toFixed(2)} €</span>
            </div>

            <div id="shipment-fee" className="flex justify-between">
              <span className='font-roboto'>Lieferung in 24 - 48 Stunden:</span>
              <span className='font-roboto'>{deliveryFee.toFixed(2)} €</span>
          </div>

        </div>

        <div id="total-order-cost" className="mt-4 flex justify-between items-center text-lg font-bold text-[#0D2C54] pb-4 border-b">
          <span>Gesamtbetrag:</span>
          <span>{totalAmount.toFixed(2)} €</span>
        </div>


        {/* Go to Shipping */}
        <button
          onClick={() => {
            if (isSubmiting) return;
            handleSubmit();
          }} 
          disabled={selectedProducts!.length === 0 || isSubmiting}
          className={`w-full border-none ${selectedProducts!.length === 0 ? `cursor-not-allowed bg-[rgba(17,221,172,.8)]` : `bg-[#06D1A1] cursor-pointer hover:opacity-90`}
              text-base text-white rounded-md py-4 font-poppins font-bold`}
        >
          Weiter zum Versand
        </button>

        {/* Back To Product Selection */}
        <div 
          className="text-[#98A0AF] cursor-pointer mt-4 text-center hover:underline" 
          onClick={() => setViewOrder!(false)}
        >
            {`Schließen`}
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;