import React, { forwardRef, useCallback, useContext, useState } from 'react'
import ProductAvailabilityTag from './ProductAvailabilityTag';
import { ChooseTreatmentContext, Product, toLocalePrice } from '../FreeDosageLayoutV2';
import ShoopingCart from '../../../../icons/Shopping-Cart-White.svg';
import BinIcon from '../../../../icons/Bin.svg';
import EditIcon from '../../../../icons/Edit.svg';
import PlusIcon from '../../../../icons/Plus.svg';
import MinusIcon from '../../../../icons/Minus.svg';

const MOBILE_BREAK_POINT = 768

interface Props {
    id: string;
    product: Product;
    price: string;
    hidePrices?: boolean
    hideTags?: boolean
    containerClassName?: string,
}

const ProductCard = forwardRef<HTMLDivElement, Props>(({ id, product, price, hidePrices = false, hideTags = false, containerClassName }, ref) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const additionalDataObj = product.additional_data ? JSON.parse(product.additional_data) : {};
    const isMaxNumOfProductSelected = chooseTreatmentContext?.selectedProductsCounter == chooseTreatmentContext?.maxCartProducts;
    const selectedProductItem = chooseTreatmentContext?.selectedProductItems.find((selectedItem) => selectedItem.catalog_id === product.catalog_id);
    const currentOpenProduct = chooseTreatmentContext?.currentOpenProduct;
    const setCurrentOpenProduct = chooseTreatmentContext?.setCurrentOpenProduct;
    const [desiredProductItem, setDesiredProductItem] = useState(product.items[0]);
    const index = product.items.findIndex((item) => item.product_item_id === desiredProductItem?.product_item_id) || 0;
    const cardRef = ref as React.MutableRefObject<HTMLDivElement | null>;
    const isOpen = currentOpenProduct?.product_id === product.product_id;
    const setViewOrder = chooseTreatmentContext?.setViewOrder;

    let customDosageForCannabis = ''
    let strain = ''
    let shouldUseCannabisDosage = false;

    if (additionalDataObj) {
        if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
            shouldUseCannabisDosage = true;
            customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
            strain = `Sorte: ${additionalDataObj['strain']}`
        }
    }

    const scrollToTop = () => {
        if (window.screen.width < MOBILE_BREAK_POINT) {
            setTimeout(() => {
                if (cardRef?.current) {
                    cardRef!.current!.scrollIntoView({ behavior: 'smooth', block: "start" });
                }
            }, 200)
        }
    }

    const getMaxPricePerQuantity = useCallback((product: Product | null) => {
        if (!product) return 0;

        if (product.items?.length > 0) {
            const firstItem = product.items[0];
            return firstItem?.price / firstItem?.quantity;
        };

        return 0;
    }, [product])

    const nextProduct = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (index < product.items.length - 1) {
            setDesiredProductItem(product.items[index + 1]);
        }
    }, [product, index])

    const previousProduct = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (index > 0) {
            setDesiredProductItem(product.items[index - 1]);
        }
    }, [product, index])

    const calculateSave = useCallback(() => {
        if (!selectedProductItem) {
            return 0;
        }

        const savePrice = selectedProductItem.quantity * maxPerGramCost - selectedProductItem.price;
        if (savePrice > 0) {
            return toLocalePrice(savePrice);
        }
    }, [product, selectedProductItem])

    const onToggle = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        const toggleAction = product.isSelected ? "REMOVE" : "ADD";
        const maxCartProducts = chooseTreatmentContext?.maxCartProducts!;

        if (toggleAction === "ADD" && isMaxNumOfProductSelected && maxCartProducts > 1) {
            return
        }

        // 1. Select the product 
        chooseTreatmentContext?.handleProductToggle(product, desiredProductItem, toggleAction);

        // 2. Scroll to top!
        scrollToTop();

    }, [product, desiredProductItem, chooseTreatmentContext?.selectedProducts, chooseTreatmentContext?.selectedProductItems])

    const handleOpenProduct = () => {
        if (!isMaxNumOfProductSelected) {
            setCurrentOpenProduct!(product)
        }
        else if (!product.isSelected) {
            chooseTreatmentContext?.setShowMaxLimitModal!(true);
        }
    }


    const maxPerGramCost = getMaxPricePerQuantity(product);
    const savedCost = calculateSave();

    return (
        <div
            id={id ? id : 'product-card-container'}
            data-product-id={product.product_id}
            onClick={ (e) => {
                    handleOpenProduct();
                    e.currentTarget.style.backgroundColor = 'white'
                }
            }
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = isOpen || product.isSelected ? 'white': '#FCFCFD'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
     
            style={{ 
                borderLeft: selectedProductItem ? "5px solid #11DDAC" : "1px solid #FFFFFF",
            }}
            ref={ref}
            className={`flex relative scroll-mt-[100px] md:mr-2 ${product.isSelected ? "" : "min-h-[110px]"} bg-white md:gap-2 p-4 pt-6 md:p-6 rounded-[8px] md:w-[500px]
                ${isMaxNumOfProductSelected && !product.isSelected ? "cursor-not-allowed" : !isOpen && !product.isSelected ? "cursor-pointer" : "cursor-default"} ${containerClassName} `}
        >
            
            {hideTags ? null : <ProductAvailabilityTag inStock={product.in_stock} className='absolute -top-2 right-6' />}

            {/* Content */}
            <div id='product-card-content' className='flex flex-col gap-y-2 w-full'>

                {/* Header  */}
                <div id='proudct-card-header' className='flex items-start justify-between gap-1'>

                    <p id="product-card-title" className='font-medium text-[#0D2C54] max-w-60'>{product.title}</p>

                    {/* Selected Product item price  */}
                    {
                        product.isSelected && product?.in_stock && !hidePrices && selectedProductItem ?
                            <div className='p-1 flex items-end flex-col'>
                                <p className='text-[#0D2C54] text-lg md:text-xl font-medium flex min-w-fit'>{toLocalePrice(selectedProductItem?.price)} €</p>
                                {savedCost && selectedProductItem?.in_stock ? <p className='text-xs font-medium text-nowrap text-[#0A9281]'>Spare {savedCost} €</p> : null}
                            </div>
                            :
                            null
                    }

                    {
                        !product.isSelected && product.in_stock && !hidePrices ?
                            <div id='product-card-prices-and-tags' className='flex flex-col min-h-10'>
                                <div className='flex items-center gap-1'>
                                    <p className='text-lg md:text-xl font-medium text-[#0D2C54] whitespace-nowrap'>
                                        {selectedProductItem ? toLocalePrice(selectedProductItem.price / selectedProductItem.quantity)
                                        : toLocalePrice(desiredProductItem!.price! / desiredProductItem!.quantity!)} €
                                    </p>
                                </div>
                                {product.quantity_type == 'gram' &&
                                    <p className='text-xs text-[#556D8C]'> pro Gramm </p>
                                }
                            </div>
                            :
                            null
                    }
                </div>

                <div id='product-card-body' className='flex'>
                    {/* Custom dosage text  */}
                    {
                        shouldUseCannabisDosage ?
                            <div id='product-card-custom-dosage' className='flex flex-col w-full'>
                                <p id='product-card-custom-dosage-text' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{customDosageForCannabis}</p>
                                <p id='product-card-custom-dosage-strain' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{strain}</p>

                                {
                                Boolean((selectedProductItem || isOpen) && product.in_stock && product.quantity_type == 'gram') &&
                                <div className="text-[#0D2C54] text-[14px] font-normal font-poppins mt-4">
                                    ab <span className="font-semibold">{
                                        selectedProductItem ? toLocalePrice(selectedProductItem.price / selectedProductItem.quantity)
                                        : toLocalePrice(desiredProductItem!.price! / desiredProductItem!.quantity!)
                                    }
                                    </span> pro gramm
                                </div>
                                }

                            </div>
                            :
                            null
                    }

                    {/* Quantity Control  */}
                    {isOpen && !product.isSelected &&
                        <div className='flex flex-col items-end w-full gap-y-2'>
                            <div id='product-card-quantity-control' style={{ border: "1px solid #E5E5E5", width: "12rem" }} className='flex justify-between items-center rounded-md px-2'>
                                <div onClick={previousProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#E7FCF7] text-center flex items-center justify-center'>
                                    <img src={MinusIcon} id='sub-quantity' className='rounded-md flex justify-center p-1 text-white items-center' />
                                </div>

                                <div className='flex flex-col justify-center items-center'>
                                    <p className='text-[#0D2C54] font-normal'> {desiredProductItem?.quantity} </p>                                        
                                    <p className='text-[#98A0AF] text-sm'>{product.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                </div>

                                <div onClick={nextProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#E7FCF7] text-center flex items-center justify-center'>
                                    <img src={PlusIcon} id='add-quantity' className='rounded-md flex justify-center p-1 text-white items-center' />
                                </div>               
                            </div>

                            {
                                !hidePrices && product.in_stock ?
                                        <p className='flex justify-center font-medium text-xs text-[#0D2C54] whitespace-nowrap mr-11'>
                                            {selectedProductItem ? toLocalePrice(selectedProductItem.price / selectedProductItem.quantity)
                                            : toLocalePrice(desiredProductItem!.price! / desiredProductItem!.quantity!)}€ pro Gramm
                                        </p>
                                    :
                                    null
                            }

                            <div id='product-card-submit-button-container' style={{width: "12rem" }} className='flex justify-center w-full'>
                                <button
                                    id={`product-card-submit-button-${product.product_id}`}
                                    onClick={(e) => {
                                        onToggle(e); 
                                    }}
                                    className='flex items-center justify-center w-full text-base cursor-pointer bg-[#11DDAC] text-white font-medium py-3 px-4 border-0 outline-none'
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#12D3A6'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#11DDAC'}
                                >
                                    <img src={ShoopingCart} className="w-[24px] h-[24px] mr-2" alt="Cart" /> 
                                    <span className='text-sm'>In den Warenkorb</span>
                                </button>
                            </div>
                        </div>
                    }

                    {/* Selected Quantity Control */}
                    {
                        product.isSelected ? 
                            <div className="flex flex-col">
                                <div className="flex items-center mb-2">
                                    {/* Edit Quantity Button */}
                                    <button 
                                        id={`product-card-edit-quantity-${product.product_id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // chooseTreatmentContext?.scrollToMainTitle!();
                                            setViewOrder!(true);  
                                        }}
                                        className='mr-2 flex items-center justify-center p-2 w-30 h-10 rounded-md cursor-pointer bg-white'
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FAFAFB'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                        style={{ border: "1px solid #E5E5E5" }} 
                                    >
                                        <div className="flex items-center">
                                            <p className='text-[#0D2C54] font-normal text-lg' style={{ marginRight: "4px" }}> {selectedProductItem?.quantity} </p>                                        
                                            <p className='text-[#98A0AF] text-xs mt-1 mr-2'>{product.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                            <img src={EditIcon} id='bin-icon' className='rounded-md flex justify-center p-2 text-xl text-white items-center' />
                                        </div>
                                    </button>
                                    
                                    {/* Remove Button */}
                                    <button 
                                        id={`product-card-remove-button-${product.product_id}`}
                                        onClick={(e) => {
                                            onToggle(e);
                                            setCurrentOpenProduct!(null);
                                        }}
                                        className='flex items-center justify-center p-2 w-10 h-10 rounded-md cursor-pointer bg-white'
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FAFAFB'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                        style={{ border: "1px solid #E5E5E5" }} 
                                    >
                                        <img src={BinIcon} id='bin-icon' className='rounded-md flex justify-center p-1 text-white items-center w-6 h-6' />
                                    </button>
                                </div>
                                
                                {
                                    !hidePrices && product.in_stock ?
                                            <p className='flex justify-center font-medium text-xs text-[#0D2C54] whitespace-nowrap'>
                                                {selectedProductItem ? toLocalePrice(selectedProductItem.price / selectedProductItem.quantity)
                                                : toLocalePrice(desiredProductItem!.price! / desiredProductItem!.quantity!)}€ pro Gramm
                                            </p>
                                        :
                                        null
                                }
                            </div> : null
                        }
                </div>
            </div>
        </div>
    )
});

export default ProductCard