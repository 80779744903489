import React, { useContext, useMemo } from 'react'
import { ShippingDataContext } from '../Shipping';
import ShippingForm from "./ShippingForm/ShippingForm";
import pharmacyIcon from "../../../icons/pharmacy-icon.svg";


const CannaleoDeliveryPanel = () => {

  const { language, preSelectedExternalPharmacy } = useContext(ShippingDataContext);

  const pharmacyDataForOptionCard = useMemo(() => {
    const pharmacyName = preSelectedExternalPharmacy?.name || null;
    const pharmacyUnofficialName = preSelectedExternalPharmacy?.cannabis_pharmacy_name || null;
    
    const title = pharmacyName
      ? `Ihre gewählte Apotheke: ${pharmacyName} ${pharmacyUnofficialName ?  "(" + pharmacyUnofficialName + ")" : ""}`
      : "Ihre gewählte Apotheke";
  
    const description = pharmacyName
      ? `Sobald Ihre Bestellung bezahlt ist und Ihr Arzt das Rezept genehmigt hat, wird es direkt an die ${pharmacyName} geschickt. Sie können sich dann mit der Apotheke in Verbindung setzen und das Medikament bezahlen.`
      : "Sobald Ihre Bestellung bezahlt ist und Ihr Arzt das Rezept genehmigt hat, wird das Rezept an die von Ihnen gewählte Apotheke geschickt. Detaillierte Anweisungen und die nächsten Schritte finden Sie in Ihrer E-Mail.";
  
    return {
      title,
      description,
    };
  }, [preSelectedExternalPharmacy?.name]);
  

  return (
    <div className={"delivery-panel-outer-container selected-pm"}>

      <div className="delivery-form-personal-address ">
        <div className='hideable-content'>
          <p className="delivery-options-title"></p>


          {/* Delivery Card  */}
          <div id='delivery-card' className='py-4 px-6 border-[1px] bg-[#E7FCF7] flex flex-col rounded-lg mb-[10px]'>

              {/* Title and icon  */}
              <div id='delivery-card-header' className='flex gap-[10px] items-center mb-2'>
                <div id='delivery-card-icon-container' className='flex rounded-full p-1 bg-[#C0F6EA] select-none'>
                  <img src={pharmacyIcon} alt='Pharmacy' className='w-[30px] h-[30px] object-cover' />
                </div>
                <p id='delivery-card-title' className='text-[#0D2C54] font-semibold text-base break-words'> {pharmacyDataForOptionCard!.title} </p>
              </div>

              {/* Description  */}
              <p id='delivery-card-description' className='text-[#656D79] font-roboto text-sm'> {pharmacyDataForOptionCard!.description} </p>


          </div>

          <p className="delivery-options-title" style={{marginTop:15, marginBottom:0}}>Rechnungsdetails</p>

          <ShippingForm
            language={language}
            selected={true}
            hideToggle
          />
        </div>
      </div>
    </div>

  )
}

export default CannaleoDeliveryPanel