import React, { forwardRef, useContext } from 'react'
import ProductCard from './ProductCard'
import { ChooseTreatmentContext, Product, getMinPricePerProduct, getMinPricePerQuantity } from '../FreeDosageLayoutV2'

interface Props {
  products: Product[],
  languageID: string,
  className?: string,
  productCardClassName?: string,
}

const ProductSelectionPanel = forwardRef<HTMLDivElement, Props>(({ products, languageID, className, productCardClassName = "" }, ref) => {
  const chooseTreatmentContext = useContext(ChooseTreatmentContext);

  
  return (
    <div id='product-selection-container' className='flex flex-col gap-5'>
      <div id='product-selection' className={`flex flex-col gap-5 pt-2 pb-6 md:overflow-y-auto ${className}`}>
        {
          products.map((product, index) => {
            const minPricePerQuantity = getMinPricePerQuantity(product);
            const minPricePerProduct = getMinPricePerProduct(product);
            const priceString = ['gram'].includes(product.quantity_type) ? minPricePerQuantity : minPricePerProduct;
            
            return (
              <ProductCard
                id={`product-card-container-${product.product_id}`}
                ref={(el: any) => {
                  if(chooseTreatmentContext?.productCardRefs?.current) {
                    chooseTreatmentContext.productCardRefs.current[index] = el;
                  }
                }}
                key={product.product_id.toString() + index + product.isSelected} 
                product={product}
                price={priceString}
                hidePrices={chooseTreatmentContext?.hidePrices}
                hideTags={chooseTreatmentContext?.hideTags} 
                containerClassName={productCardClassName}
              />
            )
          })
        }
      </div>
    </div>
  )
});

export default ProductSelectionPanel