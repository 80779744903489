import React, { useState ,ReactElement } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import InnerHTMLRenderer from '../../../components/InnerHTMLRenderer/InnerHTMLRenderer';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import * as translator from "../../../utils/translator";
import Checkbox from '../../../components/Checkbox/Checkbox';
import * as http from "../../../utils/http";
import MyAccountAccordion from '../components/MyAccountAccordion/MyAccountAccordion';
import ArrowUp from '../../../icons/ArrowUp.svg';
import ArrowDown from '../../../icons/ArrowDown.svg';

interface MyAccountOrderNotesProps {
  htmlContent: string; // the actual HTML from db
  language: string;
  orderStatus: string;
}

const PRINT = "print";
const CANCEL = "cancel";

const MyAccountOrderNotes: React.FC<MyAccountOrderNotesProps> = ({ htmlContent, language, orderStatus }): ReactElement => {
  const {setStickyNotificationDetails, setRefreshData, country} = useOutletContext<any>();
  const { order_hash_id } = useParams();
  
  const header = translator.translate(language, "my_account", `MyOrders/details/orderNotesHeader`);
  
  const accordionItems = htmlContent.split(/(?<=<\/p>)/).map((pTag: string) => {
    const noBreak = pTag.split("<br>").join(""); 
    const titleMatch = noBreak.match(/<b>.*<\/b>/);
    const title = titleMatch ? titleMatch[0].replace(/<\/?b>/g, "") : "";
    const content = noBreak.replace(/<b>.*<\/b>/, "");
    return { title, content };
  });

  const checkboxOptions = [
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/1`),
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/2`),
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/3`),
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/4`),
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/5`),
    translator.translate(language, "my_account", `MyOrders/details/cancelReasons/6`),
    'other',
  ];

  const [submitMode, setSubmitMode] = useState<string>(PRINT);
  const [cancellationOptionID, setCancellationOptionID] = useState<number>(5);
  const navigate = useNavigate()

  const onSubmit = () => {
    if (submitMode === CANCEL) {
      http
      .postToServer(`account-update/order/cancel`,
      {
      hashID:order_hash_id,
      cancellaionComment: checkboxOptions[cancellationOptionID]
      },
      [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        setStickyNotificationDetails((currentDetails:any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/success/cancel`).replace(':order_id', order_hash_id),
          level : "info",
          showInfoIcon:false,
          refresh: !currentDetails.refresh
        }))

      })
      .catch((error) => {
        setStickyNotificationDetails((currentDetails:any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
          level : "error",
          showInfoIcon:false,
          refresh: !currentDetails.refresh
        }))
      }).finally(() => {
        setRefreshData((current:boolean) => !current);
        navigate(`/${country}/account/my-orders`)
        window.scrollTo(0, 0);
      });
    } else {
      window.print();
    }
  };


  return (
    <div className="account-order-notes">
      <InnerHTMLRenderer htmlContent={header} className='information-header'/>
      <MyAccountAccordion
        items={accordionItems}
        accordionClass="information-accordion-container"
        itemClass="information-accordion-item"
        titleClass="information-accordion-title"
        contentClass="information-accordion-content"
        icons={{closeIcon: ArrowUp, openIcon: ArrowDown}}
        />

      {orderStatus === "wait_money_transfer" && (
        <>
          <span
            onClick={() => setSubmitMode((currentMode: string) => currentMode === PRINT ? CANCEL : PRINT)}
            className="cancel-order"
          >
            {translator.translate(language, "my_account", `MyOrders/details/cancel`)}
          </span>
          { submitMode === CANCEL &&
          <div className="checkbox-list-container">
            <span >{translator.translate(language, "my_account", `MyOrders/details/cancelTitle`)}</span>
            {checkboxOptions.map((option: any, index: number) => {
              if(index != 6){ //to not display the 'other' deafult option
                return(
                <Checkbox
                  key={index}
                  id={index.toString()}
                  onClick={(checkvalue:string) => checkvalue.includes('X') ? setCancellationOptionID(index) : setCancellationOptionID(5)}
                  label={option}
                  value={false}
                  groupName="cancellationOptions"
                  errorMode={false}
                  isChosenInCheckboxlist={index === cancellationOptionID ? true : false}
                />
                )
              }
              return null;
            })}
          </div>
          }
        </>
      )}

      <div className="print-button-container">
        <SubmitButton
          id="print-button"
          width="100%"
          placeholder={translator.translate(language, "my_account", `MyOrders/details/${submitMode}`)}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

export default MyAccountOrderNotes;
