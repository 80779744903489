import React, { useMemo } from 'react'
import X from "../../icons/X.svg";
import { MainTracker } from '../../PixelTrackers/MainTracker';
import { translate } from '../../utils/translator';
import { useShippingPageContext } from '../../pages/v2/ShippingV2/providers/ShippingPageProvider';


type props = {
    handleCustomerUsingSuggestion: (byteplantSuggestion: ByteplantApiResponse) => void
    handleCustomerIgnoreSuggestion: () => void
};


const ByteplantAddressValidatorModal: React.FC<props> = ({handleCustomerUsingSuggestion, handleCustomerIgnoreSuggestion}) => {

    const {byteplantAddressValidatorState, setByteplantAddressValidatorState, language } = useShippingPageContext();

    const handleHideModal = () => {
        setByteplantAddressValidatorState(current => ({ ...current, showModal: false }))
    }

    const statusCode = useMemo(() => {
        return byteplantAddressValidatorState.responseData?.status || ""
    }, [byteplantAddressValidatorState.responseData])

    const formattedAddress = useMemo(() => {
        return byteplantAddressValidatorState.responseData?.formattedaddress || ""
    }, [byteplantAddressValidatorState.responseData])

    const translationKeys = useMemo(() => {

        if (statusCode === "INVALID") {
            MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': translate('en', "default", "AddressValidationModal/title-invalid")})
            return {
                title: "AddressValidationModal/title-invalid",
                text: "AddressValidationModal/text-invalid",
                buttonChange: "AddressValidationModal/button-change-invalid",
                buttonKeep: "AddressValidationModal/button-keep-invalid",
            }

        } else if (statusCode === "SUSPECT") {
            MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': translate('en', "default", "AddressValidationModal/title-suspected")})
            return {
                title: "AddressValidationModal/title-suspected",
                text: "AddressValidationModal/text-suspected",
                buttonChange: "AddressValidationModal/button-change-suspected",
                buttonKeep: "AddressValidationModal/button-keep-suspected",
            }
        }

        return null

    },[language, statusCode])


    if (!statusCode || !translationKeys) {
        return <></>
    }

    return (
        <div
            id="byteplant-address-validator-modal-container"
            className='flex flex-col relative bg-white rounded-md rounded-tl-[30px] p-[30px] rounded-br-[30px] shadow-[0px_60px_120px_rgba(0,0,0,0.15)] w-full md:w-[640px] '
        >

            {/* Modal Close button */}
            <div id='byteplant-address-validator-modal-close' className='w-full flex justify-end'>
                <img src={X} alt="X" className='w-[18px] h-[18px] cursor-pointer' onClick={handleHideModal} />
            </div>

            {/* Modal Header */}
            <div id='byteplant-address-validator-modal-header' className='flex flex-col gap-[6px]'>
                <h1 id='byteplant-address-validator-modal-title' className='text-[18px] md:text-[24px] font-bold text-[#0d2c54]'> {translate(language, "default", translationKeys.title)} </h1>
                <p id='byteplant-address-validator-modal-description' className='text-[#656d79] text-[14px] font-roboto'> {translate(language, "default", translationKeys.text)} </p>
            </div>


            {/* Address suggestion*/}
            <p id='byteplant-address-suggestion' className='bg-[#F7F7F7] min-h-[40px] p-5 my-5 rounded-sm  font-semibold font-roboto text-[#656d79]'>
                {formattedAddress}
            </p>


            {/* Modal Action Buttons */}
            <div id='byteplant-address-validator-actions-container' className='w-full flex flex-col md:flex-row justify-center items-center gap-2'>

                {/* Keep Button (Don't take suggestion) */}
                <button 
                    id='byteplant-address-validator-action-keep'
                    type='button'
                    style={{border:"2px solid #11DDAC"}} 
                    className="cursor-pointer w-full hover:opacity-75 ring-0 focus:ring outline-none text-[14px] h-[55px] px-5 bg-white text-[#11DDAC] rounded-[3px] font-bold transition duration-400"
                    onClick={() => {
                        MainTracker.track('custom', `Decision on ${statusCode.toLowerCase()} address`, {'Answer': translate('en', "default", translationKeys.buttonKeep)}) 
                        handleCustomerIgnoreSuggestion();
                        handleHideModal();
                    }}>
                        
                    {translate(language, "default", translationKeys.buttonKeep)}
                </button>

                {/* Change Button (Take suggestion) */}
                <button 
                    id='byteplant-address-validator-action-change' 
                    type='button'
                    style={{border:"2px solid #11DDAC"}}
                    className="cursor-pointer w-full hover:opacity-75 outline-none text-[14px] h-[55px] px-5 bg-white text-[#11DDAC] rounded-[3px]  font-bold transition duration-400"
                    onClick={() => {
                        MainTracker.track('custom', `Decision on ${statusCode.toLowerCase()} address`, {'Answer': translate('en', "default", translationKeys.buttonChange)}) 
                        handleCustomerUsingSuggestion(byteplantAddressValidatorState.responseData!);
                        handleHideModal();
                    }}>

                    {translate(language, "default", translationKeys.buttonChange)}
                </button>

            </div>

        </div>
    );
}

export default ByteplantAddressValidatorModal