import React, { createContext, RefObject, useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as SessionStorageWrapper from "../../../../utils/SessionStorageWrapper";
import { PERSONAL_ADDRESS, DHL_PACKSTATION, SELF_PICKUP } from '../constants';
import { useCartContext } from '../../../../customHooks/useCart';
import { useAppContext } from '../../../../providers/AppProvider';
import { MainTracker } from '../../../../PixelTrackers/MainTracker';
import { translate } from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import { clientConfig } from '../../../../config';


export type OptionPanelCode = typeof PERSONAL_ADDRESS | typeof DHL_PACKSTATION | typeof SELF_PICKUP;
export type CouponWidgetState = {
  inputCode: string,
  errorMsg?: string,
  exposeExtraEnterContainer: boolean,
  additionalCouponData?: any
}

export type DigitalCannabisIDState = {
  showWidget: boolean,
  isToggleEnabled: boolean,
  personalIDNumber: string,
  errorMsg?: string
  productItem: ProductItem | null
  widgetRef: RefObject<HTMLDivElement> | null
  isToggleLoading:boolean
}

export type IdentityCheckState = {
  showWidget: boolean,
  isPrecautionToggleEnabled: boolean;
  precautionLevel: string;
}

export type ByteplantAddressValidatorState = {
  showModal: boolean
  shouldValidate: boolean,
  responseData: ByteplantApiResponse | null,
  customerUsedSuggestion: boolean
}

interface ShippingPageContextType {

  // ---- Global states ----
  language: string | undefined;
  country: string | undefined;
  finishedFetching: boolean;
  setFinishedFetching: (finished: boolean) => void
  selectedOptionPanelCode: OptionPanelCode;
  setSelectedOptionPanelCode: (code: OptionPanelCode) => void
  isGoogleAPILoaded: boolean;
  setIsGoogleAPILoaded: (loaded: boolean) => void
  selectedFormID: string;
  setSelectedFormID: (formID: string) => void
  countryCurrenciesByID: Record<string, { locale: string, rate: number, isDefault: boolean, countryID: string }> // By countryID
  setCountryCurrenciesByID: (currencies: Record<string, { locale: string, rate: number, isDefault: boolean, countryID: string }>) => void
  customerAddress: Record<"packstation" | "regular", any>; // TODO: maybe type to customer addresses in the client ??
  setCustomerAddress: (address: Record<"packstation" | "regular", any>) => void
  preSelectedExternalPharmacy: ExternalPharmacy | null
  setPreSelectedExternalPharmacy: (pharmacy: ExternalPharmacy) => void
  selfPickupPharmacySettings: any,
  setSelfPickupPharmacySettings: (settings: any) => void
  hidePackstation: boolean,
  setHidePackstation: (shouldHide: boolean) => void
  contentType: string,
  setContentType: (contentType: string) => void
  categoryCatalogMention: string,
  setCategoryCatalogMention: (catalogMention: string) => void
  allowPrescriptionRedemption: boolean,
  setAllowPrescriptionRedemption: (allow: boolean) => void
  isSubscriptionAlreadyPicked: boolean,
  setIsSubscriptionAlreadyPicked: (isPicked: boolean) => void
  OTCAddonsProducts: any[],
  setOTCAddonsProducts: (OTCAddonsProducts: any[]) => void
  showOTCAddonsModal: boolean,
  setShowOTCAddonsModal: (show: boolean) => void
  couponWidgetState: CouponWidgetState,
  setCouponWidgetState: React.Dispatch<React.SetStateAction<CouponWidgetState>>,
  isDeepLinkCouponApplied: boolean
  setIsDeepLinkCouponApplied: React.Dispatch<React.SetStateAction<boolean>>,
  digitalCannabisIDState: DigitalCannabisIDState,
  setDigitalCannabisIDState: React.Dispatch<React.SetStateAction<DigitalCannabisIDState>>,
  identityCheckState: IdentityCheckState,
  setIdentityCheckState: React.Dispatch<React.SetStateAction<IdentityCheckState>>,
  byteplantAddressValidatorState: ByteplantAddressValidatorState,
  setByteplantAddressValidatorState: React.Dispatch<React.SetStateAction<ByteplantAddressValidatorState>>,
  isSubmitButtonDisabled: boolean,
  setIsSubmitButtonDisabled: (isButtonDisabled: boolean) => void,
  forceFreeTreatmentFeeCoupon: boolean,
  setForceFreeTreatmentFeeCoupon : (couponCode: boolean) => void, 

  // ---- Global handlers (General functions) ----
  handleShippingSubmit: (formData: DeliveryAddressFormType | DHLPackstationFormType, customerUsedAddressSuggestion?: boolean) => void
  handleDefaultCartCurrency: (currencies: Record<string, { locale: string, rate: number, isDefault: boolean, countryID: string }>) => void
  handleApplyCoupon: (inputCode: string, shouldOverrideCartBonuses?: boolean) => void
  handleDigitalCannabisToggle: (newIsToggleOn: boolean) => void
  handleOTCProductIncrement: (productItem: ProductItem) => Promise<void>
  handleOTCProductDecrement: (productItem: ProductItem) => Promise<void>
  handleOnlyPrescriptionChange: (isOnlyPrescription: boolean) => void
  handleAddressValidation: (formData: DeliveryAddressFormType) => void

}

// Create the context
const ShippingPageContext = createContext<ShippingPageContextType | undefined>(undefined);

// Create the provider
export const ShippingPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const { setIsLoading, setStickyNotification } = useAppContext();
  const {  getCart, clearCart ,setCartCurrency, saveCart, deactivateSubscriptionInDB, incrementProductItemQuantity, decrementProductItemQuantity } = useCartContext();
  let cart = getCart();
  const navigate = useNavigate();
  const { language, country } = useParams<{ language: string, country: string }>();
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [selectedOptionPanelCode, setSelectedOptionPanelCode] = useState<OptionPanelCode>(PERSONAL_ADDRESS);
  const [isGoogleAPILoaded, setIsGoogleAPILoaded] = useState(false);
  const [selectedFormID, setSelectedFormID] = useState("");
  const [countryCurrenciesByID, setCountryCurrenciesByID] = useState<Record<string, { locale: string, rate: number, isDefault: boolean, countryID: string }>>({});
  const [customerAddress, setCustomerAddress] = useState<Record<"packstation" | "regular", any>>({"packstation": {}, "regular": {}});
  const [preSelectedExternalPharmacy, setPreSelectedExternalPharmacy] = useState<ExternalPharmacy | null>(null);
  const [selfPickupPharmacySettings, setSelfPickupPharmacySettings] = useState<any>(null);
  const [hidePackstation, setHidePackstation] = useState(false);
  const [contentType, setContentType] = useState<string>("");
  const [categoryCatalogMention, setCategoryCatalogMention] = useState<string>("");
  const [allowPrescriptionRedemption, setAllowPrescriptionRedemption] = useState(false);
  const [isSubscriptionAlreadyPicked, setIsSubscriptionAlreadyPicked] = useState(false);
  const [OTCAddonsProducts, setOTCAddonsProducts] = useState<any[]>([]);
  const [showOTCAddonsModal, setShowOTCAddonsModal] = useState(false);
  const [isDeepLinkCouponApplied, setIsDeepLinkCouponApplied] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [forceFreeTreatmentFeeCoupon, setForceFreeTreatmentFeeCoupon] = useState<boolean>(false);
  const [identityCheckState, setIdentityCheckState] = useState<IdentityCheckState>({
    showWidget: false,
    isPrecautionToggleEnabled: false,
    precautionLevel: "info",
  })
  const [couponWidgetState, setCouponWidgetState] = useState<CouponWidgetState>({
    inputCode: "",
    errorMsg: "",
    exposeExtraEnterContainer: false
  })

  const [digitalCannabisIDState, setDigitalCannabisIDState] = useState<DigitalCannabisIDState>({
    showWidget: false,
    isToggleEnabled: false,
    personalIDNumber: "",
    errorMsg: "",
    productItem: null,
    widgetRef: null,
    isToggleLoading: false,
  })

  const [byteplantAddressValidatorState, setByteplantAddressValidatorState] = useState<ByteplantAddressValidatorState>({
    showModal: false,
    shouldValidate: false,
    responseData: null,
    customerUsedSuggestion: false
  })


  const handleOnlyPrescriptionChange = async (isOnlyPrescriptionSelected: boolean) => {

    // 1. Sanity check to ensure the value has changed before proceeding
    if (Boolean(cart.only_prescription) === isOnlyPrescriptionSelected) {
      return;
    }

    // 2. Set loading state before updating DB
    setIsLoading(true);

    // 3. DB call
    http.postToServer("cart/only-prescription", { isOnlyPrescriptionSelected }, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID])
      .then(async (response) => {
        const cartView = response.data.cartView as CartView;

        // Update the cartView
        if (cartView) {
          saveCart({ ...cartView.cart, products: cartView.products });
        }

        // try to reactivate coupon
        const couponCode = cartView.cart.bonus_coupon_code || couponWidgetState.inputCode;
        if (couponCode && !cartView.cart.subscription_plan_id && !cartView.cart.auto_bonus) {
          await handleApplyCoupon(couponCode);
        }

      })
      .catch((error) => {
        const cartView = error.response?.data?.cartView || null;
        // Update the cart view
        if (cartView) {
          saveCart({ ...cartView.cart, products: cartView.products });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })


  };

  const handleDefaultCartCurrency = (currencies: Record<string, { locale: string, rate: number, isDefault: boolean, countryID: string }>) => {

    //Find the default one: 
    Object.values(currencies).forEach((currency) => {
      if (currency.isDefault) {
        setCartCurrency(currency);
      }
    })

  }

  const handleApplyCoupon = async (inputCode: string, shouldOverrideCartBonuses = true) => { //shouldOverrideCartBonuses means that we will delete the bonus on conditions fail
    setIsSubmitButtonDisabled(true);
    http.postToServer("cart/activate-coupon", { inputCode, shouldOverrideCartBonuses }, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID])
      .then((response) => {
        const cartView = response.data.cartView;
        // Update the cartView
        if (cartView) {
          saveCart({
            ...cartView.cart,
            products: cartView.products
          });
        }

        // Update the coupon widget state
        setCouponWidgetState((current) => ({
          ...current,
          inputCode,
          exposeExtraEnterContainer: false
        }));

        // Update main tracker
        MainTracker.superProperties({
          "Coupon Amount": cart.bonus_value,
          "Coupon Amount EUR": cart.bonus_value,
          "Coupon ID": cart.bonus_id,
          "Coupon Name": "test", //TODO: figure out what to do with that
          "Coupon Code": inputCode
        })

        MainTracker.track('click', 'Apply Coupon');

      })
      .catch((error) => {
        const responseMsg = error.response?.data?.message || "Network Error";
        const minimum_price_required = error.response?.data?.minimum_price_required || 0;
        const treatment_fee_fixed = error.response?.data?.treatment_fee_fixed || 0;
        const cartView = error.response?.data?.cartView || null;
        window.clientLogger.warn("Failed to activate coupon in DB (cart)", error);

        // Update the cart view
        if (cartView) {
          saveCart({
            ...cartView.cart,
            products: cartView.products
          });
        }

        // MP event
        MainTracker.track('click', 'Apply Coupon Failed', { "Coupon Code": inputCode, "Coupon Error": responseMsg });

        setCouponWidgetState({
          ...couponWidgetState,
          inputCode,
          errorMsg: translate(language, "shipping", `CouponWidget/${responseMsg}` ?? responseMsg),
          additionalCouponData: {
            minimum_price_required: minimum_price_required,
            treatment_fee_fixed: treatment_fee_fixed
          }
        })
      })
      .finally(() => {
        setIsSubmitButtonDisabled(false);
      })
  }

  const handleDigitalCannabisToggle = async (newIsToggleOn: boolean) => {
    setIsSubmitButtonDisabled(true);
    if (newIsToggleOn) {
      // 1. Update the state
      setDigitalCannabisIDState((current) => {
        return { ...current, isToggleLoading: true, }
      })

      // 2. Update The DB
      await incrementProductItemQuantity(digitalCannabisIDState.productItem!);

      setDigitalCannabisIDState((current) => {
        return { ...current, isToggleLoading: false, isToggleEnabled: true }
      })


    } else {
      // 1. Update the state
      setDigitalCannabisIDState((current) => {
        return { ...current, isToggleLoading: true }
      })

      // 2. Update The DB
      await decrementProductItemQuantity(digitalCannabisIDState.productItem!);

      setDigitalCannabisIDState((current) => {
        return { ...current, isToggleLoading: false, isToggleEnabled: false }
      })
    }

    // 3. Try to reactive coupon 
    const couponCode = cart.bonus_coupon_code || couponWidgetState.inputCode;
    if (couponCode && !cart.subscription_plan_id && !cart.auto_bonus) {
      await handleApplyCoupon(couponCode);
    }
    setIsSubmitButtonDisabled(false);
  }

  const handleOTCProductIncrement = async (productItem: ProductItem) => {
    setIsSubmitButtonDisabled(true);
    const response = await incrementProductItemQuantity(productItem)
    const couponCode = cart.bonus_coupon_code || couponWidgetState.inputCode;
    if (response.status === "ok" && couponCode && !cart.subscription_plan_id && !cart.auto_bonus) {
      await handleApplyCoupon(couponCode);
    }
    setIsSubmitButtonDisabled(false);
  }

  const handleOTCProductDecrement = async (productItem: ProductItem) => {
    setIsSubmitButtonDisabled(true);
    const response = await decrementProductItemQuantity(productItem);
    const couponCode = cart.bonus_coupon_code || couponWidgetState.inputCode;
    if (response.status === "ok" && couponCode && !cart.subscription_plan_id && !cart.auto_bonus) {
      await handleApplyCoupon(couponCode);
    }
    setIsSubmitButtonDisabled(false);
  }

  const handleCannabisIDValidation = useCallback(() => {

    const inputValue = digitalCannabisIDState.personalIDNumber;
    const germanIDRegex = /^[A-Za-z0-9]{9}$/;
    let isInputValid = false
    let errorMsg = "";

    if (!inputValue) {
      errorMsg = "Bitte geben Sie Ihre persönliche Personalausweis-Nummer ein"
      isInputValid = false

    } else if (!germanIDRegex.test(inputValue)) {  //Regex check of german id number
      errorMsg = "Bitte geben Sie eine gültige Personalausweis-Nummer ein"
      isInputValid = false

    } else {
      isInputValid = true
    }

    setDigitalCannabisIDState((prevState) => ({
      ...prevState,
      errorMsg
    }))

    return isInputValid
  }, [digitalCannabisIDState.personalIDNumber])


  const handleAddressValidation = async (formData: DeliveryAddressFormType) => {
    const apiKey = clientConfig.BYTEPLANT_API_KEY;
    let addressValidated = false

    // 1. Skip validation 
    if (clientConfig.SKIP_ADRRESS_VALIDATION) {
      return true
    }

    // 2. Skip validation if address is the same as in the customer address
    if (customerAddress["regular"]?.address === formData.address && customerAddress["regular"]?.zip === formData.zip && customerAddress["regular"]?.city === formData.city) {
      return true
    }

    // 3. Build byteplant api url
    const params = new URLSearchParams({
      StreetAddress: formData.address,
      City: formData.city,
      PostalCode: formData.zip,
      CountryCode: formData.country,
      APIKey: apiKey,
    });

    const url = `https://api.address-validator.net/api/verify?${params.toString()}`;

    setIsSubmitButtonDisabled(true);
    // 4. Send request
    fetch(url)
      .then((response) => response.json())
      .then((response: ByteplantApiResponse) => {

        // Save the response in the state
        setByteplantAddressValidatorState((current) => ({
          ...current,
          responseData: response,
          showModal: ["SUSPECT", "INVALID"].includes(response.status)
        }))

        switch (response.status) {
          case "VALID":
            MainTracker.track('custom', 'Address Validation Valid', {})
            addressValidated = true;
            break;

          case "SUSPECT":
            MainTracker.track('custom', 'Address Validation Suspected', {})
            addressValidated = false;
            break;

          case "INVALID":
            MainTracker.track('custom', 'Address Validation Invalid', {})
            addressValidated = false
            break;

          default:
            MainTracker.track('custom', 'Address Validation Not Opened', {})
            addressValidated = false
            break;
        }

      })
      .catch(error => {
        window.clientLogger.error("Auto Address validation request failed", error);
      });

    setIsSubmitButtonDisabled(false);
    return addressValidated
  }


  const handleShippingSubmitErrorByCode = (errorCode: string | null) => {


    switch (errorCode) {

      case "already_subscribed":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "already subscribed" }); 
        navigate({ pathname: `/${country}/account/orders-subscription`, search: `?ec=as` }); // as = already_subscribed
        break;

      case "dosage_not_in_stock":
      case "product_not_in_stock":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "dosage not in stock" }); 
        navigate({ pathname: `/select_treatment/${country}/${language}/${categoryCatalogMention}`, search: `?ec=dnis` }); // dnis = dosage_not_in_stock
        break;

      case "otc_product_not_in_stock":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "OTC Product not in stock" }); 
        setStickyNotification({
          show: true,
          level: "error",
          showInfoIcon: true,
          title: translate(language, "default", "StickyNotification/danger_title") as string,
          content: translate(language, "default", "StickyNotification/otc_product_not_in_stock") as string,
        })
        break;

      case "no_stock_suitable_for_subscription":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "no stock suitable for subscription" }); 
        deactivateSubscriptionInDB();
        setStickyNotification({
          show: true,
          level: "error",
          showInfoIcon: true,
          title: translate(language, "default", "StickyNotification/danger_title") as string,
          content: translate(language, "default", "StickyNotification/product_not_in_stock_for_subscription") as string,
        })
        break;

      case "country_not_allowed":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "country does not equal destinationCountry" }); 
        setStickyNotification({
          show: true,
          level: "error",
          showInfoIcon: true,
          title: translate(language, "default", "StickyNotification/danger_title") as string,
          content: translate(language, "default", "StickyNotification/country_does_not_equal_destinationCountry") as string,
        })
        break;


      case "invalid_order_price":
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "Order price cannot be 0 or less" }); 
        setStickyNotification({
          show: true,
          level: "error",
          showInfoIcon: true,
          title: translate(language, "default", "StickyNotification/danger_title") as string,
          content: translate(language, "default", "StickyNotification/invalid_order_price") as string,
        })
        break;


      default:
        MainTracker.track("custom", "Shipping Post Failure", { "Reason": "unknown error" }); 
        setStickyNotification({
          show: true,
          level: "error",
          showInfoIcon: true,
          title: translate(language, "default", "StickyNotification/danger_title") as string,
          content: "Server Failed",
        })
        break;
    }


  }

  const handleShippingSubmit = async (formData: DeliveryAddressFormType | DHLPackstationFormType, customerUsedAddressSuggestion?: boolean) => {

    // 1. Validate CannabisId input if added to order:
    if (digitalCannabisIDState.isToggleEnabled) {
      const isCannabisIDValid = handleCannabisIDValidation()
      if (!isCannabisIDValid) {
        //Scroll to digital-cannabis-id-widget-container
        if (digitalCannabisIDState.widgetRef) {
          digitalCannabisIDState.widgetRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
        }

        return
      }
    }

    // 2. Validate IdentityCheck
    if (identityCheckState.showWidget && !identityCheckState.isPrecautionToggleEnabled) {
      setIdentityCheckState((prevState) => ({
        ...prevState,
        precautionLevel: "error"
      }))

      return
    }

    // 3. Validate address, if we didn't do it already (no responseData by byteplant)
    const byteplantSuggestion = byteplantAddressValidatorState.responseData;

    let isAddressAutoVerified = false;
    if (!byteplantSuggestion && selectedFormID === "delivery-address-form") {
      isAddressAutoVerified = await handleAddressValidation(formData as DeliveryAddressFormType)
      if (!isAddressAutoVerified) { //Will open the Validator modal
        return
      }

      // Already fetched the address from byteplant, check if customer used the suggestion or not
    } else if (customerUsedAddressSuggestion) {
      isAddressAutoVerified = true;
    } else {
      isAddressAutoVerified = false;
    }

    // 4. MP Events
    MainTracker.superProperties({ "Cannabis ID": digitalCannabisIDState.isToggleEnabled ? "Yes" : "No" });

    setIsLoading(true);
    // 5. Post To the server
    http.postToServer(`v2/orders`, {
      shippingFormData: formData,
      formType: selectedFormID === "delivery-address-form" ? "delivery" : "dhl_packstation",
      isAddressAutoVerified,
      personalIDNumber: digitalCannabisIDState.personalIDNumber.toUpperCase(),
      trackingData: window.eventsTracking && window.eventsTracking.getData(),
    },
      [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID]
    )
    .then(response => {
      const responseData = response.data
      sessionStorage.removeItem('cpn');
      MainTracker.track("custom", "Placed Order", responseData);
      const newOrderHashID = responseData.newOrderHashID;
      SessionStorageWrapper.setItem("last_shipped_cart", cart.id);
      clearCart();
      navigate(`/payment/${country}/${language}/${newOrderHashID}`)

    })
    .catch(error => {
      const errorResponse = error.response?.data;
      handleShippingSubmitErrorByCode(errorResponse.status);

      if (errorResponse.logLevel === "warn") {
        window.clientLogger.warn("Error sumbit shipping", error);
      }
      else {
        window.clientLogger.error("Error sumbit shipping", error);
      }

    })
    .finally(() => setIsLoading(false))
  }

  return (
    <ShippingPageContext.Provider
      value={{
        language,
        country,
        finishedFetching,
        setFinishedFetching,
        selectedOptionPanelCode,
        setSelectedOptionPanelCode,
        isGoogleAPILoaded,
        setIsGoogleAPILoaded,
        selectedFormID,
        setSelectedFormID,
        handleOnlyPrescriptionChange,
        countryCurrenciesByID,
        setCountryCurrenciesByID,
        customerAddress,
        setCustomerAddress,
        preSelectedExternalPharmacy,
        setPreSelectedExternalPharmacy,
        selfPickupPharmacySettings,
        setSelfPickupPharmacySettings,
        hidePackstation,
        setHidePackstation,
        contentType,
        setContentType,
        categoryCatalogMention,
        setCategoryCatalogMention,
        allowPrescriptionRedemption,
        setAllowPrescriptionRedemption,
        isSubscriptionAlreadyPicked,
        setIsSubscriptionAlreadyPicked,
        OTCAddonsProducts,
        setOTCAddonsProducts,
        showOTCAddonsModal,
        setShowOTCAddonsModal,
        couponWidgetState,
        setCouponWidgetState,
        handleApplyCoupon,
        isDeepLinkCouponApplied,
        setIsDeepLinkCouponApplied,
        digitalCannabisIDState,
        setDigitalCannabisIDState,
        identityCheckState,
        byteplantAddressValidatorState,
        setByteplantAddressValidatorState,
        setIdentityCheckState,
        isSubmitButtonDisabled,
        setIsSubmitButtonDisabled,
        setForceFreeTreatmentFeeCoupon,
        forceFreeTreatmentFeeCoupon,

        //Handlers
        handleDigitalCannabisToggle,
        handleOTCProductIncrement,
        handleOTCProductDecrement,
        handleShippingSubmit,
        handleAddressValidation,
        handleDefaultCartCurrency,
      }}
    >
      {children}
    </ShippingPageContext.Provider>
  );
};

// Custom hook to use the context
export const useShippingPageContext = (): ShippingPageContextType => {
  const context = useContext(ShippingPageContext);
  // if (!context) {
  //   throw new Error("useShippingPageContext must be used within a ShippingPageProvider");
  // }
  return context || {} as ShippingPageContextType;
};
