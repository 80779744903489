import React,{useState} from "react";
import "./UserDetailsHoverConnected.css";
import { Link } from "react-router-dom";
import { clearCustomerFromSession } from '../../../../reducers/CustomerReducer';
import { clientConfig } from "../../../../config";
import * as translator from "../../../../utils/translator";






interface UserDetailsHoverConnectedProps {
  userData?: {
    first_name?: string;
    last_name?: string;
    email?: string;
  };
  country: string; 
  onClick:()=>void

}

const UserDetailsHoverConnected: React.FC<UserDetailsHoverConnectedProps> = ({ userData, country,onClick }) => {
const [language] = useState(translator.getLanguageByCountry(country))
const handleClick = (e: React.MouseEvent) => {
  e.stopPropagation();
  if (window.innerWidth <= 764) { // Adjust breakpoint as needed
    onClick();
  }
};
  return (
    <>

<div className={`user-details-hover-connected ${country === "fr" ? "fr-card" : ""}`}>
<div className="background-header"></div>
<button id="nav-bar-close-btn" onClick={onClick} className="close-btn">&times;</button>
      <div className="connected-user-flexbox-top">

        <div className="user-info">
            <div className="user-avatar" onClick={() => window.location.href = `/${country}/account/my-orders`}>{userData?.first_name?.charAt(0)?.toUpperCase() ?? "U"}</div>
            <div className="user-text">
            <p className="user-name">{userData?.first_name} {userData?.last_name}</p>
            <p className="user-email">{userData?.email}</p>
            </div>
        </div>
      </div>

      <div className="connected-user-flexbox-buttom">
      <ul className="user-menu">
      <li>
          <Link id="nav-bar-my-orders" to={`/${language}/account/my-orders`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/Shopping-Bag.svg").default} alt="Nav Bar - Orders"/>
            {translator.translate(language, "my_account", 'AsideBar/my-orders')}
          </Link>
        </li>
        {(country !== "fr")?
         (<li>
          <Link id="nav-bar-orders-subscription" to={`/${language}/account/orders-subscription`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/Refresh.svg").default} alt="Nav Bar - Treatments" />
            {translator.translate(language, "my_account", 'AsideBar/orders-subscription')}
          </Link>
        </li>): ""
        }
        <div className="bottom-border"></div>

        <li>
          <Link id="nav-bar-personal-data" to={`/${language}/account/personal-data`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/User.svg").default} alt="Nav Bar - Personal Data" />
            {translator.translate(language, "my_account", 'AsideBar/personal-data')}          </Link>
        </li>   

        <li>
          <Link id="nav-bar-delivery-address" to={`/${language}/account/delivery-address`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/Delivery.svg").default} alt="Nav Bar - Delivery"/>
            {translator.translate(language, "my_account", 'AsideBar/delivery-address')}
          </Link>
        </li>   
        {(country !== "fr")?(
        <li>
          <Link id="nav-bar-questionnaire-defaults" to={`/${language}/account/questionnaire-defaults`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/File-Plus.svg").default} alt="Nav Bar - Questionnaire"/>
            {translator.translate(language, "my_account", 'AsideBar/questionnaire-defaults')}
          </Link>
        </li>  ):""} 

        <div className="bottom-border"></div>
        <li>
          <Link id="nav-bar-ask-a-doctor"  to={`/${language}/account/ask-a-doctor`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/Message.svg").default} alt="Nav Bar - Doctor"/>
            {translator.translate(language, "my_account", 'AsideBar/ask-a-doctor')}
          </Link>
        </li>      
        <li>
          <a id="nav-bar-contact" href={clientConfig.MAIN_WEBSITE_URL + '/' + country + '/' + translator.translate(language, "my_account", 'routes/contact')} onClick={handleClick}  className="card-link">
            <img src={require("../../../../icons/secondery green/Help.svg").default} alt="Nav Bar - Support"/>
            {translator.translate(language, "my_account", 'AsideBar/contact')}
          </a>
        </li>
        <div className="bottom-border"></div>
        <li>
          <Link id="nav-bar-settings" to={`/${language}/account/newsletter`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/settings.svg").default} alt="Nav Bar - Settings" />
            {translator.translate(language, "my_account", 'AsideBar/settings')}
          </Link>
        </li>  
        <li>
          <Link id="nav-bar-change-password" to={`/${language}/account/change-password`} onClick={handleClick} className="card-link">
            <img src={require("../../../../icons/secondery green/change-password.svg").default} alt="Nav Bar - Password" />
            {translator.translate(language, "my_account", 'AsideBar/change-password')}
          </Link>
        </li>  

        <div className="bottom-border"></div>
        <div className="mobile-bottom-bar"></div>

        <li id="nav-bar-logout" className="log-out">
        <a href={clientConfig.MAIN_WEBSITE_URL + '/' + country} onClick={(e) => {e.stopPropagation(); clearCustomerFromSession();}}  className="card-link">
           <img src={require("../../../../icons/secondery green/Log-Out.svg").default} alt="Logout" />
           {translator.translate(language, "my_account", 'AsideBar/logout')}
        </a>
        </li>  
      </ul>
    </div>
    </div>
    </>
  );
};
export default UserDetailsHoverConnected;
