import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const sorted = <T>(arr: T[], callback: (a: T, b: T) => number): T[] => {
    try {
        const copy = JSON.parse(JSON.stringify(arr));
        copy.sort(callback);
        return copy;
    } catch (error) {
        return arr;
    }
};

export const debounce = <T extends (...args: any[]) => Promise<any>>(func: T, delay: number) => { //In milliseconds
  let timer: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    return new Promise<ReturnType<T>>((resolve) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        resolve(await func(...args));
      }, delay);
    });
  };
};



export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toLocaleDateString(date: string | Date, locale?: string, options?: Record<string, string>): string {
  if(!date) {
    return "";
  }
  return new Date(date).toLocaleDateString(locale, options);
}

export function daysDiff(dateOne: Date, dateTwo: Date) {
  const Difference_In_Time = dateOne.getTime() - dateTwo.getTime();
  return Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));
}