import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import "./MyAccountNewsletter.css";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import InnerHTMLRenderer from "../../../../components/InnerHTMLRenderer/InnerHTMLRenderer";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const MyAccountNewsletter = () => {
  const { setActiveMenuLinkId, setStickyNotificationDetails, myAccountData, country } = useOutletContext<any>();
  const [registered, setRegistered] = useState<any>(myAccountData.newsletter || {"email":{"marketing":true},"sms":{"marketing":true,"transactional":true}});
  const language = myAccountData.locale.language_id

  useEffect(() => {
    setActiveMenuLinkId("settings");
  }, []);

  const onSubmit = () => {
    http
      .postToServer(`account-update/newsletter`,
        { registered },
        [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        (window as any).eventsTracking.setData("opt_for_sendables", JSON.stringify(registered), true);
        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/success/Newsletter`),
          level: "info",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }))
      })
      .catch((error) => {
        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
          level: "error",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }));
      })
  }

  const preSetRegistered = (type: string, category: string) => {
    const stateClone = registered
    stateClone[type][category] = !registered[type][category]
    setRegistered(stateClone)
  }

  return (

    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", `Newsletter/title`)}
          description={translator.translate(language, "my_account", `Newsletter/subTitle`)}
        />

        <div className="newsletter-card">
          <p>{translator.translate(language, "my_account", `Newsletter/description`)}</p>
          <div className="sendable-box">
            <h5>{translator.translate(language, "my_account", `Newsletter/email/title`)}</h5>
            <h6>{translator.translate(language, "my_account", `Newsletter/type_subtitle`)}</h6>
            <Checkbox
              id={'optInMarketingEmails'}
              onClick={() => { preSetRegistered('email', 'marketing') }}
              label={translator.translate(language, "my_account", `Newsletter/email/marketing`)}
              value={registered.email.marketing}
              groupName="optToNewsletter"
              errorMode={false}
              isChosenInCheckboxlist={registered.email.marketing}
            />
          </div>
          <div className="sendable-box">
            <h5>{translator.translate(language, "my_account", `Newsletter/sms/title`)}</h5>
            <h6>{translator.translate(language, "my_account", `Newsletter/type_subtitle`)}</h6>
            <Checkbox
              id={'optInTrSMS'}
              onClick={() => { preSetRegistered('sms', 'transactional') }}
              label={translator.translate(language, "my_account", `Newsletter/sms/transactional`)}
              value={registered.sms.transactional}
              groupName="optToNewsletter"
              errorMode={false}
              isChosenInCheckboxlist={registered.sms.transactional}
            />
            <Checkbox
              id={'optInMarketingSMS'}
              onClick={() => { preSetRegistered('sms', 'marketing') }}
              label={translator.translate(language, "my_account", `Newsletter/sms/marketing`)}
              value={registered.sms.marketing}
              groupName="optToNewsletter"
              errorMode={false}
              isChosenInCheckboxlist={registered.sms.marketing}
            />
          </div>
          <p>{translator.translate(language, "my_account", `Newsletter/thank_you`)}</p>

          <SubmitButton
            id={"newsletter-submit"}
            placeholder={translator.translate(language, "my_account", `Newsletter/button`)}
            onSubmit={onSubmit}
          />
        </div>
        {country == 'fr' &&
          <div className="newsletter-disclaimer-card">
            <InnerHTMLRenderer htmlContent={translator.translate(language, "my_account", `Newsletter/disclaimer`)} />
          </div>
        }
      </div>
    </>
  )
};

export default MyAccountNewsletter;
