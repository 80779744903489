import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as translator from "../../utils/translator";
import * as http from "../../utils/http";
import * as SocialMediaTracker from "../../PixelTrackers/SocialMediaTracker";
import HeaderController from "../../components/Headers/HeaderController";
import ForgotPasswordModal from "../../components/Modals/ForgotPasswordModal/ForgotPasswordModal";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../components/ServerError/ServerError";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import Question from "./components/Question/Question";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import { useCustomerContext } from "../../reducers/CustomerReducer";
import LoginQuestion from "./components/LoginQuestion/LoginQuestion";
import "./Questionnaire.css"
import "./components/Question/Question.css";
import "./components/SubQuestion/SubQuestion.css";
import {
  bundleQuestionnaireForSending,
  formatQuestionnaire,
  isQuestionaireBlocking,
} from "./components/helpers";
import { useQuestionnaire } from "./customHooks/useQuestionnaire";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import Footer from "../../components/Footer/Footer";
import EmojiBlock from "./components/EmojiBlock/EmojiBlock";
import DarkLoadingModal from "../../components/Modals/DarkLoadingModal/DarkLoadingModal";
import { SkipQuestionnaireModal } from "../../components/Modals/SkipQuestionnaireModal/SkipQuestionnaireModal";
import { useCartContext } from "../../customHooks/useCart";
import { QUESTIONNAIRE_PAGE_VIEW_EVENT } from "../../utils/consts";


let dangerSignificancesRefs: any = []; // mainly for scrolling back to red significances on submit

type MyAccountQuestionnaireDefaultsData = {
  flattenedQuestionnaire: any[]; //questionareDefauls doesn't have questionnaire mention, so he has to provied flattend and questioAnswer
  questionAnswersObject: any[];
  country: string;
  language: string;
};

interface QuestionnaireProps {
  myAccountQuestionnaireDefaults?: MyAccountQuestionnaireDefaultsData | null  // if it came from my account
  submitButtonText?:string;
  onSubmitOverride?: (wasRefilled:boolean, bundledQuestionnaireForSending: any, files: Array<any>) => void; // overriding the default onSubmit call that creates cart and etc...
  overrideQuestionnaireParams?:any;
  prefilledQuestionnaire?:any;
  navigationOverride?: () => void;

}

const Questionnaire: React.FC<QuestionnaireProps> = ({submitButtonText=null, overrideQuestionnaireParams = null, myAccountQuestionnaireDefaults = null, onSubmitOverride = null,prefilledQuestionnaire = null, navigationOverride = null }) => {

  const navigate = useNavigate();
  const [searchParamas] = useSearchParams();
  const {saveCart} = useCartContext();
  let { country, category, language } = useParams();
  country = myAccountQuestionnaireDefaults || overrideQuestionnaireParams ? myAccountQuestionnaireDefaults?.country || overrideQuestionnaireParams.country : country;
  language = myAccountQuestionnaireDefaults || overrideQuestionnaireParams ? myAccountQuestionnaireDefaults?.language || overrideQuestionnaireParams.language : language;
  category = overrideQuestionnaireParams ? overrideQuestionnaireParams.optionalCategory : category;

  const { customerState } = useCustomerContext()
  const urlParams = new URLSearchParams(window.location.search);
  const catalogID = urlParams.get("catalog_id");
  const selfPickUpPharmacyID = urlParams.get("sppi"); //Self pickup pharmacy ID
  const cameFromOutOfStockBanner = urlParams.has("came_from_out_of_stock_banner");

  // ---------------------------------------------- questionnaire data  ----------------------------------------------
  const questionnaireAttributes = useQuestionnaire();

  // -------------------------------------------------------------------------------------------------------------
  const [files, setFiles] = useState<any>([]);
  const [mention, setMention] = useState<any>(category);
  const [loading, setLoading] = useState<boolean>(myAccountQuestionnaireDefaults ? false : true);
  const [postLoading,setPostLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [questionnaireTitle, setQuestionnaireTitle] = useState("");
  const [autoDiscount, setAutoDiscount] = useState<any>(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);
  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
  });

  const dangerSignificancesRefsDispatch = (action: "add" | "remove", ref: any) => {
    if (action === "add") {
      dangerSignificancesRefs.push(ref);

    } else { // remove
      dangerSignificancesRefs = dangerSignificancesRefs.filter((existingRef: any) => existingRef.id !== ref.id);
    }
  }


  const handleErrorNotification = (title: string, content: any) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification({ show: true, title, content });
    }
  };

  useEffect(() => {


    if (myAccountQuestionnaireDefaults) {
      questionnaireAttributes.setQuestionnaireTree(myAccountQuestionnaireDefaults.questionAnswersObject);
      questionnaireAttributes.setFlattenedQuestionnaire(myAccountQuestionnaireDefaults.flattenedQuestionnaire);

    } else {

      const fetchingStartTime = new Date().getTime();

      window.eventsTracking.track({e: 'Questionnaire loading started'});
      let fetchFinished = false

      setTimeout(()=>{
        if (!fetchFinished){
          MainTracker.track('custom', 'Questionnaire loading 3sec exceeded');
        }
      },3000)

      //Regular from questionnaire page
      http
        .getFromServer(`questionnaire/${country}/${language}/${mention}`, [http.MIDDLEWARES.CUSTOMER_ID])
        .then((response) => {
          const fetchingTotalTime = new Date().getTime() - fetchingStartTime;

          questionnaireAttributes.setQuestionnaireTree(response.data.questionAnswersObject);
          questionnaireAttributes.setFlattenedQuestionnaire(response.data.flattenedQuestionnaire);
          setMention(response.data.categoryMention);
          setQuestionnaireTitle(response.data.questionnaireTitle);
          prefillQuestionnaire(response.data.prefilledQuestionnaire)
          setAutoDiscount(response.data.autoDiscount);
          MainTracker.superProperties({ "Category Catalog": response.data.categoryShortcut });
          window.eventsTracking.track({e: 'Questionnaire loading ok',p:{ Duration: String(fetchingTotalTime / 1000) }});
          SocialMediaTracker.track("pv", QUESTIONNAIRE_PAGE_VIEW_EVENT, {mention, searchParamas: Object.fromEntries(searchParamas.entries()) });
        })
        .catch((err) => {
          window.clientLogger.error("error getting data in questionnaire", err)
          const fetchingTotalTime = new Date().getTime() - fetchingStartTime;
          MainTracker.track('custom', 'Questionnaire loading failed',  { Duration: String(fetchingTotalTime / 1000) } );
          setError(500);
        })
        .finally(() => {
          setLoading(false);
          fetchFinished = true
        })
    }


    if(prefilledQuestionnaire){
      prefillQuestionnaire(prefilledQuestionnaire);
    }


  }, []);


  const prefillQuestionnaire = (prefilledQuestionnaire: any[]) => {

    prefilledQuestionnaire.forEach((questionAnswerPair: any) => {
      switch (questionAnswerPair.recipe.type) {
        case "textarea":
        case "date":
        case "number":
          onTextChange(
            questionAnswerPair.recipe.recipe_answer_id,
            questionAnswerPair.recipe.value,
            questionAnswerPair.recipe.validation_rule,
            questionAnswerPair.recipe.recipe_question_id 
          );
          break;
        
        case "check_list":
          let activeChildern = questionAnswerPair.children?.filter((child : any) => {
            return questionAnswerPair.recipe.recipe_answer_id.includes(child.recipe.id)
          })

          if (activeChildern) {
            for (let child of activeChildern) {
              onAnswerClick(child, true);
            }
          }

          break;

        default:
          onAnswerClick(questionAnswerPair, true);
          break;
      }
    })
  }


  const onTextChange = (answerId: number, text: String | null, validationRule: any, recipeQuestionId: number | null) => {
    if (text && !questionnaireAttributes.answersSelectedMap[answerId]) {
      //fire event only where there is text and its the first time the answers object is changing
      window.eventsTracking.track({e:'Click On Answer',p:{ 'Question id': recipeQuestionId, 'Answer id': answerId }});
    }


    
    questionnaireAttributes.setAnswersSelectedMap(
      (form: any) => ({
        ...form,
        [answerId]: text,
      })
    )

    //update the validation object
    let isInputValid = true;

    //if there is an upper bound
    if (validationRule && validationRule.max_value) {
      isInputValid = Number(text) <= validationRule.max_value;
    }

    //if there is a lower bound
    if (validationRule && validationRule.min_value) {
      isInputValid = isInputValid && validationRule.min_value <= Number(text);
    }

    questionnaireAttributes.setQuestionnaireValidationObject(
      (oldVal: any) => {
        return {
          ...oldVal,
          [answerId]: isInputValid,
        };
      }
    );
  }

  const onAnswerClick = (answer: any, value: any) => {
    window.eventsTracking.track({e:'Click On Answer',p:{ 'Question id': answer.recipe.recipe_question_id, 'Answer id': answer.recipe.id}});
    let valueForSaving = true;
    if (value || value === 0) {
      valueForSaving = value;
    }
    //save the answer
    questionnaireAttributes.setAnswersSelectedMap(
      (form: any) => ({
        ...form,
        [answer.recipe.id]: valueForSaving,
      })
    );

    //update the validation object
    questionnaireAttributes.setQuestionnaireValidationObject(
      (oldVal: any) => {
        let isValid = true;
        let idToUpdate = answer.recipe.type === "number" ? answer.recipe.id : answer.recipe.parent_id;
        if (answer.recipe.validation_rule.max_value) {
          isValid = answer.recipe.validation_rule.min_value <= Number(value) && Number(value) <= answer.recipe.validation_rule.max_value;
        }

        return {
          ...oldVal,
          [idToUpdate]: isValid,

        };
      }
    );

    //mark siblings as unselected
    questionnaireAttributes.shutDownSiblingsDescendants(answer);
  }

  const onSubmit = async () => {
    //default incase we dont get onSubmit as a prop
    setSubmitButtonLoading(true);
    //combine the questions and answer information to one single object
    window.eventsTracking.track({e:'Click Submit Questionnaire'});

    //IF THERE ARE ANY DANGER (RED) SIGNIFICANCES WE SCROLL BACK
    if (dangerSignificancesRefs.length > 0) {
      MainTracker.track("click", "Click Submit Questionnaire Validation Failed");
      setTimeout(() => {
        dangerSignificancesRefs[0].current.scrollIntoView({
          behavior: "smooth",
        });
      }, 150);
      setSubmitButtonLoading(false);
      return;
    }

    let questionnaire = bundleQuestionnaireForSending(
      questionnaireAttributes.flattenedQuestionnaire,
      questionnaireAttributes.answersSelectedMap,
      questionnaireAttributes.revealedAnswerGroups
    );


    //format the answers to match backend structure for orderPrescriptionQuestions table in DB
    questionnaire = formatQuestionnaire(
      questionnaire,
      questionnaireAttributes.answersSelectedMap
    );

    
    let isValid = questionnaireAttributes.validateQuestionnaire(
      questionnaire,
      questionnaireAttributes.revealedAnswerGroups,
      questionnaireAttributes.questionnaireValidationObject
    );

  
    //this suggests the user answered a blocking answers, and should be redirected to /evaluation
    let isBlocking: Boolean = false;

    if (isValid) {

      if (onSubmitOverride) {
        await onSubmitOverride(true, questionnaire, files);
        setSubmitButtonLoading(false);

      } else {

        isBlocking = isQuestionaireBlocking(
          questionnaire,
          questionnaireAttributes.answersSelectedMap
        );

        setPostLoading(true);
        let isExpressReorder = window.location.href.includes("reorder") && window.location.href.includes("medical_disclaimer");

        http
          .postMultiPartsToServer(
            `questionnaire/${country}/${language}/${mention}?${searchParamas.toString()}`,
            {
              questionnaire: questionnaire,
              isBlocking,
              country: country,
              language: language,
              mention,
              allowOnlyPrescription: country?.toLowerCase() === "fr" || cameFromOutOfStockBanner,
              isExpressReorder,
              selfPickUpPharmacyID,
            },
            files,
            [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER]
          )
          .then((res) => {
            
            saveCart({
              id: res.data.cartID,
              token: res.data.cartToken,
              mention,
              mixpanel_title: res.data.catalog.mixpanel_title,
              products:[],
            })
            
            if (isBlocking) {
              navigate({
                pathname: `/evaluation/${country}/${language}`,
              });

            } else if (navigationOverride){
              navigationOverride()
            }
             else if (
              res.data.catalog &&
              res.data.catalog.is_video_consultation
            ) {

              // if customer logged in, then send directly to shipping
              if (customerState && customerState.customerID) {
                navigate({
                  pathname: `/shipping/${country}/${language}`,
                  search: `?${searchParamas.toString()}`
                });
              }
              else {
                // send to checkout
                navigate({
                  pathname: `/sign_up/${country}/${language}`,
                  search: `?${searchParamas.toString()}`
                });
              }
            } else {
              let path = `/select_treatment/${country}/${language}/${mention}`;

              if (catalogID) {
                navigate({
                  pathname: path,
                  search: `?catalog_id=${catalogID}&${searchParamas.toString()}`,
                });
              } else {
                navigate({ 
                  pathname: path,
                  search: `?${searchParamas.toString()}`
                 });
              }
            }
          })
          .catch((err) => {
            handleErrorNotification(
              translator.translate(
                language,
                "default",
                "StickyNotification/danger_title"
              ),
              "Server Failed"
            );
          })
          .finally(() => {
            setSubmitButtonLoading(false);
            setPostLoading(false);
          });
      }

    } else {
      MainTracker.track("click", "Click Submit Questionnaire Validation Failed");
      setSubmitButtonLoading(false);
    }
  };
  return (
    <>
      {loading && <LoadingModal />}

      {error && !loading && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={500} />
        </>
      )}

      {!loading && !error && (
        <>
          <StickyNotification
            showNotification={showStickyNotification.show}
            title={showStickyNotification.title}
            onClick={(falseValue: any) => setShowStickyNotification(falseValue)}
            content={showStickyNotification.content}
          />

          {/* if state true -> Show forgot password overlay and send component closeModal property */}
          {showForgotPasswordModal && (
            <ForgotPasswordModal
              closeModal={() => setShowForgotPasswordModal(false)}
              navigationCallback={() => { }} //TODO:
              language={language!}
            />
          )}

          <HeaderController
            language={language}
            stage="Questionnaire"
          />

          {/* if state true -> Show forgot password overlay and send component closeModal property */}

          <div className="questionnaire-body-container">
            {postLoading && <DarkLoadingModal />}
            
            <SkipQuestionnaireModal language={language} country={country} mention={mention} skipModals={searchParamas.get("skipQuestionnaire")} />
            
            <div className="questionnaire-treatment-form-container">
              {autoDiscount && autoDiscount.discount_type !== "free" && language!.toLowerCase() === "de" ?
                <EmojiBlock
                  discount_measure={autoDiscount.discount_measure}
                  discount_value={autoDiscount.discount_value}
                /> : null
              }
              <h1 className="questionnaire-title">{questionnaireTitle}</h1>
              <div className="questionnaire-container">
                <LoginQuestion
                  showForgotPasswordModal={() =>
                    setShowForgotPasswordModal(true)
                  }
                  language={language}
                  onChange={(isFirstTime: Boolean) => {
                    if (isFirstTime) {
                      window.eventsTracking.track({e: 'Clicked on First Time Order Question'});
                    }
                  }}
                />
                <form className="questionnaire-form-container" id="questionnaire-form" onSubmit={(e) => {e.preventDefault(); onSubmit();}}>
                  {/* start rending every question in the flatterened array */}
                  {questionnaireAttributes.flattenedQuestionnaire.map(
                    (question: any, idx: Number) => {
                      return (
                        <Question
                          handleRef={questionnaireAttributes.handleRef}
                          onReveal={questionnaireAttributes.onReveal}
                          onHide={questionnaireAttributes.onHide}
                          key={question.id}
                          formData={questionnaireAttributes.answersSelectedMap}
                          onAnswerClick={onAnswerClick}
                          onTextChange={onTextChange}
                          question={question}
                          files={files}
                          setFiles={setFiles}
                          questionnaireValidationObject={
                            questionnaireAttributes.questionnaireValidationObject
                          }
                          dangerSignificancesRefsDispatch={dangerSignificancesRefsDispatch}
                          answersSelectedMap={questionnaireAttributes.answersSelectedMap}
                          mention={mention}
                        />
                      );
                    }
                  )}
                  <SubmitButton
                    id="submit-button"
                    className="questionnaire-submitButton"
                    language={language}
                    placeholder={
                      submitButtonText ?
                      submitButtonText
                      :
                        translator.translate(
                          language,
                          "default",
                          "QuestionnairePage/submit_button_text"
                      )
                    }
                    showArrow={true}
                    width={"auto"}
                    margin="15px 0px"
                    backgroundColor={"var(--primary-lightgreen)"}
                    loading={submitButtonLoading}
                  />
                </form>
              </div>
            </div>
          </div>
          <Footer page="Questionnaire" language={language} />
        </>
      )}
    </>
  );
};



export default Questionnaire;