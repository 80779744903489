import React, { useContext } from 'react'
import { ChooseTreatmentContext } from '../FreeDosageLayout';
import { FilterMenu } from './FiltersMenu';
import { CiFilter } from "react-icons/ci";

type Props = {
    className?: string
}


const ProductFiltersControl: React.FC<Props> = ({ className }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const appliedFiltersCount = chooseTreatmentContext?.appliedFiltersCount || 0;

    return (
        <div className={`flex flex-col bg-white p-2 w-full ${className}`} style={{ border: "1px solid #DBE1EB", borderRadius: "6px" }}>

            <div className='flex cursor-pointer' id="products-cannabis-filter" onClick={() => chooseTreatmentContext?.setIsFilterMenuOpen(!chooseTreatmentContext?.isFilterMenuOpen)}>
                <div className='flex flex-[0.95] items-center gap-2 text-[#0D2C54]'><CiFilter className='font-thin text-[#11DDAC]' size={25} />Filtern nach ({appliedFiltersCount})</div>
                <div className='flex-[0.05] cursor-pointer select-none flex items-center justify-center text-[#11DDAC]'>+</div>
            </div>

            {
                chooseTreatmentContext?.isFilterMenuOpen ?
                    <FilterMenu filterData={chooseTreatmentContext?.filterData || {}} />
                    : null
            }
        </div>
    )
}

export default ProductFiltersControl
