import React, {useRef, useState} from 'react'
import InputField from '../../../components/CredentialsInput/InputField'
import TextArea from '../../../components/CredentialsInput/TextArea';
import ReCAPTCHA from 'react-google-recaptcha';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import * as translator from "../../../utils/translator";
import { useParams } from 'react-router-dom';
import useForm from '../../../customHooks/useForm';
import { clientConfig } from "../../../config";
import * as http from "../../../utils/http";
import ContactFormSubmitted from './ContactFormSubmitted';
import "./ContactPageOrderInformationForm.css";



type Props = {
  toggleStickyNotification: (args: any) => void
}

const GeneralQuestionsForm: React.FC<Props> = ({toggleStickyNotification }) => {

  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);
  const contactFormAttributes = useForm(
    { nameAndSurname: "", email: "", content: "", orderNumberOptional: "",},
    language
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const generalQuestionsDisclamer = translator.translate(language, "contact", "generalQuestionsDisclamer");  

  const reRef = useRef<ReCAPTCHA>(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (contactFormAttributes.validateForm()) {

      let captchaToken

      if (clientConfig.IS_RECAPTCHA_ENABLED) {
        captchaToken = await reRef.current?.executeAsync();
        reRef.current?.reset();
      }
      const formmatedContactForm = {
        ...contactFormAttributes.form,
        captchaToken
      };

      http.postToServer("contact_form", { subject: "general_request", formmatedContactForm, country })
        .then((response) => {
          if (response.data.status === "success") {
            setFormSubmitted(true);
          }
        })
        .catch((error) => {
          toggleStickyNotification({
            show: true,
            title: translator.translate(language, "contact", "error/title"),
            content: translator.translate(language, "contact", "error/content"),
            level: "error"
          });
          window.clientLogger.error("error loading order data",error);
        }).finally(() => {
          setSubmitButtonLoading(false);

        })
    } else {
      setSubmitButtonLoading(false)
    }
  }


  
  return (
    <>
    {!formSubmitted ?
      (<form id='contact form' className='flex flex-col w-full p-[30px]'>
        <div className='flex flex-col gap-[10px]'>
          <h4 className='font-semibold text-[16px] text-[#98A0AF]'>{translator.translate(language, "contact", "generalQuestionsTitle")}</h4>
          <div className='bg-[#E7FCF7]' style={{border: "1px solid #C6F4E9", borderRadius: "6px"}}>
            <p className='text-[#656D79] px-[20px] py-[15px] font-medium'>{generalQuestionsDisclamer}</p>
          </div>
        </div>

        <section id='contact-form-inputs-general' className='flex flex-col md:flex-row w-full md:gap-3 '>
          <div className='flex flex-col flex-1 pt-[30px] gap-[10px]'>
            <InputField id='name-and-surname-input'
              placeholder={translator.translate(language, "contact", "ContactPageForm/name_and_surname")}
              value={contactFormAttributes.form.nameAndSurname}
              errorMsg={
                contactFormAttributes.errorMessagePerField.nameAndSurname
              }
              mandatory
              onChange={(inputValue: string) =>
                contactFormAttributes.updateForm("nameAndSurname", inputValue)
              }
              language={language}
              onBlur={() => contactFormAttributes.onFieldBlur("nameAndSurname")}
              touched={contactFormAttributes.touched.nameAndSurname}
            />

            <InputField id='email-input'
              placeholder={translator.translate(language, "contact", "ContactPageForm/email_address")}
              value={contactFormAttributes.form.email}
              errorMsg={
                contactFormAttributes.errorMessagePerField.email
              }
              mandatory
              onChange={(inputValue: string) =>
                contactFormAttributes.updateForm("email", inputValue)
              }
              language={language}
              onBlur={() => contactFormAttributes.onFieldBlur("email")}
              touched={contactFormAttributes.touched.email}
            />
          </div>

          <div className='flex-1 pt-[15px]'>
            <TextArea id='message-input'
              placeholder={translator.translate(language, "contact", "ContactPageForm/message_with_minimum")}
              value={contactFormAttributes.form.content}
              errorMsg={
                contactFormAttributes.errorMessagePerField.content
              }
              mandatory
              onChange={(inputValue: string) =>
                contactFormAttributes.updateForm("content", inputValue)
              }
              onBlur={() => contactFormAttributes.onFieldBlur("content")}
              touched={contactFormAttributes.touched.content}
              resizeable={false}
            />
          </div>
        </section>


        {clientConfig.IS_RECAPTCHA_ENABLED &&

          <ReCAPTCHA
            sitekey={clientConfig.RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={reRef}
          />
        }
        
        <SubmitButton
          id={"contact-form-submit"}
          language={language}
          loading={submitButtonLoading}
          className="bg-[#11DDAC] text-xl text-white font-bold py-4 rounded-sm border-0 outline-0 hover:opacity-90 cursor-pointer"
          onSubmit={onSubmit}
          placeholder={translator.translate(language, "contact", "ContactPageForm/submit")}
        />

    </form>): (
          <ContactFormSubmitted selectedIssue={"general_request"} language={language} />
        )
    }
  </>
  )
}

export default GeneralQuestionsForm