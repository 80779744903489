import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import SubscriptionModal from "../../components/Modals/SubscriptionModal/SubscriptionModal";
import * as http from "../../utils/http";
import * as translator from "../../utils/translator";
import ChooseTreatmentLandingPage from "./ChooseTreatmentLandingPage/ChooseTreatmentLandingPage";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import HeaderController from "../../components/Headers/HeaderController";
import ServerError from "../../components/ServerError/ServerError";
import FreeDosageLayout from "./FreeDosageChooseTreatment/FreeDosageLayout";
import FreeDosageLayoutV2 from "./FreeDosageChooseTreatmentV2/FreeDosageLayoutV2";
import ReturningCustomersLayout from "./ReturningCustomersLayout/ReturningCustomersLayout";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import { useCartContext } from "../../customHooks/useCart";
import { CHOOSE_TREATMENT_PAGE_VIEW_EVENT, ORDER_FLOWS } from "../../utils/consts";
import CannaleoFreeDosageLayout from "./CannaleoFreeDosageChooseTreatment/CannaleoFreeDosageLayout";
import * as SocialMediaTracker from "../../PixelTrackers/SocialMediaTracker";


interface ISubscriptionContext {
  showSubscriptionModalCallback?: (value: boolean) => void;
}

interface ICartContext {
  cartData: any;
}


export const CatalogSettingContext = createContext({});
export const SubscriptionModalContext = createContext<ISubscriptionContext>({});
export const RouterCartContext = createContext<ICartContext>({cartData:null});

const ChooseTreatmentRouter = () => {

  const { getSessionFields } = useCartContext();
  const { country, category, language } = useParams() as {
    country: string;
    category: string;
    language: string;
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(false);
  const [catalogSettings, setCatalogSettings] = useState<any>({});
  const [includeTreatmentFeeInPrice, setIncludeTreatmentFeeInPrice] =
    useState(true);
  const [isNoPrefereceTreatmentEnabled, setIsNoPrefereceTreatmentEnabled] =
    useState(false);

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(true);
  const showSubscriptionModalCallback = (boolValue: boolean) => {
    setShowSubscriptionModal(boolValue);
  };

  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
  });

  const handleErrorNotification = (title: string, content: string) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification({ show: true, title, content });
    }
  };

  const [allowPrescriptionForOutOfStock, setAllowPrescriptionForOutOfStock] = useState(false);
  const [layout, setLayout] = useState("");
  const [forceHidePrices, setForceHidePrices] = useState(false);
  const [showERezeptDisclaimer, setShowERezeptDisclaimer] = useState(false);

  const [searchParams] = useSearchParams();
  const redirectError = searchParams.get("ec"); //ec means error code
  const isCannaleoFlow = Boolean(searchParams.get("epd"));

  const [cartData,setCartData] = useState(null)


  const renderBasedOnLayout = () => {
    
    switch (layout) {
      case "free_dosage":
        return <FreeDosageLayout />;

      case "new_customers":
        return <ChooseTreatmentLandingPage />;

      case "returning_customers":
        return <ReturningCustomersLayout />
        
      case ORDER_FLOWS.CANNALEO_PRE_SELECTED:
        return <CannaleoFreeDosageLayout />

      case "free_dosage_v2": //flow = multiple_products
        return <FreeDosageLayoutV2 />;

      default:
        return <ReturningCustomersLayout />;
    }
  }

  useEffect(() => {
    if (redirectError == "dnis") {
      setShowSubscriptionModal(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_stock"
        )
      );
    } else if (redirectError == "nss") {
      setShowSubscriptionModal(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_stock_for_subscription"
        )
      );
    } else if (redirectError == "npic") {
      setShowSubscriptionModal(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_cart_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/product_not_in_cart_description"
        )
      );
      //When the user enters the payment page through a link, but the product is out of stock
    }else if (redirectError == "roos"){
      setShowSubscriptionModal(false);
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/revive_out_of_stock_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/revive_out_of_stock_description"
        )
      );
    }
  }, [redirectError]);

  useEffect(() => {

    let sessionCart = getSessionFields();
    if (!sessionCart?.mention || sessionCart?.mention !== category) {
      setLoading(false);
      window.eventsTracking.track({ e: "Auto navigation from CT", p: { ...sessionCart, category } });
      navigate({pathname: `/questionnaire/${country}/${language}/${category}`, search: searchParams.toString()});
    } else {

      //get the proper layout and catalog settings
      http
        .getFromServer(`choose-treatment-settings/${category}/${language}/${country}`, [
          http.MIDDLEWARES.CUSTOMER_ID,
          http.MIDDLEWARES.CART_ORDER
        ])
        .then((response) => {
          let catalogSettingsVar = response.data.catalogSettings;
          const cartData = response.data.cart;

          setCartData(cartData);
          if (cartData.hide_prices) {
            setForceHidePrices(true);
          }

          if (cartData.allow_only_prescription) {
            setAllowPrescriptionForOutOfStock(true);
          }
          
          if (cartData.is_e_recipe) {
            setShowERezeptDisclaimer(true);
          }

          if (isCannaleoFlow){
            setLayout(ORDER_FLOWS.CANNALEO_PRE_SELECTED);
          } else if (searchParams.get("free_dosage_v2")) {
            setLayout("free_dosage_v2");
          } else {
            setLayout(catalogSettingsVar.layout);
          }
          
          setCatalogSettings(catalogSettingsVar);

          if (catalogSettingsVar.price_style === "no_treatment_fee") {
            setIncludeTreatmentFeeInPrice(false);
          }

          if (catalogSettingsVar.no_preference_medication){
            setIsNoPrefereceTreatmentEnabled(true);
          }

          SocialMediaTracker.track("pv", CHOOSE_TREATMENT_PAGE_VIEW_EVENT, {mention: category, searchParamas: Object.fromEntries(searchParams.entries())});

        })
        .catch((error) => {
          window.clientLogger.error('error in getfromServer - ChooseTreatmentRouter', error);
          setError(500);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>


      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}


      {!error && showSubscriptionModal &&
        catalogSettings.subscription_view_type &&
        catalogSettings?.subscription_popup === 1 &&
        catalogSettings?.max_discount > 0 && !showERezeptDisclaimer ? (
        <SubscriptionModal
          showModalCallback={(boolValue: boolean) =>
            showSubscriptionModalCallback(boolValue)
          }
          language={language}
          discount={catalogSettings?.max_discount}
          ftd={99}
        />
      ) : null}

      {!loading && !error && (


        <RouterCartContext.Provider value={{ cartData }}>
          <SubscriptionModalContext.Provider
            value={{ showSubscriptionModalCallback }}
          >
            <CatalogSettingContext.Provider
              value={{
                includeTreatmentFeeInPrice,
                isNoPrefereceTreatmentEnabled,
                allowPrescriptionForOutOfStock,
                forceHidePrices,
                setShowStickyNotification,
                showERezeptDisclaimer,
                ...catalogSettings,
              }}
            >
              <StickyNotification
                showNotification={showStickyNotification.show}
                title={showStickyNotification.title}
                onClick={(falseValue: any) => setShowStickyNotification(falseValue)}
                content={showStickyNotification.content}
              />
              {
                renderBasedOnLayout()
              }
            </CatalogSettingContext.Provider>
          </SubscriptionModalContext.Provider>
        </RouterCartContext.Provider >

      )}

      {loading && <LoadingModal />}
    </>
  );
};

export default ChooseTreatmentRouter;
