import React, { useMemo, useRef } from 'react'
import RadioSelect from '../../../../components/RadioSelect/RadioSelect'
import OptionCard from './OptionCard';
import { DHL_PACKSTATION, PERSONAL_ADDRESS } from '../constants';
import { useShippingPageContext } from '../providers/ShippingPageProvider';
import { translate } from '../../../../utils/translator';
import { useCartContext } from '../../../../customHooks/useCart';
import { clientConfig } from '../../../../config';
import PharmacyIcon from "../../../../icons/pharmacy-icon.svg";

type Props = {
    code: string,
    title: string,
    isSelected: boolean
    onSelect: () => void
    children?: React.ReactNode
    hideOptionCards?: boolean
    isDisabled?: boolean
    hideHeader?: boolean //For example in self pickup flow
}

const DeliveryOptionPanel: React.FC<Props> = ({ code, title, isSelected, onSelect, children, hideOptionCards = false ,isDisabled = false, hideHeader = false }) => {

    const { language, handleOnlyPrescriptionChange, preSelectedExternalPharmacy, selfPickupPharmacySettings, contentType, allowPrescriptionRedemption } = useShippingPageContext();
    const { getCart } = useCartContext();
    const cart = getCart();
    const [optionCardsTitle, setOptionCardsTitle] = React.useState<string>("");
    const optionPanelHeaderRef = useRef<HTMLDivElement | null>(null);

    const handleOnSelect = () => {

        // If the option is disabled, or already selected, don't do anything
        if (isDisabled || isSelected) {
            return;
        }

        onSelect();

        //Wait for the body to open then scroll to the header
        setTimeout(() => {
            optionPanelHeaderRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 50)
    };



    // Self-pickup option card
    const formatSelfPickupOptionCard = () => {

        const shipperAddress = selfPickupPharmacySettings.shipper_address;
        const title = `${shipperAddress.city} Apotheke: ${selfPickupPharmacySettings.title},  ${shipperAddress["street1"]}, ${shipperAddress.zip}, ${shipperAddress.city}`
        const description = shipperAddress.shipping_page_description || translate(language, "shipping", "serviceOptions/self_pickup_title");
        return {
            title,
            description: description,
            boldDescription: '',
            badgeText: '',
            headerImageSrc: '',
            headerIconSrc: PharmacyIcon,
            isSelected: true,
            onSelect,
            isDisabled: false
        }
    }


    // Cannaleo option card 
    const formatCannaeloOptionCard = () => {

        const pharmacyName = preSelectedExternalPharmacy?.name;
        const title = pharmacyName ? `Ihre gewählte Apotheke: ${pharmacyName}` : "Ihre gewählte Apotheke";
        const description = pharmacyName
            ? `Sobald Ihre Bestellung bezahlt ist und Ihr Arzt das Rezept genehmigt hat, wird es direkt an die ${pharmacyName} geschickt. Sie können sich dann mit der Apotheke in Verbindung setzen und das Medikament bezahlen.`
            : "Sobald Ihre Bestellung bezahlt ist und Ihr Arzt das Rezept genehmigt hat, wird das Rezept an die von Ihnen gewählte Apotheke geschickt. Detaillierte Anweisungen und die nächsten Schritte finden Sie in Ihrer E-Mail.";

        return {
            title,
            description,
            boldDescription: '',
            badgeText: '',
            headerImageSrc: '',
            headerIconSrc: PharmacyIcon,
            isSelected: true,
            onSelect,
            isDisabled: false,
        }
    }
    

    // Medication option card
    const formatMedicationOptionCard = () => {

        const boldDescription = contentType === "cannabis" ? translate(language, "shipping", "serviceOptions/service_option_1_cannbis_bold_description") : "";
        
        return {
            title: translate(language, "shipping", "serviceOptions/service_option_1_title"),
            description: translate(language, "shipping", "serviceOptions/service_option_1_description"),
            boldDescription: boldDescription,
            badgeText: translate(language, "shipping", "serviceOptions/service_option_1_badge_text"),
            headerImageSrc: '',
            headerIconSrc: '',
            isSelected: !cart.only_prescription,
            onSelect: () => handleOnlyPrescriptionChange(false),
            isDisabled:false,
        }
    }

    // Prescription only option card
    const formatOnlyPrescriptionOptionCard = () => {

        const title = allowPrescriptionRedemption ? translate(language, "shipping", "serviceOptions/service_option_2_cannabis_title") : translate(language, "shipping", "serviceOptions/service_option_2_title")
        const description = allowPrescriptionRedemption ? translate(language, "shipping", "serviceOptions/service_option_2_cannabis_description") : translate(language, "shipping", "serviceOptions/service_option_2_description")

        return {
            title,
            description,
            boldDescription: '',
            badgeText: '',
            headerImageSrc: '',
            isSelected: cart.only_prescription!,
            headerIconSrc: '',
            onSelect: () => handleOnlyPrescriptionChange(true),
            isDisabled: Boolean(cart.subscription_plan_id)
        }
    }


    // DHL Packstation option card
    const formatDHLPackstationOptionCard = () => {
        return  {
            title: translate(language, "shipping", "serviceOptions/dhl_packstation_title"),
            isSelected: true,
            description: '',
            boldDescription: '',
            badgeText: '',
            headerImageSrc: clientConfig.IMAGES_URL + "uploads/pages/144/dhl-delivery.png",
            headerIconSrc:'',
            onSelect: () => {},
            isDisabled: false
        }
    }


    // Option cards
    const optionCards = useMemo(() => {
        const optionCards:any[] = []

        if (code === PERSONAL_ADDRESS) {

            if (hideOptionCards) {
                return optionCards
            }

            // Cannelo flow 
            if (preSelectedExternalPharmacy) {
                optionCards.push(formatCannaeloOptionCard())
            }

            // Self pick up flow
            else if (selfPickupPharmacySettings) {
                setOptionCardsTitle("Ihre gewählte Apotheke");
                optionCards.push(formatSelfPickupOptionCard());
            }

            // Prescription only flow 
            else if (cart.allow_only_prescription && !cart.product_in_stock) {
                //return allow prescription only
                optionCards.push(formatOnlyPrescriptionOptionCard())
            }

            // STI (test_kits) Flow 
            else if (contentType === "test_kit") {
                optionCards.push(formatMedicationOptionCard())
            }

            // Regular flow (Medication + prescription only)
            else {
                setOptionCardsTitle(translate(language, "shipping", "serviceOptions/service_options_title"));
                optionCards.push(formatMedicationOptionCard())
                optionCards.push(formatOnlyPrescriptionOptionCard())
            }
        }


        if (code === DHL_PACKSTATION) {
            optionCards.push(formatDHLPackstationOptionCard())
        }



        return optionCards

    }, [code, cart, preSelectedExternalPharmacy, selfPickupPharmacySettings, hideOptionCards])



    return (
        <div
            id="option-panel"
            style={isSelected ? { border: '1px solid #11DDAC' } : {}}
            className='rounded-[6px] bg-white divide-y-2'>

            {/* Header  */}
            {
                !hideHeader && (
                    <div
                        ref={optionPanelHeaderRef}
                        id='option-panel-header'
                        onClick={handleOnSelect}
                        className={`flex items-center p-[20px] scroll-mt-[50px] ${isDisabled ? "cursor-not-allowed bg-[#EEF1F6]" : "cursor-pointer"} `} >
                        {/* Radio */}
                        <RadioSelect selected={isSelected} disabled={false} />

                        {/* Title  */}
                        <span id='option-panel-title' className={`text-[14px] font-medium ${isDisabled ? "text-[#98a0af]" : "text-[#0d2c54]"} `}>{title}</span>
                    </div>
                )
            }


            {/* Body  */}
            {
                isSelected ?

                    <div id='option-panel-body' className='px-[20px] md:px-[25px] py-[20px]' style={{ borderTop: "1px solid #dbe1eb" }} >

                        {/* Title  */}
                        {optionCardsTitle ?
                            <span id='option-panel-body-title' style={{ fontFamily: "Roboto, sans-serif" }} className='font-semibold text-[#98a0af] text-[13px] mb-[30px] block'>
                                {optionCardsTitle}
                            </span>
                            : null
                        }

                        {/* optionCards  */}
                        {
                            optionCards.map((card, index) => {
                                return (
                                    <OptionCard
                                        key={card.title + index}
                                        title={card.title}
                                        onSelect={card.onSelect}
                                        isSelected={card.isSelected}
                                        description={card.description}
                                        boldDescription={card.boldDescription}
                                        headerImageSrc={card.headerImageSrc}
                                        headerIconSrc={card.headerIconSrc}
                                        badgeText={card.badgeText}
                                        isDisabled={card.isDisabled}
                                    />
                                )
                            })
                        }

                        {/* Children (Most likely form) */}
                        {children}


                    </div>
                    :
                    null
            }

        </div>
    )

}

export default DeliveryOptionPanel



