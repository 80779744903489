import React, { useMemo } from "react";
import "./OTCAddonsModal.css";
import X from "../../../icons/X.svg";
import { useCartContext } from "../../../customHooks/useCart";
import OTCProductRowV2 from "../../OTCProductRow/OTCProductRowV2";
import { translate } from "../../../utils/translator";
import { useShippingPageContext } from "../../../pages/v2/ShippingV2/providers/ShippingPageProvider";

type props = {
  productItems: Array<ProductItem>;
  title: string;
  language: string;
  hideModal: () => void;
};

const OTCAddonsModalV2: React.FC<props> = ({productItems, title,language ,hideModal}) => {

  const {getCart} = useCartContext();
  const {handleOTCProductDecrement, handleOTCProductIncrement} = useShippingPageContext();
  const cart = getCart();

  const selectedOTCAddonsByID = useMemo(() => {
    // 1. Get the OTC products
    const cartOTCProducts = cart.products.filter((productItem) => !productItem.is_prescription_drug);
    
    // 2. Return and build the object
    return cartOTCProducts.reduce((acc, productItem) => {
      acc[productItem.product_item_id] = productItem; 
      return acc;
    }, {} as Record<number, CartProductView>);
  
  }, [cart.products]);


  return (
    <div
      className="otc-addons-modal-container"
      onClick={(event: any) => event.stopPropagation()}
    >
      <img src={X} alt="X" onClick={hideModal} />
      <h1>{title}</h1>

      <div className="otc-addons-modal-product-rows">
        {productItems.map((productItem: ProductItem, index) => {
          return (
            <OTCProductRowV2
              key={"otc-modal-product-row-" + productItem.id! + index}
              productItem={productItem}
              showBin={true}
              decrementButtonOnClick={() => handleOTCProductDecrement(productItem)}
              incrementButtonOnClick={() => handleOTCProductIncrement(productItem)}
            />
          );
        })}
      </div>

      <button onClick={hideModal} className="otc-addons-modal-submit">
          { Object.values(selectedOTCAddonsByID).length ?
            translate(language, "shipping", "OTCAddonsModal/add_to_order")
            :
            translate(language, "shipping", "OTCAddonsModal/close_and_proceed")
          }
      </button>

     
    </div>
  );
};

export default OTCAddonsModalV2;
