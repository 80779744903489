import { useState } from "react";
import { useParams } from "react-router-dom";
import * as translator from "../../../../utils/translator";
import "./MyAccountProductsList.css";

interface MyAccountProductsListProps {
    detailData: { [key: string]: string }[]
}

const MyAccountProductsList: React.FC<MyAccountProductsListProps> = ({ detailData }) => {
  const [expanded, setExpanded] = useState(false);
  const { country } = useParams();
  const visibleItems = expanded ? detailData : detailData.slice(0, 3);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <ul>
        {visibleItems.map((objectItem, index) => (
          <li key={index}>
            {Object.keys(objectItem).map((key) =>
              key === "image" ? (
                <img key={key} className="product-image" src={objectItem[key]} alt="product" />
              ) : (
                <p key={key} className="account-order-detail-row-value">
                  {objectItem[key]}
                </p>
              )
            )}
          </li>
        ))}
      </ul>

      {detailData.length > 3 && expanded == false ? (
        <button
            onClick={toggleExpanded}
            className="expand"
        >
            {translator.translate(
                    translator.getLanguageByCountry(country),
                    "my_account",
                    "MyOrders/details/viewAll"
                ) + " "}
        </button>
      ) : null}
    </div>
  );
};

export default MyAccountProductsList;
