import ToggleRadioButton from "../../../components/ToggleRadioButton/ToggleRadioButton";
import "./PaymentOptionCard/PaymentOptionCard.css"

type Props = {
  isToggleEnabled: boolean,
  updateToggle: () => void,
  precautionLevel: string,
  isOnlyPrescription?: boolean
}

const TogglePrecaution: React.FC<Props> = ({ isToggleEnabled, updateToggle, precautionLevel, isOnlyPrescription }) => {

  return (
    <div className="toggle-precaution-container">
      <ToggleRadioButton
        id={precautionLevel === "error" ? "toggle-precaution-error" : "toggle-precaution"}
        isToggleEnabled={isToggleEnabled}
        updateToggle={updateToggle}
      />
        <span className="toggle-precaution-text" style={{ color: `${precautionLevel === "error" ? "var(--primary-danger)" : "var(--radiobutton-text-color)"}` }}>
          Ich erkläre, dass meine persönlichen Daten korrekt sind und
          <b> ich meinen Ausweis entweder bei der Zustellung oder bei Abholung in der Apotheke vorzeigen werde. </b>
        </span>
    </div>
  )
}

export default TogglePrecaution  