import React, { useState } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import RadioSelect from "../../RadioSelect/RadioSelect";
import SubmitButton from "../../SubmitButton/SubmitButton";
import "./MyAccountCancelSubscriptionModal.css"
import * as translator from '../../../utils/translator';
import * as http from '../../../utils/http';
import { MainTracker } from '../../../PixelTrackers/MainTracker';
interface MyAccountCancelSubscriptionModalProps {
  closeModal: () => void;
  language: string;
  refreshData: boolean;
  setRefreshData: any;
  country: string | undefined;
  setStickyNotificationDetails:any;
  setCurrentGlobalModalName: React.Dispatch<React.SetStateAction<string | null | Object>>;
  allSubscriptionData:any;
}

type CancelOption = {
  id: number;
  translation?: string;
  value:string
  comment?:string | null;
};

const MyAccountCancelSubscriptionModal: React.FC<MyAccountCancelSubscriptionModalProps> = ({country, refreshData, setRefreshData, closeModal, language, setStickyNotificationDetails, setCurrentGlobalModalName, allSubscriptionData}) => {
  const navigate = useNavigate();

  const {order_hash_id} = useParams<{  order_hash_id: string | undefined }>(); //the hashID from url
  const [chosenCancelOption, setChosenCancelOption] = useState<CancelOption | null>(null);
  const [isTextAreaEmptyOnSubmit,setIsTextAreaEmptyOnSubmit] = useState<boolean>(false);
  const [searchParams,] = useSearchParams();
  const showCancelModal = searchParams.get("showCancelModal") === "true";

  const cancelOptions: CancelOption[] = [
    { id: 1, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/1'), value:`I'd like to change my medication` },
    { id: 2, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/2'), value:`I have enough meds to keep me going for now` },
    { id: 3, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/3'), value:`The treatment is too expensive` },
    { id: 4, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/4'), value:`I'm not happy with the service or delivery` },
    { id: 5, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/5'), value:`I no longer need medication` },
    { id: 6, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/6'), value:`I get my meds elsewhere` },
  ].filter(option => !(showCancelModal && option.id === 1)); // remove option 1 if showCancelModal is true

  const lastCancelOptionID = cancelOptions.length + 1;

  const handleCancelOptionSubmit = () => {
    if(chosenCancelOption?.id === lastCancelOptionID){
      if(chosenCancelOption.comment){
        postCancelSubscription()
      } else {
        setIsTextAreaEmptyOnSubmit(true);
      }
    }else if((chosenCancelOption?.id === 2) && order_hash_id){ //suggest skipping next order instead of canceling
      const nextPayment:any = new Date(allSubscriptionData[order_hash_id].rawNextBilling);
      const deliverySkipped:any = new Date(allSubscriptionData[order_hash_id].rawDeliverySkipped);
      const today:any = new Date();
      const prePayment = nextPayment > today ? true : false
      const skippedPassed = deliverySkipped < today ? true : false 
      if((!allSubscriptionData[order_hash_id].deliverySkipped || skippedPassed) && prePayment){ //allow skipping only if next payment is after today AND delivery skipped is historical or nonexistent
        setCurrentGlobalModalName({type:"SKIP_SUBSCRIPTION", data:{hashID:order_hash_id, from:'cancelSubscription'}})
        MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': 'Skip offer after “I have enough meds” cancel reason'})
      }else{
        postCancelSubscription()
      }
    } else if(chosenCancelOption?.id && [1, 3].includes(chosenCancelOption?.id)) { //suggest changing medication instead of canceling
      const POP_UP_NAME:any = {
        1:"I want to change my medication",
        3:"Treatment is too expensive for me"
      }
      setCurrentGlobalModalName({type:"CHANGE_SUBSCRIPTION_MEDICATION", data:{hashID:order_hash_id, from:chosenCancelOption.id}})
      MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': POP_UP_NAME[chosenCancelOption.id]})
    } else {
      postCancelSubscription()
    }
  }

  const postCancelSubscription = () =>{
    http
    .postToServer(
    `account-update/subscription/cancel`,
    {
      option: chosenCancelOption,
      hashID: order_hash_id
    },
    [http.MIDDLEWARES.CUSTOMER_ID]
    )
    .then((response) => {
      setStickyNotificationDetails((currentDetails:any) => ({
        show: true,
        title: "",
        content: 'subscription cancelled',
        level : "info",
        showInfoIcon:false,
        refresh: !currentDetails.refresh
      }))
      setRefreshData(!refreshData)
      closeModal()
      navigate(`orders-subscription`);
    })
    .catch(
      setStickyNotificationDetails((currentDetails:any) => ({
        show: true,
        title: "",
        content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
        level : "error",
        showInfoIcon:false,
        refresh: !currentDetails.refresh
      }))
    )
    .finally()
  }

    return (
      <div onClick={closeModal} className="modal-background">
        <div
          className="cancel-subscription-modal-popup-container"
          onClick={(event) => event.stopPropagation()}
        >
          <img
            onClick={closeModal}
            className="close-btn"
            src={require("../../../../src/icons/X.svg").default}
            alt="X"
          />
          <h4>{translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/title')}</h4>
          <p>{translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/subTitle')}</p>

          <ul>
            {cancelOptions.map((option) => (
              <li className="cancel-subscription-modal-option-row" key={option.id} onClick={() => setChosenCancelOption(option)}>
                <RadioSelect
                  selected={chosenCancelOption?.id === option.id ? true : false}
                  disabled={false}
                />
                <span >
                  {option.translation}
                </span>
              </li>
            ))}

              {/* Render Another 1 li for Miscellaneous (the only textarea) */}
              <li className="cancel-subscription-modal-option-row" key={lastCancelOptionID} onClick={() => setChosenCancelOption({id:lastCancelOptionID, value:`Other`, comment:null})}>
                <RadioSelect
                  selected={chosenCancelOption?.id === lastCancelOptionID ? true : false}
                  disabled={false}
                />
                <span >
                  {translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/7')}
                </span>
              </li>
          </ul>

          { //Render the textarea incase Miscellaneous (last option) is chosed
            chosenCancelOption?.id === lastCancelOptionID ? 
              <textarea 
                className={`last-cancel-option-${isTextAreaEmptyOnSubmit ? "textarea-error" : "textarea"}`}
                onChange={(e)=> {
                  if(e.target.value){
                    setIsTextAreaEmptyOnSubmit(false);
                  }
                  setChosenCancelOption({id:lastCancelOptionID, value:'Other', comment:e.target.value})}} 
                placeholder="Write here"/>
                : 
              null
          }

          <SubmitButton
           margin="15px 0px"
           id={"cancel-subscription-submit-button"}
           placeholder={translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/cancelButton')}
           isEnabledState={chosenCancelOption !== null}
           onSubmit={()=> handleCancelOptionSubmit()}
           />

          <span className="close" onClick={closeModal}>{translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/closeButton')}</span>
        </div>
      </div>
    );

};

export default MyAccountCancelSubscriptionModal;
