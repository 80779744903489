import { useForm, Controller } from "react-hook-form";
import {  z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../../../components/CredentialsInput/InputField";
// import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import * as translator from "../../../../utils/translator";
import Select from "../../../../components/Select/Select";
import { useEffect, useMemo } from "react";
import { BsTelephone } from "react-icons/bs";
import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import { validateCompany, validateName, validatePhone, validatePostalCode, validateStreetAndNumber } from "../../../../utils/fieldsValidator";
import ToggleRadioButton from "../../../../components/ToggleRadioButton/ToggleRadioButton";
import { useShippingPageContext } from "../providers/ShippingPageProvider";
import { useCartContext } from "../../../../customHooks/useCart";
import ByteplantAddressValidatorModal from "../../../../components/Modals/ByteplantAddressValidatorModal";
import PortaledModal from "../../../../components/Modals/PortaledModal";
const FORM_ID = "delivery-address-form";

type Props = {
  defaultValues?: Partial<DeliveryAddressFormType>;
  showToggle?: boolean;
  isGoogleAPILoaded?: boolean;
}


const DeliveryAddressForm: React.FC<Props> = ({ defaultValues, showToggle = true, isGoogleAPILoaded = true }) => {

  const {language, setSelectedFormID, countryCurrenciesByID, handleShippingSubmit, byteplantAddressValidatorState ,setByteplantAddressValidatorState } = useShippingPageContext();
  const {cartCurrency, setCartCurrency} = useCartContext();


  //Init the formID
  useEffect(() => {
    setSelectedFormID(FORM_ID);

  }, [defaultValues])
  

  //Country dropdown options
  const countryDropdownOptions = useMemo(() => { 
    return Object.values(countryCurrenciesByID).map((currency) => {
      return {
        value: translator.translate(language, "shipping", `ShippingForm/country_name/${currency.countryID}`),
        code: currency.countryID
      }
    })
  }, [countryCurrenciesByID])

  



  // Zod Schema 
  const deliveryAddressSchema = z.object({
    // First name field
    first_name: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validateName(value, language);
          return isValid;
        },
        (value) => ({
          message: validateName(value, language).errorMsg
        })
      ),

    // Last name field
    last_name: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validateName(value, language);
          return isValid;
        },
        (value) => ({
          message: validateName(value, language).errorMsg
        })
      ),

    // Zip field
    zip: z
      .string()
      .refine(
        (postalCode: string) => {
          const { isValid } = validatePostalCode(postalCode, language, cartCurrency.countryID);
          return isValid;
        },
        (value) => ({
          message: validatePostalCode(value, language, cartCurrency.countryID).errorMsg
        })
      ),

    // City field 
    city: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validateName(value, language);
          return isValid;
        },
        (value) => ({
          message: validateName(value, language).errorMsg
        })
      ),

    // Address field 
    address: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validateStreetAndNumber(value, language);
          return isValid;
        },
        (value) => ({
          message: validateStreetAndNumber(value, language).errorMsg
        })
      ),

    // Country Field  
    country: z.string(),


    // Company Field
    company: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validateCompany(value, language);
          return isValid;
        },
        (value) => ({
          message: validateCompany(value, language).errorMsg
        })
      ),


    // Phone Field
    phone: z
      .string()
      .refine(
        (value: string) => {
          const { isValid } = validatePhone(value, language);
          return isValid;
        },
        (value) => ({
          message: validatePhone(value, language).errorMsg
        })
      ),

    // Toggle for Billing Address:
    useAddressAsBilling: z.boolean(),

    // For billing_first_name:
    billing_first_name: z.string().optional(),

    // For billing_last_name:
    billing_last_name: z.string().optional(),

    // For billing_zip:
    billing_zip: z.string().optional(),

    // For billing_city:
    billing_city: z.string().optional(),

    // For billing_address:
    billing_address: z.string().optional(),
   
    // For billing_company:
    billing_company: z.string().optional(),

  }).superRefine((schema, ctx) => {
    if (!schema.useAddressAsBilling) { 
      // Toggle off, need to also check billing fields
      let { isValid, errorMsg } = validateName(schema.billing_first_name, language);
      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["billing_first_name"],
          message: errorMsg,
        });
      }
  
      ({ isValid, errorMsg } = validateName(schema.billing_last_name, language)); 
      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["billing_last_name"],
          message: errorMsg,
        });
      }
  
      ({ isValid, errorMsg } = validatePostalCode(schema.billing_zip, language, cartCurrency.countryID));
      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["billing_zip"],
          message: errorMsg,
        });
      }
  
      ({ isValid, errorMsg } = validateName(schema.billing_city, language));
      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["billing_city"],
          message: errorMsg,
        });
      }
  
      ({ isValid, errorMsg } = validateStreetAndNumber(schema.billing_address, language));
      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["billing_address"],
          message: errorMsg,
        });
      }
    }
  });
  


// Form hook 
const { handleSubmit, control, formState: { errors }, watch, setValue } = useForm<DeliveryAddressFormType>({
  resolver: zodResolver(deliveryAddressSchema),
  mode: "onTouched",
  defaultValues: {
    first_name: defaultValues?.first_name || "",
    last_name: defaultValues?.last_name || "",
    zip: defaultValues?.zip || "",
    city: defaultValues?.city || "",
    address: defaultValues?.address || "",
    country: cartCurrency.countryID,
    company: defaultValues?.company || "",
    phone: defaultValues?.phone || "",
    useAddressAsBilling: true,
    billing_first_name: defaultValues?.first_name || "",
    billing_last_name: defaultValues?.last_name || "",
    billing_zip: "",
    billing_city: "",
    billing_address: "",
    billing_company: "",
  },
});

//Watch the toggle in order to render billing form (or not)
const useAddressAsBillingValue = watch("useAddressAsBilling");


//Scroll to the form incase of validation error
const onValidationFailed = () => {
  const formElement = document.getElementById(FORM_ID);
  if (formElement) {
    formElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};


// Handle customer using suggestion after the modal pops up
const handleCustomerUsingSuggestion = (byteplantSuggestion:ByteplantApiResponse) => {
  let customerUsedSuggestion = false;
  const suggestionValues: Partial<DeliveryAddressFormType> = {};

  if (byteplantSuggestion.street && byteplantSuggestion.streetnumber && byteplantSuggestion.city && byteplantSuggestion.postalcode) {

    customerUsedSuggestion = true;
    suggestionValues.address = `${byteplantSuggestion.street} ${byteplantSuggestion.streetnumber}`;
    suggestionValues.city = byteplantSuggestion.city;
    suggestionValues.zip = byteplantSuggestion.postalcode;

    setValue("address", suggestionValues.address);
    setValue("city", suggestionValues.city);
    setValue("zip", suggestionValues.zip);
  }

  handleSubmit((formData) => {handleShippingSubmit({...formData, ...suggestionValues}, customerUsedSuggestion)}, onValidationFailed)()
}


return (

  <>
    {/* Small Title */}
    <span id={`${FORM_ID}-title`} className="font-semibold text-[#98a0af] font-roboto text-[13px] my-[20px] block">
      {translator.translate(language, "shipping", "layout/living_address")}
    </span>

      {/* Address Validator (Byteplant) Modal  */}
      <PortaledModal
        shouldBackgroundClickHideModal={false}
        shouldShowModal={byteplantAddressValidatorState.showModal}
        backgroundColor="rgba(13, 44, 84, 0.36)"
        hideModal={() => setByteplantAddressValidatorState(current => ({...current, showModal: false}))}
      >
      <ByteplantAddressValidatorModal
        handleCustomerUsingSuggestion={handleCustomerUsingSuggestion}
        handleCustomerIgnoreSuggestion={handleSubmit((formData) => {handleShippingSubmit(formData, false)}, onValidationFailed)}
      /> 
      </PortaledModal>

    <form 
      id={FORM_ID} 
      onSubmit={handleSubmit((formData) => {handleShippingSubmit(formData)}, onValidationFailed)}  
      className="grid grid-cols-1 md:grid-cols-2 gap-4 scroll-mt-[100px]">

      {/* First Name  */}
      <Controller
        name="first_name"
        control={control}
        render={({ field, fieldState }) => (
          <InputField
            id="delivery-address-form-first-name"
            ignoreTouched={true}
            value={field.value}
            onChange={(value: string) => {
              field.onChange(value);
            }}
            placeholder={translator.translate(language, "shipping", "ShippingForm/first_name")}
            mandatory={true}
            language={language}
            onBlur={field.onBlur}
            errorMsg={fieldState?.error?.message} // Bind error message from hook

          />
        )}
      />

      {/* Last Name  */}
      <Controller
        name="last_name"
        control={control}
        render={({ field, fieldState }) => (
          <InputField
            id="delivery-address-form-last-name"
            ignoreTouched={true}
            value={field.value}
            onChange={(value: string) => {
              field.onChange(value);
            }}
            placeholder={translator.translate(language, "shipping", "ShippingForm/surname")}
            mandatory={true}
            errorMsg={fieldState.error?.message}
            language={language}
            onBlur={field.onBlur}
          />
        )}
      />

      {/* Zip  */}
      <Controller
        name="zip"
        control={control}
        render={({ field, fieldState }) => (
          <InputField
            id="delivery-address-form-zip"
            ignoreTouched={true}
            value={field.value}
            onChange={(value: string) => {
              field.onChange(value);
              // Invalidating the byteplant address validator
              setByteplantAddressValidatorState((current) => ({...current, responseData: null}))
            }}
            placeholder={translator.translate(language, "shipping", "ShippingForm/postal_code")}
            mandatory={true}
            language={language}
            onBlur={field.onBlur}
            errorMsg={fieldState.error?.message}
          />
        )}
      />


      {/* City  */}
      <Controller
        name="city"
        control={control}
        render={({ field, fieldState }) => (
          <GoogleApiInputField
            id="delivery-address-form-city"
            value={field.value}
            onChange={(value: any) => {
              if (Array.isArray(value.split(","))) {
                value = value.split(",")[0];
              }
              field.onChange(value); // Update the form field value

              // Invalidating the byteplant address validator
              setByteplantAddressValidatorState((current) => ({...current, responseData: null}))
            }}
            placeholder={translator.translate(
              language,
              "shipping",
              "ShippingForm/location"
            )}
            mandatory={true}
            country={cartCurrency.countryID}
            errorMsg={fieldState?.error?.message} // Bind error message from hook
            onBlur={field.onBlur} // Trigger onBlur to update touched state
            addressType={"(cities)"}
            isGoogleApiLoaded={isGoogleAPILoaded}
          />
        )}
      />



      {/* Shipping address  */}
      <div id="address-input-container" className="md:col-span-2">
        <Controller
          name="address"
          control={control}
          render={({ field, fieldState }) => (
            <GoogleApiInputField
              id="delivery-address-form-address"
              value={field.value}
              onChange={(value: any) => {
                if (Array.isArray(value.split(","))) {
                  value = value.split(",")[0];
                }
                field.onChange(value); // Update the form field value
              // Invalidating the byteplant address validator
              setByteplantAddressValidatorState((current) => ({...current, responseData: null}))
              }}
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/street_and_house"
              )}
              mandatory={true}
              country={cartCurrency.countryID}
              errorMsg={fieldState?.error?.message} // Bind error message from hook
              onBlur={field.onBlur} // Trigger onBlur to update touched state
              addressType={"address"}
              isGoogleApiLoaded={isGoogleAPILoaded}
            />
          )}
        />
      </div>


      {/* Country */}
      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <Select
            id="delivery-addres-form-country"
            initialValue={{
              value: translator.translate(language, "shipping", `ShippingForm/country_name/${field.value}`),
              code: field.value,
            }}
            options={countryDropdownOptions}
            onChangeCallback={({ option }) => {

              const countryID = option.code;

              field.onChange(countryID); // Updates the form state
              setCartCurrency(countryCurrenciesByID[countryID]); //Update visual currency

              // Invalidating the byteplant address validator
              setByteplantAddressValidatorState((current) => ({...current, responseData: null}))
            }}
            questionnaireValidationObject={{}}
            errorMode={false}
            style={{
              border: "1px solid var(--sub-gray)",
              height: "56px",
              width: "100%",
              borderRadius: "6px",
              margin: "15px 0px",
              backgroundColor: "transparent",
            }}
            label={translator.translate(language, "shipping", "ShippingForm/country")}
            disabled={countryDropdownOptions.length === 1}
          />
        )}
      />


      {/* Company (Firm)  */}
      <Controller
        name="company"
        control={control}
        render={({ field }) => (
          <InputField
            id="delivery-address-form-company"
            ignoreTouched={true}
            value={field.value}
            onChange={(value: string) => {
              field.onChange(value);
            }}
            placeholder={translator.translate(language, "shipping", "ShippingForm/firm_name")}
            mandatory={false}
            language={language}
            onBlur={field.onBlur}
          />
        )}
      />


      {/* Billing Address Toggle */}
      {
        showToggle &&

        <>
          <Controller
            name="useAddressAsBilling"
            control={control}
            render={({ field }) => (
              <div id="address-as-billing" className="flex flex-row justify-center gap-3 md:gap-2 my-2 items-center md:col-span-2">
                <ToggleRadioButton
                  id="toggle_address_as_billing"
                  isToggleEnabled={field.value}
                  updateToggle={(value: boolean) => field.onChange(value)}
                />
                <p className="address-as-billing-text">
                  {translator.translate(
                    language,
                    "shipping",
                    "ShippingForm/address_as_billing_text"
                  )}
                </p>
              </div>
            )}
          />

        </>
      }


      {/* Billing Form */}
      {
        !useAddressAsBillingValue && (
          <>
            {/* Billing First Name  */}
            <Controller
              name="billing_first_name"
              control={control}
              render={({ field, fieldState }) => (
                <InputField
                  id="delivery-address-form-billing-first-name"
                  ignoreTouched={true}
                  value={field.value}
                  onChange={(value: string) => { field.onChange(value) }}
                  placeholder={translator.translate(language, "shipping", "ShippingForm/first_name")}
                  mandatory={true}
                  errorMsg={errors.billing_first_name?.message}
                  language={language}
                  onBlur={field.onBlur}
                />
              )}
            />

            {/* Billing Last Name  */}
            <Controller
              name="billing_last_name"
              control={control}
              render={({ field }) => (
                <InputField
                  id="delivery-address-form-billing-first-name"
                  ignoreTouched={true}
                  value={field.value}
                  onChange={(value: string) => { field.onChange(value) }}
                  placeholder={translator.translate(language, "shipping", "ShippingForm/last_name")}
                  mandatory={true}
                  errorMsg={errors.billing_last_name?.message}
                  language={language}
                  onBlur={field.onBlur}
                />
              )}
            />

            {/* Billing Zip */}
            <Controller
              name="billing_zip"
              control={control}
              render={({ field }) => (
                <InputField
                  id="delivery-address-form-billing-zip"
                  ignoreTouched={true}
                  value={field.value}
                  onChange={(value: string) => {
                    field.onChange(value);
                  }}
                  placeholder={translator.translate(language, "shipping", "ShippingForm/postal_code")}
                  mandatory={true}
                  errorMsg={errors.billing_zip?.message}
                  language={language}
                  onBlur={field.onBlur}
                />
              )}
            />

            {/* Billing City */}
            <Controller
              name="billing_city"
              control={control}
              render={({ field, fieldState }) => (
                <GoogleApiInputField
                  id="delivery-address-form-billing-city"
                  value={field.value}
                  onChange={(value: any) => {
                    if (Array.isArray(value.split(","))) {
                      value = value.split(",")[0];
                    }
                    field.onChange(value); // Update the form field value
                  }}
                  placeholder={translator.translate(
                    language,
                    "shipping",
                    "ShippingForm/location"
                  )}
                  mandatory={true}
                  country={cartCurrency.countryID}
                  errorMsg={fieldState?.error?.message} // Bind error message from hook
                  onBlur={field.onBlur} // Trigger onBlur to update touched state
                  addressType={"(cities)"}
                  isGoogleApiLoaded={isGoogleAPILoaded}
                />
              )}
            />


            {/* Billing shipping address */}
            <Controller
              name="billing_address"
              control={control}
              render={({ field, fieldState }) => (
                <GoogleApiInputField
                  id="delivery-address-form-billing-address"
                  value={field.value}
                  onChange={(value: any) => {
                    if (Array.isArray(value.split(","))) {
                      value = value.split(",")[0];
                    }
                    field.onChange(value); // Update the form field value
                  }}
                  placeholder={translator.translate(
                    language,
                    "shipping",
                    "ShippingForm/street_and_house"
                  )}
                  mandatory={true}
                  country={cartCurrency.countryID}
                  errorMsg={fieldState?.error?.message} // Bind error message from hook
                  onBlur={field.onBlur} // Trigger onBlur to update touched state
                  addressType={"address"}
                  isGoogleApiLoaded={isGoogleAPILoaded}
                />
              )}
            />

            {/* Billing Country */}
            <Controller
              name="country"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  id="delivery-addres-form-billing-country"
                  initialValue={{
                    value: translator.translate(language, "shipping", `ShippingForm/country_name/${field.value}`),
                    code: field.value,
                  }}
                  options={[]}
                  onChangeCallback={({ option }) => {}}
                  questionnaireValidationObject={{}}
                  errorMode={false}
                  style={{
                    border: "1px solid var(--sub-gray)",
                    height: "56px",
                    width: "100%",
                    borderRadius: "6px",
                    margin: "15px 0px",
                    backgroundColor: "transparent",
                  }}
                  label={translator.translate(language, "shipping", "ShippingForm/country")}
                  disabled={true}
                />
              )}
            />

          </>
        )
      }

      {/* Phone */}
      <Controller
        name="phone"
        control={control}
        render={({ field, fieldState }) => (
          <div id="phone-field-container" className="text-[#98a0af] flex flex-col md:flex-row gap-4 md:col-span-2">
            {/* Icon and text */}
            <div id="phone-icon-and-text" className="flex gap-3 items-center w-full">
              <BsTelephone className="w-[25px] h-[25px] flex-shrink-0" />
              <p id="phone-icon-text" className="text-[13px] font-roboto">
                {translator.translate(language, "shipping", "ShippingForm/phone_icon_text")}
              </p>
            </div>

            <InputField
              id="delivery-address-form-phone"
              ignoreTouched={true}
              value={field.value}
              onChange={(value: string) => {
                field.onChange(value);
              }}
              placeholder={translator.translate(language, "shipping", "ShippingForm/telephone")}
              mandatory={true}
              errorMsg={fieldState?.error?.message} // Bind error message from hook
              language={language}
              onBlur={field.onBlur}
            />
          </div>
        )}
      />

      {/* The Parent (shipping page) is handling the submit */}
    </form>
  </>
);
};

export default DeliveryAddressForm;
