import React, { useContext, useEffect, useRef } from 'react'
import ProductQuantityControl from './ProductQuantityControl'
import ProductAvailabilityTag from './ProductAvailabilityTag';
import { ChooseTreatmentContext, Product } from '../FreeDosageLayout';
import SubmitButton from './SubmitButton';
import RadioSelect from '../../../../components/RadioSelect/RadioSelect';
const MOBILE_BREAK_POINT = 768

interface ProductCardProps {
    productID: number,
    title: string,
    dosage: string,
    priceString: string,
    inStock: boolean,
    additionalData?: string;
    quantityType:string
}

const ProductCard:React.FC<ProductCardProps> = ({ productID, title, dosage, priceString, inStock, additionalData, quantityType}) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const isSelected = chooseTreatmentContext?.selectedProductID === productID;
    const additionalDataObj = additionalData ? JSON.parse(additionalData) : {};
    const cardRef = useRef<HTMLDivElement>(null);

    const selectedProduct = chooseTreatmentContext?.selectedProduct as Product;

    let customDosageForCannabis = ''
    let strain = ''
    let shouldUseCannabisDosage = false;

    useEffect(() => {
        if(isSelected){
            if (cardRef.current) {
                cardRef.current.scrollIntoView({ behavior: 'smooth',block: "end"});
            }
        }
      },[cardRef]);

    if (additionalDataObj) {
        if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
            shouldUseCannabisDosage = true;
            customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
            strain = `Sorte: ${additionalDataObj['strain']}`
        }
    }
    
    const scrollToTop = () => {
        if(window.screen.width < MOBILE_BREAK_POINT){
            setTimeout(() => {
                if (cardRef.current) {
                    cardRef.current.scrollIntoView({ behavior: 'smooth',block: "start"});
                }
            },200)
        }
    }
    
    const onSelect = () => {
        const newProduct = chooseTreatmentContext?.products.find((product) => product.product_id === productID) as Product;
        chooseTreatmentContext?.setSelectedProductID(productID);
        
        if (newProduct.in_stock) {
            const filteredProductItems = newProduct.items.filter((item: any) => item.in_stock);
            const medianItemIndex = Math.floor(filteredProductItems.length / 4);
            chooseTreatmentContext?.setSelectedProductItemID(filteredProductItems[medianItemIndex].product_item_id);
        } else {
            const medianItemIndex = Math.floor(newProduct.items.length / 4);
            chooseTreatmentContext?.setSelectedProductItemID(newProduct?.items[medianItemIndex].product_item_id);
        }
        scrollToTop();
    }

    

    
    return (
        <div ref={cardRef} className={`scroll-mt-[100px] shadow-lg md:mr-4 bg-white rounded-xl ${!isSelected ? "hover:shadow-xl" : ""}`}>
            {/* actual product data is here */}
            <div id={`product_${productID.toString()}`} onClick={onSelect} className={`${isSelected ? "bg-[#E7FCF7] " : "bg-white"} rounded-xl py-5 px-3 cursor-pointer pl-4 flex items-start justify-around`}>
                {/* check box */}

                <div  className='h-[90px]'>
                    <RadioSelect size={23} selected={isSelected} disabled={false} />  
                </div>

                {/* content */}
                <div className='pr-5 pl-2 flex-1 flex flex-col'>
                    <p className='font-medium text-[#0D2C54]'>{title}</p>
                    {
                        shouldUseCannabisDosage ? <div>
                            <p className='text-sm text-[#0A9281] font-medium'>{customDosageForCannabis}</p>
                            <p className='text-sm text-[#0A9281] font-medium'>{strain}</p>
                        </div> : null
                    }
                </div>


                {/* begining of test */}


                {/* price and tags */}
                <div className='p-2 flex flex-col h-[90px]'>
                    { inStock ?
                        <div>
                            <div className='flex items-center gap-1'>
                                <p className='text-xs text-[#556D8C]'>ab</p>
                                <p className='text-sm font-medium text-[#0D2C54]'>{priceString} €</p>
                            </div>
                            {quantityType == 'gram' &&
                                <p className='text-xs text-[#556D8C]'> pro Gramm </p>
                            }
                        </div> : null
                    }
                    <ProductAvailabilityTag inStock={inStock} />
                </div>
            </div>
            <div className='md:hidden md:p-4'>
                {isSelected && <ProductQuantityControl items={selectedProduct.items} />}
                {isSelected && <SubmitButton languageID={"de"}  disabled={chooseTreatmentContext?.submitLoading} onClick={chooseTreatmentContext?.onSubmit} />}
           </div>
        </div>
    )
}

export default ProductCard