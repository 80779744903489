let translationCache = {}
const DEFAULT_LANGUAGE = 'de' 

export function translate (language, category, path){

  try {
      if (!translationCache[language]){
        translationCache[language] = {}
    }

    if (!translationCache[language][category]){
      try {
        translationCache[language][category] = require(`../../resources/translations/${language}/${category}/translations.json`)
      } catch (err) {
        translationCache[language][category] = require(`../../resources/translations/${DEFAULT_LANGUAGE}/${category}/translations.json`)
      }

    }

    let paths = path.split("/")

    let currJson = translationCache[language][category]

    paths.forEach(currKey =>{currJson = currJson[currKey]} )

    return currJson
  } catch (err) {
    window.clientLogger.error("error in translator", err);
    return null;
  }

    
}

export const getCountryByLanguage = (language)=>{
  switch (language){
    case "de":
      return "DE";
    case "fr":
      return "FR";
    case "en":
      return "GB"
  }
}



export const tempGetLocale = (country) => {
  //TODO: Need to have a global function in client that returns locale, this is temp
  let locale;
  if (country === "de") {
    locale = "de-DE";
  } else if (country === "fr") {
    locale = "fr-FR";
  } else {
    return "en-GB";
  }
  return locale;
}


export const getLanguageByCountry = (country)=>{
  switch (country?.toLowerCase()){
    case "de" || "DE":
      return "de";
    case "fr" || "FR":
      return "fr";
    default:
      return "en"
  }
}

export const getCurrencyByCountry = (country, shouldReturnSymbol = true) => {
  // Convert country to lowercase for consistent comparison
  const countryLowerCase = country?.toLowerCase();
  

  switch (countryLowerCase) {
    case "de":
    case "fr":
      return shouldReturnSymbol ? "€" : "EUR";

    case "gb":
    case "uk":
    case "en":
      return shouldReturnSymbol ? "£" : "GBP";

    case "ch":
      return shouldReturnSymbol ? "CHF" : "CHF";

    default:
      return shouldReturnSymbol ? "$" : "USD";
  }
};


export const getCurrencyByLocale = (locale, shouldReturnSymbol = true) => {
  const country = locale.split("-")[1];
  const countryLowerCase = country?.toLowerCase();

  switch (countryLowerCase) {
    case "de":
    case "fr":
      return shouldReturnSymbol ? "€" : "EUR";

    case "gb":
    case "uk":
    case "en":
      return shouldReturnSymbol ? "£" : "GBP";

    case "ch":
      return shouldReturnSymbol ? "₣" : "CHF";

    default:
      return shouldReturnSymbol ? "$" : "USD";
  }
};

export const getPrice = (price, locale, currencyRate = 1) => {
  
  const options = {
    style: "currency",
    currency : getCurrencyByLocale(locale, false), // Get the three-letter currency code (not the symbol) for formatting
  };

  const formattedPrice = new Intl.NumberFormat(locale, options).format(Math.abs(price) * currencyRate);

  if (price < 0) { //Apperantly some formats do not support negative values, therefore using this small check
    return `- ${formattedPrice}`;
  }

  return formattedPrice
};


export const formatNumberByLocale = (number, locale = "en-US", fallback = "en-US") => {
  if (typeof number !== "number") {
    number = 0;
  }
  if (typeof number === "string" && !isNaN(parseFloat(number))) {
    number = parseFloat(number);
  }
  try {
    return number.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } catch (error) {
    return number.toLocaleString(fallback, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}




