import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as translator from "../../../../utils/translator";
import "./MyAccountOrderDetailsDisclaimer.css";

interface MyAccountOrderDetailsDisclaimerProps {
  statusColor: string;
  description: string;
  title?: string;
  showIcon: boolean;
}

const MyAccountOrderDetailsDisclaimer: React.FC<
  MyAccountOrderDetailsDisclaimerProps
> = ({ statusColor, description, title, showIcon = false }) => {
  const [expanded, setExpanded] = useState(false);
  const { country } = useParams();
  const replacePattern = /\{([^:]+):([^}]+)\}/g;

  //Replacing the text to be a link incase it matches that replacePattern and add coloring for bold text and links
  let htmlDescription = description.replace(
    replacePattern,
    (match, anytext, anyattributes) => {
      return `<a ${anyattributes}>${anytext}</a>`;
    }
  );
  // with <a> adnd <b> tags. If no class attribute exists, add it, then append relevant classes.
  htmlDescription = htmlDescription.replace(/<b([^>]*)>/g, (match, p1) => {
    if (p1.includes('class="')) {
      return `<b${p1.replace(
        /class="([^"]*)"/,
        (classMatch: string, existingClasses: string) => {
          return `class="${existingClasses} ${statusColor}"`;
        }
      )}>`;
    } else {
      return `<b${p1} class="${statusColor}">`;
    }
  });

  htmlDescription = htmlDescription.replace(/<a([^>]*)>/g, (match, p1) => {
    if (p1.includes('class="')) {
      return `<a${p1.replace(
        /class="([^"]*)"/,
        (classMatch: string, existingClasses: string) => {
          return `class="${existingClasses} interactable"`;
        }
      )}>`;
    } else {
      return `<a${p1} class="interactable">`;
    }
  });

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(title ? false : true);
  }, [title]);

  return (
    <div className={`card ${statusColor}`} id="my-account-details-disclaimer">
      <div className="contentWrapper">
        <div className="mainContent">
          {title ? (
            <div className="header">
              <div className="titleContainer">
                <div className="warning-icon">
                  {showIcon ? (
                    <img
                      src={require("../../../../icons/warning.svg").default}
                      alt="warning"
                    />
                  ) : null}
                </div>
                <h2 className="title">{title}</h2>
              </div>

              <button
                onClick={toggleExpanded}
                className="toggleButton interactable"
              >
                {expanded
                  ? translator.translate(
                      translator.getLanguageByCountry(country),
                      "my_account",
                      "MyOrders/showLess"
                    ) + " "
                  : translator.translate(
                      translator.getLanguageByCountry(country),
                      "my_account",
                      "MyOrders/showMore"
                    ) + " "}
                <svg
                  className={`arrow ${expanded ? "expanded" : ""}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
            </div>
          ) : null}
          {expanded && (
            <div
              className={`notificationBody ${title ? "undertitle" : ""}`}
              dangerouslySetInnerHTML={{ __html: htmlDescription }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyAccountOrderDetailsDisclaimer;
