import activeLogo from "../../../../icons/Active.svg";
import { useState, useEffect, useContext } from "react";
import DrugSubscription from "../DrugSubscription/DrugSubscription";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";
import { useParams } from "react-router-dom";
import "./DrugPacksChoicePanel.css";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import { getDefaultProductItemByRule } from "../../helpers";
import {submitOverrideContext} from "../../../../components/Modals/MyAccountChangeSubscriptionMedicationModal/MyAccountChangeSubscriptionMedicationModal";
import {formatNumberByLocale} from "../../../../utils/translator";

const DrugPacksChoicePanel = ({
  language,
  currencySign,
  showDeliveryErrorMessage,
  setShowDeliveryMessage,
  selectedSubscriptionPlanID,
  setSelectedSubscriptionPlanID,
  currentSelectedProductItemIndex,
  changeCurrentlySelectedProductItemIndex,
  productItemSubscriptionPlans,
  pricesLogistics,
  listOfPackets,
  changePacketsListCallback,
  includeCustomTitle = false,
  colLayout = false,
}) => {
  const CatalogSettings = useContext(CatalogSettingContext);
  const  {catalogSettings }  = useContext(submitOverrideContext); //for coming from the modal (needs context since not called from other providers)
  const [selectedPackItem, setSelectedPackItem] = useState(null);
  const { country } = useParams();
  const layout = CatalogSettings.layout || catalogSettings.layout;
  const showPrices = country.toUpperCase() !== "FR" && !CatalogSettings?.forceHidePrices;

  useEffect(() => {
    //set the default pack

    if (listOfPackets && listOfPackets.length > 0) {
      let productItem = getDefaultProductItemByRule(
        listOfPackets,
        listOfPackets[0].default_quantity
      );
      let foundStockItem = false;

      listOfPackets.forEach((pack, index) => {
        if (pack.product_items_id === productItem?.product_items_id) {
          setSelectedPackItem(index);
          changeCurrentlySelectedProductItemIndex(index);
          foundStockItem = true;
        }
      });

      if (!foundStockItem) {
        setSelectedPackItem(null);
      }
    }
  }, [listOfPackets]);

  function calculateSparedCost(packItem) {
    let currentProductDosageStr;
    let currentProductTitle = packItem.title;

    if (packItem.product_catalogs_title) {
      currentProductTitle = packItem.product_catalogs_title;
    }

    if (packItem.dosage_str.length > 0 && !packItem.use_product_name && (layout === 'returning_customers' || country.toUpperCase() === 'FR')) {
      currentProductDosageStr = packItem.dosage_str;
    } else {
      currentProductDosageStr = packItem.title;
    }

    if (!pricesLogistics) return -99;

    let currentItemLogistics =
      pricesLogistics[currentProductTitle][currentProductDosageStr];
    let spared =
      (packItem.quantity / currentItemLogistics.min_quantity) *
        currentItemLogistics.price -
      packItem.price;

    if (spared > 0) {
      return String(spared.toFixed(2)).replace(".", ",");
    }
    return null;
  }

  return (
    <div
      className={
        colLayout
          ? "drug-packs-choice-inner-container-col-layout "
          : "drug-packs-choice-inner-container"
      }
    >
      {listOfPackets.map((packItem, index) => {

      const formattedPrice = formatNumberByLocale(packItem.price, language.toLowerCase() + '-' + country.toUpperCase())


        return (
          <div key={index} className="package-item-container">
            <div key={index} className="li-container">
              <span
                key={index}
                onClick={
                  packItem.in_stock === 1
                    ? () => {
                        MainTracker.superProperties({
                          "Product Quantity": packItem.quantity,
                        });
                        window.eventsTracking.track({
                          e: "Click change Product Quantity",
                        });
                        setSelectedPackItem(index);
                        changeCurrentlySelectedProductItemIndex(index);
                      }
                    : null
                }
                className={
                  (index !== selectedPackItem
                    ? "pack-item"
                    : "pack-item-selected") +
                  " " +
                  (packItem.in_stock === 0 ? "out-of-stock " : " ") +
                  (colLayout ? "col-layout-item" : "")
                }
              >
                <div className={colLayout ? "row-product" : ""}>
                  {includeCustomTitle ? (
                    <>
                      <p className="quantity">{packItem.quantity}</p>
                      <p className="custom-quantity">
                        {packItem.custom_quantity_title.slice(
                          packItem.custom_quantity_title.indexOf(" ") + 1
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      {country.toUpperCase() === "FR" || colLayout ? (
                        <p className="col-layout-custom-quantity">
                          {packItem.custom_quantity_title
                            ? packItem.custom_quantity_title
                            : packItem.quantity_type}
                        </p>
                      ) : (
                        <>
                          <p className="quantity">{packItem.quantity}</p>
                          <p className="custom-quantity">
                            {packItem.custom_quantity_title.slice(
                              packItem.custom_quantity_title.indexOf(" ") + 1
                            )}
                          </p>
                        </>
                      )}
                    </>
                  )}

                  {showPrices && (
                    <p className="packet-price">
                      {
                        // REMOVED THIS TREATMENT PRICE FROM CALCULATION: (CatalogSettings.includeTreatmentFeeInPrice || catalogSettings?.price_style != "no_treatment_fee" ? packItem.treatment_fee : 0) -
                      formattedPrice + ' ' + currencySign}
                    </p>
                  )}
                </div>
              </span>
              {index === selectedPackItem ? (
                <img className="check-mark" src={activeLogo} />
              ) : null}
            </div>
            
            {calculateSparedCost(packItem) &&
              showPrices && (
                <p className="spared">Spare {calculateSparedCost(packItem) + ' ' + currencySign}</p>
              )}
          </div>
        );
      })}

      {CatalogSettings.subscription_view_type && (listOfPackets[selectedPackItem]?.original_in_stock)  ? (
        <DrugSubscription
          showDeliveryErrorMessage={showDeliveryErrorMessage}
          setShowDeliveryMessage={setShowDeliveryMessage}
          selectedSubscriptionPlanID={selectedSubscriptionPlanID}
          setSelectedSubscriptionPlanID={setSelectedSubscriptionPlanID}
          productItemSubscriptionPlans={productItemSubscriptionPlans}
          selectedPackItemObject={listOfPackets[selectedPackItem]}
          selectedPackItem={
            selectedPackItem >= 0 &&
            listOfPackets[selectedPackItem] &&
            listOfPackets[selectedPackItem].product_items_id
              ? listOfPackets[selectedPackItem].product_items_id
              : null
          }
          productName={
            selectedPackItem >= 0 && listOfPackets[selectedPackItem]
              ? listOfPackets[selectedPackItem].title
              : null
          }
          productQuantity={
            selectedPackItem >= 0 && listOfPackets[selectedPackItem]
              ? listOfPackets[selectedPackItem].custom_quantity_title
              : null
          }
        />
      ) : null}
    </div>
  );
};

export default DrugPacksChoicePanel;
