import { useEffect, useState } from 'react'
import * as http from "../utils/http"
import ShippingV2 from './v2/ShippingV2/ShippingV2';
import Shipping from './Shipping/Shipping';
import FunnelLayout from '../layouts/FunnelLayout';
import { useShippingPageContext } from './v2/ShippingV2/providers/ShippingPageProvider';

const ShippingRouter = () => {

  const [funnelVersion,setFunnelVersion] = useState("");
  const [fetched,setFetched] = useState(false);
  //Shipping Context
  const {
    setForceFreeTreatmentFeeCoupon,
  } = useShippingPageContext();

  const renderBaseOnFunnelVersion = () => {

    switch (funnelVersion) {
      case "shipping_multiple_premium":
        return (
          <FunnelLayout>
            <ShippingV2 />
          </FunnelLayout>
        );
      default:
        return (
          <Shipping />
        );
    }
  }

  useEffect(() => {
   http.getFromServer(`shipping_routing`, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID])
    .then((response: any) => {
      setFunnelVersion(response.data?.cart?.funnel_version);
      setForceFreeTreatmentFeeCoupon(response.data?.forceTreatmentFeeDiscount);

    })
    .catch((error: any) => {
      window.clientLogger.warn("ShippingRouter failed to get old cart, therefore regular shipping will be presented")
    })
    .finally(() => setFetched(true))
 
  }, []);


  if (!fetched){
    return <></>
  }
    
  return (
    renderBaseOnFunnelVersion()
  )
}

export default ShippingRouter
