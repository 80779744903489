import React, { ReactElement } from "react";
import SubmitButton from "./SubmitButton/SubmitButton";
import { useParams } from "react-router-dom";
import * as translator from "../../src/utils/translator";

type Props = {
  submitButtonLoading: boolean;
  onSubmit: () => void;
  children?: ReactElement;
  formID?: string;
  isButtonDisabled?: boolean;
};

const StickySubmit: React.FC<Props> = ({ submitButtonLoading, onSubmit, children,formID, isButtonDisabled = false }) => {
  const { language } = useParams();

  return (
    <div
      id="sticky-submit-container"
      className="w-full flex bg-white shadow-[0_-10px_70px_var(--primary-gray)] gap-2 items-center justify-center fixed bottom-0 right-0 z-[99] py-4"
    >

      <div
        id="sticky-submit"
        className="w-[970px] flex flex-col md:flex-row items-center justify-between gap-[10px] md:gap-[30px] px-[15px]"
      >
      {children ?

        <div id="sticky-submit-left-side">{children}</div>
        :
        null
      }

        <SubmitButton
          id={"sticky-submit-button"}
          loading={submitButtonLoading}
          language={language}
          onSubmit={onSubmit}
          padding="0px 75px"
          formID={formID}
          placeholder={translator.translate(language, "shipping", "ShippingForm/button_title")}
        />
      </div>
    </div>
  );
};

export default StickySubmit;
