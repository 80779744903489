import React, { useState, FormEvent,useRef,useEffect } from "react";
import "./SearchBar.css";
import AutoComplete from "../AutoComplete/AutoComplete";

import * as translator from "../../utils/translator";

interface searchBarProps {
  onSubmit: (searchQuery: string) => void;
  country: string;
  shouldFocusOnRender: boolean;
}

const SearchBar: React.FC<searchBarProps> = ({ onSubmit, country,shouldFocusOnRender=false }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(searchQuery);
  };


  useEffect(() => {
    if (shouldFocusOnRender && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  return (

    <form className="searchbar-form" onSubmit={handleFormSubmit}>
      <input
        className={"searchbar-input"}
        onChange={(e) => setSearchQuery(e.target.value)}
        ref={searchInputRef}
        placeholder={ 
          translator.translate(
            translator.getLanguageByCountry(country),
            "default",
           "SearchBar/placeholder"
        )}
        name="search"
        type="text"
        required
      ></input>
      <button type="submit" className="searchbar-button">
        <img
          src={require("../../icons/Search-White.svg").default}
          alt="search"
        />
      </button>
      <AutoComplete query={searchQuery} country={country} onSelect={(suggestion) => {
        setSearchQuery(suggestion);
        onSubmit(suggestion);
      }} />
    </form>
   

  );
};

export default SearchBar;
