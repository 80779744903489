import React, { useContext,useState} from 'react';
import HeaderCMSHamburger from './HeaderCMSHamburger';
import BadgeNotification from '../../../pages/MyAccount/components/BadgeNotification/BadgeNotification';
import UserDetailsHoverDisconnected from "../../../pages/MyAccount/components/UserDetailsHover/UserDetailsHoverDisconnected"
import UserDetailsHoverConnected from "../../../pages/MyAccount/components/UserDetailsHover/UserDetailsHoverConnected"

import * as translator from "../../../utils/translator";
import { headerCMSContext } from '../HeaderCMS/HeaderCMS'; 
import { useCustomerContext } from '../../../reducers/CustomerReducer';

const TREATMENTS = "treatments";
const SEARCH = "search";
const HAMBURGER = "hamburger";

interface HeaderCMSNavigationProps {
  isMobile?: boolean; //if its a duplication for mobile, we delete some icons using the className -mobile
}

const HeaderCMSNavigation: React.FC<HeaderCMSNavigationProps> = ({ isMobile }) => {
  const [isUserCardShown,setIsUserCardShown] =useState(false)

  const handleToggle = () => {
    setIsUserCardShown((prev) => !prev);
  };
  const handleClose = () => {
    setIsUserCardShown(false)

  };
  const handleHover=()=>{
    setIsUserCardShown(true)
  }
  const handleLeave=()=>{
    setIsUserCardShown(false)
  }
  
  const hoverEventHandlers = (!isMobile ) && { onMouseLeave: handleLeave, onMouseEnter: handleHover }
  const onClickEventHandler = isMobile ? { onClick: handleToggle } : {};
 
  const {
    currentOpenModal,
    handleModals,
    shoppingBagNotification,
    myAccountTotalNotifications,
    country,
    headerData,
    redirectToCart
  } = useContext(headerCMSContext);

  const { customerState } = useCustomerContext();
  const isUserLogged = customerState?.customerID ? true : false;
 
  return (
    <ul className={`cms-header-navigation${isMobile ? "-mobile" : ""}`}>

      {/* Treatments button*/}
      <li
        id="header-treatments-icon"
        className={`treatments-dropdown${currentOpenModal !== "" ? "-modal-active" : ""}`}
        onClick={() => handleModals(TREATMENTS)}>
        <span>
          {translator.translate(translator.getLanguageByCountry(country), "default", "HeaderCMS/treatments_dropdown_text")}
        </span>
        <img className={`${currentOpenModal === TREATMENTS ? "chevron-up" : "chevron-down"}`} src={require("../../../icons/Chevron-Down-Green.svg").default} alt="^" />
      </li>

      {/* Search Icon */}
      <li
        id="header-search-icon"
        className="nav-search nav-icon-container"
        onClick={() => handleModals(SEARCH)}>
        {currentOpenModal === SEARCH ?
          <img className="nav-icon-search" src={require("../../../icons/Search-White.svg").default} alt="Search" />
          :
          <>
            <img className="nav-icon" src={require("../../../icons/Search.svg").default} alt="Search" />
            <img className="nav-icon-hovered" src={require("../../../icons/Search-Green.svg").default} alt="Search" />
          </>
        }
      </li>

      <li  {...hoverEventHandlers}
       className={`nav-user nav-icon-container`}>

  {/* User Icon */}
  {(isUserLogged)? 
     <div className="small-user-avatar">{headerData?.customerNameAndEmail?.first_name?.charAt(0).toUpperCase() ?? "U"}</div>
:
 <img
    className="nav-icon"
    src={require("../../../icons/DisconnectedUser.svg").default}
    alt="User"
  />
  }
  
   {/* Small Arrow */}
   <img
    className={`user-arrow  ${isUserCardShown? "rotate-arrow" :""}`}
    src={require("../../../icons/Chevron-Down-Gray.svg").default}
    alt="Arrow"
  />
  <BadgeNotification
    amount={myAccountTotalNotifications ? myAccountTotalNotifications : 0}
  />
  {/* <button onClick={handleClose} className="close-btn">&times;</button> */}
  {/* an invisable bridge for the gap between the icon and the window */}
  <div id={isMobile?'mobile-icon-bridge' :'icon-bridge'} {...onClickEventHandler} className='invisible-icon-bridge'></div>
  {isUserCardShown && (
    <div className="user-details-hover-container">
      {isUserLogged ? (
        <UserDetailsHoverConnected 
          userData={headerData?.customerNameAndEmail} 
          country={country}
          onClick={handleClose}
        />
      ) : (
        <UserDetailsHoverDisconnected onClick={handleClose} country={country}
        />
      )}
    </div>
  )}
</li>


      {/* ShoppingBag Icon */}
       {
        shoppingBagNotification ? 
        <li onClick={redirectToCart} className="nav-shopping-bag nav-icon-container ">
          <img className="nav-icon" src={require("../../../icons/Shopping-Bag.svg").default} alt="User" />
          <img className="nav-icon-hovered" src={require("../../../icons/Shopping-Bag-Green.svg").default} alt="User" />
          <BadgeNotification amount={1} />
        </li>
        : 
        null
       }
    

      <li className="nav-hamburger nav-hamburger-container" onClick={() => handleModals(HAMBURGER)}>
        {/* Hamburger "Icon" */}
        <HeaderCMSHamburger />
      </li>

    </ul>
  );
}

export default HeaderCMSNavigation;
