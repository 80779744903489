import React, { useState } from 'react';
import "./OTCProductRow.css";
import Information from "../../icons/Information.svg";
import InformationGreen from "../../icons/Information-Green.svg";
import QuantityControl from '../QuantityControl/QuantityControl';
import { clientConfig } from '../../config';
import { getPrice } from '../../utils/translator';
import { useCartContext } from '../../customHooks/useCart';

type Props = {
    productItem: ProductItem
    shouldShowQuantityControl?: boolean
    incrementButtonOnClick?: () => void
    decrementButtonOnClick?: () => void
    showBin?: boolean

}

const OTCProductRowV2: React.FC<Props> = ({ productItem, shouldShowQuantityControl = true, showBin, incrementButtonOnClick, decrementButtonOnClick }) => {
    
    const {cartCurrency, getCart} = useCartContext();
    const cart = getCart();
    const [isInformationEnabled, setIsInformationEnabled] = useState<boolean>(false);

    const quantity = cart.products.find(product => product.product_item_id === productItem.id)?.quantity || 0;


    return (
        <div className='otc-addons-row-container'>
            <div className='otc-addons-row'>

                <div className='otc-product-image-and-details'>
                    <img className='otc-product-image' src={clientConfig.IMAGES_URL + productItem.imgSrc} alt="product-item" />
                    <div className='otc-product-item-details'>
                        <p className='product-item-details-title'>{productItem.title}</p>
                        {/* TODO:Translations */}

                        {
                            productItem.custom_quantity_title ?
                                <p className='product-item-details-quantity'>Pack: {productItem.custom_quantity_title}</p>
                                :
                                null
                        }

                        {
                            quantity ?
                                <p className='product-item-details-quantity'>Quantity: {quantity}</p>
                                :
                                null
                        }

                        <p className='product-item-details-price'>
                            {getPrice(productItem.price * (quantity || 1), cartCurrency.locale, cartCurrency.rate)} <span className='product-item-details-price-discount'> 25% OFF</span>
                        </p>

                        <p className='product-item-details-price-deleted'>
                            {getPrice((productItem.price * (quantity || 1)) / 0.75, cartCurrency.locale, cartCurrency.rate)} 
                        </p>
                    </div>
                </div>


                {
                    shouldShowQuantityControl && incrementButtonOnClick && decrementButtonOnClick?

                        <div className='quantity-control-wrapper'>
                            <button onClick={() => setIsInformationEnabled((current: boolean) => !current)} className={`product-information-button${isInformationEnabled ? "-enabled" : ""}`}>
                                <img src={isInformationEnabled ? Information : InformationGreen} alt="i" />
                            </button>

                            {
                                shouldShowQuantityControl ?
                                    <QuantityControl
                                        decrementButtonOnClick={decrementButtonOnClick}
                                        quantity={quantity!}
                                        incrementButtonOnClick={incrementButtonOnClick}
                                    />
                                    :
                                    null
                            }

                                </div>
                        :

                        null
                }

            </div>

            {/* Product's Information text */}
            {
                isInformationEnabled ?
                    <div className='information-text-container'>
                        <p className='information-text'>
                            {productItem.description}
                        </p>
                    </div>
                    :
                    null
            }

        </div>

    )
}

export default OTCProductRowV2