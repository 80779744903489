import React from 'react';
import Disclaimer from '../../components/Disclaimers/Disclaimer';
import SubmitButton from './SubmitButton';
import { useParams } from 'react-router-dom';

type Props = {
	disclaimerText: string;
	showDisclaimer: boolean;
	buttonDisabled: boolean;
	selectedProductsAmount: number;
	maxSelectedProductsAmount: number;
	showAmountOfSelectedProducts: boolean;
	onSubmit: () => void;
	onCartClick?: () => void; // Fixed type
	totalPrice: number;
};

const StickySubmitButton: React.FC<Props> = ({
	maxSelectedProductsAmount,
	disclaimerText,
	showDisclaimer,
	buttonDisabled,
	selectedProductsAmount,
	showAmountOfSelectedProducts,
	onSubmit,
	onCartClick,
	totalPrice
}) => {
	const { language } = useParams();

	return (
		<div
			id="sticky-submit-container"
			className="w-full flex flex-col gap-y-2 items-center justify-center sticky bottom-0 right-0"
			style={{ backgroundColor: '#f7f7f7', borderTop: '2px solid white' }}
		>
			{showDisclaimer ? (
				<div id="sticky-submit-disclaimer-container" className="w-full flex items-center justify-center">
					<Disclaimer
						text={disclaimerText}
						containerClassName={`flex max-w-[425px] p-3 items-center bg-[#EDFCF0] shadow-lg shadow-[#E5E7E5] gap-2 transition-all duration-300 ${
							showDisclaimer ? 'opacity-100 animate-fade-in-1' : 'opacity-0 pointer-events-none'
						}`}
						textClassName="!text-[#0D2C54] font-roboto text-lg !font-medium"
						iconClassName="!text-[#19D845]"
					/>
				</div>
			) : null}

			<div id="sticky-submit-container" className="w-full flex justify-center">
				<div id="sticky-submit" className="w-[500px] p-4 md:py-4 md:px-0 flex items-center gap-[15px] relative">

					<div id="sticky-submit-button" className="w-full ">
						<SubmitButton languageID={language!} disabled={buttonDisabled} desktop onClick={onSubmit} className="rounded-md w-full min-w-fit" totalPrice={totalPrice} numOfItems={selectedProductsAmount} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default StickySubmitButton;
