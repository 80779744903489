import React, { useState } from 'react'
import { IoMdStar } from 'react-icons/io';
import './OrderFeedbackForm.css'
import TextArea from '../../../components/CredentialsInput/TextArea';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import * as http from "../../../utils/http";
import ThanksForFeedback from '../components/MyAccountOrderFeedBack/ThanksForFeedbackWidget';
import * as translator from "../../../utils/translator"
import StickyNotification from '../../../components/StickyNotification/StickyNotification';
import { useOutletContext } from 'react-router-dom';


type props = {
  orderId: number,
  customerID: number,
  language: string,
  orderData: any
}

const OrderFeedbackForm = ({orderId, customerID, language, orderData}: props) => {
  const {setRefreshData} = useOutletContext<any>();
  const [starRating,setStarRating] = useState<Array<number>>([0,0,0,0,0]);
  const [rating, setRating] = useState<number>(0)
  const [hovered, setHovered] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>('');
  const [haveFeedback, setHaveFeedback] = useState<Boolean>(false);
  const [stickyNotification, setStickyNotification] = useState<{ refresh: boolean, level: string, show: boolean, title: string, content: string }>({ show: false, title: "", content: "", level: "", refresh: false })
  

  const title = translator.translate(language, "my_account", 'MyOrders/details/leaveFeedback');
  const score = translator.translate(language, "my_account", 'MyOrders/details/score');
  const placeholder = translator.translate(language, "my_account", 'MyOrders/details/leaveFeedbackPlaceHolder');
  const submit = translator.translate(language, "my_account", 'MyOrders/details/leaveFeedbackSubmit');




  const onStarClick = (index: number) => {
    const temp = [...starRating];
    for (let i = 0; i < temp.length; i++) {
      if (i < index) {
        temp[i] = 1;
      } else {
        temp[i] = 0;
      }
    }
    setStarRating(temp);
    setRating(index)
  }

  const onSubmit = () => {
    http.postToServer("account-update/order-details/add-feedback",{orderId: orderId, rating: rating, feedback: feedback, customerID: customerID}, [
      http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID
    ])
      .then(response => {
        setHaveFeedback(true);
        setRefreshData((current:boolean) => !current)
      })
      .catch(err => {
        if (err.response?.data === "feedback_exists") {
          setStickyNotification({ show: true, title: translator.translate(language, "my_account", 'stickyNotifications/error/existingFeedbackTitle'), content: translator.translate(language, "my_account", 'stickyNotifications/error/existingFeedbackContent'), level: "error", refresh: false });  
        }
        else{
          setStickyNotification({ show: true, title: "Server failed", content: "Failed to add feedback", level: "error", refresh: false });
        }
      })
  }

  return (
    <>
    <StickyNotification
    showNotification={stickyNotification.show}
    title={stickyNotification.title}
    onClick={() => setStickyNotification({ show: false, title: "", content: "", level: "", refresh: false })}
    content={stickyNotification.content}
    level={stickyNotification.level}
    refresh={stickyNotification.refresh}
    />
    {!haveFeedback ?
        <div className="leave-feedback" id="order-details-feedback">
            <h2 className='order-feedback'>{title}</h2>
            <p>{score}:</p>
            <div className='stars'>
              {starRating.map((item,index) => (
                <IoMdStar 
                  className={`star ${(index < hovered || item === 1) ? 'selected-star' : ''}`}
                  key={index + 1}
                  onClick={() => onStarClick(index + 1)}
                  onMouseEnter={() => setHovered(index + 1)}
                  onMouseLeave={() => setHovered(rating)}/>
              ))}
            <span className="credential-input-mandatory-astrix">
              *
            </span>
            </div>

            <TextArea placeholder={placeholder} value={feedback} onChange={(feedbackValue : string) => setFeedback(feedbackValue)} mandatory={false} resizeable={false}/>
            <SubmitButton id="leave-feedback-submit" placeholder={submit} onSubmit={onSubmit} isEnabledState={feedback.length <= 4000 && rating >= 1 && rating <= 5}/>
        </div>  :
        <ThanksForFeedback language={language} rating={rating} orderData={orderData}/>
        }
    </>
  )
}

export default OrderFeedbackForm