import InnerHTMLRenderer from "../../../../components/InnerHTMLRenderer/InnerHTMLRenderer";
import MyAccountAccordion from "../MyAccountAccordion/MyAccountAccordion";
import * as translator from "../../../../utils/translator"
import PlusCircled from "../../../../icons/Plus-Circled.svg";
import MinusCircled from "../../../../icons/Minus-Circled.svg";
import "./MyAccountOrderDetailsSupport.css";
import { useParams } from "react-router-dom";

interface MyAccountOrderDetailsSupportProps {
    language: string;
}

const MyAccountOrderDetailsSupport: React.FC<MyAccountOrderDetailsSupportProps> = ({ language }) => {
    const { order_hash_id } = useParams();
    const header = translator.translate(language, "my_account", `MyOrders/details/support/support_header`);
    const accordionItems = [
        {
            title: translator.translate(language, "my_account", `MyOrders/details/support/delivery_time_title`),
            content: translator.translate(language, "my_account", `MyOrders/details/support/delivery_time_content`),
        },
        {
            title: translator.translate(language, "my_account", `MyOrders/details/support/medication_unavailable_title`),
            content: translator.translate(language, "my_account", `MyOrders/details/support/medication_unavailable_content`),
        },
        {
            title: translator.translate(language, "my_account", `MyOrders/details/support/order_cancel_title`),
            content: translator.translate(language, "my_account", `MyOrders/details/support/order_cancel_content`),
            button: { placeholder:translator.translate(language, "my_account", `MyOrders/details/support/ask_support`), ref:`/${language}/contact`, searchParams:`order=${order_hash_id}` }
        }
    ]

    return (
        <div className="support-section">
            <InnerHTMLRenderer htmlContent={header} className='support-header'/>
            <MyAccountAccordion
                items={accordionItems}
                accordionClass="support-accordion-container"
                itemClass="support-accordion-item"
                titleClass="support-accordion-title"
                contentClass="support-accordion-content"
                icons={{closeIcon: MinusCircled, openIcon: PlusCircled}}
                />
        </div>
    )
}

export default MyAccountOrderDetailsSupport;