import React from 'react'

type Props = {
  inStock: boolean
  className?: string
}

const ProductAvailabilityTag:React.FC<Props> = ({inStock, className}) => {
  return (
  <div id='product-availability-tag' className={`text-white text-center py-[2px] h-[20px] text-[12px] w-[110px] rounded-[3px] shadow-lg font-poppins font-semibold
   ${inStock ? "bg-[#19D845]  shadow-[#19D845]/20" : "bg-blue-500 shadow-blue-500/20"} ${className}`}>
    {
    inStock ? "Premiumservice" : "Standardservice"
    }
  </div>
  )
}

export default ProductAvailabilityTag
