import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as http from "../../../utils/http";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductSelectionPanel from './components/ProductSelectionPanel';
import Legend from './components/Legend';
// import "./CannaleoFreeDosageLayout.css"
import { loadCustomerFromSession } from '../../../reducers/CustomerReducer';
import HeaderController from '../../../components/Headers/HeaderController';
import Footer from '../../../components/Footer/Footer';
import LoadingModal from '../../../components/Modals/LoadingModal/LoadingModal';
import ServerError from '../../../components/ServerError/ServerError';
import StickyNotification from '../../../components/StickyNotification/StickyNotification';
import { mergeArrays } from '../helpers';
import ProductSearch from './components/ProductSearch';
import ProductFiltersControl from './components/ProductFiltersControl';
import { FilterMenu } from './components/FiltersMenu';
import { MainTracker } from '../../../PixelTrackers/MainTracker';
import StickySubmitButton from './components/StickySubmitButton';
import { ORDER_FLOWS } from '../../../utils/consts';


export interface ProductItem {
  product_item_id: number,
  quantity: number,
  price: number,
  in_stock: boolean,
  custom_quantity_title: string,
  catalog_id: number,
  preSelected: boolean,
}

export interface Product {
  product_id: number,
  treatment_fee: number,
  catalog_id: number
  dosage_str: string,
  title: string,
  additional_data?: string,
  product_settings?: { [key: string]: Array<string> }
  in_stock: boolean,
  items: ProductItem[],
  quantity_type: string,
  preSelected: boolean,
  isSelected: boolean,
}

interface ChooseTreatmentContextType {
  maxCartProducts: number;
  products: Product[];
  filteredProducts: Product[];
  selectedProducts: Product[];
  selectedProductItems: ProductItem[];
  selectedProductsCounter:number;
  handleProductToggle: (product: Product, toggleAction: "ADD" | "REMOVE") => void;
  handleProductItemToggle: (productItem: ProductItem, toggleAction: "ADD" | "REMOVE") => void;
  onSubmit: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  categoryLimitation: null | { monthlyLimitation: number; purchasedQuantity: number };
  submitLoading: boolean;
  filterData: { [key: string]: { value: string; isSelected: boolean }[] };
  toggleFilter: (category: string, value: string) => void;
  isFilterMenuOpen: boolean;
  setIsFilterMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  PurchaseTierFilter: "all" | "premium" | "regular";
  setPurchaseTierFilter: React.Dispatch<React.SetStateAction<"all" | "premium" | "regular">>;
  resetAllFilters: () => void;
  appliedFiltersCount: number;
  hidePrices: boolean;
  hideTags: boolean; //Hiding the Premium/Regular tags
  productCardRefs: React.RefObject<HTMLDivElement[]>;
}

export const getMinPricePerQuantity = (product: Product) => {
  let minPrice = Infinity;
  let quantity = 1;

  product?.items.forEach((productItem: any) => {
    if ((productItem.price / productItem?.quantity) < (minPrice / quantity)) {
      minPrice = productItem?.price;
      quantity = productItem?.quantity;
    }
  })

  return toLocalePrice(minPrice / quantity);
}

export const getMinPricePerProduct = (product: Product) => {
  let minPrice = Infinity;

  product?.items.forEach((productItem: any) => {
    if (productItem?.price < minPrice) {
      minPrice = productItem?.price;
    }
  });

  return toLocalePrice(minPrice);
}

export const getMaxPricePerQuantity = (product: Product) => {
  let maxPrice = 0;
  let quantity = 1;

  product?.items.forEach((productItem: any) => {
    if ((productItem.price / productItem?.quantity) > (maxPrice / quantity)) {
      maxPrice = productItem?.price;
      quantity = productItem?.quantity;
    }
  })

  return toLocalePrice(maxPrice / quantity);
}

export const toLocalePrice = (price: number, locale = 'de-DE') => {
  if (!price) return "";
  return price.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const ChooseTreatmentContext = createContext<ChooseTreatmentContextType | null>(null);

const CannaleoFreeDosageLayout = () => {


  const { country, language, category } = useParams() as {
    category: string;
    country: string;
    language: string;
  };


  const initFilters = (products: Product[]) => {
    const filterData: any = {}
    for (const product of products) {
      if (product.product_settings) {
        for (const [key, value] of Object.entries(product.product_settings)) {
          if (!filterData[key]) {
            filterData[key] = []
          }

          const filterOptions = value.map((item) => ({
            value: item,
            isSelected: false
          }))

          filterData[key] = mergeArrays(filterData[key], filterOptions, (a: { value: string }) => a.value)
        }
      }
    }

    return filterData;
  }


  const toggleFilter = (category: string, value: string) => {
    setFilterData((prevFilterData) => {
      const newFilterData = JSON.parse(JSON.stringify(prevFilterData));
      newFilterData[category] = newFilterData[category].map((item: { value: string, isSelected: boolean }) => {
        if (item.value === value) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      });
      return newFilterData;
    })
  }

  const initStickyNotification = () => {
    if (searchParams.get("limit_disclaimer") && categoryLimitation) {
      const remainingPossibleQuantity = categoryLimitation?.monthlyLimitation - categoryLimitation?.purchasedQuantity;
      const content = `Ihr persönliches Limit ist derzeit: ${remainingPossibleQuantity > 0 ? remainingPossibleQuantity : 0} Gramm. Das generelle Limit beträgt ${categoryLimitation?.monthlyLimitation} Gramm pro Monat.`
      return { show: true, title: "Monatliches Limit überschritten", content, level: "disclaimer", refresh: false };
    } else {
      return { show: false, title: "", content: "", level: "", refresh: false };
    }
  }

  //States
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<number | null>(null);
  const [searchParams] = useSearchParams();
  const pharmacyFromParam = searchParams.get("epd") || null;
  const API_PATH = pharmacyFromParam ? "choose-treatment-external" : "choose-treatment-free-dosage";
  const [products, setProducts] = useState<Product[]>([]);
  // const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const selectedProducts = useMemo(() => (products.filter(product => product.isSelected)), [products])
  const [selectedProductItems, setSelectedProductItems] = useState<ProductItem[]>([]);
  const [categoryLimitation, setCategoryLimitation] = useState<{ monthlyLimitation: number, purchasedQuantity: number } | null>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterData, setFilterData] = useState<{ [key: string]: { value: string, isSelected: boolean }[] }>({});
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [PurchaseTierFilter, setPurchaseTierFilter] = useState<"all" | "premium" | "regular">("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [stickyNotification, setStickyNotification] = useState<{ refresh: boolean, level: string, show: boolean, title: string, content: string }>(initStickyNotification())
  const [maxCartProducts, setMaxCartProducts] = useState<number>(1);
  const [selectedProductsCounter, setSetSelectedProductsCounter] = useState(0);
  const [selectedExternalPharmacy, setSelectedExternalPharmacy] = useState<ExternalPharmacy | null>(null);
  const productCardRefs = useRef<HTMLDivElement[]>([]);


  // Memos 
  const appliedFiltersCount = useMemo(() => {
    let count = 0;

    if (!filterData) return 0;

    count += Object.values(filterData).flat().filter((item) => item.isSelected).length;

    if (PurchaseTierFilter !== 'all') count++;

    return count;
  }, [filterData, PurchaseTierFilter])


  const filteredProducts = useMemo(() => {

    // // 1. Initially filtered are all the products minus the one that are already selected 
    let filtered: Product[];
    
    //If there is multiselect (maxCartProducts > 1), we won't use selectedProducts (Cannnaelo flow for example)
    if (maxCartProducts > 1) {
      filtered = products.filter((product) => !product.isSelected);
    } else {
      filtered = products
    }


    const purchaseTier = PurchaseTierFilter;

    // 2. Filter by search term
    if (searchTerm) {
      // Create a regex that allows any characters between words in the search term (case-insensitive).
      const regex = new RegExp(searchTerm.split(' ').join('.*'), 'i');
      filtered = filtered.filter((product) => regex.test(product.title));
    }

    // 3. Filter by purchase tier
    if (purchaseTier === 'premium') {
      filtered = filtered.filter((product) => product.in_stock)
    } else if (purchaseTier === 'regular') {
      filtered = filtered.filter((product) => !product.in_stock)
    }


    // 4. Filters from the filter menu
    if (!filterData) {
      return filtered;
    }

    Object.keys(filterData).forEach((key) => {
      const whitelist = filterData[key].filter((item) => item.isSelected).map((item) => item.value);

      if (!whitelist.length) {
        return;
      }

      filtered = filtered.filter((product) => {
        const productSetting = product.product_settings;
        return productSetting && productSetting[key]?.some((item) => whitelist.includes(item));
      });
    })

    return filtered;

  }, [products, selectedProductItems, maxCartProducts, filterData, searchTerm, PurchaseTierFilter])


  // Fetch (GET)
  useEffect(() => {
    setLoading(true);
    http.getFromServer(`${API_PATH}/${category}/${language}/${country}?${searchParams.toString()}`,
      [
        http.MIDDLEWARES.CUSTOMER_ID,
        http.MIDDLEWARES.CART_ORDER
      ])
      .then((response) => {
        const serverData = response.data;
        setProducts(serverData.products.map((product:Product) => ({...product, isSelected: product.preSelected}) ));
        setCategoryLimitation(serverData.categoryLimits);
        setSelectedExternalPharmacy(serverData.externalPharmacy);
        const productsCopy = JSON.parse(JSON.stringify(serverData.products))
        setFilterData(initFilters(productsCopy));
        setDefaultProductsAndItems(productsCopy);
        setMaxCartProducts(serverData.maxCartProducts || 1);
      })
      .catch((error) => {
        setError(500);
        window.clientLogger.error("error get CannaleoFreeDosageLayout", error)
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])


  useEffect(() => {
    setStickyNotification(initStickyNotification());
  }, [searchParams, categoryLimitation])


  const onSubmit = async () => {
    setSubmitLoading(true);


    // 1. Mix panel events
    selectedProducts.forEach((product, index) => {
      MainTracker.superProperties({ [`Product Name ${index + 1}`]: product.title, "Purchase Type": product.in_stock ? "Premium" : "Regular" });
    })

    selectedProductItems.forEach((productItem, index) => {
      MainTracker.superProperties({ [`ProductItemID ${index + 1}`]: productItem.product_item_id });
    })


    // 2. Send to server
    http.postToServer("choose-treatment-free-dosage", {
      flow: searchParams.get("epd") ? ORDER_FLOWS.CANNALEO_PRE_SELECTED : null,
      cartProductsDTOs: selectedProductItems.map((item) => {
        return {
          quantity: 1,
          product_in_stock: item.in_stock,
          only_prescription: !item.in_stock,
          catalog_id: item.catalog_id,
          product_item_id: item.product_item_id
        }
      })
    }, [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])
      .then((response) => {
        let customerFromSession = loadCustomerFromSession();
        if (!customerFromSession) {
          // if no customer id send to registration
          navigate({ pathname: `/sign_up/${country}/${language}`, search: searchParams.toString() });
        } else {
          navigate({ pathname: `/shipping/${country}/${language}`, search: searchParams.toString() });
        }
      })
      .catch((err) => {
        const code = err?.response?.data?.code;

        if (code === "monthly_limit_exceeded" && categoryLimitation) {
          const remainingPossibleQuantity = categoryLimitation?.monthlyLimitation - categoryLimitation?.purchasedQuantity;
          const content = `Ihr persönliches Limit ist derzeit: ${remainingPossibleQuantity > 0 ? remainingPossibleQuantity : 0} Gramm. Das generelle Limit beträgt ${categoryLimitation?.monthlyLimitation} Gramm pro Monat.`
          setStickyNotification((current) => ({ show: true, title: "Monatliches Limit überschritten", content, level: "disclaimer", refresh: !current.refresh }));
        } else {
          setStickyNotification((current) => ({ show: true, title: "Server Failed", content: "", level: "error", refresh: !current.refresh }));
        }
      })
      .finally(() => setSubmitLoading(false));
  }


  const setDefaultProductsAndItems = (products: Product[]) => {

    // 1. Pre-select all Products:
    const preSelectedProducts = products.filter((product) => product.preSelected);
    setSetSelectedProductsCounter(preSelectedProducts.length);

    // // 2. Pre-select all Product Items:
    const preSelectedProductItems: ProductItem[] = [];
    preSelectedProducts.forEach((product) => {
      product.items.forEach((item) => {
        if (item.preSelected) {
          preSelectedProductItems.push(item);
        }
      })
    })

    setSelectedProductItems(preSelectedProductItems);
  }


  const handleProductItemToggle = (selectedProductItem: ProductItem, toggleAction: "ADD" | "REMOVE") => {
    if (toggleAction === "ADD") {

      setSelectedProductItems((current) => {

        // 1. If user may choose only 1 then return only selected product item
        if (maxCartProducts === 1) {
          return [selectedProductItem]
        }

        // 2. Else, get all the selected product items that are not from the same product
        let selectedWithoutSiblings = current.filter((currentItem) => currentItem.catalog_id !== selectedProductItem.catalog_id);

        // 3. Add the selectedProductItem
        return [...selectedWithoutSiblings, selectedProductItem]
      });

    } else {
      //Remove that product item from selected
      setSelectedProductItems((current) => current.filter((item) => item.product_item_id !== selectedProductItem.product_item_id));
    }
  }


  const handleProductToggle = useCallback((selectedProduct: Product, toggleAction: "ADD" | "REMOVE") => {

    // First, verify if we can add more products to the cart 
    // In case of maxProducts === 1, we want to always let select something else
    if (maxCartProducts !== 1 && (selectedProducts.length >= maxCartProducts) && toggleAction === "ADD") {
      return
    }

    // if (selectedProductsCounter === maxCartProducts && toggleAction === "ADD") {
    //   return
    // }

    if (toggleAction === "ADD") {

      setSetSelectedProductsCounter(current => current + 1);

      // 2. Select the product (with some timeout for the HoverCard animation)
      setTimeout(() => {
        setProducts((current) => {
          return current.map((product) => {
            if (maxCartProducts === 1) {
              return product.product_id === selectedProduct.product_id
                ? { ...product, isSelected: true }
                : { ...product, isSelected: false }; //Disable all other products if maxProductCart === 1
            } else {
              return product.product_id === selectedProduct.product_id
                ? { ...product, isSelected: true }
                : product; //Keep the other products as they are (MultiSelect)
            }
          });
        });

      }, maxCartProducts === 1 ? 0 : 1100);
      

      // 3. Select The median productItem by default
      const filteredProductItems = selectedProduct.in_stock ? selectedProduct.items.filter((item: any) => item.in_stock) : selectedProduct.items;
      const medianItemIndex = Math.floor(filteredProductItems.length / 4);
      handleProductItemToggle(filteredProductItems[medianItemIndex], toggleAction);


    } else { // Toggle action = REMOVE

      setSetSelectedProductsCounter(current => current - 1);

      setProducts(current => {
        return current.map(product =>  (product.product_id === selectedProduct.product_id ? {...product, isSelected: false} : product))
      })

      setSelectedProductItems((current) => current.filter((selectedProductItem) => selectedProductItem.catalog_id !== selectedProduct.catalog_id));
    }

  }, [selectedProducts,selectedProductsCounter, maxCartProducts, selectedProductItems, submitLoading])


  const scrollToMainTitle = useCallback(() => {
    const headerSearch = document.querySelector("#header-disclaimer");
    headerSearch?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  //   headerSearch?.scrollTo({
  //     behavior: "smooth",
  //     top: 15,
  // });
    
    const productsPanelTitle = document.querySelector("#left-panels");
    
    if (productsPanelTitle) {
      productsPanelTitle.scrollTo({
            behavior: "smooth",
            top: 0,
        });
    }
  },[selectedProducts]);

  const scrollToSelectedProduct = useCallback(() => {
    const productCard = productCardRefs.current.find(el => Number(el?.dataset.productId) === Number(selectedProducts[0].product_id));
    productCard?.scrollIntoView({
      behavior: "smooth", // Smooth scrolling
      block: "center",    // Align to the top of the viewport
    });

    // const rect = productCard?.getBoundingClientRect();
    // if (rect) {
    //   window?.scrollTo({
    //     top: rect.top + window.pageYOffset - 120,
    //     behavior: "smooth",
    //   });
    // }

   
  },[selectedProducts]);



  const freeDosageContext = {
    maxCartProducts,
    products,
    selectedProducts,
    selectedProductsCounter,
    selectedProductItems,
    handleProductToggle,
    handleProductItemToggle,
    searchTerm,
    setSearchTerm,
    onSubmit,
    categoryLimitation,
    submitLoading,
    filterData,
    toggleFilter,
    isFilterMenuOpen,
    setIsFilterMenuOpen,
    PurchaseTierFilter,
    setPurchaseTierFilter,
    appliedFiltersCount,
    filteredProducts,
    hideTags: Boolean(searchParams.get("epd")),
    hidePrices: Boolean(searchParams.get("epd")),
    resetAllFilters: () => {
      setFilterData(initFilters(products));
      setPurchaseTierFilter('all');
    },
    productCardRefs,
  }


  return (
    <ChooseTreatmentContext.Provider value={freeDosageContext}>
      {loading && <LoadingModal />}

      <StickyNotification
        showNotification={stickyNotification.show}
        title={stickyNotification.title}
        onClick={() => setStickyNotification({ show: false, title: "", content: "", level: "", refresh: false })}
        content={stickyNotification.content}
        level={stickyNotification.level}
        refresh={stickyNotification.refresh}
      />

      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}

      {!loading && !error &&

        <>

          {/* DoktorABC Nav  */}
          {category && <HeaderController language={language} stage="Treatment" />}

          {/* Main container  */}
          <main id='main-container' className='flex min-h-screen pt-10 p-4 bg-[var(--primary-gray)] flex-col items-center'>

            {/* Content Container  */}
            <div id='content-container' className='md:max-w-[500px] flex flex-col items-center justify-center '>

              {/* Header  */}
              <header className='w-full flex flex-col gap-y-2 mb-6'>
                <h1 id='main-title' className='flex text-2xl lg:text-4xl justify-center text-[#0D2C54]'> Behandlung wählen </h1>
                {freeDosageContext.hideTags ? null : <Legend />}
              </header>


              {/* Disclaimer  */} 
              <p id='header-disclaimer' style={{ fontFamily: "Roboto, sans-serif" }} className='w-full mb-4 text-[#0D2C54] text-[16px] font-medium break-words'>
                    <span className='text-[#0A9281]'>{"* "}</span>
                    Nachfolgend finden Sie alle in der von Ihnen ausgewählten Apotheke verfügbaren Produkte
                    {selectedExternalPharmacy ? ` (${selectedExternalPharmacy.cannabis_pharmacy_name})` : ""}
                </p>


              {/* Search & Desktop disclaimer  */}
              <div id='search-and-disclaimer' style={{ boxShadow: "0 25px 25px #f7f7f7", backgroundColor: "#f7f7f7", zIndex: 999 }} className='sticky top-0 w-full flex flex-col gap-1 mb-5 pt-2'>

              
                  
                <div id='header-search'  className='flex flex-row w-full justify-between items-center gap-4'>
                  <ProductSearch className={"flex-1"} />
                  <ProductFiltersControl />
                </div>
                  
              </div>


              {/* Mobile Disclaimer And Panels Container  */}
              <div id='mobile-disclaimer-and-panels-container' className='w-full'>

                  

                {/* Panels Container  */}
                <div id='panels-container' className='grid grid-col-1 md:grid-cols-1 gap-8 w-full ' >

                  {/* left panel */}
                  <div id='left-panels' className='flex flex-col  flex-1 w-[] overflow-y-auto'>

                    {/* Selected Products (Only for multiple select) */}
                    {
                      maxCartProducts > 1 ?
                      <>
                        <span id='selected-products-panel-title' style={{ fontFamily: "Roboto, sans-serif" }} className='font-medium mb-2  text-[13px] text-[#98A0AF] mt-2'> Ihr Warenkorb</span>
                        <ProductSelectionPanel products={selectedProducts} languageID={language}  />
                      </>
                      :
                      null
                    }

                    {/* All Products  */}
                      <span id='all-products-panel-title' style={{ fontFamily: "Roboto, sans-serif" }} className='font-medium mb-6  text-[13px] text-[#98A0AF]'> Alle Produkte <span id='filtered-products-amount' className='notranslate'> ({filteredProducts.length}) </span> </span>
                      <ProductSelectionPanel products={filteredProducts} languageID={language} />

                  </div>

                  {/* right panel */}
                  {/* <div id='right-panel' className='max-md:hidden overflow-y-auto h-[650px] flex flex-col gap-6 pb-6 pr-2 '>
                    {
                      selectedProducts.length ?
                        selectedProducts.map((product, idx) => <ProductInfoCard key={product.product_id + "_" + idx} product={product} hideTags={freeDosageContext.hideTags} />)
                        :
                        <div id='no-selected-products' className='w-full h-full flex  flex-col gap-2 justify-center items-center max-h-[500px] rounded-xl bg-white'>
                          <div id='no-selected-products-icon' className='bg-[#F8F8FA] rounded-full flex justify-center items-center p-4'>
                            <img src={bagCrossIcon} alt="X" />
                          </div>

                          <p id='no-selected-products-text' className='text-[#747474] font-roboto text-[16px]'>Sie haben noch keine Produkte ausgewählt.</p>
                        </div>
                    }
                  </div> */}

                </div>
              </div>

            </div>
          </main>

          {/* DoktorABC Footer  */}
          {category && <Footer showDiscountDisclaimer={true} page="ChooseTreatment" language={language} />} {/*shows footer in normal flow but not in modal*/}

          {/* Filters Menu  */}
          {isFilterMenuOpen ? <FilterMenu filterData={filterData || {}} /> : null}
 
          {/* Sticky Submit  */}
          <StickySubmitButton
            showDisclaimer={false}
            disclaimerText={`Sie haben Ihr Limit an bestellbaren Produkten erreicht. Sie können maximal ${maxCartProducts} Produkte bestellen.`}
            buttonDisabled={submitLoading || selectedProducts.length === 0}
            onSubmit={onSubmit}
            showAmountOfSelectedProducts={true}
            maxSelectedProductsAmount={maxCartProducts}
            selectedProductsAmount= {selectedProductItems.length}
            // onArrowClick={Boolean(searchParams.get("epd")) ? scrollToMainTitle : undefined }
            onCartClick={Boolean(searchParams.get("epd")) ? scrollToMainTitle : scrollToSelectedProduct }
          />
          

        </>
      }
    </ChooseTreatmentContext.Provider >
  )
}

export default CannaleoFreeDosageLayout