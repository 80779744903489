import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../../../components/CredentialsInput/InputField";
// import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import * as translator from "../../../../utils/translator";
import Select from "../../../../components/Select/Select";
import { useEffect } from "react";
import { BsTelephone } from "react-icons/bs";
import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import { validateName, validatePackstationAddress, validatePackstationNumber, validatePhone, validatePostalCode, validateStreetAndNumber } from "../../../../utils/fieldsValidator";
import { useShippingPageContext } from "../providers/ShippingPageProvider";
import { useCartContext } from "../../../../customHooks/useCart";
const FORM_ID = "dhl-packstation-form";


type Props = {
    defaultValues?: Partial<DHLPackstationFormType>
    isGoogleAPILoaded?: boolean;
}

const DHLPackStationForm:React.FC<Props> = ({ defaultValues, isGoogleAPILoaded}) => {

    const {language, setSelectedFormID, handleShippingSubmit} = useShippingPageContext();
    const {cartCurrency} = useCartContext();

    useEffect(() => {
      setSelectedFormID(FORM_ID);
    }, [])

    // Zod Schema 
    const FormSchema = z.object({

        // For packstation_zip:
        packstation_zip: z
            .string()
            .refine(
                (postalCode: string) => {
                    const { isValid } = validatePostalCode(postalCode, language, "de");
                    return isValid;
                },
                (value) => ({
                    message: validatePostalCode(value, language, "de").errorMsg
                })
            ),

        // For packstation_city:
        packstation_city: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validateName(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validateName(value, language).errorMsg
                })
            ),

        // For packstation_address:
        packstation_address: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validatePackstationAddress(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validatePackstationAddress(value, language).errorMsg
                })
            ),


        // For packstation_number:
        packstation_number: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validatePackstationNumber(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validatePackstationNumber(value, language).errorMsg
                })
            ),


        // First name field
        first_name: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validateName(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validateName(value, language).errorMsg
                })
            ),

        // Last name field
        last_name: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validateName(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validateName(value, language).errorMsg
                })
            ),

        // Zip field
        zip: z
            .string()
            .refine(
                (postalCode: string) => {
                    const { isValid } = validatePostalCode(postalCode, language, "de");
                    return isValid;
                },
                (value) => ({
                    message: validatePostalCode(value, language, "de").errorMsg
                })
            ),

        // City field 
        city: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validateName(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validateName(value, language).errorMsg
                })
            ),

        // Address field 
        address: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validateStreetAndNumber(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validateStreetAndNumber(value, language).errorMsg
                })
            ),

        // Country Field  
        country: z.string(),

        // Phone Field
        phone: z
            .string()
            .refine(
                (value: string) => {
                    const { isValid } = validatePhone(value, language);
                    return isValid;
                },
                (value) => ({
                    message: validatePhone(value, language).errorMsg
                })
            ),
    })


    // Form hook 
    const { handleSubmit, control} = useForm<DHLPackstationFormType>({
        resolver: zodResolver(FormSchema),
        mode: "onTouched",
        defaultValues: {
            packstation_number: defaultValues?.packstation_number || "",
            packstation_address: defaultValues?.packstation_address || "",
            packstation_zip: defaultValues?.packstation_zip || "",
            packstation_city:  defaultValues?.packstation_city || "",
            country: cartCurrency.countryID,
            phone: defaultValues?.phone || "",
            first_name: defaultValues?.first_name || "",
            last_name:  defaultValues?.last_name || "",
            zip: defaultValues?.zip || "",
            city: defaultValues?.city || "",
            address: defaultValues?.address || "",
        },
    });


    //Scroll to the form incase of validation error
    const onValidationFailed = () => {
        const formElement = document.getElementById(FORM_ID);
        if (formElement) {
            formElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };


    return (

        <>
            {/* Small Title */}
            <span id={`${FORM_ID}-title`} className="font-semibold text-[#98a0af] font-roboto text-[13px] my-[20px] block">
                {translator.translate(language, "shipping", "layout/living_address")}
            </span>

            <form 
                id={FORM_ID}
                onSubmit={handleSubmit((formData) => {handleShippingSubmit(formData)}, onValidationFailed)} 
                className="grid grid-cols-1 md:grid-cols-2 gap-4 scroll-mt-[100px]">

                {/* Billing Packstation Number */}
                <Controller
                    name="packstation_number"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-number"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => { field.onChange(value) }}
                            placeholder={"Packstationnummer"}
                            mandatory={true}
                            errorMsg={fieldState.error?.message}
                            language={language}
                            onBlur={field.onBlur}
                        />
                    )}
                />

                {/* Billing Packstation Address */}
                <Controller
                    name="packstation_address"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-address"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => { field.onChange(value) }}
                            placeholder={"Postnummer"}
                            mandatory={true}
                            errorMsg={fieldState.error?.message}
                            language={language}
                            onBlur={field.onBlur}
                        />
                    )}
                />

                {/* Packstation Zip */}
                <Controller
                    name="packstation_zip"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-zip"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => { field.onChange(value) }}
                            placeholder={translator.translate(language, "shipping", "ShippingForm/postal_code")}
                            mandatory={true}
                            errorMsg={fieldState.error?.message}
                            language={language}
                            onBlur={field.onBlur}
                        />
                    )}
                />


                {/* Packstation City */}
                <Controller
                    name="packstation_city"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-packstation_city"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => { field.onChange(value) }}
                            placeholder={translator.translate(language, "shipping", "ShippingForm/location")}
                            mandatory={true}
                            errorMsg={fieldState.error?.message}
                            language={language}
                            onBlur={field.onBlur}
                        />
                    )}
                />

                {/* Packstation Country */}
                <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (

                        <div className="md:col-span-2">

                            <Select
                                id="delivery-addres-form-country"
                                initialValue={{
                                    value: translator.translate(language, "shipping", `ShippingForm/country_name/${field.value}`),
                                    code: field.value,
                                }}
                                options={[
                                    {
                                        value: translator.translate(language, "shipping", "ShippingForm/country_name/de"),
                                        code: "de",
                                    },
                                    {
                                        value: translator.translate(language, "shipping", `ShippingForm/country_name/ch`),
                                        code: "ch",
                                    },
                                ]}
                                onChangeCallback={({ option }) => {
                                    field.onChange(option.code); // Updates the form state
                                }}
                                questionnaireValidationObject={{}}
                                errorMode={false}
                                style={{
                                    border: "1px solid var(--sub-gray)",
                                    height: "56px",
                                    width: "100%",
                                    borderRadius: "6px",
                                    margin: "15px 0px",
                                    backgroundColor: "transparent",
                                }}
                                label={translator.translate(language, "shipping", "ShippingForm/country")}
                                disabled={true} //TODO: laterrrr
                            />
                        </div>

                    )}
                />


                {/* Phone */}
                <Controller
                    name="phone"
                    control={control}
                    render={({ field, fieldState }) => (
                        <div id="phone-field-container" className="text-[#98a0af] flex flex-col md:flex-row gap-4 md:col-span-2">
                            {/* Icon and text */}
                            <div id="phone-icon-and-text" className="flex gap-3 items-center w-full">
                                <BsTelephone className="w-[25px] h-[25px] flex-shrink-0" />
                                <p id="phone-icon-text" className="text-[13px] font-roboto">
                                    {translator.translate(language, "shipping", "ShippingForm/phone_icon_text")}
                                </p>
                            </div>

                            <InputField
                                id="dhl-packstation-form-phone"
                                ignoreTouched={true}
                                value={field.value}
                                onChange={(value: string) => {
                                    field.onChange(value);
                                }}
                                placeholder={translator.translate(language, "shipping", "ShippingForm/telephone")}
                                mandatory={true}
                                errorMsg={fieldState?.error?.message} // Bind error message from hook
                                language={language}
                                onBlur={field.onBlur}
                            />
                        </div>
                    )}
                />

                {/* First Name  */}
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-first_name"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => {
                                field.onChange(value);
                            }}
                            placeholder={translator.translate(language, "shipping", "ShippingForm/first_name")}
                            mandatory={true}
                            language={language}
                            onBlur={field.onBlur}
                            errorMsg={fieldState?.error?.message} // Bind error message from hook

                        />
                    )}
                />

                {/* Last Name  */}
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-last-name"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => {
                                field.onChange(value);
                            }}
                            placeholder={translator.translate(language, "shipping", "ShippingForm/surname")}
                            mandatory={true}
                            errorMsg={fieldState.error?.message}
                            language={language}
                            onBlur={field.onBlur}
                        />
                    )}
                />

                {/* Zip  */}
                <Controller
                    name="zip"
                    control={control}
                    render={({ field, fieldState }) => (
                        <InputField
                            id="dhl-packstation-form-zip"
                            ignoreTouched={true}
                            value={field.value}
                            onChange={(value: string) => {
                                field.onChange(value);
                            }}
                            placeholder={translator.translate(language, "shipping", "ShippingForm/postal_code")}
                            mandatory={true}
                            language={language}
                            onBlur={field.onBlur}
                            errorMsg={fieldState.error?.message}

                        />
                    )}
                />


                {/* City  */}
                <Controller
                    name="city"
                    control={control}
                    render={({ field, fieldState }) => (
                        <GoogleApiInputField
                            id="dhl-packstation-form-city"
                            value={field.value}
                            onChange={(value: any) => {
                                if (Array.isArray(value.split(","))) {
                                    value = value.split(",")[0];
                                }
                                field.onChange(value); // Update the form field value
                            }}
                            placeholder={translator.translate(
                                language,
                                "shipping",
                                "ShippingForm/location"
                            )}
                            mandatory={true}
                            country={"de"}
                            errorMsg={fieldState?.error?.message} // Bind error message from hook
                            onBlur={field.onBlur} // Trigger onBlur to update touched state
                            addressType={"(cities)"}
                            isGoogleApiLoaded={isGoogleAPILoaded}
                        />
                    )}
                />


                <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState }) => (
                        <div id="dhl-packstation-form-address-container" className="md:col-span-2">
                            <GoogleApiInputField
                                id="delivery-address-form-billing-address"
                                value={field.value}
                                onChange={(value: any) => {
                                    if (Array.isArray(value.split(","))) {
                                        value = value.split(",")[0];
                                    }
                                    field.onChange(value); // Update the form field value
                                }}
                                placeholder={translator.translate(
                                    language,
                                    "shipping",
                                    "ShippingForm/street_and_house"
                                )}
                                mandatory={true}
                                country={"de"}
                                errorMsg={fieldState?.error?.message} // Bind error message from hook
                                onBlur={field.onBlur} // Trigger onBlur to update touched state
                                addressType={"address"}
                                isGoogleApiLoaded={isGoogleAPILoaded}
                            />

                        </div>

                    )}
                />



            {/* The Parent (shipping page) is handling the submit */}

            </form>
        </>
    );
};

export default DHLPackStationForm;
