import React,{ReactNode, useState} from "react";
import SubscriptionDisclaimer from "../SubscriptionDisclaimer/SubscriptionDisclaimer";
import OtherOrders from "../OtherOrders/OtherOrders";
import {useParams} from "react-router-dom";
import "./MyAccountContentHeader.css";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import InnerHTMLRenderer from "../../../../components/InnerHTMLRenderer/InnerHTMLRenderer";


interface MyAccountContentHeaderProps{
  title:string;
  titleFurnish?:ReactNode;
  description:string;
  showPrintOptionInDescription?:boolean
  showOtherOrders?:boolean //Shows the other orders in different country
  otherCountry?: { [key: string]: number };
  otherOrdersCount?:number;
  country?:any;
  showSubscriptionDisclaimer?:boolean
  descriptionClass?: string
}


/*
   Header may contain OtherOrders & SubscriptionDisclaimer like in my-orders section (comes from props)
*/
const MyAccountContentHeader: React.FC<MyAccountContentHeaderProps> = ({title, titleFurnish,description,showPrintOptionInDescription=false,showOtherOrders=false,otherCountry,otherOrdersCount,showSubscriptionDisclaimer=false, descriptionClass=""}) => {

  const [isLoadingPdf,setIsLoadingPdf] = useState<boolean>(false);
  const {country} = useParams();

  const getOrdersPdf = () => {
    setIsLoadingPdf(true)
    http
      .getFromServer(`${country}/account/orders/pdf`,
       [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.GENERATE_FILE])
      .then((response:any) => {
        const url = window.URL.createObjectURL(new Blob([response.data],{type:"application/pdf"}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.pdf'); // replace with desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error:any) => {
          window.clientLogger.error("Failed to get pdf with error: ", error)
      })
      .finally(() => setIsLoadingPdf(false));
      
  };

  return (
    <div className="account-content-header">
      <p className="account-content-header-title">
        {title}
        {titleFurnish}     
      </p>
      <InnerHTMLRenderer className={`account-content-header-description ${descriptionClass}`} htmlContent={description} >
        {showPrintOptionInDescription ? 

          isLoadingPdf ? 

            <div className="loader-2 center"><span></span></div>
          
            : 
          
            <span onClick={getOrdersPdf} > 
              {translator.translate(country, "my_account", 'MyOrders/print')} 
            </span>
        
          : 
          null
        } 
      </InnerHTMLRenderer>
      

      {otherCountry && Object.keys(otherCountry).map(key => (
        <OtherOrders key={key} country={country} otherCountry={key} otherOrdersCount={otherCountry[key]}/>
      ))}

      {/* Subscription Disclaimer Section */
      showSubscriptionDisclaimer ? <SubscriptionDisclaimer country={country!}/> : null
      }

    </div>
  );
};

export default MyAccountContentHeader;
