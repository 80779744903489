import React, { useEffect, useState } from 'react';
// import * as translator from '../../utils/translator';
import Footer from '../../components/Footer/Footer';
import HeaderController from '../../components/Headers/HeaderController';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import StartConsultationCard from '../../components/StartConsultationCard';
import MailOrderPharmaciesModalTrigger, { PharmaciesModalInfo } from './MailOrderPharmaciesModalTrigger';
import PrescribingPhysiciansModalTrigger, { DoctorsModalInfo } from './PrescribingPhysiciansModalTrigger';
import LimitationsModalTrigger, { LimitationsModalInfo } from './LimitationsModalTrigger';
import "./StartConsultation.css";
import LoadingModal from '../../components/Modals/LoadingModal/LoadingModal';
import ServerError from '../../components/ServerError/ServerError';
import * as http from '../../utils/http';

type Card = {
	step: string | number;
	title: string;
	content: string;
	imgSrc: string;
	imgAlt: string;
};

type HeaderTitles = {
	title: string;
	subTitles: string[];
	submitButtonText: string;
};

type Props = any;

const StartConsultation: React.FC<Props> = () => {
	const { country, mention, language } = useParams();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState<any>(false);
	const [cards, setCards] = useState<Card[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [doctorsModalInfo, setDoctorsModalInfo] = useState<DoctorsModalInfo | null>(null);
	const [pharmaciesModalInfo, setPharmaciesModalInfo] = useState<PharmaciesModalInfo | null>(null);
	const [headerTitles, setHeaderTitles] = useState<HeaderTitles | null>(null);
	const [limitationsModalInfo, setLimitationsModalInfo] = useState<LimitationsModalInfo | null>(null);

	const isExternalFlow = searchParams.get("epd") ? true : false;
	
	// get needed data from server
	useEffect(() => {
		http
			.getFromServer(`start-consultation/${mention}/${country}/${language}?${searchParams.toString()}`)
			.then((response) => {
				setCards(response.data.cards);
				setDoctorsModalInfo(response.data.doctorsModalInfo);
				setPharmaciesModalInfo(response.data.pharmaciesModalInfo);
				setHeaderTitles(response.data.headerTitles);
				setLimitationsModalInfo(response.data.limitationsModalInfo);
			})
			.catch((error) => {
				window.clientLogger.error('Error in StartConsultation: ', error);
				setError(error?.response?.status || 500);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	if (error) {
		return <ServerError status={error} />;
	}

	if (isLoading) {
		return <LoadingModal />;
	}
	return (
		<>
			<div style={{ borderBottom: '1px solid #eef1f6' }}>
				<HeaderController showBackButton={false} stage="StartConsultation" country={country!} showSteps={false} />
			</div>

			<div id="start-consultation-page-container" className="overflow-x-hidden flex flex-col justify-center items-center  pt-10 gap-10">
				<div id="start-consultation-page" className="w-full flex flex-col lg:w-[1200px] items-center pb-0 px-4">
					{/* Header  */}
					<header id="start-consulation-header" className="flex flex-col items-center justify-center text-[#0d2c54] font-poppins mb-5">
						<h1 id="start-consultation-header-title" className="text-center mb-[10px] text-[24px] md:text-[32px]">
							{headerTitles?.title}
						</h1>
						<h3 id="start-consultation-header-description" className="text-[1rem] mb-0 font-normal text-center">
							{headerTitles?.subTitles?.map((subTitle,index) => (
								<span key={index} className="block">{subTitle}</span>
							))}
						</h3>
					</header>

					<main id="main-container" className="w-full flex flex-col justify-center items-center mb-24">
						<div id="cards-container" className="w-full  flex flex-col lg:flex-row justify-center items-center px-2 md:px-10 gap-[40px] p-2 mt-4">
							{cards?.map((card,index) => (
								<StartConsultationCard key={index} step={card.step} title={card.title} content={card.content} imgSrc={card.imgSrc} imgAlt={card.imgAlt} />
							))}
						</div>

						<div className="sticky bottom-0 bg-white w-full p-5">
							<Link className="font-poppins  max-w-[350px] bg-[#11DDAC] rounded-md p-5 text-white text-center font-bold hover:opacity-90 transition-opacity cursor-pointer hover:underline hover:decoration-[#00B086] mx-auto block"
								to={`/questionnaire/de/de/${mention}?${searchParams.toString()}`}>
								{headerTitles?.submitButtonText}
							</Link>
						</div>

						{Boolean(country?.toUpperCase() === 'DE') && !isExternalFlow && (
							<p className="font-poppins text-center">
								Sie haben bereits ein E-Rezept?{' '}
								<Link
									className="underline text-[#11DDAC] text-nowrap"
									to={`/e-recipe-upload/de/de?${searchParams.toString()}`}>

									Klicken Sie hier.
								</Link>
							</p>
						)}
					</main>

					<div className="flex flex-col md:flex-row gap-2 md:gap-6 text-xs mb-6 font-poppins">
						{doctorsModalInfo && <PrescribingPhysiciansModalTrigger doctorsModalInfo={doctorsModalInfo} />}
						{pharmaciesModalInfo && <MailOrderPharmaciesModalTrigger pharmaciesModalInfo={pharmaciesModalInfo} />}
						{limitationsModalInfo && <LimitationsModalTrigger limitationsInfo={limitationsModalInfo} />}
					</div>
				</div>
			</div>

			<Footer page="questionnaire" />
		</>
	);
};

export default StartConsultation;
