import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
// import "./ReturningCustomersLayout.css"
import * as translator from "../../../utils/translator";
import ProductSelection from './components/ProductSelection'
import TreatmentSelection from './components/TreatmentSelection';
import * as http from "../../../../src/utils/http";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { loadCustomerFromSession } from '../../../reducers/CustomerReducer';
import HeaderController from '../../../components/Headers/HeaderController';
import { CatalogSettingContext, RouterCartContext } from '../ChooseTreatmentRouter';
import LoadingModal from '../../../components/Modals/LoadingModal/LoadingModal';
import ServerError from '../../../components/ServerError/ServerError';
import { submitOverrideContext } from "../../../../src/components/Modals/MyAccountChangeSubscriptionMedicationModal/MyAccountChangeSubscriptionMedicationModal";
import ProductAvailabilityTag from '../FreeDosageChooseTreatment/components/ProductAvailabilityTag';
import Disclaimer from '../components/Disclaimers/Disclaimer';
import { MainTracker } from '../../../PixelTrackers/MainTracker';
import StickyNotification from '../../../components/StickyNotification/StickyNotification';

export interface ProductItem {
    product_item_id: number,
    quantity: number,
    price: number,
    in_stock: boolean,
    custom_quantity_title: string,
}

export interface Product {
    product_id: number,
    treatment_fee: number,
    subscription_ftd: number,
    catalog_id: number
    dosage_str: string,
    title: string,
    product_title: string,
    additional_data?: string,
    product_settings?: { [key: string]: Array<string> }
    in_stock: boolean,
    items: ProductItem[],
    is_default_dosage: boolean,
    default_quantity?: string
    treatments_order: number,
    use_custom_quantity: boolean
}
export interface groupedProductsType {
    [key: number]: Product[]
}
interface ReturingCustomersLayoutContextType {
    currencySign: string,
    hidePrices: boolean,
    countryID: string,
    languageID: string,
    isNoPreferenceSelected: boolean,
    setIsNoPreferenceSelected: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCatalogID: number | null,
    setSelectedCatalogID: React.Dispatch<React.SetStateAction<number | null>>,
    selectedProductID: number | null,
    setSelectedProductID: React.Dispatch<React.SetStateAction<number | null>>,
    groupedProducts: null | groupedProductsType,
    selectedProduct: null | Product,
    selectedProductItemID: number | null,
    setSelectedProductItemID: React.Dispatch<React.SetStateAction<number | null>>,
    productItemToPlan: Record<number, number[]>,
    subscriptionPlansData: Record<number, any>,
    selectedSubscriptionPlanID: number | null,
    setSelectedSubscriptionPlanID: React.Dispatch<React.SetStateAction<number | null>>,
    allowPrescriptionOnlyFlow: boolean,
    findByDefaultDosage: (productList: Product[]) => Product | undefined,
    findQuantityBasedOnRule: (product: Product) => ProductItem | undefined,
    onSubmit: () => any,
    defaultCatalogID: string | null,
    initialProduct: { catalog_id: number, product_id: number, product_item_id: number } | null,
    localeID: string,
    isFR: boolean,
    setIsSubmitButtonDisabled: (isDisabled: boolean) => void,
    isSubmitButtonDisabled: boolean
}



export const ReturingCustomersLayoutContext = createContext<ReturingCustomersLayoutContextType | null>(null);

const ReturningCustomersLayout = () => {
    const { country, language, category } = useParams() as {
        category: string;
        country: string;
        language: string;
    };
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const defaultCatalogID = searchParams.get("catalog_id");

    const [selectedCatalogID, setSelectedCatalogID] = useState<number | null>(null);
    const [selectedProductID, setSelectedProductID] = useState<number | null>(null);
    const [selectedProductItemID, setSelectedProductItemID] = useState<number | null>(null);
    const [groupedProducts, setGroupedProducts] = useState<groupedProductsType | null>(null);
    const [productItemToPlan, setProductItemToPlan] = useState<Record<number, number[]>>({});
    const [subscriptionPlansData, setSubscriptionPlansData] = useState<Record<number, any>>({});
    const [selectedSubscriptionPlanID, setSelectedSubscriptionPlanID] = useState<number | null>(null);
    const [initialProduct, setInitialProduct] = useState<{ catalog_id: number, product_id: number, product_item_id: number } | null>(null);
    const [isNoPreferenceSelected, setIsNoPreferenceSelected] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
    const [stickyNotification, setStickyNotification] = useState<{ refresh: boolean, level: string, show: boolean, title: string, content: string }>({ show: false, title: "", content: "", level: "", refresh: false })


    const routerContext = useContext(CatalogSettingContext) as any;
    const cartContext = useContext(RouterCartContext) as any;

    const isFR = country.toUpperCase() === 'FR';
    const currencySign = country.toUpperCase() === 'CH' ? 'CHF' : '€';

    const hidePrices = routerContext?.forceHidePrices;
    const allowPrescriptionOnlyFlow = routerContext?.allowPrescriptionForOutOfStock;
    const isNoPrefEnabled = routerContext?.isNoPrefereceTreatmentEnabled;
    const localeID = `${language.toLowerCase()}-${country.toUpperCase()}`
    const showERezeptDisclaimer = routerContext?.showERezeptDisclaimer;

    const selectedProduct = useMemo(() => {
        let catalogToFind = selectedCatalogID;
        if (isNoPreferenceSelected) {
            catalogToFind = initialProduct?.catalog_id || null;
        }
        return (selectedProductID && groupedProducts && catalogToFind) ? groupedProducts[catalogToFind].find((product) => product.product_id === selectedProductID)! : null;
    }, [selectedProductID, groupedProducts, selectedCatalogID, isNoPreferenceSelected]);

    const findByDefaultDosage = (productList: Product[]) => {
        let defaultProduct: Product | undefined ;
        for (const product of productList as Product[]) {
            if (product.is_default_dosage && (product.in_stock)) {
                defaultProduct = product;
                break;
            }
        }
        
        if (defaultProduct) {
            return defaultProduct;
        } 
            
        for (const product of productList as Product[]) {
            if (product.in_stock) {
                return product;
            }
        }

        return undefined;

    }

    const findQuantityBasedOnRule = (product: Product) => {
        try {

            let defaultQuantity = product.items.find((item) => item.in_stock);
            let candidate: ProductItem | undefined = undefined;
            let allowedProducts = product.items.filter((item) => item.in_stock);

            switch (product.default_quantity) {
                case "min_quantity":
                    candidate = allowedProducts.sort((a, b) => a.quantity - b.quantity)[0];
                    break;

                case "max_quantity":
                    candidate = allowedProducts.sort((a, b) => b.quantity - a.quantity)[0];
                    break;

                case "second_from_min_available":
                    candidate = allowedProducts.sort((a, b) => a.quantity - b.quantity)[1];
                    break;

                case "second_from_max_available":
                    candidate = allowedProducts.sort((a, b) => b.quantity - a.quantity)[1];
                    break;

                default:
                    candidate = allowedProducts.sort((a, b) => a.quantity - b.quantity)[0];
                    break;
            }

            if (candidate) {
                defaultQuantity = candidate;
            }

            return defaultQuantity || product.items[0];
        } catch (err: any) {
            return product.items.find((item) => item.in_stock);
        }

    }

    const selectInitialProduct = useCallback((groupedProducts: any, productItemToPlan: Record<number, number[]>) => {
        const sortedGroupedProducts = Object.values(groupedProducts).sort((a: any, b: any) => a[0].treatments_order - b[0].treatments_order);
        const cart = cartContext?.cartData;
        if (isNoPrefEnabled) {
            setIsNoPreferenceSelected(true);
        }
        //first attempt to select intial by default product settings
        let defaultProduct: Product | undefined;
        
        if (defaultCatalogID && groupedProducts[Number(defaultCatalogID)]) { //if there is a default catalog id in the url
            const prodCatalog: Product[] = groupedProducts[Number(defaultCatalogID)];

            const prod = findByDefaultDosage(prodCatalog);
            if (prod) {
                setSelectedProductID(prod.product_id);
                !isNoPrefEnabled && setSelectedCatalogID(prod.catalog_id);
                const firstQuantityInStock = findQuantityBasedOnRule(prod);
                setSelectedProductItemID(firstQuantityInStock!.product_item_id);
                const plans = productItemToPlan && productItemToPlan[firstQuantityInStock?.product_item_id || 0];
                setSelectedSubscriptionPlanID(plans ? plans[0] : null);
                setInitialProduct({ catalog_id: prod.catalog_id, product_id: prod.product_id, product_item_id: firstQuantityInStock!.product_item_id });
                return
            }

        }

        //if there is an item from this catalog in the cart
        if (cart.product_item_id !== null){
            for (const productList of sortedGroupedProducts) {
                for (const product of productList as Product[]) {
                    const item = product.items.find((item) => item.product_item_id === cart.product_item_id)
                    if (item) {
                        if (item.in_stock || isFR) {
                            setSelectedProductID(product.product_id);
                            !isNoPrefEnabled && setSelectedCatalogID(product.catalog_id);
                            const firstQuantityInStock = findQuantityBasedOnRule(product);
                            setSelectedProductItemID(cart.product_item_id);
                            const plans = productItemToPlan && productItemToPlan[firstQuantityInStock?.product_item_id || 0];
                            setSelectedSubscriptionPlanID(plans ? plans[0] : null);
                            setInitialProduct({ catalog_id: product.catalog_id, product_id: product.product_id, product_item_id: cart.product_item_id });
                            return
                        }
                        else{
                            setStickyNotification({ show: true, title: translator.translate(language, "default", "StickyNotification/out_of_stock_title"), content: translator.translate(language, "default", "StickyNotification/out_of_stock_description"), level: "warning", refresh: false });
                        }
                    }
                }
            }
        }

        for (const productList of sortedGroupedProducts) {
            defaultProduct = findByDefaultDosage(productList as Product[]);
            if (defaultProduct) {
                break;
            }
        }

        if (defaultProduct) {
            setSelectedProductID(defaultProduct.product_id);
            !isNoPrefEnabled && setSelectedCatalogID(defaultProduct.catalog_id);
            const firstQuantityInStock = findQuantityBasedOnRule(defaultProduct);
            setSelectedProductItemID(firstQuantityInStock!.product_item_id);
            const plans = productItemToPlan && productItemToPlan[firstQuantityInStock?.product_item_id || 0];
            setSelectedSubscriptionPlanID(plans ? plans[0] : null);
            setInitialProduct({ catalog_id: defaultProduct.catalog_id, product_id: defaultProduct.product_id, product_item_id: firstQuantityInStock!.product_item_id });
            return
        }


        //otherwise find first product in stock
        let found = false;
        for (const productList of sortedGroupedProducts) {
            for (const product of productList as Product[]) {
                if (product.in_stock) {
                    found = true;
                    setSelectedProductID(product.product_id);
                    !isNoPrefEnabled && setSelectedCatalogID(product.catalog_id);
                    const firstQuantityInStock = product.items.find((item) => (item.in_stock));
                    setSelectedProductItemID(firstQuantityInStock!.product_item_id);
                    const plans = productItemToPlan && productItemToPlan[firstQuantityInStock?.product_item_id || 0];
                    setSelectedSubscriptionPlanID(plans ? plans[0] : null);
                    setInitialProduct({ catalog_id: product.catalog_id, product_id: product.product_id, product_item_id: firstQuantityInStock!.product_item_id });
                    break;
                }
            }

            if (found) {
                break;
            }
        }
    }, [groupedProducts, allowPrescriptionOnlyFlow, productItemToPlan]);

    const { postChangeSubscriptionMediaction } = useContext(submitOverrideContext); // overrides for change subscription medication


    const onSubmit = () => {
        setIsSubmitButtonDisabled(true)
        //True:
        if (!selectedProduct || !selectedProductItemID) {
            return;
        } else if (postChangeSubscriptionMediaction) {
            postChangeSubscriptionMediaction({ product_item_id: selectedProductItemID })
            return;
        }

        const selectedItem = selectedProduct.items.find((item) => item.product_item_id === selectedProductItemID);

        MainTracker.superProperties({ "Product Name": selectedProduct?.title, "ProductItemID": selectedProductItemID });
        const productItemOutOfStock = !selectedItem?.in_stock;
        http.postToServer(`choose-treatment-returning-customers`, {
            selectedProductItemID,
            catalogID: selectedProduct.catalog_id,
            prescriptionOnly: productItemOutOfStock,
            subscriptionPlanID: !productItemOutOfStock ? (selectedSubscriptionPlanID !== 0 ? selectedSubscriptionPlanID : null) : null,
            isSftd: selectedSubscriptionPlanID === 0 ? 1 : 0,
        }, [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])
            .then((response) => {
                let customerFromSession = loadCustomerFromSession();
                if (!customerFromSession) {
                    // if no customer id send to registration
                    navigate({ pathname: `/sign_up/${country}/${language}` });
                } else {
                    navigate({ pathname: `/shipping/${country}/${language}` });
                }
            })
            .catch((err) => {
                // const code = err?.response?.data?.code;
                routerContext.setStickyNotification((current: any) => ({ show: true, title: "Server Failed", content: "", level: "error", refresh: !current.refresh }));
            })
        .finally(() =>  setIsSubmitButtonDisabled(false));

    }


    const context = {
        countryID: country,
        languageID: language,
        hidePrices,
        isNoPreferenceSelected,
        setIsNoPreferenceSelected,
        selectedCatalogID,
        setSelectedCatalogID,
        selectedProductID,
        setSelectedProductID,
        groupedProducts,
        selectedProduct,
        selectedProductItemID,
        setSelectedProductItemID,
        productItemToPlan,
        subscriptionPlansData,
        selectedSubscriptionPlanID,
        setSelectedSubscriptionPlanID,
        allowPrescriptionOnlyFlow,
        findByDefaultDosage,
        findQuantityBasedOnRule,
        onSubmit,
        defaultCatalogID,
        initialProduct,
        localeID,
        currencySign,
        isFR,
        setIsSubmitButtonDisabled,
        isSubmitButtonDisabled
    }

    useEffect(() => {
        setLoading(true);
        http.getFromServer(`choose-treatment-returning-customers/${category}/${language}/${country}`, [
            http.MIDDLEWARES.CUSTOMER_ID,
            http.MIDDLEWARES.CART_ORDER
        ])
            .then((response) => {
                const products = response.data.productsGroupedByCatalog;
                const productItemToPlan = response.data.productItemToPlan;

                setGroupedProducts(products);
                setProductItemToPlan(productItemToPlan);
                selectInitialProduct(products, productItemToPlan);
                setSubscriptionPlansData(response.data.subscriptionPlansData);
                setLoading(false);
            })
            .catch((error) => {
                window.clientLogger.error("failed get response from server", error);
                setLoading(false);
                setError(500);
            })
    }, [])


    return (
        <>
            {loading && <LoadingModal />}

            {error && (
                <>
                    <HeaderController showSteps={false} stage={"Questionnaire"} />
                    <ServerError status={error} />
                </>
            )}
            
            <ReturingCustomersLayoutContext.Provider value={context}>
                <StickyNotification
                showNotification={stickyNotification.show}
                title={stickyNotification.title}
                onClick={() => setStickyNotification({ show: false, title: "", content: "", level: "", refresh: false })}
                content={stickyNotification.content}
                level={stickyNotification.level}
                refresh={stickyNotification.refresh}
                />
                {category && <HeaderController language={language} stage="Treatment" />}
                <div id='outer-container' className='bg-[#F7F7F7] min-h-screen py-10'>
                    {/* main title */}
                    <MainTitle category={category} country={country} language={language} />
                    
                    {isFR ? 
                        <div id='legend-fr' className='flex flex-col md:flex-row gap-2 justify-center bg-[#F7F7F7] p-4 text-[#0D2C54] font-normal w-full'>
                        <div className='flex items-center gap-1  '>
                            <ProductAvailabilityTag inStock={true} />
                            <p>Médicament + ordonnance + livraison;</p>
                        </div>

                        <div className='flex items-center gap-1 '>
                            <ProductAvailabilityTag inStock={false} />
                            <p>Sur ordonnance seulement</p>
                        </div>

                    
                        </div>
                    : 
                    ""
                    }

                    {/* main content */}
                    <div id='main-content' className='flex gap-8 w-full justify-center h-full py-10'>
                        <div id='left-panel' className='px-3 shrink-0 w-full md:w-[470px]'>
                            <div id='significance_above' className='md:pr-7 mb-5 md:hidden'>
                                {showERezeptDisclaimer && <Disclaimer text={translator.translate(language, "default", "ChooseTreatment/e-recipe-significance")} />}
                            </div>
                            <div id="scrollable" className='overflow-y-auto md:h-[540px]'>
                                <ProductSelection />
                            </div>
                            <div id='significance_under' className='max-md:hidden md:pr-7 mt-5 flex'>
                                {showERezeptDisclaimer && <Disclaimer text={translator.translate(language, "default", "ChooseTreatment/e-recipe-significance")} />}
                            </div>
                        </div>

                        <div id='right-panel' className='hidden md:flex max-h-full md:w-[445px] rounded-md overflow-y-auto'>
                            <TreatmentSelection />
                        </div>
                    </div>
                </div>
            </ReturingCustomersLayoutContext.Provider>
        </>
    )
}

export default ReturningCustomersLayout;


const MainTitle: React.FC<{ category: string, country: string, language: string }> = ({ category, country, language }) => {
    return <div className='flex justify-center flex-col items-center'>
        <h1
            className="selectTreatment-title text-center flex items-center justify-center"
        >
            {translator.translate(
                language,
                "default",
                "ChooseTreatment/ChooseTreatmentTitle"
            )}
        </h1>

        {country.toUpperCase() === "FR" ? (
            <p className="selectTreatment-subtitle !my-2 w-full px-4 text-center md:px-0 md:w-[50%]">
                {translator.translate(
                    language,
                    "default",
                    "ChooseTreatment/ChooseTreatmentSubTitle"
                )}
            </p>
        ) : null}

    </div>
}
