import { useEffect, useState } from 'react';
import { IconContext } from "react-icons";
import { LuCopy } from "react-icons/lu";
import "./copyToClipboard.css";

interface CopyToClipboardProps {
    textToCopy: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({textToCopy}) => {
    const [toggleMessage, setToggleMessage] = useState(false)

    const copy = (text:string) => {
        let tempTextArea = document.createElement("textarea");
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);
        
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999); // For mobile devices
    
        document.execCommand("copy");
    
        document.body.removeChild(tempTextArea);
        setToggleMessage((current)=> !current)
    }

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        if(toggleMessage){
            timeoutId = setTimeout(() => {
                setToggleMessage((current)=> !current)
            }, 1000);
        }

        return () => {
          clearTimeout(timeoutId);
        };
    }, [toggleMessage])
    

  return (
    <IconContext.Provider value={{ color: "grey", className: "global-class-name" }}>
        <span className='relative'>
            <LuCopy className='copy-icon' size={20} onClick={()=>{copy(textToCopy)}}/>
            {toggleMessage ? <span id='copy-message'>Copied!</span> : null}
        </span>
    </IconContext.Provider>
  )
}

export default CopyToClipboard