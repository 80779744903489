import React from "react";
import ReactDOM from "react-dom";
import ModalsWrapper from "./ModalsWrapper/ModalsWrapper";

type Props = {
  children: React.ReactNode; //The modal itself
  shouldShowModal: boolean;
  shouldBackgroundClickHideModal?: boolean;
  backgroundColor?: string;
  hideModal: () => void;
  showModal?: () => void;
};

const PortaledModal: React.FC<Props> = ({
  children,
  shouldShowModal,
  shouldBackgroundClickHideModal = true,
  backgroundColor = "rgba(24, 39, 59, 0.75)",
  hideModal,
  showModal,
}) => {

  const modalRoot = document.getElementById("modal-root");

  if (!modalRoot) {
    window.clientLogger.warn("Modal root not found. Ensure a div with id 'modal-root' exists in your HTML.");
    return null;
  }

  return ReactDOM.createPortal(
    <ModalsWrapper
      shouldShowModal={shouldShowModal}
      shouldBackgroundClickHideModal={shouldBackgroundClickHideModal}
      backgroundColor={backgroundColor}
      hideModal={hideModal}
      showModal={showModal}
    >
       {/* The Actual modal goes here */}
      {children} 
    </ModalsWrapper>,
    modalRoot
  );
};

export default PortaledModal;
