import { getPrice } from '../utils/translator'
import { clientConfig } from '../config'
import { useCartContext } from '../customHooks/useCart'
import { useSearchParams } from 'react-router-dom'
import QuantityControl from './QuantityControl/QuantityControl'
import { useShippingPageContext } from '../pages/v2/ShippingV2/providers/ShippingPageProvider'
import { useMemo } from 'react'

type Props = {
  cartViewProduct: CartProductView
  className?: string
  showQuantityControl?: boolean
}
const CANNABIS_ID_TITLE = "Digitaler Cannabis-Ausweis";

const CartViewProductRow: React.FC<Props> = ({ cartViewProduct, className, showQuantityControl = false }) => {

  const prescriptionOnlyImage = clientConfig.IMAGES_URL + clientConfig.PRESCRIPTION_IMAGE;
  const { getCart, cartCurrency } = useCartContext();
  const { handleOTCProductDecrement, handleOTCProductIncrement  } = useShippingPageContext();
  const cart = getCart();
  const [searchParams] = useSearchParams();

  const CartViewProductAsProductItem = useMemo(() => {
    return {
      id: cartViewProduct.product_item_id,
      title: cartViewProduct.title,
      quantity: String(cartViewProduct.quantity),
      price: cartViewProduct.item_price,
      type: cartViewProduct.type,
      description: "", //TODO:
      custom_quantity_title: cartViewProduct.quantityStr,
      imageSrc: cartViewProduct.imageSrc
    } as ProductItem
  }, [cart.products, cartViewProduct])


  const productPriceRow = useMemo(() => {
    let price = 0;

    if (cartViewProduct.type === "video_consultation") {
      return <></>
    }

    if (cartViewProduct.is_prescription_drug) {
      // Cannaleo flow or only prescription mode - No price should be shown
      if (searchParams.get("epd") || cart.only_prescription) {
        price = 0;
      } else {
        // Regular flow (not prescription-only) - Show price
        price = cartViewProduct.item_price * cartViewProduct.quantity!
      }
    }

    // If the product is NOT a prescription drug
    else {
      if ((cartViewProduct.type === "cannabis_id" || cartViewProduct.title === CANNABIS_ID_TITLE) && !cart.only_prescription) { //Cannabis card product
        price = 0;
      }
       else {
        // Show price for regular OTC products
        price = cartViewProduct.item_price * cartViewProduct.quantity!
      }
    }

    return (
      <p id='cart-view-product-row-price' className='font-roboto font-medium text-md text-[#0D2C54]'>
        {getPrice(price, cartCurrency.locale, cartCurrency.rate)}
      </p>
    );
  }, [cart.only_prescription, cartViewProduct.item_price, cartViewProduct.quantity, cartCurrency, searchParams]);




  return (
    <div id='cart-view-product-row' style={{ borderBottom: "1px solid #DBE1EB40" }} className={` flex flex-col gap-2 justify-start ${className}`}>
      <div id='cart-view-product-upper-row' className='flex gap-4 items-center justify-between '>

        <div className='flex items-center gap-2'>
          {/* Image */}
          <img src={(cart.only_prescription && cartViewProduct.is_prescription_drug) ? prescriptionOnlyImage : cartViewProduct.imageSrc} alt="Product" style={{ width: "75px", height: "75px", objectFit: "cover" }} />

          {/* Title & Quantity */}
          <div id='cart-view-product-row-details' className='flex flex-col gap-1'>
            <p id='cart-view-product-row-title' className='font-roboto font-medium text-md text-[#0D2C54] break-words !leading-[15px]'> {cartViewProduct.title} </p>

            {!["cannabis_id","video_consultation","test_kit"].includes(cartViewProduct.type!) || cartViewProduct.title === CANNABIS_ID_TITLE ?
              <div>
                <p id='cart-view-product-row-dosage' className='text-[#656D79] text-xs font-roboto '> Dosage: {cartViewProduct.quantityStr} </p>
                <p id='cart-view-product-row-quantity' className='text-[#656D79] text-xs font-roboto '> Quantity: {cartViewProduct.quantity} </p>
              </div>
              : 
              null
            } 
          </div>
        </div>

        {/* Price row */}
        {(searchParams.get("epd") || cart.only_prescription) && cartViewProduct.is_prescription_drug ? null : productPriceRow}

      </div>

      {
        Boolean(showQuantityControl) &&
        <div id='cart-view-product-bottom-row' className='w-full flex justify-end'>
          <QuantityControl
            decrementButtonOnClick={() => handleOTCProductDecrement(CartViewProductAsProductItem)}
            incrementButtonOnClick={() => handleOTCProductIncrement(CartViewProductAsProductItem)}
            quantity={cartViewProduct.quantity} />
        </div>
      }


    </div>
  )
}

export default CartViewProductRow

