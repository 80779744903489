import { useState, useEffect, useRef, createContext } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { clientConfig } from "../../config";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import * as http from "../../utils/http";
import * as translator from "../../utils/translator";
import HeaderController from "../../components/Headers/HeaderController";
import OrderWindow from "../../components/OrderWindow/OrderWindow";
import "./PaymentPage.css";
import Footer from "../../components/Footer/Footer";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../components/ServerError/ServerError";
import OrderOTCAddons from "../../components/OrderOTCAddons/OrderOTCAddons";
import OrderSummary from "../../components/OrderSummary/OrderSummary";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import CartView from "../../components/CartView";
import { useCartContext } from "../../customHooks/useCart";
import { ORDER_FLOWS, PAYMENT_PAGE_VIEW_EVENT } from "../../utils/consts";
import * as SocialMediaTracker from "../../PixelTrackers/SocialMediaTracker"



const refusalReasonCodes = ["11", "2"];
const ADYEN_ERROR_STATUS_CODES=[422]
const SEARCH_PARAM_FOR_NAV_TO_SELECT_TREATMENT ='roos'

//only for test, because dropin of adyen cannot get data from react state
// window.neverUseThisSubscription = false;
export const PaymentDataContext = createContext();

export default function PaymentPage(props) {
  const { country, language, order_hash_id } = useParams();
  const [error, setError] = useState(false);
  const [currencyRate, setCurrencyRate] = useState("");
  const [currencySign, setCurrencySign] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [otcDiscount, setOtcDiscount] = useState(0)
  const [loading, setLoading] = useState(true);
  const [OTCProducts,setOTCProducts] = useState([]);
  const [rxProductFinalPrice,setRxProductFinalPrice] = useState(0);
  const [paymentRefused, setPaymentRefused] = useState(false);
  let dropinElement = useRef(null);
  const navigate = useNavigate();
  const [orderWindowDetails, setOrderWindowDetails] = useState({});
  const [destinationCountry, setDestinationCountry] = useState('');
  const [orderState, setOrderState] = useState(null);
  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
  });

  // const [reorderData,setReorderData] = useState()
    const { saveCart } = useCartContext();
  
  

  const {updateCart} = useCartContext();

  const handleErrorNotification = (title, content) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification({ show: true, title, content });
    }
  };
  
  let relocationString = `/${country}/account/my-orders/${order_hash_id}/details`;
  let customerData;
  let hashID = order_hash_id;
  let orderAddresses;
  let itemTitle;
  let isSubscription = false;
  let orderPrice;
  let catalog;
  let isOrderOnlyPrescription = 0;

  let [searchParams] = useSearchParams();
  let force3DS = searchParams.get("force_3ds");
  const disallowPaymentMethods = searchParams.get("disallow")?.split(",") || [];

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      navigate({pathname: relocationString, search: "showRedirectModal=true"});
    });
  }, []);

  useEffect(() => {
    let bonusValue;
    let refusalReason = searchParams.get("rr");
    if (refusalReasonCodes.includes(refusalReason)) {
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        translator.translate(
          language,
          "default",
          "StickyNotification/adyen_message_refused"
        )
      );
    }

    // get from server only if not paid
    if (window.eventsTracking.getData("last_paid_order") === hashID){
      setError(true)
      setLoading(false)
    }
    else{
      http.getFromServer(`payment/${country}/${language}/${hashID}?disallow=${disallowPaymentMethods}`, [
        http.MIDDLEWARES.CUSTOMER_ID,
      ])
      .then(async (response) => {
        const prescriptionProduct = response.data.prescriptionProduct;
        setOtcDiscount(response.data.otcDiscount)
        // let orderCalc = response.data.orderCalculations;
        setOTCProducts(response.data.OTCProducts);
        customerData = response.data.customerData;
        orderAddresses = response.data.orderAddresses;
        const currencyRate = response.data.order.currency_rate;
        bonusValue = prescriptionProduct.bonus_value;
        itemTitle = prescriptionProduct.short_title || prescriptionProduct.title;
        isSubscription = response.data.isSubscription;
        orderPrice = response.data.totalOrdersPrice;
        isOrderOnlyPrescription = response.data.order.only_prescription;
        setDestinationCountry(response.data.country)
        setOrderWindowDetails({
          itemImageUrl: clientConfig.IMAGES_URL + prescriptionProduct.img_url,
          itemTitle,
          itemQuantity: prescriptionProduct.custom_quantity_title,
          treatmentFee: prescriptionProduct.treatment_fee * currencyRate,
          Price: prescriptionProduct.price * currencyRate,
          serviceFee: prescriptionProduct.service_fee * currencyRate,
          isSubsidized: prescriptionProduct.is_subsidized,
          shippingFee: prescriptionProduct.shipping_fee * currencyRate,
          discount: prescriptionProduct.subscription_discount * currencyRate,
          bonusValue: bonusValue * currencyRate,
          isSelfPickup: response.data.order.is_self_pickup,
          isCannabisIDAddedToOrder: response.data.order.is_cannabis_card_required,
          disclaimer: response.data.isCannabisCatalog && !response.data.order.is_self_pickup ? translator.translate(language, "default", "OrderWindow/high_demand_disclaimer") : "", 
          cannabisIdCardCost: response.data.cannabisIdCardCost,
          onlyPrescription: response.data.order.only_prescription,
        });

        catalog = response.data.catalog;
        setOrderState(response.data.order);
        setCurrencySign(response.data.currencySign);
        setCurrencyCode(response.data.currency);
        setCurrencyRate(currencyRate);

        const cartView = response.data.cartView
        updateCart({...cartView.cart, products: cartView.products});
        let adyenConf = getAdyenConf(response.data, isSubscription);
        const checkout = await AdyenCheckout(adyenConf);

        dropinElement.current = checkout.create("dropin", {
          // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
          openFirstPaymentMethod: true,
          showStoredPaymentMethods: true,
          // openFirstStoredPaymentMethod: true,
          setStatusAutomatically: true,
        });

        
        SocialMediaTracker.track("pv", PAYMENT_PAGE_VIEW_EVENT, {searchParamas: Object.fromEntries(searchParams.entries())});

      })
      .catch((err) => {
        if (err.response?.data?.logLevel === "warn"){
          if(err.response?.data?.status === "out_of_stock"){
            const cartData=err.response?.data?.cartData
            handleReviveOutOfStock(cartData)
            return
          }
          window.clientLogger.warn("warning in get payment", err);
          setError(400);
        }
        else{
          window.clientLogger.error("error in get payment", err)
          setError(400);
        }

      }).finally(() => setLoading(false));
    }


  }, [paymentRefused]);

  // load dropin element only after loading state was set
  useEffect(() => {
    if (!loading && !error) {
      dropinElement.current.mount("#dropin-container");
    }
  }, [loading]);



  const callPaymentInServer = (data, dropin, thankyouHash) => {
    const clientHost = window.location.protocol + "//" + window.location.host;

    http
      .postToServer(
        `adyen/make_payment`,
        {
          gatewayState: data,
          clientHost,
          force3DS,
          hashID,
          country,
          language,
          currencyCode,
          itemTitle,
      },
        [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        // Available statuses: Authorised,Error,Pending,PresentToShopper,Refused,Received
        if (response.data.action) {
          // Drop-in handles the action object from the /payments response
          dropin.handleAction(response.data.action);
        } else {
          // Your function to show the final result to the shopper
          showFinalResult(response.data, thankyouHash);
        }
      })
      .catch((error) => {
        if (error.response && ADYEN_ERROR_STATUS_CODES.includes(error?.response?.status)) {
          handleErrorNotification(
          translator.translate(
            language,
            "default",
            "StickyNotification/danger_title"
          ),
          translator.translate(
            language,
            "payment",
            "adyen/refusalCode/DEFAULT"
          )
        );
        setPaymentRefused(true);
        dropinElement.current.setStatus("ready")
        } else {
        window.clientLogger.error("error in callPaymentInServer", error);
        setError(500);
        MainTracker.track('custom', 'Payment Error',{paymentMethod:data.paymentMethod?.type,hashID,country,language,currencyCode});
        }
      });
  };

  const handleReviveOutOfStock = async (cartData) => {

    if(cartData){
      saveCart({
        id: cartData.cartID,
        token: cartData.cartToken,
        mention: cartData.mention,
        mixpanel_title: cartData.mixpanelTitle,
        products: [],
      });
    }
    navigate({
      pathname: `/select_treatment/${country}/${language}/${cartData.mention}`,
      search: `?ec=${SEARCH_PARAM_FOR_NAV_TO_SELECT_TREATMENT}`,
    });
  };
  

  const showFinalResult = (data, thankyouHash) => {

    const redirectStatuses = ["Authorised", "Received", "Pending"];
    const urlParams = new URLSearchParams(window.location.search);

    if (redirectStatuses.indexOf(data.resultCode) > -1) {
      dropinElement.current.setStatus("success");
      let params = {
        ...Object.fromEntries(urlParams.entries()), // Current query parameters
        result: data.resultCode,
        thankyouHash: thankyouHash,
        a: customerData.age,
        g: customerData.gender ? "m" : "f",
        ord: customerData.paidOrdersCount ? 0 : 1, //data is queried before current order is paid, ord determines whether this is first paid order 
        cat: catalog.mention,
        pm: data.paymentMethod?.type,
        onlyPrescription: isOrderOnlyPrescription,
      };

      setTimeout(() => {

        // save paid in client - in order to not load payment page
        window.eventsTracking.setData("last_paid_order", hashID)

        navigate({
          pathname: `/thank-you/${country}/${language}/${hashID}`,
          search: createSearchParams(params).toString(),
        });
      }, 3500);
    } else if (data.resultCode === "Refused") {
      let refusalCode;
      let errorMsg = translator.translate(language, "payment","adyen/refusalCode/DEFAULT");

      if (data?.refusalReasonCode) {
          refusalCode = Number(data?.refusalReasonCode);
          errorMsg = translator.translate(language,"payment",`adyen/refusalCode/${refusalCode}`) || errorMsg;
      }
     
      handleErrorNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        errorMsg
      );
      setPaymentRefused(true);
      dropinElement.current.setStatus("ready");
    }
  };

  const callDetailsInServer = (data, dropin, thankyouHash) => {
    http
      .postToServer(`adyen/payment_details`, data, [
        http.MIDDLEWARES.CUSTOMER_ID,
      ])
      .then((response) => {
        // Available statuses: Authorised,Error,Pending,PresentToShopper,Refused,Received

        if (response.data.action) {
          // Drop-in handles the action object from the /payments response
          dropin.handleAction(response.data.action);
        } else {
          // Your function to show the final result to the shopper
          showFinalResult(response.data, thankyouHash);
        }
      })
      .catch((error) => {
        window.clientLogger.error("error in callDetailsInServer", error);
        setError(500);
      });
  };

  const getAdyenConf = (response, isSubscription = false) => {
    const currencyRate = response.order.currency_rate

    return {
      paymentMethodsResponse: response.gatewayResult, // The `/paymentMethods` response from the server.
      clientKey: response.clientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
      locale: language.toLowerCase() + "-" + orderAddresses.country_id,
      environment: response.environment,
      onError: (error) => {
        window.clientLogger.error("adyen error", error);
        setError(500);
      },
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      onSubmit: (state, dropin) => {
        // Global configuration for onSubmit
        // Your function calling your server to make the `/payments` request
        //update the payment method in orderPayments
        callPaymentInServer(state.data, dropin, response.thankyouHash);
      },
      onAdditionalDetails: (state, dropin) => {
        // Your function calling your server to make a `/payments/details` request
        callDetailsInServer(state.data, dropin, response.thankyouHash);
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: !isSubscription,
          billingAddressRequired: true,
          hideCVC: false, // Change this to true to hide the CVC field for stored cards
          data: {
            holderName: `${customerData.first_name} ${customerData.last_name}`,
            billingAddress: {
              country: orderAddresses.country_id,
              city: orderAddresses.city,
              street: orderAddresses.street,
              postalCode: orderAddresses.zip,
              houseNumberOrName: orderAddresses.house_number
            },
          },
          name: "Credit or debit card",
          onSubmit: (state, dropin) => {
            callPaymentInServer(state.data, dropin, response.thankyouHash);
          }, // onSubmit configuration for card payments. Overrides the global configuration.
        },
        directEbanking: {
          name: "Online-Banküberweisung",
        },
        applepay: {
          amount: {
            currency: response.currency,
            value: orderPrice * 100 * currencyRate, //adyen multiplier
          },
          onCancel: (data, dropIn) => {
            dropIn.setStatus("ready");
          },
          onError: (error, dropIn) => {
            dropIn.setStatus("ready");
          },
          countryCode: country.toUpperCase(),
        },
        googlepay: {
          amount: {
            currency: response.currency,
            value: orderPrice * 100 * currencyRate, //adyen multiplier
          },
          onCancel: (data, dropIn) => {
            dropIn.setStatus("ready");
          },
          onError: (error, dropIn) => {
            dropIn.setStatus("ready");
          },
          countryCode: country.toUpperCase(),
          environment: clientConfig.ENV === 'production' ?  "PRODUCTION" : "TEST",
          merchantId: clientConfig.GOOGLE_PAY_MERCHANT_ID
        },
        paypal: {
          amount: {
            currency: response.currency,
            value: orderPrice * currencyRate,
          },
          onCancel: (data, dropIn) => {
            dropIn.setStatus("ready");
          },
          onError: (error, dropIn) => {
            dropIn.setStatus("ready");
          },
        },
      },
    };
  };


  return (
    <>
      {loading && <LoadingModal />}
      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}
      {!error && !loading && (
        <>
          <StickyNotification
            showNotification={showStickyNotification.show}
            title={showStickyNotification.title}
            onClick={(falseValue) => setShowStickyNotification(falseValue)}
            content={showStickyNotification.content}
          />
          <HeaderController language={language} stage="Payment" />
          <div className="payment-container">
            <div className="payment-title">
              <p> {translator.translate(language, "default", "OrderWindow/payment_title")}</p>
            </div>
            <div className="payment-main-content">
              <div className="left-panel">
                <p className="orderWindow-container-title">
                  {translator.translate(language, "default", "OrderWindow/payment_options")}
                </p>
                <div id="dropin-container"></div>
              </div>
            <PaymentDataContext.Provider
              value={{
                destinationCountry,
                currencyRate,
                currencySign
              }}
            >
            <>
              <div className="right-panel">

                {[ORDER_FLOWS.CANNALEO_PRE_SELECTED, ORDER_FLOWS.MULTIPLE_PRODUCTS].includes(orderState.flow) ? 
                <>
                  <p className="orderWindow-container-title">
                    {translator.translate(language, "default", "OrderWindow/title")}
                  </p>
                  
                  <CartView
                    showProductsQuantityControl={false}
                  />
                </>
                :

                <>
                <OrderWindow
                  itemImageUrl={orderWindowDetails.itemImageUrl}
                  itemTitle={orderWindowDetails.itemTitle}
                  Price={orderWindowDetails.Price}
                  serviceFee={orderWindowDetails.serviceFee}
                  isSubsidized={orderWindowDetails.isSubsidized}
                  shippingFee={orderWindowDetails.shippingFee}
                  itemQuantity={orderWindowDetails.itemQuantity}
                  treatmentFee={orderWindowDetails.treatmentFee}
                  subscriptionDiscount={orderWindowDetails.discount}
                  couponDiscount={orderWindowDetails.bonusValue}
                  setRxProductFinalPrice={setRxProductFinalPrice}
                  isSelfPickup={orderWindowDetails.isSelfPickup}
                  isCannabisIDAddedToOrder={orderWindowDetails.isCannabisIDAddedToOrder}
                  disclaimer={orderWindowDetails.disclaimer}
                  cannabisIdCardCost={orderWindowDetails.cannabisIdCardCost}
                  onlyPrescriptionForPayment={orderWindowDetails.onlyPrescription}
                />

                {OTCProducts.length ? 
                  <OrderOTCAddons
                    title={translator.translate(language,"default","OrderSummary/otc_addons_price")} 
                    shouldShowOtcAddonsModalButton={false}
                    currencyRate={currencyRate}
                    currencySign={currencySign}
                    selectedOtcAddons={OTCProducts.map((OTCProduct) => ({
                      quantity: OTCProduct.quantity,
                      productItem: {
                        id: OTCProduct.id,
                        title: OTCProduct.title,
                        price: OTCProduct.price / OTCProduct.quantity,
                        imgSrc: OTCProduct.imgSrc,
                        custom_quantity_title: OTCProduct.custom_quantity_title
                      }
                    }))}
                  />
                  :
                  null
  
                  }
                  {
                    OTCProducts.length && !orderWindowDetails.isCannabisIDAddedToOrder ?
                    (
                      <OrderSummary
                        currencySign={currencySign}
                        currencyRate={currencyRate}
                        rxProductFinalPrice={rxProductFinalPrice} // No need to multiply by currencyRate because it's already multiplied
                        otcAddonsTotalPrice={OTCProducts.reduce((total, OTCProduct) => {
                          const price = OTCProduct?.price ?? 0;
                          return total + (price * currencyRate);
                        }, 0)}
                        otcDiscount={otcDiscount}
                        countryID={country}
                      />
                    ) : null
                  }

                </>
              }

              </div>
            </>
            </PaymentDataContext.Provider>
            </div>
          </div>
          <Footer language={language} />
        </>
      )}
    </>
  );
}
