import "./ChooseTreatmentLandingPageDrugInfo.css";
import DrugInfoPanel from "../DrugInfoPanel/DrugInfoPanel";
import DrugPacksChoicePanel from "../DrugPacksChoicePanel/DrugPacksChoicePanel";
import { clientConfig } from "../../../../config";
import * as http from "../../../../utils/http";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { useParams } from "react-router-dom";
import { useState, useContext } from "react";
import { loadCustomerFromSession } from "../../../../reducers/CustomerReducer";
import * as translator from "../../../../utils/translator";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";
import { StickyNotificationContext } from "../../ChooseTreatmentLandingPage/ChooseTreatmentLandingPage";

const SUBS_DISCOUNT_FTD = "subs_discount_ftd";

const ChooseTreatmentLandingPageDrugInfo = ({
  products,
  currentlySelectedDosageTitle,
  changeSelectedDosage,
  currentSelectedProductItemIndex,
  changeCurrentlySelectedProductItemIndex,
  productItemSubscriptionPlans,
  pricesLogistics,
  language,
  minPrices,
  hideable,
  drugDescription,
  drugImagePath,
  selecetedDrugState,
  listOfPackets,
  changePacketsList,
  navigationCallback,
}) => {
  const triggerStickyNotification = useContext(StickyNotificationContext);
  const [showDeliveryErrorMessage, setShowDeliveryMessage] = useState(false);

  const [selectedSubscriptionPlanID, setSelectedSubscriptionPlanID] =
    useState(null);

  const { country, category } = useParams();

  const CatalogSettings = useContext(CatalogSettingContext);

  function getSelectedProductItem() {
    return products[selecetedDrugState][selecetedDrugState] || products[selecetedDrugState]
  }

  function getSubscriptionDiscount(productItemID, indexOfSelectedPlan) {
    let productItemPlan = productItemSubscriptionPlans[String(productItemID)];

    let oneTimeFTD =
      products[selecetedDrugState][selecetedDrugState][
        currentSelectedProductItemIndex
      ].subscription_ftd;

    if (selectedSubscriptionPlanID === "one-time") {
      return oneTimeFTD ? oneTimeFTD : 0;
    }

    if (
      productItemPlan &&
      selectedSubscriptionPlanID !== "none" &&
      productItemPlan[indexOfSelectedPlan]
    ) {
      if (CatalogSettings.subscription_discount_logic === SUBS_DISCOUNT_FTD) {
        return productItemPlan[indexOfSelectedPlan].first_time_discount;
      }

      return productItemPlan[indexOfSelectedPlan].discount;
    } else {
      return 0;
    }
  }

  const getIndexOfSelectedPlan = (productItemID) => {
    let index = null;

    if (productItemSubscriptionPlans[String(productItemID)]) {
      productItemSubscriptionPlans[String(productItemID)].forEach(
        (plan, planIndex) => {
          if (plan.product_plan_id === selectedSubscriptionPlanID) {
            index = planIndex;
          }
        }
      );
    }

    return index;
  };

  let selectedTreatmentData;
  let currentProduct = getSelectedProductItem();
  if (currentProduct[currentSelectedProductItemIndex]) {

    currentProduct = currentProduct[currentSelectedProductItemIndex]
    let productItemID = currentProduct.product_items_id;
    let indexOfSelectedPlan = getIndexOfSelectedPlan(productItemID);

    selectedTreatmentData = {
      product_item_id: productItemID,
      item_price: currentProduct.price,
      subscription_plan_id:
        selectedSubscriptionPlanID && selectedSubscriptionPlanID !== "one-time"
          ? selectedSubscriptionPlanID
          : null,
      treatment_fee: currentProduct.treatment_fee,
      catalog_id: currentProduct.catalog_id,
      product_catalog_id: currentProduct.product_catalog_id,
      applied_subscription_discount: getSubscriptionDiscount(
        productItemID,
        indexOfSelectedPlan
      ),
      item_confirmed: 1,
      is_sftd: selectedSubscriptionPlanID === "one-time" ? 1 : 0,
    };
  } else {
    selectedTreatmentData = null;
  }

  const onCheckOut = () => {

    if (selectedSubscriptionPlanID === "none") {
      setShowDeliveryMessage(true);
    }
    else if (!selectedTreatmentData) {
      triggerStickyNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        "")
      return;
    }
    //otherwise:
    else {
      http
        .postToServer(
          `products/${country}/${language}/${category}/false`,
          selectedTreatmentData,
          [http.MIDDLEWARES.CART_ORDER]
        )
        .then((res) => {
          let customerFromSession = loadCustomerFromSession();
          if (!customerFromSession) {
            // if no customer id send to registration
            navigationCallback(`/sign_up/${country}/${language}`);
          } else {
            navigationCallback(`/shipping/${country}/${language}`);
          }
        })
        .catch((err) => {
          triggerStickyNotification(
            translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            ),
            "Server Failed"
          );
          window.clientLogger.error(
            "error post data in ChooseTreatmentLandingPageDrugInfo",
            err
          );
        });
    }
  };

  return (
    <div className={hideable ? "right-panel hideable" : "right-panel"}>
      <DrugInfoPanel
        language={language}
        drugDescription={drugDescription}
        img={clientConfig.IMAGES_URL + drugImagePath[selecetedDrugState]}
        drugTitle={selecetedDrugState}
        fromPrice={minPrices[selecetedDrugState]}
      />

      <h2 className="choose-a-pack">
        {translator.translate(
          language,
          "default",
          "ChooseTreatmentLandingPage/choose_a_pack"
        )}
      </h2>

      <DrugPacksChoicePanel
        language={language}
        currencySign={'€'}
        selectedSubscriptionPlanID={selectedSubscriptionPlanID}
        setShowDeliveryMessage={setShowDeliveryMessage}
        showDeliveryErrorMessage={showDeliveryErrorMessage}
        setSelectedSubscriptionPlanID={setSelectedSubscriptionPlanID}
        currentSelectedProductItemIndex={currentSelectedProductItemIndex}
        changeCurrentlySelectedProductItemIndex={
          changeCurrentlySelectedProductItemIndex
        }
        //add relevant params here
        productItemSubscriptionPlans={productItemSubscriptionPlans}
        pricesLogistics={pricesLogistics}
        includeCustomTitle={true}
        listOfPackets={listOfPackets}
        changePacketsListCallback={changePacketsList}
      />

      <SubmitButton
        onSubmit={onCheckOut}
        isEnabledState={true}
        language={language}
        placeholder={translator.translate(
          language,
          "default",
          "DrugSelectionSidePanel/button-text"
        )}
        showArrow={true}
        width={"100%"}
      />
    </div>
  );
};

export default ChooseTreatmentLandingPageDrugInfo;