import { useEffect, useState, useRef } from "react";
import "./Select.css";
import { TbChevronDown } from "react-icons/tb";
import { BiLockAlt } from "react-icons/bi";

export default function Select({
  onAnswerClick = () => { },
  placeholder = "Please select",
  submitClicked = null,
  onChangeCallback = (option) => { },
  questionnaireValidationObject = {},
  options = [],
  page = null,
  style = {
    border: "1px solid #aaa",
    height: "44px",
    width: "100%",
    borderRadius: "3px",
    backgroundColor: "transparent",
    margin: "0px"
  },
  errorMode,
  id,
  initialValue = {},
  includeShortDescription = false,
  label = "",
  disabled = false
}) {

  const [optionSelected, setOptionSelected] = useState(initialValue?.value ?? null);
  const [showOptions, setShowOptions] = useState(false);
  const [showError, setShowError] = useState(false);
  const containerRef = useRef(null);


  //initial value sometimes depends on an external state so we force rerender
  useEffect(() => {
    if(initialValue?.value){
      setOptionSelected(initialValue)
    }
  }, [initialValue])
  

  useEffect(() => {
    if (submitClicked && !optionSelected) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [submitClicked, optionSelected]);


  const getContainerClassName = () => {
    if (errorMode || showError) { 
      return "questionnaire-select-error-container"
    }

    if (disabled) {
      return "questionnaire-select-disabled-container"
    }

    return "questionnaire-select-container"
  }

  /* Acces the DOM directly whenever user clicks outside the container */
  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div
      className={disabled ? `questionnaire-disabled-dropdown-container`  : `questionnaire-select-dropdown-container`}
      style={{
        width: style.width,
        height: style.height,
        border: errorMode ? null : style.border,
        borderRadius: style.borderRadius,
        backgroundColor: disabled ? null : style.backgroundColor,
        margin: style.margin
      }}
      ref={containerRef}
    >
      {label && 
      <label className="questionnaire-select-dropdown-label">
        {label}
        <span className="credential-input-mandatory-astrix">*</span>
      </label> 
      }
      
     
      <div
        className={getContainerClassName()}
        id={id}
        onClick={() => {

          if (disabled){
            return
          }

          setShowOptions((currentMode) => !currentMode)
        
        }}
        style={{ width: style.width, height: style.height }}
        tabIndex={0}
      >


        {
          includeShortDescription ? 
          <span className="legend">
            Zu ändernde Adresse
            <span className="credential-input-mandatory-astrix">
              {"*"}
            </span>
          </span> : null
        }

        {optionSelected ? (
          <span className="questionnaire-selected-item-placeholder">
            {" "}
            {optionSelected.value}
          </span>
        ) : (
          <span className="questionnaire-select-placeholder">
            {placeholder}
          </span>
        )}

        {disabled ?
          <BiLockAlt size={22}/>
          :
          <TbChevronDown
          className={
            showOptions
              ? "questionnaire-select-logo-up"
              : "questionnaire-select-logo-down"
          }
        />
        }

     
      </div>

      {showOptions ? (
        <div className="questionnaire-select-options-container">
          {options.map((option, index) => (
            <div
              className="questionnaire-select-option "
              key={index}
              onClick={() => {
                onAnswerClick(option);
                if (page && page.toLowerCase() === "questionnaire") {
                  setOptionSelected(option.recipe);
                } else {
                  setOptionSelected(option);
                }
                setShowOptions(false);
                onChangeCallback({ option, index });
              }}
            >
              <span
                id={"answer_" + (option.id || option.recipe?.id)}
                className="questionnaire-select-option-text"
              >
                {option.value || option.recipe.value}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
