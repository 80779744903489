import React, { useContext, useEffect, useMemo } from 'react'
import { getPrice, translate } from '../utils/translator'
import { useParams } from 'react-router-dom'
import { useCartContext } from '../customHooks/useCart'
import { ShippingDataContext } from '../pages/Shipping/Shipping'

type Props = {
    setRxProductFinalPrice?: (price: number) => void
}

const CANNABIS_ID_TITLE = "Digitaler Cannabis-Ausweis";
const CartViewSummary: React.FC<Props> = ({ setRxProductFinalPrice }) => {

    const { language } = useParams()
    const { getCart, cartCurrency } = useCartContext();
    const cart = getCart();
    const { cannabisIDState } = useContext(ShippingDataContext) || {}; // Shipping page
    const cannbisIdAsOrderProduct = cart.products.find(product => (product.type === "cannabis_id" || product.title === CANNABIS_ID_TITLE));
    const videoConsultationAsOrderProduct = cart.products.find(product => product.type === "online_vd"); // There is no other way to get this without breaking some types 

    const summaryRows = useMemo(() => {
        const totalDrugsPrice = cart.only_prescription ? 0 : cart.products.filter(product => product.is_prescription_drug).reduce((sum, cartProduct) => sum += cartProduct.item_price * cartProduct.quantity, 0);


        const totalOTCPrice = cart.products.filter(product => product.type === "free_drug").reduce((sum, cartProduct) => sum += cartProduct.item_price * cartProduct.quantity, 0);

        return {
            treatment_fee: {
                shouldShow: true,
                value: cart.treatment_fee || 0, //Treatment fee defined as the maximum value of the treatment fee of all products in the cart
            },

            total_drugs: {
                shouldShow: Boolean(!cart.only_prescription) && totalDrugsPrice > 0,
                value: cart.only_prescription ? 0 : totalDrugsPrice
            },

            total_otc_drugs: {
                shouldShow: totalOTCPrice > 0,
                value: totalOTCPrice
            },

            cannabis_id: {
                shouldShow: Boolean(cannbisIdAsOrderProduct) || Boolean(cannabisIDState?.isCannabisIDAddedToOrder),
                value: cart.only_prescription ? (cannbisIdAsOrderProduct?.item_price ? cannbisIdAsOrderProduct.item_price : 0) : 0
            },

            shipping_fee: {
                shouldShow: videoConsultationAsOrderProduct ? false : true,
                value: 0,
            },

            coupon: {
                shouldShow: Boolean(cart.bonus_value) && !Boolean(cart.applied_subscription_discount) && Boolean(cart.only_prescription ? !cart.auto_bonus : true), // Auto bonus should not be applied on only prescription
                value: (-1) * Number(cart.bonus_value),
            },

            subscription_discount: {
                shouldShow: Boolean(cart.applied_subscription_discount),
                value: (-1) * Number(cart.applied_subscription_discount),
            }
        }

    },[cart, cart.locale_id, cart.products, cannabisIDState?.isCannabisIDAddedToOrder, cannabisIDState?.cost]);

    const shippingFeeRowElement = useMemo(() => {
        let text;
        let value = 0;

        if (cart.self_pickup_pharmacy_id ) {
            text = translate(language, "default", "OrderWindow/self_pickup");
        }

        else if (cart.only_prescription) { //TODO: check if it's subsidized (mainly for FR catalogs) and if so, we need to show the shipping fee
            // text = translate(language, "default", "OrderWindow/prescription_delivery");
            // value = DISCOUNTED_SHIPPING_FEE
            return <></>

        } else {
            text = translate(language, "default", "OrderWindow/next_day_delivery");
        }

        return (
            <li id="cart-view-summary-row" className="flex justify-between items-center text-sm text-[#656D79]">
                <p id="cart-view-summary-row-text" className="font-roboto"> {text} </p>
                <span id='cart-view-summary-row-value' className={`font-roboto ${value !== 0 ? "line-through" : ""}`}>
                    {getPrice(value, cartCurrency.locale, cartCurrency.rate)}
                </span>
            </li>
        )

    }, [cart, cartCurrency])

    useEffect(() => {
    //TODO: This is backwards compatibility for old shipping, remove this once Shipping.js is deprecated
        if (setRxProductFinalPrice){ 
            setRxProductFinalPrice(summaryRows.total_drugs.value + summaryRows.treatment_fee.value + summaryRows.subscription_discount.value + summaryRows.coupon.value);
        }
    }, [summaryRows])






    const cartTotalPrice = useMemo(() => {

        //Sum the total of summaryRows
        let sum = 0;
        
        Object.entries(summaryRows).forEach(([field, summaryRow]) => {
            if (summaryRow.shouldShow) {
                sum += summaryRow.value;
            }
        })

        return sum

    }, [cart, language, summaryRows]);


    return (
        <ul id='cart-view-summary' className='flex flex-col gap-y-1'>

            {/* Treatment Fee  */}
            {
                summaryRows["treatment_fee"].shouldShow &&
                <li id="cart-view-summary-row-treatment-fee" className="flex justify-between items-center text-sm text-[#656D79]">
                    <p id="cart-view-summary-row-text-treatment-fee" className="font-roboto">
                        {translate(language, "default", "OrderWindow/treatment_fee")}
                    </p>
                    
                   <span id='cart-view-summary-row-value-treatment-fee' className="font-roboto">
                        {getPrice(summaryRows["treatment_fee"].value, cartCurrency.locale, cartCurrency.rate)}
                    </span>
                </li>
            }

            {/* Drugs Price  */}
            {
                summaryRows["total_drugs"].shouldShow &&
                <li id="cart-view-summary-row-total-drugs" className="flex justify-between items-center text-sm text-[#656D79]">
                    <p id="cart-view-summary-row-text-total-drugs" className="font-roboto">
                        {translate(language, "default", "OrderWindow/drug_price")}
                    </p>
                    <span id='cart-view-summary-row-value-total-drugs' className="font-roboto">
                        {getPrice(summaryRows["total_drugs"].value, cartCurrency.locale, cartCurrency.rate)}
                    </span>
                </li>
            }


            {/* OTC Drugs Price  */}
            {
                summaryRows["total_otc_drugs"].shouldShow &&
                <li id="cart-view-summary-row-total-otc-drugs" className="flex justify-between items-center text-sm text-[#656D79]">
                    <p id="cart-view-summary-row-text-total-otc-drugs" className="font-roboto">
                        {translate(language, "default", "OrderSummary/otc_addons_price")}
                    </p>
                    <span id='cart-view-summary-row-value-total-otc-drugs' className="font-roboto">
                        {getPrice(summaryRows["total_otc_drugs"].value, cartCurrency.locale, cartCurrency.rate)}
                    </span>
                </li>
            }

            {/* Shipping Fee  */}
            {summaryRows["shipping_fee"].shouldShow && shippingFeeRowElement}


            {/* Digital Cannabis ID Price row  */}
            {summaryRows["cannabis_id"].shouldShow &&
                <li id='cart-view-summary-row-cannabis_id' className='flex justify-between items-center text-sm text-[#656D79]'>
                    <p className='font-roboto'>Digitaler Cannabis-Ausweis</p>
                    <span id='cart-view-summary-row-value-cannabis_id' className="font-roboto"> {getPrice(summaryRows["cannabis_id"].value, cartCurrency.locale, cartCurrency.rate)} </span>
                </li>
            }


            {/* Coupon discount */}
            {
                summaryRows["coupon"].shouldShow &&
                <li id='cart-view-summary-row-coupon' className='flex justify-between items-center text-sm text-[#656D79]'>
                    <p className='font-roboto'> {translate(language, "default", "OrderWindow/coupon")} </p>
                    <span className='font-roboto text-[#11DDAC]'> {getPrice(summaryRows["coupon"].value, cartCurrency.locale, cartCurrency.rate)} </span>
                </li>
            }


            {/* Applied subscription discount */}
            {
                summaryRows["subscription_discount"].shouldShow &&
                <li id='cart-view-summary-row' className='flex justify-between items-center text-sm text-[#656D79]'>
                    <p className='font-roboto'> {translate(language, "default", "OrderWindow/treatment_plan_discount")} </p>
                    <span className='font-roboto text-[#11DDAC]'> {getPrice(summaryRows["subscription_discount"].value, cartCurrency.locale, cartCurrency.rate)} </span>
                </li>
            }


            {/* Cart total Price */}
            <li id='cart-view-summary-row-total-price' className='flex justify-between items-center text-sm text-[#656D79]'>
                <p className='font-roboto font-semibold text-[#0D2C54] text-lg'> {translate(language, "default", "OrderWindow/total_price")} </p>
                <span id="cart_final_price" className='font-roboto font-semibold text-[#0D2C54]'> {getPrice(cartTotalPrice, cartCurrency.locale, cartCurrency.rate)} </span>
            </li>
            
        </ul>
    )
}

export default CartViewSummary;