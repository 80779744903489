import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AutoComplete.css";
import { clientConfig } from "../../config";
import * as translator from "../../utils/translator";




interface AutoCompleteProps {
  query: string;
  country: string;
  onSelect: (suggestion: string) => void;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({ query, country, onSelect }) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const language = translator.getLanguageByCountry(country)
  
    function debounce<T extends (...args: any[]) => void>(func: T, delay: number): T {
      let timeout: NodeJS.Timeout;
      return ((...args: any[]) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), delay);
      }) as T;
    }

    const highlightMatch = (text: string, query: string) => {
      if (!query) return text;
      const regex = new RegExp(`(${query})`, "gi");
      return text.replace(regex, "<span class='highlight'>$1</span>");
    };
    // Debounced function to fetch search suggestions
    const fetchSuggestions = useCallback(
        debounce((searchQuery: string) => {
        if (!searchQuery) {
          setSuggestions([]);
          return;
        }
        const endpoint = `${clientConfig.SEARCH_URL}auto_complete_search/${language}-${country.toUpperCase()}/${encodeURIComponent(searchQuery)}`;
        fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: "tes1" }),
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok " + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          setSuggestions(data?.titles || []);
          setSelectedIndex(-1); // Reset selection on new fetch
        })
        .catch((error) => {
          window.clientLogger.error("Error fetching autocomplete suggestions:", error);
        });
      }, 500),
      []
    );

  useEffect(() => {
    fetchSuggestions(query);
  }, [query, fetchSuggestions]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!suggestions.length) return;

      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedIndex((prev) => (prev < suggestions.length - 1 ? prev + 1 : 0));
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : suggestions.length - 1));
      } else if (e.key === "Enter" && selectedIndex >= 0) {
        e.preventDefault();
        onSelect(suggestions[selectedIndex]);
        setSuggestions([]); // Hide dropdown after selection
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [suggestions, selectedIndex, onSelect]);

  // Auto-scroll the selected item into view
  useEffect(() => {
    if (selectedIndex >= 0 && suggestionsRef.current) {
      const selectedItem = suggestionsRef.current.children[selectedIndex] as HTMLElement;
      if (selectedItem) {
        selectedItem.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }, [selectedIndex]);

  return (
    <>
      {suggestions.length > 0 && (
        <ul className="autocomplete-results" ref={suggestionsRef}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={`autocomplete-item ${index === selectedIndex ? "selected" : ""}`}
              onMouseEnter={() => setSelectedIndex(index)}
              onClick={() => onSelect(suggestion)}
            >
              <span dangerouslySetInnerHTML={{ __html: highlightMatch(suggestion, query) }} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};



export default AutoComplete;
