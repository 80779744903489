import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface AppContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  errorStatus: number | null;
  setErrorStatus: (error: number | null) => void;
  stickyNotification: {
    show: boolean;
    title: string;
    content: string;
    level: string;
    refresh: boolean;
    showInfoIcon: boolean;
  };
  setStickyNotification: (notification: {
    show: boolean;
    title: string;
    content: string;
    level: string;
    showInfoIcon: boolean;
  }) => void;
}

// Create the context
const AppContext = createContext<AppContextType | undefined>(undefined);

// Create the provider
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoadingState] = useState(true);
  const [errorStatus, setErrorStatusState] = useState<number | null>(null);
  const [stickyNotification, setStickyNotificationState] = useState({
    show: false,
    title: '',
    content: '',
    level: 'error',
    refresh: false,
    showInfoIcon: true,
  });

  const setIsLoading = (loading: boolean) => setIsLoadingState(loading); // Accepts a boolean
  const setErrorStatus = (error: number | null) => setErrorStatusState(error); // Accepts error status (number or null)
  const setStickyNotification = (notification: {
    show: boolean;
    level: string;
    showInfoIcon: boolean;
    title: string;
    content: string;
  }) => setStickyNotificationState((current) => ({...notification, refresh: !current.refresh})); // Accepts the notification object

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        errorStatus,
        setErrorStatus,
        stickyNotification,
        setStickyNotification,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
