import { useLayoutEffect } from "react";
import "./Timeline.css";

export type TimelineCheckpoint = {
  title: string;
  date: string;
  isToggled: boolean;
  index: number;
  danger?: boolean;
};

type TimelineProps = {
  customClass?: string;
  checkpoints: TimelineCheckpoint[];
};

const Timeline: React.FC<TimelineProps> = ({ customClass='', checkpoints }) => {
  
  useLayoutEffect(() => {
    if (window.screen.width <= 764) {
      let ind = checkpoints.slice().reverse().findIndex((item: TimelineCheckpoint) => item.isToggled);
      
      if (ind !== -1) {
        ind = checkpoints.length - 1 - ind;  
        const element = document.getElementById(`timeline-contents-container-${ind}`);
        if (element) element.scrollIntoView({behavior: "auto", inline:"center", block: "center"});
      }
  
    }
  }, [])

  if (!checkpoints) return <></>;
  
  return (
    <div className={`timeline ${customClass}`}>
      {checkpoints.map((checkpoint, index) => (
     
          <div key={index} className="timeline-contents-container" id={`timeline-contents-container-${index}`}>
            {/* date */}
            <p
              className={`timeline-checkpoint-date ${
                checkpoint.isToggled ? "active" : "inactive"
              }`}
            >
              {checkpoint.date}
            </p>

            <div className="line-and-circle-container">
              <div
                className={`timeline-central-line left
              ${index == 0 || checkpoint.isToggled ? "active" : "inactive"}`}
              ></div>

              <div
                className={`timeline-checkpoint-circle ${
                  checkpoint.isToggled ? "active" : "inactive"
                }`}
              >
                {checkpoint.index}
              </div>

              <div
                className={`timeline-central-line right
              ${
                index == checkpoints.length - 1 ||
                checkpoints[index + 1].isToggled ||
                checkpoint.danger
                  ? "active"
                  : "inactive"
              }`}
              >
                {/* danger sign*/}
                {checkpoint.danger ? <div className="danger-sign"></div> : null}
              </div>
            </div>

            {/* status */}
            <p
              className={`timeline-checkpoint-title ${
                checkpoint.isToggled ? "active" : "inactive"
              }`}
            >
              {checkpoint.title}
            </p>
          </div>
    
      ))}
    </div>
  );
};

export default Timeline;
