import "./RegistrationForm.css";
import { useParams } from "react-router-dom";
import Select from "../Select/Select";
import * as translator from "../../utils/translator";
import * as http from "../../utils/http";
import SubmitButton from "../SubmitButton/SubmitButton";
import Checkbox from "../Checkbox/Checkbox";
import InputField from "../CredentialsInput/InputField";
import BinarySelect from "../BinarySelect/BinarySelect";
import { useCustomerContext } from "../../reducers/CustomerReducer";
import { useState, useEffect ,useRef } from "react";
import { deRegistrationForm, frRegistrationForm } from "./constants";
import useForm from "../../customHooks/useForm";
import ReCAPTCHA from "react-google-recaptcha";
import { clientConfig } from "../../config";
import { useCartContext } from "../../customHooks/useCart";
import { REGISTRATION_COMPLETED_EVENT,SIGN_UP_PAGE_VIEW_EVENT } from "../../utils/consts";
import * as SocialMediaTracker from "../../PixelTrackers/SocialMediaTracker";



export default function RegistrationForm({
  navigationCallback,
  setStickyNotificationDetails,
  languageProp,
  submitButtonPlaceholder,
}) {
  let { country, language } = useParams();
  language = language || languageProp; //In funnel with have language from url but in LoginPage we don't so we pass it has prop
  
  const {getSessionFields} = useCartContext();
  const { customerDispatch } = useCustomerContext();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(0); 
  const MALE = "1";
  const FEMALE = "0";
  const reRef = useRef();
  const sessionCart = getSessionFields()

  let initialValues;

  if (country.toLowerCase() === "de") {
    initialValues = { ...deRegistrationForm, category: sessionCart.mention };
  } else {
    initialValues = { ...frRegistrationForm, category: sessionCart.mention };
  }

  const registrationFormAttributes = useForm(initialValues, language);

  useEffect(() => {
    window.addEventListener('resize', setWindowSize(window.innerWidth));
    SocialMediaTracker.track("pv", SIGN_UP_PAGE_VIEW_EVENT, {mention: sessionCart.mention});
  }, []);

  const onSubmit = async () => {
    setSubmitButtonLoading(true);
    setSubmitClicked(true);
    window.eventsTracking.track({e: "Click registration"});

    if (registrationFormAttributes.validateForm()) {
      const locale = language.toLowerCase() + "-" + country.toUpperCase();
      const birthday = formatDateToYYYYMMDD(
        registrationFormAttributes.form.year,
        registrationFormAttributes.form.month,
        registrationFormAttributes.form.day
      );

      // ReCaptcha handling
    let captchaToken 

    if (clientConfig.IS_RECAPTCHA_ENABLED){
      captchaToken = await reRef.current.executeAsync();
      reRef.current.reset();
    }

      const trackingData =
        window.eventsTracking && window.eventsTracking.getData();
      const formmatedRegistrationForm = {
        ...registrationFormAttributes.form,
        birthday,
        trackingData,
        locale,
        captchaToken
      };

      await http
        .postToServer(`register`, formmatedRegistrationForm, [
          http.MIDDLEWARES.CART_ORDER,
        ])
        .then((response) => {
          if (response.data.status === "ok") {

            SocialMediaTracker.track("pv", REGISTRATION_COMPLETED_EVENT, {"email": formmatedRegistrationForm.email});

            customerDispatch({
              type: "register",
              customerID: response.data.customerID,
              uuid: response.data.uuid,
              rememberToken: response.data.rememberToken,
              email: formmatedRegistrationForm.email,
              optForSendables: null,
              customerFullName: response.data.customerFullName
            });

            navigationCallback();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "captcha_validation") {
            setStickyNotificationDetails((currentDetails) => ({
              show: true,
              title: translator.translate(
                language,
                "default",
                "StickyNotification/danger_title"
              ),
              content: translator.translate(
                language,
                "default",
                "StickyNotification/captcha_failed"
              ),
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }))
          }

          else if (error.response.data.status == "below_age_threshold") {
            setStickyNotificationDetails((currentDetails) => ({
              show: true,
              title: translator.translate(
                language,
                "default",
                "StickyNotification/danger_title"
              ),
              content: translator.translate(
                language,
                "default",
                "StickyNotification/at_least_18"
              ),
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }))
          }

          else if (error.response.data.status === "already_exist") {
            setStickyNotificationDetails((currentDetails) => ({
              show: true,
              title: translator.translate(
                language,
                "default",
                "StickyNotification/danger_title"
              ),
              content: translator.translate(
                language,
                "default",
                "StickyNotification/email_already_taken"
              ),
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }))
           
          } else {
            setStickyNotificationDetails((currentDetails) => ({
              show: true,
              title: translator.translate(
                language,
                "default",
                "StickyNotification/danger_title"
              ),
              content: "Server Failed",
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }))
          }
        });
    } else {
      // do not delete that else
    }

    setSubmitButtonLoading(false);
  };

  const getOptionsRange = (startRange, finishRange) => {
    let options = [];
    for (let i = startRange; i <= finishRange; i++) {
      options.push({ value: i });
    }

    return options;
  };

  const getCurrentYear = () => {
    let date = new Date();
    let year = date.getFullYear();
    return year;
  };

  const getMonthsOptionsByLanguage = () => {
    let options = [];
    const year = new Date().getFullYear(); // 2020
    const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const formatter = new Intl.DateTimeFormat(language, { month: "long" }); //return month by language,js based class

    monthList.forEach((monthNumber) => {
      options.push({ value: formatter.format(new Date(year, monthNumber)) });
    });

    return options;
  };

  const formatDateToYYYYMMDD = (year, month, day) => {
    return new Date(Date.UTC(year, month-1, day)).toISOString().slice(0, 10);
  };

  return (
    <>
      <div className="registration-form">
        <div className="registration-form-row">
          <InputField
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/first_name"
            )}
            value={registrationFormAttributes.form.first_name}
            errorMsg={
              registrationFormAttributes.errorMessagePerField.first_name
            }
            mandatory
            onChange={(inputValue) =>
              registrationFormAttributes.updateForm("first_name", inputValue)
            }
            language={language}
            onBlur={() => registrationFormAttributes.onFieldBlur("first_name")}
            touched={registrationFormAttributes.touched.first_name}
          />

          <InputField
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/surname"
            )}
            value={registrationFormAttributes.form.last_name}
            errorMsg={registrationFormAttributes.errorMessagePerField.last_name}
            mandatory
            onChange={(inputValue) =>
              registrationFormAttributes.updateForm("last_name", inputValue)
            }
            language={language}
            onBlur={() => registrationFormAttributes.onFieldBlur("last_name")}
            touched={registrationFormAttributes.touched.last_name}
          />
        </div>

        <div className="registration-form-row">
          <InputField
            className="registration-emailInput"
            type="email"
            placeholder={"E-Mail"}
            value={registrationFormAttributes.form.email}
            errorMsg={registrationFormAttributes.errorMessagePerField.email}
            mandatory
            onChange={(inputValue) =>
              registrationFormAttributes.updateForm("email", inputValue)
            }
            language={language}
            onBlur={() => registrationFormAttributes.onFieldBlur("email")}
            touched={registrationFormAttributes.touched.email}
          />
        </div>

        <div className="registration-form-row">
          <InputField
            value={registrationFormAttributes.form.password}
            errorMsg={registrationFormAttributes.errorMessagePerField.password}
            type="password"
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/password"
            )}
            onChange={(inputValue) =>
              registrationFormAttributes.updateForm("password", inputValue)
            }
            language={language}
            mandatory
            onBlur={() => registrationFormAttributes.onFieldBlur("password")}
            touched={registrationFormAttributes.touched.password}
          />

          {windowSize < 1024 && 
            <p className="registration-password-info">
              {translator.translate(
                language,
                "default",
                "RegistrationForm/password_info"
              )}
            </p>
          }

          <InputField
            value={registrationFormAttributes.form.repeat_password}
            errorMsg={
              registrationFormAttributes.errorMessagePerField.repeat_password
            }
            type="password"
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/repeat_password"
            )}
            mandatory
            onChange={(inputValue) =>
              registrationFormAttributes.updateForm(
                "repeat_password",
                inputValue
              )
            }
            language={language}
            onBlur={() => registrationFormAttributes.onFieldBlur("repeat_password")}
            touched={registrationFormAttributes.touched.repeat_password}
          />
        </div>
        {windowSize >= 1024 && 
        <p className="registration-password-info">
          {translator.translate(
            language,
            "default",
            "RegistrationForm/password_info"
          )}
        </p>
        }

        <p className="registration-birthday-title">
          {translator.translate(
            language,
            "default",
            "RegistrationForm/birthday"
          )}{" "}
          <span className="form-mandatory-astrix">*</span>
        </p>

        <div className="registration-form-row">
          <Select
            id={"day"}
            options={getOptionsRange(1, 31)}
            style={{
              backgroundColor: "var(--primary-white)",
              border: "1px solid var(--sub-gray)",
              height: 52,
              width: "100%",
              borderRadius: "6px",
            }}
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/day"
            )}
            submitClicked={submitClicked}
            onChangeCallback={(optionObject) =>
              registrationFormAttributes.updateForm(
                "day",
                optionObject.option.value
              )
            }
            errorMsg={registrationFormAttributes.errorMessagePerField.day}
          />
          <Select
            id={"month"}
            options={getMonthsOptionsByLanguage()}
            style={{
              backgroundColor: "var(--primary-white)",
              border: "1px solid var(--sub-gray)",
              height: 52,
              width: "100%",
              borderRadius: "6px",
            }}
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/month"
            )}
            submitClicked={submitClicked}
            onChangeCallback={(optionObject) =>
              registrationFormAttributes.updateForm(
                "month",
                optionObject.index + 1
              )
            }
            errorMsg={registrationFormAttributes.errorMessagePerField.month}
          />
          <Select
          
            id={"year"}
            options={getOptionsRange(1910, getCurrentYear() - 18).reverse()}
            style={{
              backgroundColor: "var(--primary-white)",
              border: "1px solid var(--sub-gray)",
              height: 52,
              width: "100%",
              borderRadius: "6px",
            }}
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/year"
            )}
            submitClicked={submitClicked}
            onChangeCallback={(optionObject) =>
              registrationFormAttributes.updateForm(
                "year",
                optionObject.option.value
              )
            }
            errorMsg={registrationFormAttributes.errorMessagePerField.year}
          />
        </div>
        <p className="registration-gender-title">
        {translator.translate(language, "default", "RegistrationForm/gender")} <span className="form-mandatory-astrix">*</span>
        </p>
        <BinarySelect
          errorMsg={registrationFormAttributes.errorMessagePerField.gender}
          submitClicked={submitClicked}
          valueLeft={translator.translate(
            language,
            "default",
            "RegistrationForm/male"
          )}
          valueRight={translator.translate(
            language,
            "default",
            "RegistrationForm/female"
          )}
          onChange={(genderValue) => {
            if (
              genderValue == "Männlich" ||
              genderValue == "Male" ||
              genderValue == "Homme"
            ) {
              registrationFormAttributes.updateForm("gender", MALE);
            } else {
              registrationFormAttributes.updateForm("gender", FEMALE);
            }
          }}
        />

        {/* if langauge has the checkbox "agree to terms" */}
        {translator.translate(
          language,
          "default",
          "RegistrationForm/agree_to_terms"
        ) !== "" ? (
          <div className="registration-agreement-checkbox-container">
            <Checkbox
              onClick={(inputValue) => {
                if (inputValue.includes("X")) {
                  registrationFormAttributes.updateForm("agree_to_terms", 1);
                } else {
                  registrationFormAttributes.updateForm("agree_to_terms", 0);
                }
              }}
              errorMsg={
                registrationFormAttributes.errorMessagePerField[
                  "agree_to_terms"
                ]
              }
            />

            <span
              dangerouslySetInnerHTML={{
                __html: translator.translate(
                  language,
                  "default",
                  "RegistrationForm/agree_to_terms"
                ),
              }}
            ></span>
          </div>
        ) : null}
        <div
          className="registration-agreement"
          dangerouslySetInnerHTML={{
            __html: translator.translate(
              language,
              "default",
              "RegistrationForm/agreement"
            ),
          }}
        />
        
        { clientConfig.IS_RECAPTCHA_ENABLED &&
        
        <ReCAPTCHA
          sitekey= {clientConfig.RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
        }
        
        <SubmitButton
          loading={submitButtonLoading}
          isEnabledState={true}
          onSubmit={onSubmit}
          showArrow
          width={"100%"}
          language={language}
          placeholder={submitButtonPlaceholder}
        />
      </div>
    </>
  );
}
