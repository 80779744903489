import { useState } from "react";
import "./Popup.css";

type Props = {
    title: string;
    description: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    handlePrimaryButton?: () => void;
    handleSecondaryButton?: () => void;
}


const Popup: React.FC<Props> = ({title, description, primaryButtonText, secondaryButtonText, handlePrimaryButton, handleSecondaryButton}) => {

    const [showPopup, setShowPopup] = useState<boolean>(true);

    return (
        <>
        { showPopup ? (
            <div className="popup-overlay">
                <div className="popup-container">
                    <button  onClick={() => setShowPopup(false)}  className="popup-close">
                        ×
                    </button>
                    
                    <h3 className="popup-title">
                        {title}
                    </h3>
                    
                    <p className="popup-description">
                        {description}
                    </p>
                    
                    <div className="popup-buttons">
                        <button onClick={handlePrimaryButton ? handlePrimaryButton : () => setShowPopup(false)} className="popup-button-primary" >
                            {primaryButtonText}
                        </button>
                        
                        <button onClick={handleSecondaryButton ? handleSecondaryButton : () => setShowPopup(false)} className="popup-button-secondary">
                            {secondaryButtonText}
                        </button>
                    </div>
                </div>
            </div>
        ) : null }
        </>
    );
};

export default Popup;