import React from 'react';
import {Link, useOutletContext} from "react-router-dom";
import MyAccountEditSubscriptionPanel from '../MyAccountEditSubscriptionPanel/MyAccountEditSubscriptionPanel';
import "./MyAccountSubscriptionOrderCard.css";
import * as translator from '../../../../utils/translator';
import Significance from '../../../Questionnaire/components/Significance/Significance';

interface MyAccountSubscriptionOrderCardProps {
    showEditPanel:boolean, //Indicates if we in only 1 card page or not
    orderHashId: string | undefined;
    language:string | undefined;
    subscriptionPrice:string;
    regularPrice:string;
    currencySign:string
    orderPeriod:string;
    deliveryAddress:string;
    nextBillingDate:string;
    nextDeliveryDate:string;
    image:string;
    title:string;
    showCancelModal?:boolean
}

const MyAccountSubscriptionOrderCard:React.FC<MyAccountSubscriptionOrderCardProps> = ({
    showEditPanel,
    orderHashId,
    language,
    subscriptionPrice,
    regularPrice,
    currencySign,
    orderPeriod,
    deliveryAddress,
    nextBillingDate,
    nextDeliveryDate,
    image,
    title,
    showCancelModal
}) => {

    const {myAccountData} = useOutletContext<any>();
    let subscriptionData
    if(orderHashId){
        (window as any).orderHashId = orderHashId
        subscriptionData = myAccountData.mySubscriptions.subscriptionData[orderHashId]
    }

  return (
    <div className="account-subscription-order-card">
        <div className="account-subscription-order-card-head">
            <div className="account-subscription-order-card-img-title">
                <div className="product-img">
                    <img src={image} alt="productImg" />
                </div>
                <p className="order-title">{title}</p>
            </div>

            <div className="order-subscription-prices">
                <p className="order-subscription-price">{subscriptionPrice} {currencySign}</p>
                <p className="order-regular-price">{regularPrice} {currencySign}</p>
            </div>
           
        </div>
        {
          subscriptionData.isQuestionnaireExpired && !showEditPanel &&
          <Significance dangerSignificancesRefsDispatch={null} parentID={null} showIcon level={"danger"} fontWeight={400} margin={'0px'} fontSize={'16px'} text={`Ihre medizinischen Daten müssen aktualisiert werden, damit die nächste Bestellung Ihres Abonnements erstellt werden kann. Bitte {klicken Sie hier:onclick="window.location.href = window.location.pathname + '/' + window.orderHashId + '#renew_questionnaire_banner';"}, um sie zu überprüfen und gegebenenfalls zu ändern`}/>
        }
        <div className="account-subscription-order-card-bottom">
            <ul>
                <li>
                    <p className="order-info-field">{translator.translate(language, "my_account", 'SubscriptionOrders/plan')}</p> 
                    <p className="order-info-field-value">{orderPeriod}</p>
                </li>
                <li>
                    <p className="order-info-field">{translator.translate(language, "my_account", 'SubscriptionOrders/address')}</p>
                    <p className="order-info-field-value">{deliveryAddress}</p>
                </li>
                <li>
                    <p className="order-info-field">{translator.translate(language, "my_account", 'SubscriptionOrders/nextPayment')}</p>
                    <p className="order-info-field-value">{nextBillingDate}</p>
                </li>
                <li>
                    <p className="order-info-field">{translator.translate(language, "my_account", 'SubscriptionOrders/nextDelivery')}</p>
                    <p className="order-info-field-value">{nextDeliveryDate}</p>
                </li>
            </ul>
            {
                !showEditPanel &&
                <div className="adminster-container">
                    <Link to={`${orderHashId}`}>{translator.translate(language, "my_account", 'SubscriptionOrders/edit')}</Link> 
                    <img src={require("../../../../icons/Edit-Green.svg").default} alt="Edit" />
                </div>
            }

        </div>



        {
        showEditPanel &&
        <MyAccountEditSubscriptionPanel 
        showCancelModal={showCancelModal} />
        }
 
    </div>
  )
}

export default MyAccountSubscriptionOrderCard