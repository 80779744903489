import { TimelineCheckpoint } from "./Timeline";
import { toLocaleDateString } from "../../utils/helpers";
import * as translator from "../../utils/translator";

// time formatting
const date_options = {
  month: "short",
  day: "numeric",
};

export const myAccountTimelineCheckpointsCreator = (
  orderData: any,
  language: string
): TimelineCheckpoint[] => {
  let doctorReviewdAt = "";
  let isRejected = false;
  const pharmacyReviewdAt = orderData.pharmacyRejectedAt
    ? orderData.pharmacyRejectedAt
    : orderData.pharmacyApprovedAt;
  const isPharmacyRejected = orderData.pharmacyRejectedAt ? true : false;
  if (orderData.orderPrescription) {
    doctorReviewdAt = orderData.orderPrescription.rejected_at
      ? orderData.orderPrescription.rejected_at
      : orderData.orderPrescription.approved_at;
    isRejected = Boolean(orderData.orderPrescription.rejected_at);
  }
  const checkpointDates = orderData.checkpointDates;
  if (!checkpointDates) return [];
  
  if (orderData.isCannabisOrder && orderData.orderType == "prescription_only") {
    return [
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/created`
        ),
        date: toLocaleDateString(checkpointDates[0], language, date_options),
        isToggled: orderData.createdAt,
        index: 1,
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/paid`
        ),
        date: toLocaleDateString(checkpointDates[1], language, date_options),
        isToggled: orderData.paidAt,
        index: 2,
      },
      {
        title: !isRejected
          ? translator.translate(
              language,
              "my_account",
              `MyOrders/details/timelineStatus/prescription_confirmed`
            )
          : translator.translate(
              language,
              "my_account",
              `MyOrders/details/timelineStatus/prescription_rejected`
            ),
        date: toLocaleDateString(checkpointDates[2], language, date_options),
        isToggled: Boolean(doctorReviewdAt),
        index: 3,
        danger: Boolean(isRejected) && Boolean(doctorReviewdAt),
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/accepted_by_pharmacy`
        ),
        date: toLocaleDateString(checkpointDates[3], language, date_options),
        isToggled: !Boolean(isPharmacyRejected) && Boolean(pharmacyReviewdAt),
        index: 4,
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/delivered`
        ),
        date: toLocaleDateString(checkpointDates[4], language, date_options),
        isToggled: orderData.deliveredAt,
        index: 5,
      },
    ];
  } else if (orderData.orderType == "prescription_only") {
    return [
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/created`
        ),
        date: toLocaleDateString(checkpointDates[0], language, date_options),
        isToggled: orderData.createdAt,
        index: 1,
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/paid`
        ),
        date: toLocaleDateString(checkpointDates[1], language, date_options),
        isToggled: orderData.paidAt,
        index: 2,
      },
      {
        title: !isRejected
          ? translator.translate(
              language,
              "my_account",
              `MyOrders/details/timelineStatus/prescription_confirmed`
            )
          : translator.translate(
              language,
              "my_account",
              `MyOrders/details/timelineStatus/prescription_rejected`
            ),
        date: toLocaleDateString(checkpointDates[2], language, date_options),
        isToggled: Boolean(doctorReviewdAt),
        index: 3,
        danger: Boolean(isRejected) && Boolean(doctorReviewdAt),
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/sent_to_customer`
        ),
        date: toLocaleDateString(checkpointDates[3], language, date_options),
        isToggled: orderData.sentAt,
        index: 4,
      },
      {
        title: translator.translate(
          language,
          "my_account",
          `MyOrders/details/timelineStatus/delivered`
        ),
        date: toLocaleDateString(checkpointDates[4], language, date_options),
        isToggled: orderData.deliveredAt,
        index: 5,
      },
    ];
  }
  return [
    {
      title: translator.translate(
        language,
        "my_account",
        `MyOrders/details/timelineStatus/created`
      ),
      date: toLocaleDateString(checkpointDates[0], language, date_options),
      isToggled: orderData.createdAt,
      index: 1,
    },
    {
      title: translator.translate(
        language,
        "my_account",
        `MyOrders/details/timelineStatus/paid`
      ),
      date: toLocaleDateString(checkpointDates[1], language, date_options),
      isToggled: orderData.paidAt,
      index: 2,
    },
    {
      title: !isRejected
        ? translator.translate(
            language,
            "my_account",
            `MyOrders/details/timelineStatus/prescription_confirmed`
          )
        : translator.translate(
            language,
            "my_account",
            `MyOrders/details/timelineStatus/prescription_rejected`
          ),
      date: toLocaleDateString(checkpointDates[2], language, date_options),
      isToggled: Boolean(doctorReviewdAt),
      index: 3,
      danger: Boolean(isRejected) && Boolean(doctorReviewdAt),
    },
    {
      title: translator.translate(
        language,
        "my_account",
        `MyOrders/details/timelineStatus/sent_to_pharmacy`
      ),
      date: toLocaleDateString(checkpointDates[3], language, date_options),
      isToggled: orderData.sentToPharmacyAt,
      index: 4,
    },
    {
      title: translator.translate(
        language,
        "my_account",
        `MyOrders/details/timelineStatus/sent_to_customer`
      ),
      date: toLocaleDateString(checkpointDates[4], language, date_options),
      isToggled: orderData.sentAt,
      index: 5,
    },
    {
      title: translator.translate(
        language,
        "my_account",
        `MyOrders/details/timelineStatus/delivered`
      ),
      date: toLocaleDateString(checkpointDates[5], language, date_options),
      isToggled: orderData.deliveredAt,
      index: 6,
    },
  ];
};
