import React, {useState } from "react";
import "./UserDetailsHoverDisconnected.css";
import { Link } from "react-router-dom";
import * as translator from "../../../../utils/translator";

interface UserDetailsHoverDisconnectedProps {
  onClick: () => void;
  country: string;
}


const UserDetailsHoverDisconnected: React.FC<UserDetailsHoverDisconnectedProps> = ({ onClick, country }) => {


  const [language] = useState(translator.getLanguageByCountry(country));
  return (
    <div className="user-details-hover">
      <div className="disconnected-background-header"></div>

      <button onClick={onClick} className="close-btn">&times;</button>

      <div className="disconnected-content-grid-container">
        <div className="disconnected-grid-row1">
          <div className="user-icon-container">
            <img
              src={require("../../../../icons/DisconnectedUser.svg").default}
              alt="User"
              className="user-icon"
            />
          </div>
          <p className="user-message">
          {translator.translate(language, "my_account", 'NavigationBar/top-massage')}

            {/* Please login/register <br /> to see my account */}
          </p>
        </div>

        <div className="disconnected-grid-row2">
        <button id="nav-bar-login-btn" className="disconnected-login-btn" onClick={(e) => e.stopPropagation()}>
            <Link to={`/${language}/login`} style={{ color: 'white', textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              <img
                src={require("../../../../icons/Log-Out-White.svg").default}
                alt="Login"
                className="login-icon"
              />
              {translator.translate(language, "my_account", 'NavigationBar/login')}

              {/* Login */}
            </Link>
          </button>


          <p className="register-link">
          <Link id="nav-bar-register-btn" 
              to={`/${language}/register`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {translator.translate(language, "my_account", 'NavigationBar/register')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsHoverDisconnected;