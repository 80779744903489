import { createContext, useContext } from "react";
import { MainTracker } from "../PixelTrackers/MainTracker";

export const CustomerContext = createContext();

export function useCustomerContext() {
  return useContext(CustomerContext);
}

export const CustomerReducer = (state, action) => {
  /* eslint no-fallthrough: ["error", { "allowEmptyCase": true }] */
  if (action.type === "register" || action.type === "logged_in") {
    let newState = {
      customerID: action.customerID,
      rememberToken: action.rememberToken,
      email: action.email,
      uuid: action.uuid,
      optForSendables: action.optForSendables,
      loginSource: action.loginSource,
      customerFullName: action.customerFullName
    };

    //update oneSignal user
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async function(OneSignal) {
      await OneSignal.login(newState.uuid);
    })

    window.eventsTracking.setData("customerID", newState.customerID, true);
    window.eventsTracking.setData("full_name", newState.customerFullName, true);
    window.eventsTracking.setData("token", newState.rememberToken, true);
    window.eventsTracking.setData("email", newState.email, true);
    window.eventsTracking.setData("uuid", newState.uuid, true);
    window.eventsTracking.setData("opt_for_sendables", newState.optForSendables, true);

    //Invoke MixPanel identity function
    MainTracker.identify(action.uuid, newState.email);
    MainTracker.people.set();
    MainTracker.superProperties({
      "Is Logged In?": "Yes",
    });

    if(action.type === "logged_in"){
    MainTracker.superProperties({"New User?": "No"})
    }else{
      MainTracker.superProperties({"New User?": "Yes"})
    }

    if (action.type === "login"){
      MainTracker.track('custom','Login')
    }

    return newState;
  } else {
    return state;
  }
};


export function loadCustomerFromSession() {
  let customerID = window.eventsTracking.getData("customerID");
  let rememberToken = window.eventsTracking.getData("token");

  if (rememberToken) {
    return {
      customerID,
      rememberToken,
    };
  }
}

export function clearCustomerFromSession() {
  
  // clear only token and not customer. the customer is needed for tracking
  window.eventsTracking.setData("token", "");
  // window.eventsTracking.setData("cart_id", "");
  // window.eventsTracking.setData("last_order", "");
  // window.eventsTracking.setData("last_paid_order", "");
}
