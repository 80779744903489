import React, { useCallback } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import * as translator from "../../../utils/translator";
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';


type Props = {
  issue: string,
  reasonContent?: string,
  nextStepContent?: string,
  orderHashId: string
}
const KnownIssuesWindow: React.FC<Props> =  ({issue, reasonContent, nextStepContent, orderHashId}) => {
  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);

  const LinkToOrderDetails = useCallback(({orderHashId}:{orderHashId: string}) => {
    return (
        <Link target='_blank' to={`../${language}/account/my-orders/${orderHashId}/details`}>{translator.translate(language, "my_account", 'MyOrders/cardDetails')}</Link>
    )
  },[])

  const reason = translator.translate(language, "contact", "modal/reason");
  const nextStep = translator.translate(language, "contact", "modal/next_step");
  const knownIssues = translator.translate(language, "contact", "modal/known_issues");

  let parts = [""];
  if (nextStepContent) {
    parts = nextStepContent!.split("{Link}");
  }


  return (
        <div id='known-issues-window' className='bg-[#FBFBFBB2] bg-opacity-[70%] border-[3px] flex flex-col border-neutral-200 font-roboto' style={{border: "1px solid #E5E5E5", borderRadius: "12px"}}>
          <div className='bg-neutral-100 w-full rounded-t-[12px] p-[20px] min-w-[436] ' style={{borderBottom: "2px solid #E5E5E5"}}>
            <h3 className='font-medium'>
              {knownIssues}
            </h3>
          </div>
          <div className='flex flex-col gap-[20px] p-[20px]'>
            <p className='font-normal text-[16px] font-roboto text-neutral-500'>
              {issue}
            </p>
            {reasonContent && (
            <div>
              <div className='flex flex-row gap-[10px] font-medium' style={{color: "#B77208"}}>
                <FiAlertTriangle size={20}/>
                {reason}:
              </div>
              <div className='pl-[30px] font-normal text-[16px] text-neutral-600'>
                {reasonContent}
              </div>
            </div>
            )}
            {nextStepContent && (
            <div>
              <div className='flex flex-row gap-[10px] text-neutral-700 font-medium'>
                <FaRegArrowAltCircleRight size={20} color='#9D9D9D'/>
                {nextStep}:
              </div>
              <div className='pl-[30px] font-normal text-[16px] text-neutral-600'>
                {parts.length == 1 ?(<div
                  id='description-depended-category'
                  dangerouslySetInnerHTML={{ __html: nextStepContent }}>  
                </div>):(
                  <>
                    {parts[0]}
                    <LinkToOrderDetails orderHashId={orderHashId}/>
                    {parts[1]}
                  </>
                )
                }
              </div>
            </div>
            )}
          </div>
  
        </div>
      )
}

export default KnownIssuesWindow