import React, { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { translate } from '../utils/translator';
import CartViewSummary from './CartViewSummary';
import { useCartContext } from '../customHooks/useCart';
import CartViewProductRow from './CartViewProductRow';
import { ORDER_FLOWS } from '../utils/consts';
import CartViewSubscriptionPlans from './CartViewSubscriptionPlans';

type Props = {
  className?: string
  children?: ReactNode
  showProductsQuantityControl?: boolean
  hideSubscriptionPlans?: boolean
  setRxProductFinalPrice?: (price: number) => void //TODO: Delete it when old shipping is dead
}

const CartView: React.FC<Props> = ({ className, hideSubscriptionPlans = false, showProductsQuantityControl=true ,children, setRxProductFinalPrice}) => {

  const { language } = useParams()
  const { getCart } = useCartContext();
  const cart = getCart();

  const rxProducts = useMemo(() => {
    return cart.products.filter((product) => product.is_prescription_drug)
  }, [cart.products])

  const addonProducts = useMemo(() => {
    return cart.products.filter((product) => !product.is_prescription_drug)
  }, [cart.products])

  return (
    <div id='cart-view-container' className={`flex flex-col bg-white px-5 py-6 rounded-[4px] h-fit gap-6 ${className} `}>

      <div id='cart-view-products-container' className='flex flex-col gap-2'>
         {/* Rx Product rows  */}
        {
          rxProducts.map((cartViewProduct) => (
            <CartViewProductRow
              key={cartViewProduct.product_item_id}
              cartViewProduct={cartViewProduct}
              className='py-3'
            />
          ))
        }

        {/* Subscription plans */}
        {hideSubscriptionPlans ? null : <CartViewSubscriptionPlans />}

        {/* Addons products of the order (OTC, Cannabis card...)   */}
        {
          addonProducts.length > 0 &&
          addonProducts.map((cartViewProduct) => (
            <CartViewProductRow
              key={cartViewProduct.product_item_id}
              cartViewProduct={cartViewProduct}
              showQuantityControl={showProductsQuantityControl && !["cannabis_id", "video_consultation","test_kit"].includes(cartViewProduct.type!)}
              className='py-3'
            />
          ))
        }

        {children}
      </div>


   

      {/* Images disclaimer */}
      {cart.flow !== ORDER_FLOWS.CANNALEO_PRE_SELECTED ?
        <p
          id='cart-view-images-disclaimer'
          className='text-[10px] text font-roboto text-center text-[#98a0af]'>{translate(language, "default", "OrderWindow/image_disclaimer")}</p>
        :
        null
      }

      {/* Calculations Summary */}
      <CartViewSummary setRxProductFinalPrice={setRxProductFinalPrice} />

    </div>
  )
}

export default CartView