import React from "react";
import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import { RiInformationLine } from "react-icons/ri";

interface Props {
  isSelected: boolean;
  onSelect: () => void;
  isDisabled?: boolean;
  title: string;
  description?: string;
  boldDescription?: string;
  headerImageSrc?: string;
  headerIconSrc?: string;
  badgeText?: string;
}

const OptionCard: React.FC<Props> = ({
  isSelected,
  onSelect,
  isDisabled,
  title,
  description,
  boldDescription,
  headerImageSrc,
  headerIconSrc,
  badgeText,
}) => {

  return (
    <div
      id="option-card-container"
      className={`relative flex flex-col p-4 mb-2 w-full select-none rounded-md ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} `}
      style={{
        backgroundColor: isDisabled ? "#EEF1F6" : (isSelected ? "#E7FCF7" : "transparent"),
        zIndex: 3,
        border: isDisabled ? "1px solid #EEF1F6" : isSelected ? "1px solid #11DDAC" : "1px solid #EEF1F6",
      }}
      onClick={() => isDisabled ? null : onSelect()}
    >
      
      {/* Header */}
      <div id="option-card-header" className="flex items-center justify-between gap-2">
        <div className="flex items-center clas">
          {
            headerIconSrc ?
              <div id='option-card-icon-container' className='flex rounded-full p-1 bg-[#C0F6EA] select-none mr-2'>
                <img src={headerIconSrc} alt='Pharmacy' className='w-[30px] h-[30px] object-cover' />
              </div>
              :
              <RadioSelect
                backgroundColor="#11ddac"
                borderColor="#c6f4e9"
                selected={isSelected}
                disabled={false}
              />
          }

          <p id="option-card-title" className={`font-poppins text-sm font-medium ${isDisabled ? "text-[#98a0af]" : "text-[#0d2c54]"}`}>{title}</p>
        </div>

        {headerImageSrc && <img src={headerImageSrc} alt="icon" className="h-[30px] max-w-[60px] object-contain" />}

      </div>

      {/* Body  */}
      {
        isSelected ?
          <div id="option-card-body" className="flex flex-col mt-2 gap-y-[5px] z-[3]">
            {/* Description */}
            {description && (
              <p id="option-card-description" className="font-roboto pl-[27px] font-normal text-[13px] text-[#656d79] ">{description}</p>
            )}

            {/* Bold Description */}
            {boldDescription && (
              <p id="option-card-bold-description" className="font-roboto pl-[27px] font-semibold text-[13px] text-[#656d79] ">{boldDescription}</p>
            )}

          </div>
          :
          null
      }

      {/* Badge */}
      {badgeText && (
        <span id="option-card-badge" className="absolute top-[-26px] right-[30px] flex items-center gap-1 bg-[#59CE3B] text-white px-2 py-1 rounded-tl-md rounded-tr-md text-[12px] z-1">
          <RiInformationLine size={14} id="info-icon" />
          {badgeText}
        </span>
      )}

    </div>
  );
};

export default OptionCard;
