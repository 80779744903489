import React, { useEffect, useMemo } from "react";
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import MyAccountOrderDetailRow from "../MyAccountOrderDetailRow/MyAccountOrderDetailRow";
import MyAccountOrderNotes from "../MyAccountOrderNotes";
import "./MyAccountOrderDetails.css";
import * as translator from "../../../../utils/translator"
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import PharmacySelection from "./PharmacySelection/PharmacySelection";
import * as http from '../../../../utils/http';
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import {
  TEST,
  ADDRESS_VERIFY,
  ADDRESS_VERIFY_DHL,
  ADDRESS_VERIFY_UPS,
  BLACKLIST,
  CANCELLED,
  CANCELLED_BY_CUSTOMER,
  CLARIFIED_BY_SUPPORT,
  COMPLETED, DELIVERED,
  DOCTOR_REQUESTED_CLARIFICATION,
  FORWARDED_REQUEST_TO_EXTERNAL_PHARMACY,
  NEED_CUSTOMER_ANSWER,
  ORDER_FLOWS,
  PAUSED_FOR_STOCK_VERIFICATION_SWISS,
  PHARMACY_INTERNAL_ERROR,
  PHARMACY_PENDING_STOCK,
  PRESCRIPTION_REJECTED,
  READY_TO_SIGN,
  REJECTED_BY_PHARMACY,
  RETURNED_TO_PHARMACY,
  SENT_TO_CUSTOMER,
  SENT_TO_PHARMACY,
  WAIT_CHARGE_BACK,
  WAIT_CUSTOMER_PHARMACY_SELECTION,
  WAIT_MONEY_TRANSFER,
  WAIT_PAYMENT_CONFIRM,
  WAIT_PRESCRIPTION,
  WAIT_REFUND,
  WAIT_REFUND_CONFIRMATION,
  WAIT_REPEAT_PRESCRIPTION,
  WAIT_SIGN_CONFIRMATION,
  PRESCRIPTION_REJECTED_BY_PHARMACY } from "../../../../utils/consts";
import OrderFeedbackForm from "../../Forms/OrderFeedbackForm"
import ThanksForFeedback from "../MyAccountOrderFeedBack/ThanksForFeedbackWidget";
import Timeline from "../../../../components/Timeline/Timeline";
import { daysDiff } from "../../../../utils/helpers";
import CopyToClipboard from "../../../../components/copyToClipboard/copyToClipboard";
import MyAccountOrderDetailsDisclaimer from "../MyAccountOrderDetailsDisclaimer/MyAccountOrderDetailsDisclaimer";
import MyAccountOrderDetailsSupport from "../MyAccountOrderDetailsSupport/MyAccountOrderDetailsSupport";
import Popup from "../../../../components/Popup/Popup";
import { myAccountTimelineCheckpointsCreator } from "../../../../components/Timeline/TimelineProcessor";

const DEPRACATED_PAYMENT_METHOD_CODE = ["swift", "iban"]

const MyAccountOrderDetails = () => {
  const {setError ,myAccountData, country, setStickyNotificationDetails} = useOutletContext<any>();
  const { order_hash_id } = useParams();
  const language = myAccountData.locale.language_id
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('re');
  const orderData = myAccountData.myOrders.ordersData[order_hash_id!];
  const navigate = useNavigate();

  const paymentMethodWithoutIbanAndSwift = Object.fromEntries(
    Object.entries(orderData.paymentMethod).filter(
      ([key]) => !DEPRACATED_PAYMENT_METHOD_CODE.includes(key)
    )
  );

  //used for reship, refund, or cancel
  useEffect(() => {
    window.scrollTo(0, 0);
    if(action){
      MainTracker.track('custom', 'Customer initiated reship or cancel', {action})
      http.postToServer(
        'action',
        {
          action,
          hashID: order_hash_id
        },
        [http.MIDDLEWARES.CUSTOMER_ID]
      ).then((response)=>{
        queryParams.delete('re')
        window.history.replaceState(null, '', `${url.pathname}?${queryParams.toString()}`);

        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: translator.translate(language, "my_account", `stickyNotifications/success/thank_you`),
          content: translator.translate(language, "my_account", `stickyNotifications/success/${response.data.messageCode}`).replace(":order_id", order_hash_id),
          level: "info",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }))
      }).catch((error)=>{
        queryParams.delete('re')
        window.history.replaceState(null, '', `${url.pathname}?${queryParams.toString()}`);

        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/actionDenied`).replace(":order_id", order_hash_id),
          level: "error",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }));
      })
    }
  }, [])

  const getStatusData = (orderData: {status: string, rejectReason: string, last_status_update: string, haveFeedback: boolean}): {class:string, title: string, description: string, showIcon: boolean} => {
    const statusUpdated = orderData.last_status_update ? new Date(orderData.last_status_update) : new Date();
    
    let daysPassed = daysDiff(statusUpdated, new Date());

    // skip weekends
    if (statusUpdated.getDay() == 0) {
      daysPassed = daysPassed - 1;
    } else if (statusUpdated.getDay() == 6) {
      daysPassed = daysPassed - 2;
    }

    const statusData = {class: "", title: "", description: "", showIcon: false};
    statusData["title"] = orderData.rejectReason ? translator.translate(language, "my_account", 'MyOrders/rejectReason') : '';
    statusData["description"] = orderData.rejectReason ? orderData.rejectReason: '';
    statusData["showIcon"] = orderData.rejectReason ? true : false;

    switch(orderData.status) {
      case CLARIFIED_BY_SUPPORT:
        statusData["class"] = "warning"; 
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${CLARIFIED_BY_SUPPORT}`);
        break;
      case FORWARDED_REQUEST_TO_EXTERNAL_PHARMACY:
        statusData["class"] = "warning"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${FORWARDED_REQUEST_TO_EXTERNAL_PHARMACY}`);
        break;
      case WAIT_REFUND:
        statusData["class"] = "warning"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${WAIT_REFUND}`);
        break;
      case WAIT_SIGN_CONFIRMATION:
      case WAIT_PRESCRIPTION:
        statusData["class"] = "warning"; 
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${WAIT_PRESCRIPTION}`);
        break;
      case SENT_TO_PHARMACY:
        statusData["class"] = "warning"; 
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${SENT_TO_PHARMACY}`);
        break;
      case PAUSED_FOR_STOCK_VERIFICATION_SWISS:
        statusData["class"] = "warning"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${PAUSED_FOR_STOCK_VERIFICATION_SWISS}`);
        break;
      case SENT_TO_CUSTOMER:
        statusData["class"] = "success"; 
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${SENT_TO_CUSTOMER}`);
        break;
      case DELIVERED:
        statusData["class"] = "success"; 
        if(!orderData.haveFeedback) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${DELIVERED}`);
        break;
      case NEED_CUSTOMER_ANSWER:
        statusData["class"] = "info"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${NEED_CUSTOMER_ANSWER}`);
        break;
      case DOCTOR_REQUESTED_CLARIFICATION:
        statusData["class"] = "info";
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${DOCTOR_REQUESTED_CLARIFICATION}`);
        break;
      case RETURNED_TO_PHARMACY:
        statusData["class"] = "info"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${RETURNED_TO_PHARMACY}`);
        break;
      case REJECTED_BY_PHARMACY:
        statusData["class"] = "danger"; 
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${REJECTED_BY_PHARMACY}`);
        break;
      case READY_TO_SIGN:
        statusData["class"] = "warning";
        if (daysPassed > 1) statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${READY_TO_SIGN}`);
        break;
      case WAIT_CUSTOMER_PHARMACY_SELECTION:
        statusData["class"] = "info";
        statusData["description"] = translator.translate(language, "my_account", `MyOrders/details/disclaimerDescriptions/${WAIT_CUSTOMER_PHARMACY_SELECTION}`);
        break;
      case WAIT_MONEY_TRANSFER:
      case WAIT_PAYMENT_CONFIRM:
      case WAIT_CHARGE_BACK:
      case WAIT_REFUND_CONFIRMATION:
      case ADDRESS_VERIFY:
      case ADDRESS_VERIFY_DHL:
      case ADDRESS_VERIFY_UPS:
      case WAIT_REPEAT_PRESCRIPTION:
      case PHARMACY_PENDING_STOCK:
      case PHARMACY_INTERNAL_ERROR:
        statusData["class"] = "warning";
        break;
      case CANCELLED:
      case BLACKLIST:
      case TEST:
      case CANCELLED_BY_CUSTOMER:
        statusData["class"] = "cancel"; 
        break;
      case COMPLETED:
        statusData["class"] = "success"; 
        break;
      case PRESCRIPTION_REJECTED:
      case PRESCRIPTION_REJECTED_BY_PHARMACY:
        statusData["class"] = "danger";
        break;
      default:
        statusData["class"] = ""; 
    }
    return statusData;
  }
  
  const statusData = useMemo(() => getStatusData(orderData), [orderData.status]);
  
  
  if(!order_hash_id || !country){
    return setError(400)
  }else if(!myAccountData.myOrders.ordersData[order_hash_id]){
    return setError(400)
  }
  const data = JSON.parse(JSON.stringify(myAccountData.myOrders.ordersData[order_hash_id]))
  const orderNotesString = data.orderType == 'prescription_only' ? 'orderNotesPrescriptionOnly' : 'orderNotes'
  delete data['prices']['treatmentFeeDiscount'] // we dont need this key in price display
  if(!data.isCannabisCardRequired){
    delete data['prices']['cannabisIdFee']
  }
  
  const products = data.orderType == 'prescription_only' ? [] : data['cartView']['products'];
  const productsView = products.map((product: {titleView: string, imageSrc: string}) => {
    return {image: product.imageSrc, title: product.titleView};
  })

  const handleNewOrder = () => {
    navigate({ pathname: `/select_treatment/${country}/${language}/${data.mention}`, search: `?skipQuestionnaire=true` });
  }

  const checkpoints = myAccountTimelineCheckpointsCreator(orderData, language);

  return (
    <>
      <div className="account-content">

      { queryParams.get("showRedirectModal") === "true" ?
          <Popup 
            title={translator.translate(language, "my_account", 'PopupFromPayment/title')}
            description={translator.translate(language, "my_account", 'PopupFromPayment/text')}
            primaryButtonText={translator.translate(language, "my_account", 'PopupFromPayment/stay')}
            secondaryButtonText={translator.translate(language, "my_account", 'PopupFromPayment/new_order')}
            handleSecondaryButton={handleNewOrder}
          /> : null
        }

        <MyAccountContentHeader
          title={`${data['hashID']}`}
          titleFurnish={<CopyToClipboard textToCopy={data['hashID']} /> }
          description={translator.translate(language, "my_account", `MyOrders/status/${orderData.status}`)}
          descriptionClass={`order-status ${statusData.class}`}
        />
        
        <Timeline customClass={data.orderType == 'prescription_only' ? 'regular' : 'premium'} checkpoints={checkpoints} />

        {statusData.description ?
        <MyAccountOrderDetailsDisclaimer
        statusColor={orderData.rejectReason ? "danger" : statusData.class}
        description={statusData.description}
        title={statusData.title}
        showIcon={statusData.showIcon}
        />
        : null}

        <div className="account-order-details">
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/hashID')}
          detailData={data['hashID']} 
          language = {language}
          />

          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/dateTime')}
            detailData={data['orderDate']}
            language = {language}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/details')}
            detailData={`${translator.translate(language, "my_account", `MyOrders/details/${data['orderType']}`)}
                          ${data.isCannabisCardRequired ? 
                          translator.translate(language, "my_account", 'MyOrders/details/digital_cannabis_id') : ''}`}
            language={language}
          />
            
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/treatment')}
            detailData={data.orderType == 'prescription_only' ? translator.translate(language, "my_account", 'MyOrders/CardPrescriptionFor') : productsView}
            language = {language}
            rowID="space-between-row"
            />
          
          { (orderData.isExternalPharmacy && orderData.flow !== ORDER_FLOWS.CANNALEO_PRE_SELECTED) ?
            <PharmacySelection orderHashID={order_hash_id} language={language} />
            :
            null
          }

          <MyAccountOrderDetailRow
           title={translator.translate(language, "my_account", 'MyOrders/details/shippingMethod')} 
           detailData={orderData.isExternalPharmacy ? "Selbstabholung in der ausgewählten Apotheke" : data['shippingMethod']}
           language = {language}
           rowID= "space-between-row"
           >

          {data['orderShipping'].tracking_url && data.status === 'sent_to_customer'  && (
            <SubmitButton
              key="shippingMethod"
              id="detail-row-shipping-method"
              placeholder= {translator.translate(language, "my_account", `MyOrders/details/trackDelivery`)}
              onSubmit={()=>{window.open(data['orderShipping'].tracking_url)}}
            />
          )}
          </MyAccountOrderDetailRow>

          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/price')}
            detailData={data['prices']}
            language = {language}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/paymentInfo')}
            detailData={paymentMethodWithoutIbanAndSwift}
            language = {language}
            additionalData = {{...data['prices'], hashID:data['hashID'], country:country, isOnlineVD:data.isOnlineVD}}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/address')}
            detailData={data['address']}
            language = {language}
          />
          {data.orderType != 'prescription_only' &&
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/deliveryAddress')}
          detailData={data['deliveryAddress']} 
          language = {language}
          />
          }

          {country == 'fr' && data.orderType != 'prescription_only' && //legal requirement for fr
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/pharmacyDetails')}
          detailData={data['pharmacyDetails']} 
          language = {language}
          />
          }
          {orderData.isAvailableForReorder ?
          <MyAccountOrderDetailRow
            title={""}
            detailData={""}
            language = {language}
            >
              <SubmitButton
                id={"account-order-card-reorder"}
                className="align-left"
                placeholder={translator.translate(language, "my_account", 'MyOrders/reorder')}
                onSubmit={() => {
                  MainTracker.track("click","Click-Reorder");
                  navigate({ pathname: `/reorder/${data['hashID']}/${country}/${language}/medical_disclaimer/` });
                }}
              />
          </MyAccountOrderDetailRow> : null}


        </div>
        { data.status === 'delivered' && (
          !data.haveFeedback ? <OrderFeedbackForm language={language} orderId={orderData.orderShipping.order_id}
          customerID={myAccountData.customer.id} orderData={orderData}/>
          :<ThanksForFeedback language={language} rating={orderData.feedbackRating} orderData={orderData}/>
          )
        }
        <MyAccountOrderDetailsSupport language={language} />
        <MyAccountOrderNotes
          htmlContent={translator.translate(language, "my_account", `MyOrders/details/${orderNotesString}`)}
          language={language}
          orderStatus={myAccountData.myOrders.ordersData[order_hash_id].status}
        />
      </div>
    </>
  );
};

export default MyAccountOrderDetails;
