import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCartContext } from '../customHooks/useCart'
import { getPrice, translate } from '../utils/translator';
import ToggleRadioButton from './ToggleRadioButton/ToggleRadioButton';
import vCheck from "../icons/vCheck.svg";
import { useParams } from 'react-router-dom';
import RadioSelect from './RadioSelect/RadioSelect';
import { useAppContext } from '../providers/AppProvider';


type Props = {}
const CartViewSubscriptionPlans: React.FC<Props> = () => {

    const { language } = useParams();
    const {setIsLoading} = useAppContext();
    const { getCart, cartCurrency, activateSubscriptionInDB, deactivateSubscriptionInDB } = useCartContext();
    const cart = getCart();
    const subscriptionPlans = cart.products[0]?.subscriptionPlans || [];
    const [selectedSubscriptionPlanID, setSelectedSubscriptionPlanID] = useState(cart.subscription_plan_id || subscriptionPlans[0]?.subscription_plan_id || 0);
    const [isToggleOn, setIsToggleOn] = useState(Boolean(cart.subscription_plan_id));

    // If other components update cart, we need to change the toggle accordingly (for example when turning on prescription only, we need to turn off the subscription plan)
    useEffect(() => {
        setIsToggleOn(Boolean(cart.subscription_plan_id));
        setSelectedSubscriptionPlanID(cart.subscription_plan_id || subscriptionPlans[0]?.subscription_plan_id);
    },[cart])

    // Max Discount for header 
    const maxDiscountOutOfPlans = useMemo(() => {
        if (subscriptionPlans.length === 0) {
            return 0;
        }
        const maxDiscount = Math.max(...subscriptionPlans.map(plan => plan.discount));
        return getPrice(maxDiscount, cartCurrency.locale, cartCurrency.rate);
    }, [subscriptionPlans, cartCurrency]);

    // Header title 
    const headerTitle = useMemo(() => {
        return (
            <span id='cartview-subscription-plans-header-title' className='font-roboto text-[14px] font-semibold text-[#0D2C54]'>
                Jetzt Behandlungsplan aktivieren und bis zu
                <span className='text-[#EF4747] font-roboto font-semibold'> {maxDiscountOutOfPlans} </span>
                sparen
            </span>
        )

    }, [maxDiscountOutOfPlans]);

    // Subscription Plans rows 
    const subscriptionPlansRows = useMemo(() => {
        const rows = subscriptionPlans.map((plan) => {

            const isRowSelected = plan.subscription_plan_id === selectedSubscriptionPlanID;
            
            return (
                <div 
                    id='cartview-subscription-plan-row' 
                    key={plan.subscription_plan_id + plan.choose_treatments_title} 
                    className='flex items-center justify-between gap-1 bg-white py-[12px] px-[15px] cursor-pointer'
                    onClick={() => {
                        if(selectedSubscriptionPlanID !== plan.subscription_plan_id){
                            activateSubscriptionPlan(plan.subscription_plan_id)
                        }
                    }}
                    >   

                    {/* Row Left side  */}
                    <div id='cartview-subscription-plan-row-left' className='flex items-center'>
                        <RadioSelect selected={isRowSelected} disabled={false} />
                        <p className={` ${isRowSelected ? "text-[#0D2C54]" : "text-[#656d79]"} text-[13px]  font-medium font-roboto`}> {plan.choose_treatments_title} </p>
                    </div>

                    {/* Row Right Side  */}
                    <span id='' className='flex font-bold text-[12px] font-roboto py-[2px] px-[5px] rounded-[3px] bg-[#EF4747] text-white'> {getPrice(plan.discount, cartCurrency.locale, cartCurrency.rate)} Rabbat </span>
                </div>

            )
        })

        return rows

    }, [subscriptionPlans, selectedSubscriptionPlanID, cartCurrency])


    const activateSubscriptionPlan = useCallback(async (subscriptionPlanID: number) => {
        setIsLoading(true);
        const response = await activateSubscriptionInDB(subscriptionPlanID)
        if (response.status === "ok") { 
            setSelectedSubscriptionPlanID(subscriptionPlanID);
            setIsToggleOn(true);
        } else {
            //TODO: maybe a stick error message (server failed)
        }
        setIsLoading(false);
    },[selectedSubscriptionPlanID])


    const handlePlansToggle = useCallback(async (newIsToggleOn: boolean) => {
        setIsLoading(true);

        if (newIsToggleOn) {
            await activateSubscriptionPlan(selectedSubscriptionPlanID);

        } else { //Toggle Off
           await deactivateSubscriptionInDB();
        }

        setIsLoading(false);
    },[selectedSubscriptionPlanID])



    if (cart.only_prescription || subscriptionPlans.length === 0) {
        return <></>;
    }


    return (
        <div id='cartview-subscription-plans-container' className='flex flex-col p-[15px] bg-[#E7FCF7]   rounded-[6px] gap-[10px]'>

            {/* Header  */}
            <div id='cartview-subscription-plans-header' className='flex justify-between gap-1'>
                {headerTitle}
                <ToggleRadioButton
                    id="toggle-subscription-plans"
                    isToggleEnabled={isToggleOn}
                    updateToggle={handlePlansToggle}
                />
            </div>

            {/* Check row 1  */}
            <div id="cartview-subscription-plan-check-row" className='flex items-center gap-1'>
                <img src={vCheck} alt="v" className='w-[12px] h-[12px]' />
                <p className='text-[12px] text-[#656d79] font-roboto'> {translate(language, "default", "OrderWindow/subscription_plan_check_row_top")} </p>
            </div>

            {/* Check row 2  */}
            <div id="cartview-subscription-plan-check-row" className='flex items-center gap-1'>
                <img src={vCheck} alt="v" className='w-[12px] h-[12px]' />
                <p className='text-[12px] text-[#656d79] font-roboto'> {translate(language, "default", "OrderWindow/subscription_plan_check_row_bot")} </p>
            </div>

            {/* Body (Plans) */}
            {
                isToggleOn && (
                    <div id='cartview-subscription-plans-body' style={{ borderTop: "1px solid #C6F4E9" }} className='flex flex-col gap-[10px]'>
                        <span id='cartview-subscription-plans-body-title' className='font-roboto text-[12px] font-semibold text-[#0D2C54] mt-[10px]'> Wählen Sie das Lieferintervall</span>
                        {subscriptionPlansRows}
                    </div>
                )
            }

        </div>
    )
}

export default CartViewSubscriptionPlans


