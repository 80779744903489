import { useEffect, useMemo, useState } from 'react';
import { loadCustomerFromSession } from '../reducers/CustomerReducer';
import { MainTracker } from '../PixelTrackers/MainTracker';
import { updateBonusDBCart } from "../pages/Shipping/helpers";
const SHOW_COUPON_CONFIRMED =  "show_coupon_confirmed";
const SHOW_COUPON_INPUT = "show_coupon_input";
const DISCOUNT_DATA = "discount_data";
const OTC_COUPON = 'otc_coupon'
const COUPON = 'coupon'


const useOTCAddons = (shouldShowModalOnStart: boolean, otcProductItems: Array<ProductItem>, updateDispatch:any): OTCAddonsAttributes => {
  
  const resetBonus = async () =>{

    if (!updateDispatch) { //ShippingV2 doesn't pass that flag,
      return
    }

    await updateBonusDBCart(null, null, false);
    updateDispatch(SHOW_COUPON_CONFIRMED, false);
    updateDispatch(SHOW_COUPON_INPUT ,true);
    updateDispatch(DISCOUNT_DATA,0);
    updateDispatch(OTC_COUPON, 0)
    updateDispatch(COUPON, 0)
  }

  useEffect(() => {
    setProductAndQuantityById(resetProductsQuantity(otcProductItems));
    setShouldShowModal(shouldShowModalOnStart && Boolean(otcProductItems.length));

  }, [otcProductItems])

  // Hooks
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(shouldShowModalOnStart);
  const [ProductAndQuantityById, setProductAndQuantityById] = useState<ProductAndQuantityById>({});
  const totalPickedQuantity = useMemo(() => {
    //Sum all quantities
    return Object.values(ProductAndQuantityById).reduce((acc: number, productAndQuantity: ProductAndQuantity) => {
      return acc + productAndQuantity.quantity
    }, 0)
  }, [ProductAndQuantityById])

  // Functions
  function hideModal() {
    setShouldShowModal(false);
  }

  function showModal() {
    setShouldShowModal(true);
  }


  async function incrementQuantity(productItemId: number) {
    await resetBonus();
    setProductAndQuantityById((currentObject: ProductAndQuantityById) => {
      //Send MP event 
      let customerFromSession = loadCustomerFromSession();
      MainTracker.track('custom', 'OTC more quantity', {
        customerID: customerFromSession?.customerID,
        productItemId,
        selectedQuantity: currentObject[productItemId].quantity + 1
      });

      //Return the state
      return { ...currentObject, [productItemId]: { ...currentObject[productItemId], quantity: currentObject[productItemId].quantity + 1 } };
    });
  }


  async function decrementQuantity(productItemId: number) {

    let customerFromSession = loadCustomerFromSession();
    await resetBonus()
    // Decrement only if quantity is greater than 1
    if (ProductAndQuantityById[productItemId].quantity > 0) {
      setProductAndQuantityById((currentObject: ProductAndQuantityById) => {
        //Send MP event 
        MainTracker.track('custom', 'OTC decreased quantity', {
          customerID: customerFromSession?.customerID,
          productItemId,
          selectedQuantity: currentObject[productItemId].quantity - 1
        });

        //Return the state
        return { ...currentObject, [productItemId]: { ...currentObject[productItemId], quantity: currentObject[productItemId].quantity - 1 } };
      });
    }
  }


  function resetProductsQuantity(otcProductItems: ProductItem[]): ProductAndQuantityById {
    let ProductAndQuantityByIdInit: any = {};
    otcProductItems.forEach((otcProductItem: ProductItem) => {
      ProductAndQuantityByIdInit[otcProductItem.id] = { productItem: otcProductItem, quantity: 0 };
    });

    return ProductAndQuantityByIdInit;
  }


  async function removeFromOrder (productItemId: number) {

    await resetBonus();

    if (ProductAndQuantityById[productItemId].quantity === 0) {
      return;
    }

    //Send MP event
    let customerFromSession = loadCustomerFromSession();
    MainTracker.track('custom', 'OTC removed to 0', {
      customerID: customerFromSession?.customerID,
      productItemId,
      selectedQuantity: 0
    });
    
    //Reset it's quantity in the productAndQuantityById object state
    setProductAndQuantityById((currentObject: ProductAndQuantityById) => {
      return { ...currentObject, [productItemId]: { ...currentObject[productItemId], quantity: 0 } };
    });
  }


  function getTotalPrice(): number {
    let totalOtcPrice = 0;

    Object.values(ProductAndQuantityById).forEach((productAndQuantity: ProductAndQuantity) => {
      if (productAndQuantity.quantity) {
        totalOtcPrice += productAndQuantity.productItem.price * productAndQuantity.quantity
      }
    })

    return totalOtcPrice;
  }


  function getSelectedAddons(): Array<ProductAndQuantity> {
    const selectedAddons: ProductAndQuantity[] = []

    Object.values(ProductAndQuantityById).forEach((productAndQuantity: ProductAndQuantity) => {
      if (productAndQuantity.quantity > 0) {
        selectedAddons.push({ ...productAndQuantity })
      }
    })

    return selectedAddons
  }


  function formatForSubmit() {
    const selectedAddons = getSelectedAddons()
    const formattedSelectedAddons = selectedAddons.map((productAndQuantity: ProductAndQuantity) => ({ id: productAndQuantity.productItem.id, quantity: productAndQuantity.quantity }))
    return formattedSelectedAddons;
  }


  return {
    ProductAndQuantityById,
    shouldShowModal,
    showModal,
    hideModal,
    incrementQuantity,
    decrementQuantity,
    removeFromOrder,
    getTotalPrice,
    getSelectedAddons,
    formatForSubmit,
    totalPickedQuantity,
  };
};

export default useOTCAddons;
