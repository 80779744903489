import "./ToggleRadioButton.css";

const ToggleRadioButton = ({ isToggleEnabled, updateToggle, id, pulsing = false }) => {
  
  const getToggleClassName = () => {
    const baseClass = "toggle-radio-button";
    const opaqueClass = !isToggleEnabled ? "opaque" : "";
    
    return [baseClass, opaqueClass].filter(Boolean).join(" ");
  };
  
  const getToggleWidgetClassName = () => {
    return isToggleEnabled ? "toggle-radio-button-widget-right" : "toggle-radio-button-widget-left";
  };

  return (
    <div className={`toggle-radio-button-container ${pulsing ? " pulsing" : ""}`}>
      <div
        id={id}
        onClick={() => {
          if (pulsing) {
            return;
          }
          updateToggle(!isToggleEnabled);
        }}
        className={getToggleClassName()}
      >
        <div
          id="toggle-radio-ball"
          className={getToggleWidgetClassName()}
        ></div>
      </div>
    </div>
  );
};

export default ToggleRadioButton;