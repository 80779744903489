import { useContext, useState } from "react";
import { ChooseTreatmentContext } from "../CannaleoFreeDosageLayout";
import { IoIosArrowUp, IoIosArrowDown, IoMdClose } from "react-icons/io";
import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import * as translator from "../../../../utils/translator";
import { RouterCartContext } from "../../ChooseTreatmentRouter";
import { useSearchParams } from "react-router-dom";


const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'  // Optional, smooth scroll behavior
    });
};

const FilterOptionsSubMenu: React.FC<{ filterOptions: { value: string, isSelected: boolean }[], keyWord: string }> = ({ filterOptions, keyWord }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const [isOpen, setIsOpen] = useState(true);

    const handleChooseFilter = (item: { value: string, isSelected: boolean }) => () => {
        chooseTreatmentContext?.toggleFilter(keyWord, item.value);
        if (keyWord === "strain") {
            MainTracker.track("custom", `${item.value} Filter`);
        }
        if (keyWord === "THC / CBD Gehalt") {
            const words = item.value.split(' ');
            const filterName = words[0] + " " + words[words.length - 1];
            MainTracker.track("custom", `${filterName} Filter`);
        }
    }

    return (<div className="select-none" >
        <div className="flex justify-between items-center">

            <div className='text-md font-medium capitalize my-3'>{translator.translate('de', "default", `Filters/${keyWord}`)}</div>
            <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </div>
        <div className={`flex flex-col gap-3 overflow-y-auto ${isOpen ? '' : 'hidden'}`}>
            {
                filterOptions.map((item, idx) => {
                    return <div key={idx} id={`filter_${item.value}_${idx}`} onClick={handleChooseFilter(item)} style={{ borderBottom: "1px solid #DBE1EB" }} className='flex items-center gap-2 pr-3 py-2 cursor-pointer mb-1 px-2'>
                        {
                            item.isSelected ? <div style={{ border: "2px solid #DBE1EB" }} className='rounded-sm w-6 h-6 bg-[#11DDAC]'></div> : <div style={{ border: "2px solid #DBE1EB" }} className='rounded-sm w-6 h-6 bg-white'></div>
                        }
                        <p className="capitalize">{translator.translate('de', "default", `Filters/${item.value}`) ?? item.value}</p>
                    </div>
                })
            }
        </div>
    </div>)
}

export const FilterMenu: React.FC<{ filterData: { [key: string]: { value: string, isSelected: boolean }[] } }> = ({ filterData }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const routerCartContext: any = useContext(RouterCartContext)
    const cart = routerCartContext.cartData
    const [searchParamas] = useSearchParams();

    const handlePurchaseTierChoice = (tier : "regular" | "all" | "premium") => {
        chooseTreatmentContext?.setPurchaseTierFilter(tier);
        MainTracker.track("custom", `${tier.charAt(0).toUpperCase()}${tier.slice(1)} Filter`);
    }

    return (
        <div id="filters-modal" className="h-screen w-screen fixed top-0 right-0 flex justify-end" style={{ zIndex: 999 }}>

            <div id="filters-modal-overlay" onClick={() => { chooseTreatmentContext?.setIsFilterMenuOpen(false) }} className="md:w-[73%] bg-black opacity-50 w-0 h-full"/>

            <div id="filters-modal-menu" className='md:w-[27%] w-[100%] p-4 z-3 bg-white flex flex-col justify-between'>
                <p id="close" className="absolute top-[10px] right-[25px] cursor-pointer font-medium text-lg" onClick={() => { chooseTreatmentContext?.setIsFilterMenuOpen(false) }}><IoMdClose /></p>


                {/* dynamic filters */}
                <div className="h-full overflow-y-auto">
                    <p className="font-normal text-lg">Filters</p>

                    <div className="mt-2 w-full pr-4 ">
                        {
                            Object.keys(filterData).map((key, idx) => {
                                return (
                                    <div className='flex flex-col gap-5' key={idx}>
                                        <FilterOptionsSubMenu key={idx} filterOptions={filterData[key]} keyWord={key} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>



                 <div id="buttons-and-static-filters" className="">
                    {/* static filters */}
                    <div className="flex flex-col gap-5 mt-5 w-full">
                        {/* hide premium and regular filter for pre selected external pharmacy flow */}
                        {!Boolean(searchParamas.get("epd")) && 
                        <>
                        <div id="premium" onClick={() => { handlePurchaseTierChoice("premium") }} className="cursor-pointer flex items-center gap-2">
                            <RadioSelect selected={chooseTreatmentContext?.PurchaseTierFilter === "premium"} disabled={false} size={16} />
                            <div className="flex flex-col">
                                <p className="font-medium">Premium</p>
                                <p className="text-sm">Medikament + Rezept + {cart?.self_pickup_pharmacy_id ? "Selbstabholung" : "Lieferung"}</p>
                            </div>
                        </div>


                        <div id="regular" onClick={() => { handlePurchaseTierChoice("regular") }} className="cursor-pointer flex items-center gap-2">
                            <RadioSelect selected={chooseTreatmentContext?.PurchaseTierFilter === "regular"} disabled={false} size={16} />
                            <div className="flex flex-col">
                                <p className="font-medium">Regulär</p>
                                <p className="text-sm">Nur Rezept</p>
                            </div>
                        </div>
                        </>
                    }


                    </div>

                    {/* buttons */}
                    <div className="mt-5 sticky">
                        <div className="w-full font-semibold text-center cursor-pointer text-sm bg-[#11DDAC] text-white p-3 rounded-md" onClick={() => { chooseTreatmentContext?.setIsFilterMenuOpen(false); scrollToTop(); }}>Filter anwenden ({chooseTreatmentContext?.appliedFiltersCount})</div>
                        <div className="w-full font-semibold text-center cursor-pointer text-sm underline text-[#616161] p-3 rounded-md" onClick={() => { chooseTreatmentContext?.resetAllFilters(); }}>Filter zurücksetzen</div>
                    </div>
                </div>
            </div>
        </div>
    )
}