import React, { useState } from "react";
import "./MyAccountAccordion.css";
import plusIcon from "../../../../icons/Plus-Green.svg";
import minusIcon from "../../../../icons/Minus-Green.svg";
import InnerHTMLRenderer from "../../../../components/InnerHTMLRenderer/InnerHTMLRenderer";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { useNavigate } from 'react-router-dom';

interface MyAccountAccordionProps {
  items: {
    title: string;
    content: string;
    button?: { placeholder: string; ref: string, searchParams?: string };
  }[];
  accordionClass?: string;
  itemClass?: string;
  titleClass?: string;
  contentClass?: string;
  defaultOpenIndex?: number;
  icons?: { closeIcon: string; openIcon: string };
}

const MyAccountAccordion: React.FC<MyAccountAccordionProps> = ({
  items,
  accordionClass = "",
  itemClass = "",
  titleClass = "",
  contentClass = "",
  defaultOpenIndex = null,
  icons = { closeIcon: minusIcon, openIcon: plusIcon },
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(
    defaultOpenIndex
  );
  const { closeIcon, openIcon } = icons;
  const navigate = useNavigate();

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={`my-account-accordion ${accordionClass}`}>
      {items.map((item, index) => (
        <div className={itemClass} key={index} id={`${itemClass}-${index}`}>
          <div
            className={`my-account-accordion-title ${titleClass} ${
              activeIndex === index ? "active" : ""
            }`}
            id={`${titleClass}-${index}`}
            onClick={() => handleClick(index)}
          >
            {item.title}
            <img src={activeIndex == index ? closeIcon : openIcon} />
          </div>

          <InnerHTMLRenderer
            htmlContent={item.content}
            className={`my-account-accordion-content ${contentClass} ${
              activeIndex === index ? "open" : ""
            }`}
          >
            {item.button ?
            <SubmitButton
                            id={`my-account-accordion-button-${index}`}
                            className="my-account-accordion-button align-left"
                            placeholder={item.button.placeholder}
                            onSubmit={() => {
                              navigate({ pathname: item.button?.ref, search:item.button?.searchParams ? `?${item.button.searchParams}` : "" });
                            }}
                          />
            : null}
          </InnerHTMLRenderer>
        </div>
      ))}
    </div>
  );
};

export default MyAccountAccordion;
