import React, { createContext, useContext, useState } from "react";
import * as SessionStorageWrapper from "../utils/SessionStorageWrapper";
import * as http from "../utils/http";
import { clientConfig } from "../config";
import { debounce } from "../utils/helpers";

// CartContext type
type CartContextType = {
  getCart: () => CartView;
  getSessionFields: () => { mention: string | null; id: string | null; token: string | null; mixpanel_title: string | null };
  saveCart: (cart: CartView) => void;
  clearCart: () => void;
  updateCart: (fields: Partial<CartView>) => void;
  updateCartAndDB: (fields: Partial<CartView>) => Promise<Record<string, string | number>>;
  addProduct: (product: CartProductView) => void;
  removeProduct: (productItemIdToRemove: number) => void;
  incrementProductItemQuantity: (productItem: ProductItem) => Promise<{status: string}>;
  decrementProductItemQuantity: (productItem: ProductItem) => Promise<{status: string}>;
  cartCurrency: { locale: string, rate: number, isDefault: boolean, countryID: string }
  setCartCurrency: (currency: { locale: string, rate: number, isDefault: boolean, countryID: string }) => void
  activateSubscriptionInDB: (subscriptionPlanID: number) => Promise<{ status: string }>
  deactivateSubscriptionInDB: () => Promise<any>
}

// CartState type
type CartView = Cart & { products: CartProductView[] }

// Create the context
const CartContext = createContext<CartContextType | undefined>(undefined);

// Custom hook to use the context
export function useCartContext(): CartContextType {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
}


// Updating product quantity in DB cart (using debounce) - *NEEDS TO BE IN THIS SCOPE*
const debouncedBatchUpdateDBCartQuantity = debounce(
  async (currentCartProducts: CartProductView[]) => {
    const productItemsWithQuantity = currentCartProducts.map((productItem) => ({
      productItemID: productItem.product_item_id,
      quantity: productItem.quantity, 
      catalogID: productItem.catalog_id
    }));

    // Send all the updated products at once in a single batch
    const response = await http.postToServer(
      "cart/products/update/quantity", // Assume this endpoint supports batch updates
      { productItemsWithQuantity },
      [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID]
    );

    return response;
    
  },
  1000  // Fixed delay to prevent spamming the server
);

// Provider
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [cartViewState, setCartViewState] = useState<CartView>({ products: [] });
  const [cartCurrency, setCartCurrency] = useState<{ locale: string, rate: number, isDefault: boolean, countryID: string }>({ locale: "de-DE", rate: 1, isDefault: true, countryID: "de" })


  const getCart = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cartFromParam = urlParams.get("crt");

    if (cartFromParam && SessionStorageWrapper.getItem("last_shipped_cart") !== cartFromParam) {
      window.eventsTracking.setData(`cart_id`, cartFromParam, true);
    }

    return cartViewState;
  }


  const getSessionFields = () => {
    return {
      id: window.eventsTracking.getData("cart_id"),
      token: window.eventsTracking.getData("cart_token"),
      mention: window.eventsTracking.getData("cart_mention"),
      mixpanel_title: window.eventsTracking.getData("cart_mixpanel_title"),
      is_e_recipe: window.eventsTracking.getData("cart_is_e_recipe"),
    }
  }

  const saveCart = (cart: CartView) => {

    // 1. Save in eventsTracking  
    window.eventsTracking.setData(`cart_id`, cart.id!, true);
    window.eventsTracking.setData(`cart_token`, cart.token!, true);
    window.eventsTracking.setData(`cart_is_e_recipe`, cart.is_e_recipe!, true);

    if (cart.mention && cart.mixpanel_title) {
      window.eventsTracking.setData('cart_mention', cart.mention!, true);
      window.eventsTracking.setData('cart_mixpanel_title', cart.mixpanel_title!, true);
    }

    // 2. Save the state
    setCartViewState(cart);

  };

  const clearCart = () => {

    // 1. Clear eventsTracking
    window.eventsTracking.setData(`cart_id`, "", true);
    window.eventsTracking.setData(`cart_token`, "", true);
    window.eventsTracking.setData('cart_mention', "", true);
    window.eventsTracking.setData('cart_mixpanel_title', "", true);
    window.eventsTracking.setData('cart_is_e_recipe', "", true);

    // 2. Clear the state
    setCartViewState({ products: [] });

  };

  const updateCart = (fields: Partial<CartView>) => {
    setCartViewState((current) => ({ ...current, ...fields }));
  };

  const addProduct = (product: CartProductView) => {
    setCartViewState((prev) => ({
      ...prev,
      products: [...prev.products, product],
    }));
  };



  //This will increment the quantity of the product or add a new cart-product
  const incrementProductItemQuantity = async (productItem: ProductItem): Promise<{status: string}> => {
    let updatedProducts: CartProductView[] = [];
  
    // Optimistic state update
    setCartViewState((prev) => {
      updatedProducts = prev.products.map((p) => {
        if (p.product_item_id === productItem.id) {
          return { ...p, quantity: p.quantity + 1 };
        }
        return p;
      });

  
      // If the product is not found, add it to the cart
      if (!updatedProducts.some((p) => p.product_item_id === productItem.id)) {
        updatedProducts = [
          ...updatedProducts,
          {
            ...productItem,
            cart_id: cartViewState.id ?? 0,
            product_item_id: productItem.id,
            treatment_fee: 0,
            item_price: productItem.price,
            is_prescription_drug: false,
            is_test_kit: false,
            type: productItem.type,
            only_prescription: false,
            quantity: 1, // Set the initial quantity to 1 since it's a new product being added
            quantityStr: productItem.custom_quantity_title || "1",
            imageSrc: clientConfig.IMAGES_URL + productItem.imgSrc || "",
            subscriptionPlans: [],
          } as CartProductView,
        ];
      }

      if (productItem.type === "cannabis_id") { //Dont update the optimistic state for cannabis id, because of the pulsing effect
        return {...prev}
      }
  
      return { ...prev, products: updatedProducts };
    });
  
    // Update the DB in batch
    const response = await debouncedBatchUpdateDBCartQuantity(updatedProducts);

    if (response.data.status === "ok" && response.data.cartView) {
      const cartView = response.data.cartView;
      setCartViewState({ ...cartView.cart, products: cartView.products });
      return { status:"ok" };

    } else {
      window.clientLogger.warn("Failed to update cart quantities", response);
      return { status:"failed" };
    }
  };
  
  

  //This will decrement the quantity of the product or remove an existing cart-product
  const decrementProductItemQuantity = async (productItem: ProductItem): Promise<{status: string}> => {
    const existingProductIndex = cartViewState.products.findIndex((cp) => cp.product_item_id === productItem.id);
    if (existingProductIndex === -1) {
      return { status:"ignored" };
    }
  
    let updatedQuantity = 0;
    let updatedProducts: CartProductView[] = [];
  
    // Update the optimistic state
    setCartViewState((prev) => {
      updatedProducts = prev.products.map((p, index) => {
        if (index === existingProductIndex) {
          updatedQuantity = Math.max(0, p.quantity - 1);
          return { ...p, quantity: updatedQuantity };
        }
        return p;
      }).filter((p) => p.quantity > 0);
  
      // Remove product if quantity reaches 0
      if (productItem.type === "cannabis_id") { //Dont update the optimistic state for cannabis id, because of the pulsing effect
        return {...prev}
      }

      return { ...prev, products: updatedProducts };
    });
  
    // Update the DB (using debounced batch update)
    const response = await debouncedBatchUpdateDBCartQuantity(updatedProducts);
    if (response.data.status === "ok" && response.data.cartView) {
      const cartView = response.data.cartView;
      setCartViewState({ ...cartView.cart, products: cartView.products });
      return { status:"ok" };

    } else {
      window.clientLogger.warn("Failed to update cart quantities", response);
      return { status:"failed" };
    }
  };
  

  const removeProduct = (productItemIdToRemove: number) => {
    setCartViewState((prev) => ({
      ...prev,
      products: prev.products.filter((product) => product.product_item_id !== productItemIdToRemove),
    }));
  };


  // Maybe we won't use that, we will see...
  const updateCartAndDB = async (fields: Partial<CartView>, callBack?: (cartView:any) => void) => {

    const cartID = window.eventsTracking.getData(`cart_id`)

    if (!cartID) {
      return { status: "failed" }
    }

    try {
      const response = await http.postToServer("cart/update", { ...fields }, [http.MIDDLEWARES.CART_ORDER]);

      if (response.data.status === "ok" && response.data.cartView) {
        const cartView = response.data.cartView;
        const newCartViewState = {
          ...cartView.cart,
          products: cartView.products
        }

        setCartViewState(newCartViewState);

        if (callBack){
          callBack(newCartViewState);
        }
        
        return {status:"ok"};
        
      } else {
        window.clientLogger.error("Couldn't update cart", response);
        return { status: "failed" }
      }

    } catch (error) {
      window.clientLogger.error("Server failed to update the cart:", error);
      return { status: "failed" }
    }
  };




  const activateSubscriptionInDB = async (subscriptionPlanID: number) => {
    const response = await http.postToServer("cart/activate-subscrption", { subscriptionPlanID }, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID]);
    if (response.data.status === "ok" && response.data.cartView) {
      const cartView = response.data.cartView;
      setCartViewState({
        ...cartView.cart,
        products: cartView.products
      });

    } else {
      window.clientLogger.warn("Failed to deactivate subscription in DB (cart)", response);
    }

    return response
  }


  const deactivateSubscriptionInDB = async (): Promise<any> => {
    const response = await http.postToServer("cart/deactivate-subscrption", {}, [http.MIDDLEWARES.CART_ORDER, http.MIDDLEWARES.CUSTOMER_ID]);
    if (response.data.status === "ok" && response.data.cartView) {
      const cartView = response.data.cartView;
      setCartViewState({
        ...cartView.cart,
        products: cartView.products
      });

    } else {
      window.clientLogger.warn("Failed to deactivate subscription in DB (cart)", response);
    }

    return response
  }


  return (
    <CartContext.Provider value={{
      getCart,
      getSessionFields,
      saveCart,
      clearCart,
      updateCart,
      updateCartAndDB,
      addProduct,
      removeProduct,
      incrementProductItemQuantity,
      decrementProductItemQuantity,
      cartCurrency,
      setCartCurrency,
      activateSubscriptionInDB,
      deactivateSubscriptionInDB,
    }} 
    >
      {children}
    </CartContext.Provider>
  );
};
