import React from 'react';
import "./PharmacySelectionConfirmationModal.css";
import CloseIcon from "../../../../src/icons/X.svg";
import Disclaimer from '../../../pages/ChooseTreatmentVariants/components/Disclaimers/Disclaimer';

interface Props{
    closeModal: () => void
    onSubmit: () => void
    selectedPharmacy: {name:string, address:string, availability: string}
    rejectionCount: number
}

const getAvailabilityDisclaimerText = (availability: string) => {
    if (availability === '0') { // No info
        return "Wir haben keine Echtzeit-Lagerinformationen für diese Apotheke. Wenn Sie fortfahren, liegt es in Ihrer Verantwortung, die Verfügbarkeit bei dieser Apotheke zu überprüfen.";
    }

    if (availability !== '1' && availability !== '2') { // out of stock
        return "Diese Apotheke hat bestätigt, dass das Medikament nicht mehr vorrätig ist. Wenn Sie fortfahren, liegt es in Ihrer Verantwortung, die Verfügbarkeit bei dieser Apotheke zu überprüfen.";
    }

    return "";
};


const PharmacySelectionConfirmationModal:React.FC<Props> = ({closeModal,onSubmit,selectedPharmacy, rejectionCount=0}) => {
  return (
    <div onClick={closeModal} className="pharmacy-selection-modal-background">
        <div 
        className="pharmacy-selection-modal-container"
        onClick={(event) => event.stopPropagation()}
        >
            <img onClick={closeModal} className="close-btn" src={CloseIcon} alt="Close" />
              <h6>{rejectionCount ? "Die ausgewählte Apotheke hat Ihre Bestellung schon einmal abgelehnt. Möchten Sie Ihr Rezept trotzdem dorthin schicken?" : "Sie haben diese Apotheke zur Abwicklung Ihrer Bestellung ausgewählt:"}</h6>
              <ul>
                  <li>{selectedPharmacy!.name}</li>
                  <li>{selectedPharmacy!.address}</li>
              </ul>

              {getAvailabilityDisclaimerText(selectedPharmacy!.availability)
                  ? <Disclaimer text={getAvailabilityDisclaimerText(selectedPharmacy!.availability)!} />
                  : null}

              <p className='pharmacy-selection-disclaimer'>
                  Klicken Sie auf „Bestätigen“, damit wir Ihr Rezept an die ausgewählte Apotheke senden können, sobald es vom Arzt genehmigt wurde. Sie werden per E-Mail über die Abholung der Bestellung benachrichtigt werden.
              </p>

            <div className="actions-container">
                <span className="confirm-btn" onClick={onSubmit}>Bestätigen</span>
                <span className='cancel-btn' onClick={closeModal}>Stornieren</span>
            </div>
        </div>
    </div>
  )
}

export default PharmacySelectionConfirmationModal