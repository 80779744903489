
import React from 'react'

type Props = {
    languageID: string,
    onClick: () => void,
    className?: string,
    desktop?: boolean
    disabled?: boolean,
    totalPrice?: number,
    numOfItems?: number
}

const SubmitButton: React.FC<Props> = ({onClick, desktop, disabled,languageID, className, totalPrice, numOfItems}) => {

  const styleBasedOnDisabled = () => {
    if (disabled) {
      return `cursor-not-allowed rounded-md bg-[rgba(17,221,172,.8)] opacity-60 p-5 text-white text-center font-bold ${className}`
    }
    return `cursor-pointer rounded-md bg-[#11DDAC] p-5 text-center font-bold hover:opacity-90 transition-opacity ${className}`
  }

  return (
    <div
      id={desktop ? "desktop_submit_btn" : "submit_btn"}
      onClick={disabled ? () => {} : onClick}
      className={`flex flex-row ${styleBasedOnDisabled()}`}
    >
      <div className="flex items-center justify-center bg-white text-[#11DDAC] rounded-full w-6 h-6 mr-2">
        {numOfItems}
      </div>
      {/* Rest of the text */}
      <p id="submit-button-text" className="m-0 flex-1 flex justify-between text-white">
        <span>Bestellung ansehen</span>
        <span>{totalPrice ? totalPrice.toFixed(2): "0.00"} €</span>
      </p>
    </div>
  )
}

export default SubmitButton
