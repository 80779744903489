import React, { useCallback, useEffect, useState } from 'react'
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import * as translator from "../../../utils/translator";
import { useParams } from 'react-router-dom';
import xIcon from "../../../../src/icons/X.svg";
import KnownIssuesWindow from './KnownIssuesWindow';
import ContactPageForm from './ContactPageForm';
import { MainTracker } from '../../../PixelTrackers/MainTracker';


export const parseAllOrderTypesWithTranslations = (orderTypes: string[], language: string) => {
  let allOrderTypes = "";
  orderTypes.forEach((orderType, index) => {
    allOrderTypes += translator.translate(language, "contact", `modal/order_types/${orderType}`);
    if (index !== orderTypes.length - 1) {
      allOrderTypes += " + ";
    }
  });
  return allOrderTypes !== undefined ? allOrderTypes : orderTypes[0];
}

const sendMixPanelEvent = (eventCase: string, orderID: string, orderStatus: string) => {
  if (eventCase === DELIVERY_DELAY) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Delivery Delay', "Order ID" : orderID, "Order Status": orderStatus, "Delivery Delay" : "Delivery Delay - We can see that your order is on its way and it is delayed by more than 2 bussiness days." })
  }
  else if (eventCase === PHARMACY_DELAY) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Pharmacy Delay', "Order ID" : orderID, "Order Status": orderStatus, "Pharmacy Delay" : "The pharmacy is preparing your order for more than 1 Business day" });
  }
  else if (eventCase === DOCTOR_DELAY) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Doctor Delay', "Order ID" : orderID, "Order Status": orderStatus, "Doctor Delay" : "The Doctor is Checking your Questionnaire for more than 1 Business day" });
  }
  else if (eventCase === ITEM_IS_OUT_OF_STOCK) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Item is out-of-stock', "Order ID" : orderID, "Order Status": orderStatus, "Uten is out-of-stock" : "The medication you requested is out of stock" });
  }
  else if (eventCase === PENDING_PHARMACY_SELECTION) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Pending pharmacy selection', "Order ID" : orderID, "Order Status": orderStatus, "Pending pharmacy selection" : "No Known issues" });
  }
  else if (eventCase === PENDING_EXTERNAL_PHARMACY_CHOICE) {
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Pending external pharmacy selection', "Order ID" : orderID, "Order Status": orderStatus, "Pending external pharmacy selection" : "No Known issues" });
  }
  else{
    MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'No issue with order', "Order ID" : orderID, "Order Status": orderStatus, "No issue with order" : "No Known issues" });
  }
}

export type ContactPageModalData = {
orderHashID: string,
orderTypes: string[],
orderStatus: string,
lastUpdated: string,
shipmentTracking: string,
shipmentNumber: string,
pharmacyAdress: string,
pharmacyName: string,
pharmacyContact: string,
daysInStatus: number, 
productName: string,
case: string
}

type ContactPageModalProps = {
  orderData: ContactPageModalData;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DELIVERY_DELAY = "delivery_delay";
const PHARMACY_DELAY = "pharmacy_delay";
const DOCTOR_DELAY = "doctor_delay";
const ITEM_IS_OUT_OF_STOCK = "item_is_out_of_stock";
const PENDING_PHARMACY_SELECTION = "pending_pharmacy_selection";
const PENDING_EXTERNAL_PHARMACY_CHOICE = "pending_external_pharmacy_choice";
const WAIT_PRESCRIPTION_AFTER_PHARMACY_SELECTION = "wait_prescription_after_pharmacy_selection";
const NO_ISSUE_WITH_ORDER = "no_issue_with_order";

const ContactPageModal = ({orderData, setOpenModal} : ContactPageModalProps) => {
  const contactModalCase = orderData.case;
  const modalWithOrderType = [DOCTOR_DELAY, PENDING_PHARMACY_SELECTION,PENDING_EXTERNAL_PHARMACY_CHOICE,NO_ISSUE_WITH_ORDER,WAIT_PRESCRIPTION_AFTER_PHARMACY_SELECTION];
  const modalWithShipmentData = [DELIVERY_DELAY,NO_ISSUE_WITH_ORDER];
  const modalWithPharmacyData = [DELIVERY_DELAY,PHARMACY_DELAY,NO_ISSUE_WITH_ORDER,WAIT_PRESCRIPTION_AFTER_PHARMACY_SELECTION];
  const modalWithProductData = [ITEM_IS_OUT_OF_STOCK];
  const modalWithReason = [DELIVERY_DELAY, PHARMACY_DELAY, DOCTOR_DELAY, ITEM_IS_OUT_OF_STOCK, PENDING_PHARMACY_SELECTION];
  const [showForm, setShowForm] = useState(false);

  //mixpanel events

  useEffect(() => {
    sendMixPanelEvent(contactModalCase, orderData.orderHashID, orderData.orderStatus);
  },[])
  
  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);
  //static fields translations
  const orderStatus = translator.translate(language, "contact", `modal/order_status`);
  const orderType = translator.translate(language, "contact", `modal/order_type`);
  const pharmacyDetails = translator.translate(language, "contact", `modal/pharmacy_details`);
  const pharmacyContact = translator.translate(language, "contact", `modal/pharmacy_contact`);
  const shipmentLink = translator.translate(language, "contact", `modal/shipment_link`);
  const shipmentNumber = translator.translate(language, "contact", `modal/shipment_number`);
  const lastUpdated = translator.translate(language, "contact", `modal/last_updated`);
  const productName = translator.translate(language, "contact", `modal/product_name`);
  const okButton = translator.translate(language, "contact", "modal/ok_i_understand_button");
  const stillNeedHelpButton = translator.translate(language, "contact", "modal/i_still_need_help");
  
  

  const onClickMixPanelEvent = (event: React.MouseEvent<HTMLElement>) => {
    const eventName = event.currentTarget.id;
    let decision = "";
    if (eventName === "understand") {
      decision = "Okay, I understand";
    }
    else if (eventName === "still_need_help") {
      decision = "I still need help";
    }
    else {
      decision = "Close (click on X)";
    }
    MainTracker.track('custom', 'Decision on customer support pop-up', {"Decision": decision, "Order ID" : orderData.orderHashID, "Order Status": orderData.orderStatus, "Product name": orderData.productName});
  }


  let reasonContent = "";
  if (modalWithReason.includes(contactModalCase)) {
      reasonContent = translator.translate(language, "contact", `modal/${contactModalCase}/reason_content`);
  }

  let nextStepContent = "";
  if(contactModalCase !== NO_ISSUE_WITH_ORDER) {
    const nextStepContentTemp = translator.translate(language, "contact", `modal/${contactModalCase}/next_step_content`);
    if (nextStepContentTemp.includes("Pharmacy name")) {
      nextStepContent = nextStepContentTemp.replace("Pharmacy name", orderData.pharmacyName);
    }
    else {
      nextStepContent = nextStepContentTemp;
    }
  }

  const getInfoLine = useCallback((title: string, data: string, id: string, isLink: boolean = false, isMail: boolean = false) => { 
    return (
      <div id={id} className='flex flex-row flex-wrap items-start'>
        <p className='font-medium font-roboto text-neutral-700'>
          {title}:&nbsp;
        </p>
        {!isLink ? (<p className='text-neutral-600 font-normal break-words'>
          {data}
        </p>) :(
          <>
          <a id='contact-modal-tracking-link'
            href={isMail? `mailto:${data}`: data} 
            target='_blank'
            rel="noreferrer"> {isMail? data: "Link"} </a>
          </>
        )
        }
      </div>
    )
  },[orderData])


  return (
    <div id='background_div' className='bg-[#0D2C545C] bg-opacity-36 fixed z-[1000] w-full h-full flex items-center justify-center pointer-events-auto' onClick={() => setOpenModal(false)}>
      <div 
      id='contact-page-order-details-modal' 
      className='fixed flex md:w-[498px] md:max-h-[870px] md:h-fit flex-col bg-white shadow-md p-[20px] gap-[20px] rounded-tl-[30px] rounded-br-[30px] rounded-[6px] m-[20px] h-[95vh] min-w-0 overflow-hidden'
      onClick={(e) => {
        e.stopPropagation();
      }}
      >
        <div className='flex flex-row items-center'>
          <h3 className='text-[#0D2C54] font-roboto p-[10px] font-normal '>
            {translator.translate(language, "contact", "modal/order_details")} - {orderData.orderHashID}  
          </h3>

          <img id='contact-modal-close-button'
          onClick={(e) => {
              setOpenModal(false);
              onClickMixPanelEvent(e);
           }}
          className="ml-auto justify-end cursor-pointer h-fit " 
          src={xIcon} 
          alt="x" />
        </div>
        <hr className='w-[120%] self-center border-gray-300 border-t-0 border-b-1'/>

        {showForm ? (
          <div className='md:max-h-[870px] md:min-h-[520px] overflow-y-auto h-full'>
            <ContactPageForm toggleStickyNotification={()=> {}}/>
          </div>
        ): (
        <>
          <div id="info-lines" className='flex flex-col gap-4 p-[10px] !max-h-[520px] overflow-y-auto'>
            <KnownIssuesWindow issue={translator.translate(language, "contact", `modal/${contactModalCase}/issue_content`)} reasonContent={reasonContent} nextStepContent={nextStepContent} orderHashId={orderData.orderHashID}/>
            {getInfoLine(orderStatus, translator.translate(language, "my_account", `MyOrders/status/${orderData.orderStatus}`), "order-status-line")}

            {/* order type */}
            {modalWithOrderType.includes(contactModalCase) && getInfoLine(orderType, parseAllOrderTypesWithTranslations(orderData.orderTypes, language), "order-type-line")}

            {/* shipment data */}
            {(modalWithShipmentData.includes(contactModalCase) && orderData.shipmentTracking !== null) && getInfoLine(shipmentLink, orderData.shipmentTracking,"shipment-link-line" ,true)}
            {(modalWithShipmentData.includes(contactModalCase) && orderData.shipmentNumber !== null) && getInfoLine(shipmentNumber, orderData.shipmentNumber, "shipment-number-line")}

            {/* pharmacy data */}
            {(modalWithPharmacyData.includes(contactModalCase) && orderData.pharmacyName !== null && orderData.pharmacyAdress !== null) && getInfoLine(pharmacyDetails, orderData.pharmacyName + " " + orderData.pharmacyAdress, "pharmacy-details-line")}
            {(modalWithPharmacyData.includes(contactModalCase) && orderData.pharmacyName !== null && orderData.pharmacyAdress !== null) && getInfoLine(pharmacyContact, orderData.pharmacyContact, "pharmacy-contact-line",true, true)}

            {/* product data */}
            {(modalWithProductData.includes(contactModalCase)) && getInfoLine(productName, orderData.productName, "product-name-line")}


            {getInfoLine(lastUpdated, orderData.lastUpdated, "last-updated-line")}
          </div>

          <hr className='w-[120%] self-center border-gray-300 border-t-0 border-b-1'/>

          <div className='w-full !min-w-0 flex flex-col gap-2 p-[10px] md:flex-row'>
            <SubmitButton id={"understand"} placeholder={okButton} language={"de"} className={'!min-w-0 w-full border-[#11DDAC] border-[2px] !rounded-[6px]'} onSubmit={(e) => { setOpenModal(false); onClickMixPanelEvent(e) }}/>
            <SubmitButton id={"still-need-help"} placeholder={stillNeedHelpButton} language={"de"} className={'outlined !min-w-0 w-full border-[#11DDAC] border-[2px] !rounded-[3px]'} onSubmit={(e) => {setShowForm(true); onClickMixPanelEvent(e)} }/>
          </div>
        </>
        )}
      </div>
    </div>
  )
}

export default ContactPageModal
