import React, { useEffect, useState } from 'react'
import "./Cookiebot.css";
import * as translator from "../../utils/translator";
import RadioSelect from '../RadioSelect/RadioSelect';
import SubmitButton from '../SubmitButton/SubmitButton';
import * as GtmTracker from "../../PixelTrackers/GtmTracker";
import { MainTracker } from '../../PixelTrackers/MainTracker';
import { useLocationContext } from '../../App';

const Cookiebot = () => {
    let language = 'de'
    const LANGS = ['de', 'fr', 'en']
    LANGS.forEach(element => {
        
        let url = window.location.href;
        
        let LANG = new RegExp(`/${element}/|=(${element})`); //const LANG = new RegExp(`/${element}/|(${element})`);
    
        if (LANG.test(url)){language = element; return}
    });

    const { location } = useLocationContext();

    const save = translator.translate(language, "default", "Cookiebot/modal_save_button");
    const [showOptionsModal, setShowOptionsModal] = useState(false)
    const [createCookie, setCreateCookie] = useState(false)
    const [preferencesConsent, setPreferencesConsent] = useState(true)
    const [statisticsConsent, setStatisticsConsent] = useState(true)
    const [marketingConsent, setMarketingConsent] = useState(true)
    const [allowObject, setAllowObject] = useState({
        functionality: true,
        analytical: true,
        targeting: true
    })

    let customerID
    if (window.eventsTracking.getData("token")){
        customerID = window.eventsTracking.getData("customerID")
    }

    useEffect(() => {
        const params = window.eventsCommon.getFromUrl(window.location.href,'consent');
        if(params && !checkCookieExists('CookieConsent')){
            params[0] === '1' ? setPreferencesConsent('true') : setPreferencesConsent('false')
            params[1] === '1' ? setStatisticsConsent('true') : setStatisticsConsent('false')
            params[2] === '1' ? setMarketingConsent('true') : setMarketingConsent('false')
            setCreateCookie(true)
        }
        else if (!checkCookieExists('CookieConsent')) {
            showCookieBanner()
        }
        else{
            checkCookieAndSendEvents()
        }
    }, [location])

    useEffect(() => {
        if(createCookie == true || customerID){
           createCookieConsentCookie()
           checkCookieAndSendEvents() 
        }
    }, [createCookie, customerID])

    const checkCookieExists = (cookieName) => {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return true;
            }
        }
        return false;
    }

    const showCookieBanner = () => {
        var cookiebanner = document.getElementById("cookiebanner"),
            isLP = false,
            delay = 0;

        if (isLP) {
            delay = 3000;
        }

        setTimeout(function () {
            cookiebanner.style.bottom = "0"
            cookiebanner.style.display = "flex";
        }, delay);
    }

    const hideCookieBanner = () => {
        setTimeout(function () {
            var cookiebanner = document.getElementById("cookiebanner");
            cookiebanner.style.display = "none";
        })
    }

    const createCookieConsentCookie = () => {
        let value = `necessary:true,preferences:${preferencesConsent},statistics:${statisticsConsent},marketing:${marketingConsent},method:explicit`
        window.eventsStorage.addToCookie('CookieConsent', value ,365);
        window.eventsTracking.track({e:'Cookie Consent Created',p:{'Cookie preferences': preferencesConsent, 'Cookie statistics': statisticsConsent, 'Cookie marketing': marketingConsent}});
        hideCookieBanner()
    }

    const updateAllowObjectField = (field) => {

        let allowObjectCopy = JSON.parse(JSON.stringify(allowObject));
        allowObjectCopy[field] = !allowObjectCopy[field]
        setAllowObject(allowObjectCopy)

        switch (field) {
            case 'functionality':
                setPreferencesConsent(!preferencesConsent)
            break;

            case 'analytical':
                setStatisticsConsent(!statisticsConsent)
            break;

            case 'targeting':
                setMarketingConsent(!marketingConsent)
            break;
        
            default:
                break;
        }
    }

    const checkCookieAndSendEvents = () =>{
        let cookieConsent = window.eventsStorage.getFromCookie('CookieConsent');
        cookieConsent = decodeURIComponent(cookieConsent)
        if (cookieConsent.includes('preferences:true')) {
            // MainTracker.track('custom', 'cookie_consent_preferences')
        }
        if (cookieConsent.includes('statistics:true')) {
            // MainTracker.track('custom', 'cookie_consent_statistics')
        }
        if (cookieConsent.includes('marketing:true')) {
            GtmTracker.track('custom', 'cookie_consent_marketing')
        }
    }

    return (
        <>

            <div id="cookiebanner" className="cookieAcceptBar white" style={ showOptionsModal ? {display:'none',padding:'0px' } : { display: 'none', padding:'30px' } }>
                {
                    !showOptionsModal && (
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8" id="agreement">
                                    <div className="agreement-info">
                                        <div className="title">{translator.translate(language, "default", "Cookiebot/title")}</div>
                                        {translator.translate(language, "default", "Cookiebot/text")}
                                        <a href={translator.translate(language, "default", "Cookiebot/link")} className="manage-preferences agreement-info" style={{ margin: '0 auto' }}>{translator.translate(language, "default", "Cookiebot/details")}</a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div>
                                        <button id="btn_accept_cookies" className="btn-accept-cookies" onClick={() => {setCreateCookie(true); window.eventsTracking.track({e: "Accept All Cookies"})}} type="button">{translator.translate(language, "default", "Cookiebot/allow_all")}</button>
                                    </div>
                                    <div className="manage-preferences"
                                        onClick={() => {
                                            setShowOptionsModal(true);
                                            window.eventsTracking.track({e: "Click Cookie Settings"})
                                        }}
                                    >
                                        {translator.translate(language, "default", "Cookiebot/options")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    showOptionsModal && (
                        <div className="cookiePreferencesModal-card">
                            <div className='content'>
                                <div id='close-button' onClick={()=>{setShowOptionsModal(false)}}>✖</div>
                                <div id='title'>{translator.translate(language, "default", "Cookiebot/modal_title")}</div>
                                <p id='text'>{translator.translate(language, "default", "Cookiebot/modal_text")}</p>
                                <a href='' id='link'>{translator.translate(language, "default", "Cookiebot/modal_policy")}</a>
                                <table className="frame-table">
                                <tbody>
                                    <tr>
                                        <th>{translator.translate(language, "default", "Cookiebot/modal_th_type")}</th>
                                        <th>{translator.translate(language, "default", "Cookiebot/modal_th_allow")}</th>
                                        <th>{translator.translate(language, "default", "Cookiebot/modal_th_explanation")}</th>
                                    </tr>
                                    <tr>
                                        <td>{translator.translate(language, "default", "Cookiebot/modal_type_necessary")}</td>
                                        <td>{translator.translate(language, "default", "Cookiebot/modal_allow_necessary")}</td>
                                        <td className='explanation'>{translator.translate(language, "default", "Cookiebot/modal_explanation_necessary")}</td>
                                    </tr>
                                    <tr>
                                        <td>{translator.translate(language, "default", "Cookiebot/modal_type_functionality")}</td>
                                        <td><div className='allow-cell' onClick={() => { updateAllowObjectField('functionality') }}><RadioSelect selected={allowObject.functionality} /><span>{translator.translate(language, "default", "Cookiebot/modal_allow_all")}</span></div></td>
                                        <td className='explanation'>{translator.translate(language, "default", "Cookiebot/modal_explanation_functionality")}</td>
                                    </tr>
                                    <tr>
                                        <td>{translator.translate(language, "default", "Cookiebot/modal_type_analytical")}</td>
                                        <td><div className='allow-cell' onClick={() => { updateAllowObjectField('analytical') }}><RadioSelect selected={allowObject.analytical} /><span>{translator.translate(language, "default", "Cookiebot/modal_allow_all")}</span></div></td>
                                        <td className='explanation'>{translator.translate(language, "default", "Cookiebot/modal_explanation_analytical")}</td>
                                    </tr>
                                    <tr>
                                        <td>{translator.translate(language, "default", "Cookiebot/modal_type_targeting")}</td>
                                        <td><div className='allow-cell' onClick={() => { updateAllowObjectField('targeting') }}><RadioSelect selected={allowObject.targeting} /><span>{translator.translate(language, "default", "Cookiebot/modal_allow_all")}</span></div></td>
                                        <td className='explanation'>{translator.translate(language, "default", "Cookiebot/modal_explanation_targeting")}</td>
                                    </tr>

                                </tbody>    
                                </table>
                            </div>
                            <div id='footer-banner'>
                                <div onClick={() => { 
                                    setCreateCookie(true)
                                    MainTracker.track('click', 'Click Save Cookie Settings')
                                    }}>
                                    <SubmitButton placeholder={save} minWidth={30} height={'45px'} />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default Cookiebot