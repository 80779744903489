import React, { useCallback, useContext, useMemo } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import activeLogo from "../../../../icons/Active.svg";
import { formatNumberByLocale } from '../../../../utils/translator';
import { MainTracker } from '../../../../PixelTrackers/MainTracker';
import ProductAvailabilityTag from '../../FreeDosageChooseTreatment/components/ProductAvailabilityTag';
import * as translator from "../../../../utils/translator";

interface QuantityOptionProps {
    quantity: number,
    productItemID: number,
    inStock: boolean,
    price: number,
    isColLayout: boolean,
    customQuantity?: string,
    sparedCost: number,
    disabled?: boolean,
    isResponsiveLayout?: boolean
}
const QuantityOption: React.FC<QuantityOptionProps> = ({ quantity, productItemID, inStock, disabled, price, isColLayout, customQuantity, isResponsiveLayout, sparedCost }) => {

    const context = useContext(ReturingCustomersLayoutContext);
    const isSelected = context?.selectedProductItemID === productItemID && !disabled;
    const selectedProduct = context?.selectedProduct;
    const productItemToPlan = context?.productItemToPlan;
    const useCustomQuantity = selectedProduct?.use_custom_quantity || context?.countryID.toUpperCase() === "FR";
    const spareTranslation = translator.translate(
        context?.languageID,
        "default",
        "ChooseTreatment/spare"
    )

    const quantityType = customQuantity?.slice(customQuantity?.indexOf(" ") + 1)

    const onSelect = useCallback(() => {
        if (context?.selectedProductItemID !== productItemID) {
            context?.setSelectedProductItemID(productItemID);
            context?.setSelectedSubscriptionPlanID(null);
            MainTracker.superProperties({
                "Product Quantity": quantity,
            });
            window.eventsTracking.track({ e: "Click change Product Quantity" });

            if (productItemToPlan) {
                const plans = productItemToPlan[productItemID]
                if (plans) {
                    context?.setSelectedSubscriptionPlanID(plans[0]);
                } else {
                    context?.setSelectedSubscriptionPlanID(null);
                }
            }
        }

    }, [context, productItemID, productItemToPlan]);

    const style = useMemo(() => {
        let css = 'max-h-[120px] p-10 relative rounded-md flex items-center justify-center';
        if (!disabled) {
            css += ' cursor-pointer';
        } else {
            css += ' cursor-not-allowed bg-[#f0eded]';
        }

        if (isSelected) {
            css += ' bg-[#e7fcf7] text-[#0d2c54]';
        } else {
            css += ' text-[#808080]';
        }

        if (isColLayout) {
            css += ' flex-row justify-between items-center h-[60px] p-5';
        } else {
            css += ' flex-col items-center justify-center';
        }

        return css
    }, [inStock, isSelected, isColLayout])

    return (
        <div>
            <div id={"product_item_" + productItemID.toString() + (isResponsiveLayout ? "_responsive" : "")} style={
                {
                    border: isSelected ? "1px solid #11ddac" : "1px solid #e0e0e0",
                }
            } onClick={!disabled ? onSelect : () => { }} className={style}>
                {isSelected && <img className='check-mark' src={activeLogo} alt="" />}

                {useCustomQuantity || isColLayout ? <>
                    <p className='font-medium text-md'>{customQuantity}</p>
                </> :
                    <div className={isColLayout ? 'flex flex-row gap-2 items-end' : ''}>
                        <p className={`font-medium text-lg `}>{quantity}</p>
                        {quantityType && <p className={isColLayout ? 'text-md' : 'text-xs flex justify-center'}>{quantityType}</p>}
                    </div>
                }

                <div id='availability-tag-and-price' className='flex  items-center gap-2'>
                    {
                        context?.hidePrices || (context?.isFR && !inStock) ?  null : <p className={`${isColLayout ? 'text-md' : 'text-xs'} flex text-nowrap items-center font-medium `}>{formatNumberByLocale(price, context?.localeID)} {context?.currencySign}</p> 
                    }

                    {
                        context?.isFR ? <ProductAvailabilityTag inStock={inStock} /> : null
                    }
                </div>

            </div>
            {sparedCost <= 0 || (context?.hidePrices || (context?.isFR && !inStock)) ? null : <p id='saved-price' className='text-xs mt-1 flex items-center justify-center text-[#656d79]'>{spareTranslation} { formatNumberByLocale(sparedCost, context?.localeID)} {context?.currencySign}</p>}
        </div>
    )
}

export default QuantityOption
