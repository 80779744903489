
let env = process.env.REACT_APP_ENV
let configByEnv = require(`../resources/config/${env}.json`)
export const clientConfig = {
    ENV: env,
    API_URL: process.env.REACT_APP_API_URL,
    SEARCH_URL : process.env.REACT_APP_SEARCH_URL,
    IMAGES_URL : process.env.REACT_APP_IMAGES_URL,
    MAIN_WEBSITE_URL : process.env.REACT_APP_MAIN_WEBSITE_URL,
    FUNNEL_URL : process.env.REACT_APP_FUNNEL_URL,
    MIXPANEL_TOKEN : process.env.REACT_APP_MIXPANEL_TOKEN,
    MIXPANEL_HOST : process.env.REACT_APP_MIXPANEL_HOST,
    FB_PIXEL_ID : process.env.REACT_APP_FB_PIXEL_ID,
    BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY,
    GOOGLE_PLACES_API_KEY : process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    GTM_ID : process.env.REACT_APP_GTM_ID,
    VERSION: process.env.REACT_APP_VERSION,
    TRUSTED_SHOPS_KEY_DE: process.env.REACT_APP_TRUSTED_SHOPS_KEY_DE,
    TRUSTED_SHOPS_KEY_FR: process.env.REACT_APP_TRUSTED_SHOPS_KEY_FR,
    BYTEPLANT_API_KEY: process.env.REACT_APP_BYTEPLANT_API_KEY,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE,
    RECAPTCHA_SECRET_KEY: process.env.REACT_APP_RECAPTCHA_SECRET,
    IS_RECAPTCHA_ENABLED: process.env.REACT_APP_IS_RECAPTCHA_ENABLED && process.env.REACT_APP_IS_RECAPTCHA_ENABLED.toLowerCase() === "true" ,
    IS_UNDER_MAINTENANCE: process.env.REACT_APP_IS_UNDER_MAINTENANCE,
    PRESCRIPTION_IMAGE: process.env.REACT_APP_PRESCRIPTION_IMAGE,
    PRODUCT_BOX: process.env.REACT_APP_PRODUCT_BOX,
    ONESIGNAL_APP_ID: process.env.REACT_APP_ONESIGNAL_APP_ID,
    SKIP_ADRRESS_VALIDATION: process.env.REACT_APP_SKIP_ADRRESS_VALIDATION,
    ...configByEnv
} 
