import "./CheckoutRegister.css";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import HeaderController from "../../components/Headers/HeaderController";
import * as translator from "../../utils/translator";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import * as http from "../../utils/http";
import { useEffect, useState } from "react";
import OrderWindow from "../../components/OrderWindow/OrderWindow";
import LoginModal from "../../components/Modals/LoginModal/LoginModal";
import StickyNotification from "../../components/StickyNotification/StickyNotification";
import { clientConfig } from "../../config";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../components/ServerError/ServerError";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import ForgotPasswordModal from "../../components/Modals/ForgotPasswordModal/ForgotPasswordModal";
import Footer from "../../components/Footer/Footer";
import { useCartContext } from "../../customHooks/useCart";
import CartView from "../../components/CartView";


export default function CheckoutRegister() {
  const navigate = useNavigate();
  const { country, language } = useParams();
  const { getCart, saveCart } = useCartContext();
  const cart = getCart();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showForgotPasswordModal, setShowForgotPassword] = useState(false);
  const [orderWindowDetails, setOrderWindowDetails] = useState({});
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level: "",
    showInfoIcon: true,
    refresh: false,
  });



  const navigationCallback = (pathname, options) => {
    navigate({
      pathname,
      search: createSearchParams(options).toString(),
    },);
  };

  useEffect(() => {

    http
      .getFromServer(`checkout_register/${country}/${language}`, [http.MIDDLEWARES.CART_ORDER])

      .then((response) => {

        const responseCart = response.data.cart

        //New multiple rx funnel flow
        if (["funnel_new_multiple", "shipping_multiple_premium"].includes(responseCart.funnel_version)) {
          saveCart({
            ...responseCart,
            products: response.data.products
          })

        //Backwards compatibility
        } else {
          setOrderWindowDetails({
            itemImageUrl: clientConfig.IMAGES_URL + response.data.productFeatures.imageSuffix,
            itemTitle: response.data.productFeatures.title,
            itemQuantity: response.data.productFeatures.quantity,
            treatmentFee: Number(responseCart.treatment_fee),
            Price: Number(responseCart.item_price),
            serviceFee: response.data.productFeatures.service_fee,
            isSubsidized: response.data.productFeatures.is_subsidized,
            subscriptionDiscount: responseCart.applied_subscription_discount,
            autoBonusValue: Number(responseCart.auto_bonus) === 1 ? Number(responseCart.bonus_value) : 0,
            prescriptionOnlyFlow: responseCart.allow_only_prescription && !responseCart.product_in_stock,
            isSelfPickup: responseCart.self_pickup_pharmacy_id ? true : false
          });
        }

        MainTracker.superProperties({ "Treatment fee": responseCart.treatment_fee });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.data?.logLevel === "warn") {
          window.clientLogger.warn("error get data from server checkout register", err)
        }
        else {
          window.clientLogger.error("error get data from server checkout register", err)
        }

        setError(500);
        setLoading(false);
      });

  }, []);


  return (
    <>
      {loading && <LoadingModal />}

      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}

      {!error && !loading && (
        <>
          <StickyNotification
            showNotification={stickyNotificationDetails.show}
            title={stickyNotificationDetails.title}
            onClick={() => setStickyNotificationDetails((currentDetails) => { return { ...currentDetails, show: false } })}
            content={stickyNotificationDetails.content}
            level={stickyNotificationDetails.level}
            showInfoIcon={stickyNotificationDetails.showInfoIcon}
            refresh={stickyNotificationDetails.refresh}
          />

          {showLoginModal && (
            <LoginModal
              setStickyNotificationDetails={setStickyNotificationDetails}
              closeModal={() => setShowLoginModal((current) => !current)}
              navigationCallback={(exceededCategoryLimit, mention) => {
                if (exceededCategoryLimit) {
                  navigationCallback(`/select_treatment/${country}/${language}/${mention}`, { limit_disclaimer: true })
                } else {
                  navigationCallback(`/shipping/${country}/${language}`)
                }
              }
              }
              showForgotPassword={showForgotPasswordModal}
              changeShowForgotPassword={(boolValue) => { setShowForgotPassword(boolValue) }}
              language={language}
            />
          )}

          {showForgotPasswordModal && (
            <ForgotPasswordModal
              closeModal={() => setShowForgotPassword(false)}
              navigationCallback={() =>
                navigationCallback(`/shipping/${country}/${language}`)
              }
              language={language}
            />
          )}

          <HeaderController language={language} stage="Payment" />
          <div className="checkout-register-body">
            <div className="checkout-register-container">
              <div className="orderWindow-container-upper">
                {
                  ["funnel_new_multiple", "shipping_multiple_premium"].includes(cart?.funnel_version) ?
                    <CartView 
                      showProductsQuantityControl={false} 
                      hideSubscriptionPlans 
                      className="lg:hidden mb-[30px]" />
                    :
                    <OrderWindow
                      shippingFee={0}
                      itemImageUrl={orderWindowDetails.itemImageUrl}
                      itemTitle={orderWindowDetails.itemTitle}
                      Price={orderWindowDetails.Price}
                      serviceFee={orderWindowDetails.serviceFee}
                      isSubsidized={orderWindowDetails.isSubsidized}
                      itemQuantity={orderWindowDetails.itemQuantity}
                      treatmentFee={orderWindowDetails.treatmentFee}
                      subscriptionDiscount={orderWindowDetails.subscriptionDiscount}
                      autoBonusValue={orderWindowDetails.autoBonusValue}
                      prescriptionOnlyFlow={orderWindowDetails.prescriptionOnlyFlow}
                    />
                }
              </div>


              <div className="checkout-register-headers">
                <p className="checkout-register-mainTitle">
                  {translator.translate(
                    language,
                    "default",
                    "RegistrationForm/title"
                  )}
                </p>
                <span
                  onClick={() => setShowLoginModal((current) => !current)}
                  className="checkout-register-loginTitle"
                >
                  {translator.translate(
                    language,
                    "default",
                    "RegistrationForm/login_title"
                  )}{" "}
                  <span className="checkout-register-loginTitle-loginhere">
                    {" "}
                    {translator.translate(
                      language,
                      "default",
                      "RegistrationForm/login_here"
                    )}{" "}
                  </span>
                </span>
              </div>

              <div className="checkout-register-form-and-order-container">
                <RegistrationForm
                  navigationCallback={() =>
                    navigationCallback(`/shipping/${country}/${language}`)
                  }
                  setStickyNotificationDetails={setStickyNotificationDetails}
                  submitButtonPlaceholder={translator.translate(
                    language,
                    "default",
                    "RegistrationForm/submit_button_text"
                  )}
                />

                <div className="orderWindow-container-bottom">
                  {
                    ["funnel_new_multiple", "shipping_multiple_premium"].includes(cart?.funnel_version) ?
                      <CartView 
                        hideSubscriptionPlans
                        showProductsQuantityControl={false}
                        className="max-lg:hidden lg:w-[400px]"
                      />
                      
                      :
                      <OrderWindow
                        shippingFee={0}
                        itemImageUrl={orderWindowDetails.itemImageUrl}
                        itemTitle={orderWindowDetails.itemTitle}
                        Price={orderWindowDetails.Price}
                        serviceFee={orderWindowDetails.serviceFee}
                        isSubsidized={orderWindowDetails.isSubsidized}
                        itemQuantity={orderWindowDetails.itemQuantity}
                        treatmentFee={orderWindowDetails.treatmentFee}
                        subscriptionDiscount={orderWindowDetails.subscriptionDiscount}
                        autoBonusValue={orderWindowDetails.autoBonusValue}
                        prescriptionOnlyFlow={orderWindowDetails.prescriptionOnlyFlow}
                      />
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer page="CheckoutRegister" language={language} />
        </>
      )}
    </>
  );
}
